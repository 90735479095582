import { Skeleton } from '@travelpass/design-system'
import { ProgressCircleLoading } from 'src/common/components'

export const DashboardAchievementsOldLoading = () => (
  <div className='space-y-4'>
    <div className='h-5.25 w-15 lg:h-6'>
      <Skeleton />
    </div>
    <div className='lg:h-4.5 h-4 w-10'>
      <Skeleton />
    </div>
    <div className='flex flex-row gap-6'>
      <div>
        <ProgressCircleLoading />
        <div className='m-a h-4 w-10'>
          <Skeleton />
        </div>
      </div>
      <div>
        <ProgressCircleLoading />
        <div className='m-a h-4 w-10'>
          <Skeleton />
        </div>
      </div>
      <div>
        <ProgressCircleLoading />
        <div className='m-a h-4 w-10'>
          <Skeleton />
        </div>
      </div>
    </div>
  </div>
)
