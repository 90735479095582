import { Skeleton } from '@travelpass/design-system'

export const GuideDraftTopNavMenuLoading = () => (
  <div className='flex flex-row items-center gap-8'>
    <div className='hidden flex-row items-center gap-16 lg:flex'>
      <div className='w-30 h-8'>
        <Skeleton variant='rounded' />
      </div>
      <div className='h-8 w-32'>
        <Skeleton variant='rounded' />
      </div>
    </div>
    <div className='h-12 w-12'>
      <Skeleton variant='rounded' />
    </div>
  </div>
)
