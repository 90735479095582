import { gql } from 'src/__generated__'

const currentUserHotelBookingsGQL = gql(` 
query currentUserHotelBookingsQuery($after: String, $first: Int, $searchUserHotelBookingsArgs: SearchUserHotelBookingsArgs!) {
  currentUser {
    id
    hotelBookings(after: $after, first: $first, searchUserHotelBookingsArgs: $searchUserHotelBookingsArgs) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          arrival
          departure
          hotelId
          hotelName
          status
          bookingDetails {
            occupancies {
              adults
              childAges
            }
          }
        }
      }
    }
  }
}
`)

const currentUserHotelBookingsHotelImageGQL = gql(` 
query currentUserHotelBookingsHotelImageQuery($hotelId: ID!) {
    standardHotel(id: $hotelId, idType: STANDARD) {
      id
      imageLinks
    }
  }
`)

export { currentUserHotelBookingsGQL, currentUserHotelBookingsHotelImageGQL }
