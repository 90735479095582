import type { ApolloError } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import type { GetGuideEventsQueryInGuideDetailsOldQueryVariables } from 'src/__generated__/graphql'
import type { GuideEventsOldData } from './types'

const getGuideQuery = gql(`
  query GetGuideEventsQueryInGuideDetailsOld($id: ID!) {
    getGuide(id: $id) {
      id
      groupedEvents {
        id
        description
        events {
          id
          addresses {
            id
            addressLine1
            city
            country
            googlePlaceId
            lat
            long
            state
          }
          imageUrl
          name
          isCollected
          notes
          type
        }
        name
      }
      publishedEventCategories {
        id
        description
        name
      }
    }
  }
`)

type UseGetGuideEventsOldQuery = {
  guideEventsData: GuideEventsOldData
  hasError: ApolloError
  isLoading: boolean
}

export const useGetGuideEventsOldQuery = (
  guideId: GetGuideEventsQueryInGuideDetailsOldQueryVariables['id']
): UseGetGuideEventsOldQuery => {
  const guideEventsResult = useQuery(getGuideQuery, {
    skip: !guideId,
    variables: {
      id: guideId,
    },
  })

  return {
    guideEventsData: guideEventsResult?.data?.getGuide,
    hasError: guideEventsResult?.error,
    isLoading: guideEventsResult?.loading,
  }
}
