import { ProgressCircle } from 'src/common/components'
import { getDashboardAchievementsOldProgressValue } from './dashboardAchievementsUtils'

interface DashboardAchievementsOldProps {
  numberOfCreatedGuides: number
}

export const DashboardAchievementsOld = ({
  numberOfCreatedGuides,
}: DashboardAchievementsOldProps) => {
  /** @todo should be handled on the BE. */
  const firstValue = getDashboardAchievementsOldProgressValue(
    numberOfCreatedGuides,
    1
  )
  /** @todo should be handled on the BE. */
  const secondValue = getDashboardAchievementsOldProgressValue(
    numberOfCreatedGuides,
    3
  )
  /** @todo should be handled on the BE. */
  const thirdValue = getDashboardAchievementsOldProgressValue(
    numberOfCreatedGuides,
    5
  )

  return (
    <section className='shadow-3 rounded-3 space-y-4 bg-white p-3'>
      <header className='flex flex-row gap-1'>
        <div className='min-w-0 grow space-y-4'>
          <h3 className='c-black type-h3-mobile type-h4-desktop'>
            Achievements
          </h3>
          <p className='c-grey-800 type-body-2'>
            {numberOfCreatedGuides} Total Guides Created
          </p>
        </div>
      </header>
      <div className='flex flex-row gap-6'>
        <div>
          <ProgressCircle
            ariaLabel={`${firstValue === 1 ? '1 guide has been created' : 'No guides have been created'}`}
            label='1'
            value={firstValue}
          />
          <p className='c-grey-800 type-small-text-desktop text-center'>
            {numberOfCreatedGuides < 1 ? '0 Guides' : '1 Guide'}
          </p>
        </div>
        <div>
          <ProgressCircle
            ariaLabel={`${secondValue >= 1 ? '3 guides have been created' : 'Less than 3 guides have been created'}`}
            label='3'
            value={secondValue}
          />
          {numberOfCreatedGuides > 1 && (
            <p className='c-grey-800 type-small-text-desktop text-center'>
              {numberOfCreatedGuides < 3 ? '2 Guides' : '3 Guides'}
            </p>
          )}
        </div>
        <div>
          <ProgressCircle
            ariaLabel={`${thirdValue >= 1 ? '5 or more guides have been created' : 'Less than 5 guides have been created'}`}
            label='5+'
            value={thirdValue}
          />
          {numberOfCreatedGuides > 3 && (
            <p className='c-grey-800 type-small-text-desktop text-center'>
              {numberOfCreatedGuides < 5
                ? `${numberOfCreatedGuides} Guides`
                : '5+ Guides'}
            </p>
          )}
        </div>
      </div>
    </section>
  )
}
