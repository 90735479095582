import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

export const ENTER_COMPETITION_MUTATION = gql(`
  mutation EnterCompetition($input: EnterCompetitionInput!) {
    enterCompetition(input: $input){
      userProfileCompetition {
        id
        enteredCompetition
      }
    }
  }
`)

export const useEnterCompetitionMutation = () =>
  useMutation(ENTER_COMPETITION_MUTATION)
