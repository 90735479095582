import { useEffect } from 'react'
import { isProdEnv } from 'src/utils/environmentUtils'
import { lucencyNumber } from './lucencyUtils'

export const useVwoUserData = () => {
  useEffect(() => {
    if (isProdEnv) {
      // @ts-ignore
      window?.VWO?.push([
        'onEventReceive',
        'vA',
        function (data) {
          const experimentId = data[1]
          const variationId = data[2]
          //@ts-ignore
          const abTestName = _vwo_exp[experimentId].name
          //@ts-ignore
          const variationName = _vwo_exp[experimentId].comb_n[variationId]

          if (
            //@ts-ignore
            typeof _vwo_exp[experimentId].comb_n[variationId] !== 'undefined' &&
            ['VISUAL_AB', 'VISUAL', 'SPLIT_URL', 'SURVEY'].indexOf(
              //@ts-ignore
              _vwo_exp[experimentId].type
            ) > -1
          ) {
            lucencyNumber({
              userVwoExperiments: {
                experimentId,
                variationId,
                abTestName,
                variationName,
              },
            })
          }
        },
      ])
    }
  }, [])
}
