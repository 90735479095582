import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { gql } from 'src/__generated__'
import { IdType } from 'src/__generated__/graphql'
import { BookingDetailsManage } from './BookingDetailsManage'
import type {
  BookingDetailsHotel,
  BookingDetailsNode,
} from './bookingDetailsManageTypes'
import { useBookingDetailsIdsQuery } from '../../hooks'

const { Standard } = IdType

const nodeQuery = gql(`
  query NodeQueryInBookingDetailsManage(
    $hotelId: ID!
    $hotelIdType: IdType!
    $nodeId: ID!
  ) {
    node(id: $nodeId) {
      id
      ... on Booking {
        id
        email
      }
    }
    standardHotel(id: $hotelId, idType: $hotelIdType) {
      id
      phoneNumbers
    }
  }
`)

export const BookingDetailsManageApollo = () => {
  const { bookingId } = useParams()
  const { bookingDetailsIdsData } = useBookingDetailsIdsQuery({
    bookingId,
  })
  const { hotelId } = bookingDetailsIdsData ?? {}
  const willSkip = !bookingId || !hotelId

  const { data, error: hasError } = useQuery(nodeQuery, {
    skip: willSkip,
    variables: {
      hotelId,
      hotelIdType: Standard,
      nodeId: bookingId,
    },
  })

  const { node, standardHotel } = data ?? {}
  const bookingDetailsData = node as BookingDetailsNode
  const bookingDetailsHotelData = standardHotel as BookingDetailsHotel

  if (hasError) return

  return (
    <BookingDetailsManage
      bookingDetailsData={bookingDetailsData}
      bookingDetailsHotelData={bookingDetailsHotelData}
    />
  )
}
