import { ButtonLink } from 'src/common/components'
import { AddToCollectionsModalListener } from 'src/common/components/Collections/AddToCollectionsModalListener'
import { AddToGuideModalListener } from 'src/common/components/Guides/AddToGuideModalListener'
import { useFlag } from 'src/common/hooks'
import { FeaturedCities } from './Cities'
import { Experiences } from './Experiences'
import { FindYourNextDestination } from './FindYourNextDestination'
import { Guides } from './Guides'
import { Hotels } from './Hotels'
import { SearchHero } from './SearchHero'

export const Search = () => {
  const enablePlanPage = useFlag('enablePlanPage')

  return (
    <div>
      <SearchHero />
      {enablePlanPage ? <FeaturedCities /> : <Guides />}
      <div className='space-y-22 md:space-y-32 lg:space-y-44'>
        {!enablePlanPage && (
          <div className='text-center'>
            <ButtonLink
              className='mx-auto w-fit'
              to='/guides/all'
              variant='outline'
            >
              Explore more guides
            </ButtonLink>
          </div>
        )}
        <Hotels />
        <Experiences />
        <FindYourNextDestination />
      </div>
      <AddToCollectionsModalListener />
      <AddToGuideModalListener />
    </div>
  )
}
