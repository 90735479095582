import { useState } from 'react'
import { Button, Illustration } from '@travelpass/design-system'
import { GuideDraftCreateModalOld } from 'src/pages/guides/lists-old/common'

export const DashboardGuideDraftsEmpty = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const onModalClose = () => setIsModalOpen(false)

  const onModalOpen = () => setIsModalOpen(true)

  return (
    <>
      <section className='space-y-10'>
        <div className='flex flex-row items-center justify-between'>
          <h2 className='type-h3'>My Guides</h2>
          <Button
            aria-label='Create a guide'
            startIcon='add'
            onClick={onModalOpen}
          >
            New Guide
          </Button>
        </div>
        <div className='bg-warm-grey p-x-11 p-t-18.5 p-b-11 rounded-3 b-1 b-solid b-canyon-light'>
          <div className='flex flex-row items-center'>
            <div className='space-y-5'>
              <div className='m-a block w-64 md:hidden'>
                <Illustration name='jeep' />
              </div>
              <h3 className='c-forest-light type-h3'>Create a Guide</h3>
              <p className='type-body-1'>
                Create guides of your favorite places and activities. Organize
                them by city, interests, or your own unique theme!
              </p>
              <div className='m-a flex flex-row justify-center md:block'>
                <Button
                  aria-label='Create a guide'
                  size='small'
                  startIcon='add'
                  variant='text'
                  onClick={onModalOpen}
                >
                  Create Your First Guide
                </Button>
              </div>
            </div>
            <div className='hidden min-w-64 md:block'>
              <Illustration name='jeep' />
            </div>
          </div>
        </div>
      </section>
      {isModalOpen && <GuideDraftCreateModalOld onDismiss={onModalClose} />}
    </>
  )
}
