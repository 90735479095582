import { useState } from 'react'
import { Button, ModalNext } from '@travelpass/design-system'

export const TermsAndConditionsModal = () => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <Button variant='text' onClick={() => setIsOpen(true)}>
        See terms and conditions
      </Button>
      {isOpen && (
        <ModalNext
          size='large'
          title='TERMS AND CONDITIONS FOR “Travelpass Ultimate Job Share” CAMPAIGN'
          onClose={() => setIsOpen(false)}
        >
          <ol className='list-decimal'>
            <li>
              These terms and conditions of application, including information
              on how to participate and job details (Terms), apply to the
              Campaign and application into the Campaign is deemed acceptance of
              these Terms.
            </li>
            <li>
              Submission into the Campaign is also deemed as acceptance of the
              Travelpass&apos;s Website Terms and Conditions, which can be
              viewed at https://ultimatejob.travelpass.com.
            </li>
            <li>
              The Campaign is being organized and managed by Travelpass, which
              is a part of Travelpass Group, located at 4700 W Daybreak Pkwy Ste
              100N, South Jordan, UT 84009. Travelpass and the Company are used
              synonymously in these terms and conditions.
            </li>
            <li>
              Partners in the campaign include numerous tourism organizations,
              airlines, accommodations, attractions, and restaurants.
            </li>
            <li>
              The initial Submission portion of the campaign will be open on
              August 20, 2024 at 9:00am Mountain Standard Time and closes on
              September 22, 2024, at 11:59PM Mountain Standard Time. (Campaign
              Period). Applicants will need to calculate their local start and
              close dates and times based on the time zone in their location.
            </li>
            <li>
              Submission into the Campaign gives eligible applicants the chance
              to become Travelpass&apos; Chief Hospitality Officer (CHO)
            </li>
            <li>
              Submission into the Campaign is open to individuals aged 18 years
              and over as of August 20, 2024, who are able to obtain an
              appropriate visa that will allow them to work in both the United
              States excluding: management, employees, directors and contractors
              of Travelpass, its related entities and other agencies, firms or
              companies associated with the Campaign (including suppliers of
              jobs); and a spouse, de-facto spouse, parent, guardian, child or
              sibling of a person referred to in paragraph 7(a) of these Terms.
            </li>
          </ol>
          <p>
            More information on U.S. Visa eligibility can be viewed at
            https://travel.state.gov/content/visas/en/employment.html
          </p>
          <h2>Travelpass CHO QUALIFICATIONS & CRITERIA FOR SELECTION</h2>
          <p>
            Chief Hospitality Officer applicants and finalists (whether in teams
            or as individuals) will be selected based on qualifications
            including but not limited to:
          </p>
          <ol className='list-decimal'>
            <li>
              Content, creativity and originality of candidate’s video
              application.
            </li>
            <li>
              Proficiency with social media platforms (Facebook, Instagram,
              TikTok, Twitter, etc.)
            </li>
            <li>
              Technical demonstration of digital photography and video skills.
            </li>
            <li>Travelpass Guide creation abilities</li>
            <li>Knack for crafting stories through various mediums.</li>
            <li>
              Ability to authentically engage, sustain, and mobilize audiences.
            </li>
            <li>Exhibited, positive self-motivation.</li>
            <li>Displayed comfort and ease during media interviews.</li>
            <li>
              Acts responsibly and demonstrates integrity and good moral
              character.
            </li>
            <li>
              Ability to withstand physical demands of extensive travel (with
              reasonable accommodations being provided).
            </li>
            <li>
              Ability to fulfill administrative responsibilities from a home
              office when not traveling.
            </li>
            <li>Spoken and written English language proficiency.</li>
            <li>
              Ability to represent and infuse content with Travelpass brand
              values.
            </li>
            <li>
              Demonstrated performance of applicants’ interview (for finalists
              only).
            </li>
            <li>Overall merits of each candidate.</li>
          </ol>
          <h2>HOW TO APPLY</h2>
          <p>
            To apply and be eligible for the CHO position, eligible applicants
            must, during the Campaign Period (August 20, 2024 to September 22,
            2024) complete the following:
          </p>
          <p>Eligible Applicants (individuals and teams) must:</p>
          <ul className='list-circle'>
            <li>
              Create a profile on https://www.travelpass.com complete with the
              following information:
              <ul className='list-circle'>
                <li>Bio</li>
                <li>Profile photo</li>
                <li>Social media handles</li>
              </ul>
            </li>

            <li>
              Create at least 1 travel guide featuring a destination the
              candidate has experience visiting. The guide should include the
              following details:
              <ul className='list-circle'>
                <li>Guide name</li>
                <li>Guide location</li>
                <li>Guide description</li>
                <li>
                  At least 5 recommended locations within the destination that
                  may include restaurants, points of interest, hotels or other
                  recommendations
                </li>
                <li>
                  Notes with recommendations or insights about the points of
                  interest
                </li>
              </ul>
            </li>

            <li>
              Create a 60 second or less application video in English, making a
              creative case for why
            </li>
          </ul>
        </ModalNext>
      )}
    </>
  )
}
