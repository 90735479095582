import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'

export const CURRENT_USER_FULL_SEARCH_PREFERENCES = gql(`
  query GetUserFullSearchPreferences {
    currentUser {
      id
      searchPreferences {
        id
        partyType
        budgetSelection {
          budgetType
          min
          max
        }
        hotelStarRating
        selectedAmenities { 
          id
          displayName
          displayOrder
          iconName
          typeName
          isSelected
        }
        brandSelections
      }
    }
  }
`)

export const useGetUserFullSearchPreferences = () =>
  useQuery(CURRENT_USER_FULL_SEARCH_PREFERENCES)
