import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const UPDATE_HOTEL_RECOMMENDATION = gql(`
    mutation UpdateHotelRecommendation($input: UpdateHotelRecommendationInput!) {
        updateHotelRecommendation(input: $input){
            hotelRecommendation{
                id
                notes
                googlePlaceId
                isKnownHotel
            }
        }
    }
`)
export const useUpdateHotelRecommendation = () =>
  useMutation(UPDATE_HOTEL_RECOMMENDATION)
