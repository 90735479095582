import { useState } from 'react'
import { Modal, Button, UnderlineTab, Divider } from '@travelpass/design-system'
import { ScrollMenu } from 'react-horizontal-scrolling-menu'
import { useParams, useSearchParams } from 'react-router-dom'
import { HOTEL_ROOMS_DIV_ID } from 'src/pages/hotels/details/constants'
import { useHotelHeroStandardHotelQuery } from 'src/pages/hotels/details/hooks/useHotelHeroStandardHotelQuery'
import { getPrice } from 'src/utils'
import { HotelModalImages } from './HotelModalImages'
import { useHotelLocationStandardHotelQuery } from '../../hooks'
import { HotelLoading } from '../HotelLoading'
import { HotelMap } from '../HotelMap'
import { filteredPointsOfInterest } from '../HotelMap/hotelMapUtils'

interface HotelModalProps {
  onCloseModal: () => void
  initialTab?: 'PHOTOS' | 'MAP'
  galleryIndex?: number
}

export const HotelModal = ({
  onCloseModal,
  initialTab = 'PHOTOS',
  galleryIndex = 0,
}: HotelModalProps) => {
  const { hotelId } = useParams()
  const [searchParams] = useSearchParams()
  const { standardHotel, isLoading, hasError } =
    useHotelHeroStandardHotelQuery()
  const { hotelLocationData } = useHotelLocationStandardHotelQuery()
  const filteredPoints = filteredPointsOfInterest(
    hotelLocationData?.pointsOfInterest || []
  )
  const [activeTab, setActiveTab] = useState(initialTab === 'PHOTOS' ? 0 : 1)
  const sectionRefs = ['PHOTOS', 'MAP']
  const tabs = sectionRefs.map((tab, index) => ({
    label: tab ?? '',
    onClick: () => setActiveTab(index),
  }))
  const lowestRate = getPrice(searchParams.get('lowestRate'))

  if (hasError) {
    return <div>Something went wrong</div> //TODO: Add error component
  }

  return (
    <Modal
      size='large'
      title={standardHotel?.name || 'Current Hotel'}
      onDismiss={onCloseModal}
    >
      {isLoading && <HotelLoading />}
      {activeTab === 0 && !isLoading && (
        <HotelModalImages
          hotelImages={standardHotel?.imageLinks || []}
          hotelName={standardHotel?.name || ''}
          initialSlideIndex={galleryIndex}
        />
      )}
      {activeTab === 1 && (
        <div className='h-full'>
          <HotelMap height='h-100' points={filteredPoints || []} />
        </div>
      )}
      <div className='flex-justify-center lg:flex-justify-between flex h-auto flex-col items-center gap-4 pt-6 lg:flex-row'>
        <div>
          <div className='all-[.hotel-modal-nav-buttons]:px-4 all-[.hotel-modal-nav-buttons]:py-0.5 all-[.hotel-modal-nav-buttons]:md:px-0 mx--4 md:mx-0'>
            <ScrollMenu scrollContainerClassName='hide-scrollbar hotel-modal-nav-buttons'>
              <UnderlineTab
                activeIndex={activeTab}
                tabs={tabs}
                willScroll={false}
              />
            </ScrollMenu>
          </div>
          <Divider className='-mt-3px' />
        </div>
        <div>
          <Button
            label={lowestRate ? `Rates from ${lowestRate}` : 'View Rates'}
            onClick={() => {
              document
                .getElementById(HOTEL_ROOMS_DIV_ID)
                ?.scrollIntoView({ behavior: 'smooth' })
              onCloseModal()
            }}
          />
        </div>
      </div>
    </Modal>
  )
}
