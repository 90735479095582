import { useState } from 'react'
import type { DraggableAttributes } from '@dnd-kit/core'
import type { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities'
import { AccordionButton } from '@reach/accordion'
import { Icon, IconButton } from '@travelpass/design-system'
import classNames from 'classnames'
import { GuideDraftSectionDeleteModal } from './GuideDraftSectionDeleteModal'
import { GuideDraftSectionEditModal } from './GuideDraftSectionEditModal'
import type { GuideDraftDataOld } from '../../types'

const getCountText = (eventGroup: GuideDraftDataOld['groupedEvents'][0]) => {
  if (eventGroup?.events?.length === 0) return '(0)'

  return `(${eventGroup?.events?.length})`
}

interface GuideDraftSectionHeaderProps {
  attributes: DraggableAttributes
  groupedEvent: GuideDraftDataOld['groupedEvents'][0]
  isDraggingOrOverlay: boolean
  isExpanded: boolean
  listeners: SyntheticListenerMap
}

export const GuideDraftSectionHeader = ({
  attributes,
  groupedEvent,
  isDraggingOrOverlay,
  isExpanded,
  listeners,
}: GuideDraftSectionHeaderProps) => {
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false)
  const [isModalEditOpen, setIsModalEditOpen] = useState(false)
  const { description, name } = groupedEvent ?? {}

  const onModalEditOpen = () => setIsModalEditOpen(true)

  return (
    <>
      <header className='p-x-8 lg:m-l--8 lg:p-l-8 group relative'>
        <button
          className={classNames(
            'c-forest-dark lg:op-100 transition-opacity-100 b-none group-hover:op-100 absolute left-0 top-0 w-6 cursor-pointer bg-transparent p-0 ease-linear group-hover:visible',
            {
              'lg:op-100 lg:visible': isDraggingOrOverlay,
              'lg:invisible': !isDraggingOrOverlay,
            }
          )}
          {...attributes}
          {...listeners}
        >
          <Icon name='draggable' />
        </button>
        <div className='of-hidden flex flex-row items-center'>
          <h4 className='color-grey-900 type-h6 line-clamp-3'>
            {name} {getCountText(groupedEvent)}
          </h4>
          {/** @todo remove when IconButton supports a smaller size */}
          <div className='m-y--1'>
            <IconButton
              aria-label='Edit section'
              icon='modeEdit'
              size='small'
              onClick={onModalEditOpen}
            />
          </div>
        </div>
        {description && isExpanded && (
          <p className='c-grey-700 type-body-2'>{description}</p>
        )}
        {/** @todo This is a one-off implementation that should be refactored in the future. */}
        {!description && isExpanded && (
          <button
            className='focus:outline-valley c-grey-700 b-none p-none type-body-2 transition-color-100 hover:c-forest-dark cursor-pointer bg-transparent ease-linear'
            onClick={onModalEditOpen}
          >
            Add a description for this section
          </button>
        )}
        <AccordionButton className='b-none color-valley absolute right-0 top-0 flex cursor-pointer flex-col items-center justify-center bg-transparent p-0'>
          <Icon name={isExpanded ? 'arrowUpIos' : 'arrowDownIos'} />
        </AccordionButton>
      </header>
      {isModalDeleteOpen && (
        <GuideDraftSectionDeleteModal
          groupedEvent={groupedEvent}
          onDismiss={() => {
            setIsModalEditOpen(true)
            setIsModalDeleteOpen(false)
          }}
        />
      )}
      {isModalEditOpen && (
        <GuideDraftSectionEditModal
          groupedEvent={groupedEvent}
          onDismiss={() => setIsModalEditOpen(false)}
          onSectionDeleteOpen={() => {
            setIsModalEditOpen(false)
            setIsModalDeleteOpen(true)
          }}
        />
      )}
    </>
  )
}
