import { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import cloneDeep from 'lodash.clonedeep'
import { gql } from 'src/__generated__'
import type {
  CollectionItemIdType,
  GetAllCollectionsQueryVariables,
} from 'src/__generated__/graphql'

export const GET_ALL_COLLECTIONS = gql(`
  query GetAllCollections($itemId: String, $itemIdType: CollectionItemIdType, $first: Int, $after: String) {
    currentUser{
      collections(first: $first, after: $after){
        totalCount
        pageInfo{
          hasNextPage
          endCursor
        }
        edges{ 
          cursor
          node{
            existingCollectedItem(itemId: $itemId, itemIdType: $itemIdType)
            id
            isDefault
            previewImages
            name
          }
        }
      }
    }
  }
`)

export const useGetAllCollections = (
  itemId?: string,
  itemIdType?: CollectionItemIdType,
  first?: number
) => {
  const [hasMoreResults, setHasMoreResults] = useState(true)

  let variables: GetAllCollectionsQueryVariables = { first: first || 10 }
  if (itemId && itemIdType) variables = { ...variables, itemId, itemIdType }

  const { data, error, loading, fetchMore, refetch } = useQuery(
    GET_ALL_COLLECTIONS,
    { variables, fetchPolicy: 'no-cache' }
  )

  const fetchMoreCollections = async () => {
    if (hasMoreResults) {
      await fetchMore({
        variables: {
          ...variables,
          after: data?.currentUser?.collections?.pageInfo?.endCursor,
        },
        updateQuery: ({ currentUser } = {}, { fetchMoreResult }) => {
          const updatedCollections = cloneDeep(fetchMoreResult)

          const { edges: previousCollections } = currentUser.collections ?? {}
          const { pageInfo, edges: currentCollections } =
            fetchMoreResult?.currentUser.collections ?? {}

          setHasMoreResults(pageInfo.hasNextPage)

          const updatedData = previousCollections.concat(currentCollections)
          updatedCollections.currentUser.collections.edges = updatedData

          return updatedCollections
        },
      })
    } else setHasMoreResults(false)
  }

  useEffect(() => {
    setHasMoreResults(data?.currentUser?.collections?.pageInfo?.hasNextPage)
  }, [data?.currentUser?.collections?.pageInfo])

  return {
    loading,
    collections: data?.currentUser?.collections?.edges,
    hasMoreResults,
    error,
    fetchMoreCollections,
    refetch,
  }
}
