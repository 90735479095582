import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'
import {
  guideDraftPublishedEventFragment,
  useUpdateGuideDraftQueryCache,
} from './useGetGuideDraftQuery'

const updateGuideDraftMutation = gql(`
  mutation UpdateGuideDraftMutationInGuideDraft(
    $first: Int = 100,
    $input: UpdateGuideDraftInput!
  ) {
    updateGuideDraft(input: $input) {
      guideDraft {
        id
        addresses {
          id
          addressLine1
          city
          country
          googlePlaceId
          lat
          long
          state
        }
        description
        guide {
          id
          status
        }
        hasUnpublishedChanges
        images {
          id
          source
          type
          url
        }
        imageUrl
        name
        ownerProfile {
          id
          accountHandle
          displayName
          guides {
            id
          }
          isUserOwner
          profileImageUrl
          profileImageSource
          userId
        }
        paginatedEventCategories(first: 100) {
          edges {
            node {
              id
              description
              name
              publishedEvents(first: $first) {
                edges {
                  node {
                    ...GuideDraftPublishedEventFields
                  }
                }
                pageInfo {
                  endCursor
                  hasNextPage
                }
                totalCount
              }
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
          totalCount
        }
        uncategorizedPublishedEvents(first: $first) {
          edges {
            node {
              ...GuideDraftPublishedEventFields
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
          totalCount
        }
        status
        updatedAt
      }
    }
  }
`)

/** @todo hardcoding `hasUnpublishedChanges` for now until we have a better way to handle this */
export const useUpdateGuideDraftMutation = (hasUnpublishedChanges = true) => {
  const { updateGuideDraftQueryCache } = useUpdateGuideDraftQueryCache()

  return useMutation(updateGuideDraftMutation, {
    update: (cache, { data }) => {
      const updatedCache = updateGuideDraftQueryCache(
        data?.updateGuideDraft?.guideDraft,
        hasUnpublishedChanges
      )

      if (!updatedCache) return

      cache.modify(updatedCache)
    },
  })
}
