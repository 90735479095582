import { useApolloClient, useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const createGuideFromGuideDraftMutation = gql(`
  mutation CreateGuideFromGuideDraft($input: CreateGuideFromGuideDraftInput!) {
    createGuideFromGuideDraft(input: $input) {
      guide {
        id
        guideDraft {
          id
          hasUnpublishedChanges
        }
        status
      }
    }
  }
`)

export const useCreateGuideFromGuideDraftMutation = () => {
  const apolloClient = useApolloClient()

  return useMutation(createGuideFromGuideDraftMutation, {
    update: (cache, { data }) => {
      const { id } = data?.createGuideFromGuideDraft?.guide?.guideDraft ?? {}

      if (!id) return

      cache.modify({
        id: apolloClient.cache.identify({
          id,
          __typename: 'GuideDraft',
        }),
        fields: {
          hasUnpublishedChanges() {
            /** @todo hardcoding `hasUnpublishedChanges` for now until we have a better way to handle this */
            return false
          },
        },
      })
    },
  })
}
