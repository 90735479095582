import { useEffect, useRef } from 'react'
import { useLazyQuery } from '@apollo/client'
import {
  Carousel,
  SkeletonDots,
  useScreenQuery,
} from '@travelpass/design-system'
import { gql } from 'src/__generated__'
import { useIsElementOnScreen } from 'src/common/hooks'
import PolaroidProfileCard from './PolaroidProfileCard'

const GET_PROFILES = gql(`
  query GetProfilesForHomepage($travelpassCurated: Boolean!) {
    searchUserProfiles(travelpassCurated: $travelpassCurated) {
      id
      guideCount
      bragStates
      bragCountries
      displayName
      profileImageUrl
      accountHandle
    }
  }
`)

export const HomePopularProfiles = () => {
  const ref = useRef<HTMLDivElement>(null)
  const isIntercepting = useIsElementOnScreen(ref, {})

  const { isDesktopScreen, isMobileScreen, isTabletScreen } = useScreenQuery()
  const [getProfilesData, { data, loading }] = useLazyQuery(GET_PROFILES, {
    variables: { travelpassCurated: true },
  })

  useEffect(() => {
    if (isIntercepting) {
      getProfilesData()
    }
  }, [isIntercepting])

  const profiles = data?.searchUserProfiles
  const thereIsProfiles = !!profiles?.length

  return (
    <>
      <header className='space-y-6'>
        <h4 className='type-overline uppercase'>Join the community</h4>
        <h3 className='type-h2'>Follow Other Travelers Like You</h3>
      </header>
      <div className='lg:h-90 md:max-w-300 block min-h-10 md:mx-auto' ref={ref}>
        {loading && <SkeletonDots />}
        {thereIsProfiles && isDesktopScreen && (
          <div className='grid h-full w-full gap-12 px-6 md:grid-cols-2 md:gap-6 md:px-12 lg:grid-cols-4'>
            {profiles.map((profile, index) => (
              <PolaroidProfileCard
                key={`profile-${index}`}
                data={{
                  name: profile?.displayName,
                  // followersCount: 0,
                  countriesCount: profile?.bragCountries,
                  statesCount: profile?.bragStates,
                  guidesCount: profile?.guideCount,
                  imageSource: profile?.profileImageUrl,
                  profileURL: 'profile/' + profile?.accountHandle,
                }}
              />
            ))}
          </div>
        )}
        {thereIsProfiles && (isMobileScreen || isTabletScreen) && (
          <Carousel
            isLight
            borderRadius={12}
            hideArrows={true}
            keyBoardControl={true}
            size='small'
            slidesPerViewOnDesktop={2}
            slidesPerViewOnMobile={1}
            slidesPerViewOnTablet={2}
          >
            {profiles.slice(0, isMobileScreen ? 2 : 4).map((profile, index) => (
              <div
                key={`profile-${index}`}
                className='mx-auto mb-8 max-w-[calc(100%-24px)]'
              >
                <PolaroidProfileCard
                  className='h-120 mx-auto max-w-80'
                  data={{
                    name: profile?.displayName,
                    // followersCount: 0,
                    countriesCount: profile?.bragCountries,
                    statesCount: profile?.bragStates,
                    guidesCount: profile?.guideCount,
                    imageSource: profile?.profileImageUrl,
                    profileURL: 'profile/' + profile?.accountHandle,
                  }}
                />
              </div>
            ))}
          </Carousel>
        )}
      </div>
    </>
  )
}
