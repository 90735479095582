import { useFormContext } from 'react-hook-form'
import { LinkForm } from './LinkForm'
import { LinkVisualizer } from './LinkVisualizer'
import type { ProfileFields } from '../../../../types'

export const Links = () => {
  const { watch } = useFormContext<ProfileFields>()
  const links =
    watch('links')?.length > 0 ? JSON.parse(watch('links') as string) : []

  return (
    <div className='mb-12 space-y-2'>
      <h6 className='type-h6 c-black'>Add additional links</h6>
      <p className='type-small-text-desktop c-grey-800'>
        Link out to content that’s not on your socials (think your podcast,
        Amazon store, or Goodreads travel list).{' '}
      </p>
      {links.map(({ title, url }, index) => (
        <LinkVisualizer
          key={`${title}-${index}`}
          index={index}
          links={links}
          title={title}
          url={url}
        />
      ))}
      <LinkForm links={links} />
    </div>
  )
}
