import { Button } from '@travelpass/design-system'
import type {
  ButtonSize,
  ButtonVariant,
} from '@travelpass/design-system/dist/src/components/Button/buttonConstants'
import { useFlag } from 'src/common/hooks'
import { getGuideMetricString } from './'
import type { GuideData } from '../../details/types'

interface GuideMetricViewProps {
  guideData: Pick<GuideData, 'viewCount'>
  hideLabel?: boolean
  isDisabled?: boolean
  size?: ButtonSize
  variant?: ButtonVariant
}

export const GuideMetricView = ({
  guideData,
  hideLabel = false,
  isDisabled = false,
  size = 'medium',
  variant = 'text',
}: GuideMetricViewProps) => {
  const { viewCount } = guideData ?? {}
  const isGuideMetricsViewCountHideEnabled = useFlag(
    'guideMetricsViewCountHide'
  )

  if (isGuideMetricsViewCountHideEnabled) return

  return (
    <Button
      isDisabled={isDisabled}
      name='visibility'
      size={size}
      startIcon='visibility'
      variant={variant}
    >
      {getGuideMetricString(viewCount, hideLabel ? '' : 'view')}
    </Button>
  )
}
