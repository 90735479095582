import { Breadcrumbs, Button, useScreenQuery } from '@travelpass/design-system'
import { useNavigate } from 'react-router-dom'
import { PageLayoutContainer } from 'src/common/components'
import { myAccountPath } from 'src/constants'
import { NotificationsForm } from './NotificationsForm'
import { useUserProfileQuery } from '../../common/hooks/useUserProfileQuery'

export const Notifications = () => {
  const { isMobileScreen } = useScreenQuery()
  const navigate = useNavigate()
  const { data, loading } = useUserProfileQuery()

  const redirect = () => navigate(myAccountPath, { replace: true })

  const breadcrumbs = [
    { label: 'My Account', onClick: redirect },
    { label: 'Notifications' },
  ]

  return (
    <PageLayoutContainer includeTopPadding size='large'>
      {isMobileScreen ? (
        <Button startIcon='arrowBackIos' variant='text' onClick={redirect}>
          Back
        </Button>
      ) : (
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      )}
      <h1 className='type-h2-desktop mt-10'>Notifications</h1>
      <div className='my-10'>
        {loading ? null : <NotificationsForm user={data?.currentUser} />}
      </div>
    </PageLayoutContainer>
  )
}
