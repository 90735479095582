import { Button } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import type { CurrentUserProfileQuery } from 'src/__generated__/graphql'
import { AddButton } from './AddButton'
import { useCheckEditingMode } from '../components/hooks/useCheckEditingMode'

interface GeneralLinksProps {
  openEditingMode?: () => void
  links?: CurrentUserProfileQuery['currentUser']['userProfile']['links']
  isUserOwner?: boolean
}

export const GeneralLinks = ({
  links,
  openEditingMode,
  isUserOwner,
}: GeneralLinksProps) => {
  const { isEditingMode } = useCheckEditingMode()
  const noLinks = isEmpty(links)

  if (noLinks && (isEditingMode || !isUserOwner)) return null

  return (
    <div
      className={`box-border flex flex-col items-center self-center ${isEditingMode ? 'gap-y-6' : 'gap-y-2'}`}
    >
      <p className='type-h4 pb-2 sm:hidden md:block'>Links</p>
      {noLinks && isUserOwner && (
        <AddButton
          text='Join the adventure! Add your favorite travel links now.'
          onClick={openEditingMode}
        />
      )}
      {links?.map(({ url, title }) => (
        <Button
          key={`${title}-${url}`}
          fullWidth
          className='px-2 py-1'
          size='small'
          type='button'
          variant='outlined'
          onClick={() => window.open(url, '_blank')}
        >
          {title}
        </Button>
      ))}
    </div>
  )
}
