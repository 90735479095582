import { useEffect, useState } from 'react'
import { Accordion } from '@reach/accordion'
import { useNavigate } from 'react-router-dom'
import { isLastIndex } from 'src/utils'
import { FaqCard } from './FaqCard'
import { FaqPagination } from './FaqPagination'
import { FaqSearch } from './FaqSearch'
import { faqItemsData } from './faqData'
import { FAQ_PAGE_SIZE, FIRST_PAGE } from '../../../infoConstants'
import { containerClasses } from '../../../infoStyles'
import {
  formatFaqItems,
  generateFuseObject,
  searchFuseObject,
} from '../../../infoUtils'

const fuseObject = generateFuseObject()

interface FaqDetailsProps {
  hasPagination?: boolean
  hasSearch?: boolean
  isFeatured?: boolean
}

export const FaqDetails = ({
  hasPagination,
  hasSearch,
  isFeatured,
}: FaqDetailsProps) => {
  const [faqItems, setFaqItems] = useState(null)
  const [currentPage, setCurrentPage] = useState(FIRST_PAGE)
  const [searchTerm, setSearchTerm] = useState('')
  const [totalPages, setTotalPages] = useState(0)

  const navigate = useNavigate()

  const onSearchTermChange = (value: string) => {
    const updatedFaqItems = searchFuseObject(fuseObject, value)
    setCurrentPage(FIRST_PAGE)
    setFaqItems(updatedFaqItems)
    setSearchTerm(value)
    setTotalPages(updatedFaqItems?.length / FAQ_PAGE_SIZE)
  }

  useEffect(() => {
    fuseObject.setCollection(
      !isFeatured
        ? faqItemsData(navigate)
        : faqItemsData(navigate).filter(faqItem => faqItem.isFeatured)
    )
    onSearchTermChange(searchTerm)
  }, [])

  return (
    <div>
      <div className={containerClasses}>
        <div className={subcontainerCss}>
          {hasSearch && (
            <FaqSearch
              searchTerm={searchTerm}
              onSearchTermChange={onSearchTermChange}
            />
          )}
          {faqItems && (
            <Accordion collapsible multiple>
              {formatFaqItems(faqItems, hasPagination, currentPage).map(
                (faqItem, faqItemsIndex) => (
                  <FaqCard
                    key={faqItem.item?.title + faqItemsIndex}
                    faqItem={faqItem}
                    isLast={isLastIndex(faqItems, faqItemsIndex)}
                  />
                )
              )}
            </Accordion>
          )}
          {hasPagination && (
            <FaqPagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={totalPages}
            />
          )}
        </div>
      </div>
    </div>
  )
}

const subcontainerCss = 'flex flex-col sm:gap-8 md:gap-16 w-full'
