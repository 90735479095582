import { useEffect, useMemo, useState } from 'react'
import { Button, IconButton, Input } from '@travelpass/design-system'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { FlagsSection } from './FlagsSection'
import { featureFlags } from './featureFlags'
import { getLocalFlags, updateFlags } from './utils'

export const FeatureFlagsList = () => {
  const ldFlags = useFlags()
  const [flags, setFlags] = useState({
    ...featureFlags,
    ...ldFlags,
    ...getLocalFlags(),
  })
  const [showFlags, setShowFlags] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const showFeaturesFlagsButton = localStorage.getItem('devtools') === 'true'

  useEffect(() => {
    if (Object.keys(ldFlags).length === 0) return
    const newFlags = { ...featureFlags, ...ldFlags, ...getLocalFlags() }
    setFlags(newFlags)
    updateFlags(newFlags)
  }, [ldFlags])

  useEffect(() => {
    if (!showFeaturesFlagsButton) onResetFlags()
  }, [showFeaturesFlagsButton])

  const onResetFlags = () => {
    const newFlags = { ...featureFlags, ...ldFlags }
    setFlags(newFlags)
    updateFlags(newFlags)
  }

  const { activeFlags, inactiveFlags, searchedFlags } = useMemo(
    () =>
      Object.keys(flags).reduce(
        (acc, key) => {
          const flagValue = flags[key]
          const isIncluded = key
            .toLowerCase()
            .includes(searchTerm.toLowerCase())

          if (typeof flagValue !== 'boolean') return acc

          if (flagValue) {
            acc.activeFlags = { ...acc.activeFlags, [key]: flagValue }
          } else if (isIncluded) {
            acc.inactiveFlags = { ...acc.inactiveFlags, [key]: flagValue }
          }

          if (isIncluded && searchTerm.length > 0) {
            acc.searchedFlags = { ...acc.searchedFlags, [key]: flagValue }
          }

          return acc
        },
        { activeFlags: {}, inactiveFlags: {}, searchedFlags: {} }
      ),
    [flags, searchTerm]
  )

  const clearButton = (
    <IconButton icon='clear' onClick={() => setSearchTerm('')} />
  )

  const onChange = flag => {
    flags[flag] = !flags[flag]
    setFlags({ ...flags })
    updateFlags({ ...flags })
  }

  if (!showFeaturesFlagsButton) return null

  return (
    <div className='md:w-unset z-10000 fixed bottom-2 right-10 w-full'>
      <div className='relative'>
        {showFlags && (
          <div className='h-100 md:position-unset md:left-unset md:right-unset md:bottom-unset md:w-120 md:h-120 shadow-1 b-forest rounded-4 absolute bottom--2 left-10 right--10 z-10 overflow-y-scroll bg-white p-5'>
            <div className='flex items-baseline justify-between gap-8'>
              <Button label='Reset' variant='outlined' onClick={onResetFlags} />
              <Input
                autoFocus
                fullWidth
                aria-label='Search feature flags'
                placeholder='Search feature flags'
                slotAfter={clearButton}
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
              />
            </div>
            {searchTerm.length > 0 && (
              <FlagsSection
                allFlags={flags}
                sectionFlags={searchedFlags}
                title='Searched Flags'
                onChange={onChange}
              />
            )}
            <FlagsSection
              allFlags={flags}
              sectionFlags={activeFlags}
              title='Active Flags'
              onChange={onChange}
            />
            <FlagsSection
              allFlags={flags}
              sectionFlags={inactiveFlags}
              title='Inactive Flags'
              onChange={onChange}
            />
          </div>
        )}
        <div className='absolute bottom-2 right-0 right-2 z-10'>
          <IconButton
            color='forestLight'
            icon='flag'
            onClick={() => setShowFlags(!showFlags)}
          />
        </div>
      </div>
    </div>
  )
}
