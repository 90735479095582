import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const likeGuideMutation = gql(`
  mutation LikeGuide($guideId: ID!) {
    likeListGuide(guideId: $guideId) {
      id
      numberOfLikes
    }
  }
`)

export const useLikeGuideMutation = () => useMutation(likeGuideMutation)
