import { Button } from '@travelpass/design-system'
import debounce from 'lodash.debounce'
import { useSearchParams } from 'react-router-dom'
import type { LatLong, PublishedEvent } from 'src/__generated__/graphql'
import { Map } from 'src/common/components/Map'
import { initialMapZoom } from 'src/common/components/Map/constants'
import {
  GuideSearchParam,
  onGuideSessionStorageHoverIdChange,
  useGuideSessionStorageIds,
} from 'src/pages/guides/details'
import { GuideMapEvents } from './GuideMapEvents'
import { GuideMapLoading } from './GuideMapLoading'
import { GuideMapMarker } from './GuideMapMarker'
import type { GuideOwner } from '../../types'
import { onGuideSessionStorageSelectedIdChange } from '../../useGuideSessionStorageIds'

interface GuideMapProps {
  isLoading: boolean
  location: LatLong
  points: PublishedEvent[]
  owner: GuideOwner
}

export const GuideMap = ({
  isLoading,
  location,
  owner,
  points,
}: GuideMapProps) => {
  const { hoverId, selectedId } = useGuideSessionStorageIds()
  const [searchParams, setSearchParams] = useSearchParams()
  const { latitude, longitude } = location ?? {}

  const onBack = () => {
    searchParams.delete(GuideSearchParam.mapExpanded)
    setSearchParams(searchParams)
  }

  const onClose = debounce(() => {
    onGuideSessionStorageHoverIdChange('')
    onGuideSessionStorageSelectedIdChange('')
  }, 100)

  return (
    <div className='bg-grey-100 h-full w-full'>
      <Map
        defaultCenter={{
          lat: latitude,
          lng: longitude,
        }}
        defaultZoom={initialMapZoom}
        enableZoomControl={false}
        maxZoom={20}
        minZoom={3}
        onClick={onClose}
      >
        {points?.map(
          point =>
            point?.id && (
              <GuideMapMarker
                key={point?.id}
                isHovered={point?.id === hoverId}
                isSelected={point?.id === selectedId}
                owner={owner}
                point={point}
              />
            )
        )}
        <GuideMapEvents points={points} />
        <div className='p-x-6 p-y-8 absolute bottom-0 left-0 right-0 flex flex-col items-center lg:hidden'>
          <Button
            aria-label='Back to list view'
            size='small'
            startIcon='arrowBackIos'
            onClick={onBack}
          >
            Back to List
          </Button>
        </div>
      </Map>
      {isLoading && (
        <div className='absolute inset-0'>
          <GuideMapLoading />
        </div>
      )}
    </div>
  )
}
