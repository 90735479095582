import { useId, useState } from 'react'
import './mini-accordion.css'
import { Icon } from '@travelpass/design-system'
import {
  AccordionContext,
  AccordionItemGroupId,
  useAccordionGroupState,
  useAccordionTrigger,
} from './hooks'

/**
 * mini accordion lib
 *
 * @desc this was created because:
 * - design-system does not have an accordion component
 * - reach-ui/accordion could not be integrated with design-system button (`as` prop fails to work)
 * - avoids a new dependency
 *
 * @todo move to design system someday?
 *
 * @see https://www.w3.org/WAI/ARIA/apg/patterns/accordion/ for accessibility requirements
 */
export const Accordion = ({
  children,
  multiple = false,
}: {
  children: React.ReactNode
  multiple?: boolean
}) => {
  const [selectedPanelId, setSelectedPanelId] = useState(null)
  const onSelect = updatedPanelId => {
    const panelId = selectedPanelId === updatedPanelId ? null : updatedPanelId
    setSelectedPanelId(panelId)
  }

  return (
    <AccordionContext.Provider
      value={{ expandedPanelId: selectedPanelId, onSelect, multiple }}
    >
      <div>{children}</div>
    </AccordionContext.Provider>
  )
}

export const AccordionItemGroup = ({ children, ...props }) => {
  const id = useId()
  const htmlId = `accordion-item-group-${id}`

  return (
    <AccordionItemGroupId.Provider value={htmlId}>
      <div {...props}>{children}</div>
    </AccordionItemGroupId.Provider>
  )
}

export const AccordionPanel = ({ children }) => {
  const { id, expanded } = useAccordionGroupState()

  return (
    <div
      className='accordion-panel'
      /* aria-expanded applies to the _trigger_ NOT the panel, so instead we communicate the current state using data-attr  */
      data-expanded={expanded}
      id={id}
    >
      {children}
    </div>
  )
}

export const AccordionButton = props => {
  const { id, ariaExpanded, onClick } = useAccordionTrigger()
  const [expanded, setExpanded] = useState(false)
  const { multiple } = useAccordionGroupState()

  return (
    <button
      {...props}
      aria-controls={id}
      aria-expanded={multiple ? expanded : ariaExpanded}
      className={`accordion-button flex w-full items-center justify-between border-none bg-transparent ${props?.className || ''}`}
      type='button'
      onClick={multiple ? () => setExpanded(s => !s) : onClick}
    >
      {props?.children}
      <Icon name={ariaExpanded ? 'expandLess' : 'expandMore'} />
    </button>
  )
}
