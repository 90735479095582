import { useRef, useState } from 'react'
import { IconButton, MenuItem } from '@travelpass/design-system'
import { Popover } from 'react-tiny-popover'

export const TripCardActions = ({
  onDeleteClick,
  onEditClick,
}: {
  onDeleteClick?(): void
  onEditClick?(): void
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const ref = useRef<HTMLInputElement>(null)

  const onClick = () => setIsPopoverOpen(!isPopoverOpen)

  return (
    <Popover
      containerClassName='left-15! md:left-unset!'
      containerStyle={{ zIndex: '2' }}
      content={
        <div className='shadow-1 w-50 mr-6 mt-1 rounded-xl bg-white p-2'>
          <MenuItem
            isHighlighted={false}
            label='Edit information'
            startIcon='modeEdit'
            value='Edit information'
            onClick={() => {
              onEditClick?.()
              setIsPopoverOpen(false)
            }}
          />
          <MenuItem
            isHighlighted={false}
            label='Delete trip'
            startIcon='delete'
            value='Delete trip'
            onClick={() => {
              onDeleteClick?.()
              setIsPopoverOpen(false)
            }}
          />
        </div>
      }
      isOpen={isPopoverOpen}
      positions={['bottom']}
      ref={ref}
      onClickOutside={() => setIsPopoverOpen(false)}
    >
      <span className='z-2'>
        <IconButton icon='moreHoriz' onClick={onClick} />
      </span>
    </Popover>
  )
}
