import type { MutableRefObject } from 'react'
import { useScreenQuery } from '@travelpass/design-system'
import classNames from 'classnames'
import {
  MINI_IMAGE_SIZE_MOBILE,
  MINI_IMAGE_SIZE_DESKTOP,
} from 'src/pages/hotels/details/constants'

interface HeroModalImagesScrollerProps {
  images: string[]
  slideIndex: number
  scrollContainerRef: MutableRefObject<HTMLDivElement>
  onClick: (index: number) => void
  experienceName?: string
}

export const HeroModalImageScroller = ({
  images,
  slideIndex,
  scrollContainerRef,
  onClick,
  experienceName: hotelName = '',
}: HeroModalImagesScrollerProps) => {
  const { isMobileScreen } = useScreenQuery()
  const handleScroll = e => {
    const delta = Math.max(
      -1,
      Math.min(1, e.nativeEvent.deltaY || -e.nativeEvent.wheelDelta)
    )
    const container = scrollContainerRef.current
    container.scrollLeft += delta * 80
  }

  return (
    <div
      className='md:display-unset flex flex-row space-x-3 space-y-0 overflow-x-auto md:h-auto md:flex-col md:space-x-0 md:space-y-3 md:overflow-y-auto'
      ref={scrollContainerRef}
      onWheel={handleScroll}
    >
      {images?.map((image, index) => {
        const imageAlt = `${hotelName} hero modal grid image ${index + 1}`
        return (
          <button
            key={imageAlt}
            aria-label='Show hotel photo'
            className='m-0 block cursor-pointer border-none bg-transparent p-0 outline-none'
            onClick={() => onClick(index)}
          >
            <img
              alt={imageAlt}
              className={classNames(
                'rounded-2 max-w-45 border-3 block aspect-[3/2] border-solid bg-gray-200 transition-all duration-300 md:max-w-full',
                {
                  'border-forest opacity-100': index == slideIndex,
                },
                { 'border-white opacity-70': index != slideIndex }
              )}
              draggable='false'
              id={`wheel-images-${index}`}
              loading='lazy'
              src={image}
            />
          </button>
        )
      })}
    </div>
  )
}
