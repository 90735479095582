import { BookingCardLoading } from 'src/pages/bookings/MyBookings/common/components'

export const DashboardCollectionsLoading = () => (
  <div className='grid grid-cols-1 content-center gap-5 px-5 pt-3 md:grid-cols-2 md:px-0'>
    <BookingCardLoading className='w-full' />
    <BookingCardLoading className='w-full' />
    <BookingCardLoading className='w-full' />
    <BookingCardLoading className='w-full' />
    <BookingCardLoading className='w-full' />
    <BookingCardLoading className='w-full' />
  </div>
)
