import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { gql } from 'src/__generated__'
import { constructHotelId } from '../utils/hotelUtils'

const HOTEL_REVIEWS_SUMMARY = gql(`
  query HotelReviewsSummaryListHotelReviewsQuery(
    $listHotelReviewsArgs: ListHotelReviewsArgs!
  ) {
    listHotelReviews(listHotelReviewsArgs: $listHotelReviewsArgs) {
      averageOverall
      cleanlinessAverage
      locationAverage
      staffAverage
      totalReviews
    }
  }
`)

export const useHotelReviewsSummaryListHotelReviewsQuery = () => {
  const { hotelId } = useParams()
  const constructedHotelId = hotelId ? constructHotelId(hotelId) : null

  const {
    data,
    error: hasError,
    loading: isLoading,
  } = useQuery(HOTEL_REVIEWS_SUMMARY, {
    skip: !constructedHotelId,
    variables: {
      listHotelReviewsArgs: {
        standardHotelId: constructedHotelId ?? 0,
      },
    },
  })

  const { listHotelReviews: hotelReviewsSummaryData } = data ?? {}

  return {
    hasError,
    hotelReviewsSummaryData,
    isLoading,
  }
}
