import { useState } from 'react'
import { Breadcrumbs, Button, useScreenQuery } from '@travelpass/design-system'
import { useNavigate } from 'react-router-dom'
import { PageLayoutContainer, Helmet } from 'src/common/components'
import { myAccountPath } from 'src/constants'
import { AccountSettingsTabs } from './components/AccountSettingsTabs'
import { PersonalInformationForm } from './components/PersonalInformationForm'
import { SignInAndSecurity } from './components/SignInAndSecurity/SignInAndSecurity'
import { useGetCurrentUserProfileAccountHandleAndAvatar } from '../profile/components/hooks/useGetCurrentUserProfileAccountHandleAndAvatar'

export const AccountSettings = () => {
  const { isMobileScreen } = useScreenQuery()
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState(0)
  const { data } = useGetCurrentUserProfileAccountHandleAndAvatar()
  const displayName = data?.currentUser?.userProfile?.displayName
  const pageName = `${activeTab === 0 ? 'Personal Information ' : 'Sign-in and Security '} ${displayName ? '|' : ''} ${displayName || ''}`

  const redirect = () => navigate(myAccountPath, { replace: true })

  const breadcrumbs = [
    {
      label: 'My Account',
      onClick: redirect,
    },
    { label: 'Account Settings' },
  ]

  return (
    <>
      <Helmet pageName={pageName} shouldFollow={false} />
      <PageLayoutContainer includeTopPadding size='large'>
        {isMobileScreen ? (
          <Button startIcon='arrowBackIos' variant='text' onClick={redirect}>
            Back
          </Button>
        ) : (
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        )}
        <h1 className='type-h2-desktop mt-10'>Account Settings</h1>
        <div className='flexed-column my-10 gap-10'>
          <AccountSettingsTabs
            activeTab={activeTab}
            onTabChange={(index: number) => setActiveTab(index)}
          />
          {activeTab == 0 ? <PersonalInformationForm /> : <SignInAndSecurity />}
        </div>
      </PageLayoutContainer>
    </>
  )
}
