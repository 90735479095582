import type { UniqueIdentifier } from '@dnd-kit/core'
import isEmpty from 'lodash.isempty'
import { createSearchParams } from 'react-router-dom'
import {
  GuideDraftStatus,
  type CreateGuideDraftMutationInGuideDraftMutationVariables,
  type CreatePublishedEventMutationInGuideDraftMutationVariables,
  type GetPlaceDetailsInTripsQuery,
  type UpdateGuideDraftMutationInGuideDraftMutationVariables,
  type UpdateGuideMutationInGuideMutationVariables,
} from 'src/__generated__/graphql'
import { type GeocoderType } from 'src/constants/user'
import { constructAddressInput, getEventType } from 'src/pages/trips/utils'
import { getGuideDraftDetailsUrl } from 'src/utils'
import type {
  GuideDraftData,
  GuideDraftDragItem,
  GuideDraftDragItems,
  GuideDraftPublishedEvent,
} from './types'
import { GuideSearchParam } from '../details'

const getCreateGuideDraftVariables = ({
  description,
  geocoder,
  name,
}: {
  description: string
  geocoder: GeocoderType
  name: string
}): CreateGuideDraftMutationInGuideDraftMutationVariables => {
  const variables: CreateGuideDraftMutationInGuideDraftMutationVariables = {
    createGuideDraftInput: {
      name,
    },
    userGuideDraftsArgs: {
      includeStatuses: [GuideDraftStatus.Active],
    },
  }

  if (description) variables.createGuideDraftInput.description = description

  if (!isEmpty(geocoder?.center))
    variables.createGuideDraftInput.addresses = [
      constructAddressInput(geocoder),
    ]

  return variables
}

const getGuideDraftCreatePublishedVariables = ({
  guideDraftId,
  placeDetailsData,
}: {
  guideDraftId: CreatePublishedEventMutationInGuideDraftMutationVariables['publishedEventInput']['guideDraftId']
  placeDetailsData: GetPlaceDetailsInTripsQuery
}): CreatePublishedEventMutationInGuideDraftMutationVariables => {
  const {
    address,
    city,
    country,
    googlePlaceId,
    latitude,
    longitude,
    name,
    postalCode,
    state,
    type,
  } = placeDetailsData?.getPlaceDetails ?? {}
  const inputAddress = {
    addressLine1: address,
    city,
    country,
    googlePlaceId,
    lat: latitude,
    long: longitude,
    state,
    zipcode: postalCode,
  }

  return {
    publishedEventInput: {
      addresses: [inputAddress],
      guideDraftId,
      name,
      type: getEventType(type),
    },
  }
}

const getGuideDraftDragItems = ({
  paginatedEventCategories,
  uncategorizedPublishedEvents,
}: {
  paginatedEventCategories: GuideDraftData['paginatedEventCategories']
  uncategorizedPublishedEvents: GuideDraftData['uncategorizedPublishedEvents']
}): GuideDraftDragItems => {
  const updatedUncategorizedEventCategory: GuideDraftDragItem = {
    id: null,
    description: null,
    name: null,
    publishedEvents:
      uncategorizedPublishedEvents?.edges?.map(({ node }) => node) ?? [],
    pageInfo: uncategorizedPublishedEvents?.pageInfo,
  }
  const updatedPaginatedEventCategories: GuideDraftDragItem[] =
    paginatedEventCategories?.edges?.map(({ node }) => {
      return {
        ...node,
        publishedEvents:
          node?.publishedEvents?.edges?.map(({ node }) => node) ?? [],
        pageInfo: node?.publishedEvents?.pageInfo,
      }
    }) ?? []
  const updatedItems: GuideDraftDragItem[] = [
    updatedUncategorizedEventCategory,
    ...updatedPaginatedEventCategories,
  ]

  return updatedItems?.reduce((total, current) => {
    total[current?.id] = current

    return total
  }, {})
}

const getGuideDraftEventMarkers = ({
  paginatedEventCategories,
  uncategorizedPublishedEvents,
}: {
  paginatedEventCategories: GuideDraftData['paginatedEventCategories']
  uncategorizedPublishedEvents: GuideDraftData['uncategorizedPublishedEvents']
}): GuideDraftPublishedEvent[] => {
  if (
    isEmpty(paginatedEventCategories) &&
    isEmpty(uncategorizedPublishedEvents)
  )
    return []

  const flattenedPublishedEvents = paginatedEventCategories?.edges?.flatMap(
    paginatedEventCategories =>
      paginatedEventCategories?.node?.publishedEvents?.edges ?? []
  )

  return flattenedPublishedEvents
    .concat(uncategorizedPublishedEvents?.edges ?? [])
    ?.reduce((total, publishedEvent) => {
      const { addresses } = publishedEvent?.node ?? {}
      const [address] = addresses ?? []

      if (address?.lat && address?.long) total.push(publishedEvent?.node)

      return total
    }, [])
}

/**
 * We need to filter out uncategorized by checking if the id is 'null' string.
 * The id is 'null' because of SortableContext and requiring a unique identifier null
 * @link https://docs.dndkit.com/presets/sortable/sortable-context#identifier
 *
 */
const getGuideDraftPublishedEventCategorySortOrder = (
  paginatedEventCategoryIds: UniqueIdentifier[]
): UpdateGuideMutationInGuideMutationVariables['updateGuideInput']['publishedEventCategorySortOrder'] =>
  paginatedEventCategoryIds?.filter(id => id !== 'null') as string[]

const getUpdateGuideDraftVariables = ({
  description,
  geocoder,
  guideDraftId,
  name,
}: {
  description: string
  geocoder: GeocoderType
  guideDraftId: string
  name: string
}): UpdateGuideDraftMutationInGuideDraftMutationVariables => {
  const variables: UpdateGuideDraftMutationInGuideDraftMutationVariables = {
    input: {
      description: description ?? '',
      guideDraftId,
      name,
      addresses: [],
    },
  }

  if (!isEmpty(geocoder?.center))
    variables.input.addresses = [constructAddressInput(geocoder)]

  return variables
}

export {
  getCreateGuideDraftVariables,
  getGuideDraftCreatePublishedVariables,
  getGuideDraftDragItems,
  getGuideDraftEventMarkers,
  getGuideDraftPublishedEventCategorySortOrder,
  getUpdateGuideDraftVariables,
}
