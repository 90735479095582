import { useEffect } from 'react'
import { Icon, Tile } from '@travelpass/design-system'
import { useFormContext } from 'react-hook-form'
import { starItems } from 'src/pages/hotels/results/common/HotelResultsFilters/HotelResultsFiltersStars/hotelResultsFiltersStarsConstants'
import type { ExtendedUpsertSearchPreferencesInput } from '../ExtendedUpsertInputType'
import { useCurrentUserSearchPreferences } from '../hooks/useCurrentUserSearchPreferences'

export const QuestionStarRating = () => {
  const { watch, setValue } =
    useFormContext<ExtendedUpsertSearchPreferencesInput>()
  const { data } = useCurrentUserSearchPreferences()
  const currentStarRating =
    data?.currentUser?.searchPreferences?.hotelStarRating

  const hotelStarRating =
    watch('hotelStarRating') !== null && watch('hotelStarRating') !== null
      ? watch('hotelStarRating')
      : currentStarRating

  useEffect(() => {
    // This useEffect helps to set up the initial value of the form based on the user's current search preferences
    if (currentStarRating) {
      setValue('hotelStarRating', currentStarRating, { shouldDirty: false })
    }
  }, [currentStarRating])

  return (
    <div className='flex w-full flex-col gap-y-5 lg:gap-y-10'>
      <h2 className='type-h2-desktop'>I tend to book...</h2>
      <p className='type-h5-desktop c-grey-800'>
        Let’s get you in your ideal hotel! Select your preference here for
        easier booking.
      </p>
      <div className='grid grid-cols-2 gap-4 lg:grid-cols-4'>
        {starItems.map(({ label, value }) => (
          <Tile
            key={value}
            pressed={Number.parseInt(value) === hotelStarRating}
            onClick={() =>
              setValue('hotelStarRating', Number.parseInt(value), {
                shouldDirty: true,
              })
            }
          >
            <div className='flex flex-col items-center justify-center gap-y-2'>
              <Icon name='star' size='medium' />
              <span>{label} star +</span>
            </div>
          </Tile>
        ))}
      </div>
    </div>
  )
}
