import { useEffect, useState, useRef } from 'react'
import {
  Autocomplete,
  AutocompleteOption,
  Chip,
  Icon,
} from '@travelpass/design-system'
import Fuse from 'fuse.js'
import isEmpty from 'lodash.isempty'
import { useFormContext } from 'react-hook-form'
import { CheckedTile } from '../../common/CheckedTile'
import type { ExtendedUpsertSearchPreferencesInput } from '../ExtendedUpsertInputType'
import { useGetBrandSelections } from '../hooks/useGetBrandSelections'
import { useGetParentChainsAll } from '../hooks/useGetParentChainsAll'

const fuse = new Fuse([], {
  keys: ['name'],
  shouldSort: true,
})

const brandSelected = (brand, brandSelections) => {
  return brandSelections.includes(brand)
}

export const QuestionBrands = () => {
  const { data } = useGetBrandSelections()
  const brandsLists = data?.getBrandSelections || {}
  const spotlightBrands = data?.getBrandSelections?.spotlightBrands || []

  const { data: allBrandsData } = useGetParentChainsAll()
  const fullBrandList = allBrandsData?.parentChainsAll || []

  const { watch, setValue } =
    useFormContext<ExtendedUpsertSearchPreferencesInput>()

  const [searchValue, setSearchValue] = useState('')
  const autocompleteRef = useRef(null)
  const refocusTimerRef = useRef(null)

  const searchedBrands =
    fuse?.search(searchValue || '', {
      limit: 5,
    }) ?? []

  // Convert the watched brand selections into a Set to manage uniqueness
  const brandSelections = watch('brandSelections') || []

  useEffect(() => {
    if (spotlightBrands?.length) {
      const selectedBrandNames = spotlightBrands
        .filter(brand => brand.isSelected)
        .map(brand => brand.name)

      const uniqueBrands = new Set([
        ...selectedBrandNames,
        ...(Array.isArray(brandsLists.brandSelections)
          ? brandsLists.brandSelections
          : []),
      ])
      setValue('brandSelections', Array.from(uniqueBrands), {
        shouldDirty: false,
      })
    }
  }, [brandsLists, setValue, spotlightBrands])

  useEffect(() => {
    fuse.setCollection(fullBrandList)
  }, [fullBrandList])

  // Cleanup the timeout when the component unmounts
  useEffect(() => {
    return () => {
      if (refocusTimerRef.current) {
        clearTimeout(refocusTimerRef.current)
      }
    }
  }, [])

  const handleBrandInteraction = brandName => {
    // Convert the current brand selections to a Set to ensure easy manipulation and uniqueness
    const currentBrands = new Set(watch('brandSelections'))

    if (currentBrands.has(brandName)) {
      // If the set already contains the brand, remove it
      currentBrands.delete(brandName)
    } else {
      // If the set does not contain the brand, add it
      currentBrands.add(brandName)
    }

    // Convert the set back to an array and update the form state
    setValue('brandSelections', Array.from(currentBrands), {
      shouldDirty: true,
    })
    setSearchValue('')

    // Clear any existing timeout to avoid memory leaks
    if (refocusTimerRef.current) {
      clearTimeout(refocusTimerRef.current)
    }

    // Set a new timeout and store its ID
    refocusTimerRef.current = setTimeout(() => {
      if (autocompleteRef.current) {
        autocompleteRef.current.focus()
      }
    }, 100)
  }

  return (
    <div className='flex flex-col'>
      <div className='type-h2 mb-10 self-start'>Loyal to your brands?</div>
      <p className='type-h5-desktop c-grey-800'>
        Us too! Add your favorite hotel chains here for easier searching.
      </p>
      <div className='grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4'>
        {spotlightBrands.map((brand, index) => (
          <div
            key={index}
            className='flex flex-col items-center justify-center'
          >
            <CheckedTile
              pressed={brandSelected(brand.name, Array.from(brandSelections))}
              size='minHeight'
              onClick={() => handleBrandInteraction(brand.name)}
            >
              <img
                alt={brand.name}
                className='max-w-75 max-h-50 m-2 h-auto w-[95%] rounded-md'
                src={`${brand.imageUrl}500x500`}
              />
            </CheckedTile>
          </div>
        ))}
      </div>
      <div className='mt-10 w-full'>
        <Autocomplete
          autoExpand
          aria-label='Search for brands'
          autoComplete='on'
          placeholder='Not seeing your go to? Search all hotel brands here.'
          ref={autocompleteRef}
          slotBefore={
            <div className='color-new-forest flex'>
              <Icon name='search' />
            </div>
          }
          value={searchValue}
          onChange={event => setSearchValue(event?.target?.value)}
          onOptionSelect={handleBrandInteraction}
        >
          {searchedBrands?.map(({ item }) => (
            <AutocompleteOption
              key={item.name}
              className='color-black rounded-0 transition-background-color-200 type-body-1 focus:bg-grey-100 hover:bg-grey-100 aria-selected:bg-grey-100 w-full cursor-pointer bg-white px-4 py-4 ease-linear'
              value={item.name}
            >
              <div className='flex items-center'>
                {/* TODO - Leaving for when someone inevitably wants to add a "is selected" indicator, nuke this code in a few weeks if no such desires arise */}
                {/* {brandSelections.some(brand => brand === item.name) && (
                  <div className='l-[-4]'>
                    <Icon name='check' size='small' />
                  </div>
                )} */}
                {item.name}
              </div>
            </AutocompleteOption>
          ))}
        </Autocomplete>
        {!isEmpty(brandSelections) && (
          <div className='mt-10 flex flex-wrap gap-4'>
            {brandSelections.map(brandName => (
              <Chip
                key={brandName}
                includeCloseIcon
                label={brandName}
                onClick={() => handleBrandInteraction(brandName)}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
