import type { ApolloError } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'

export const getCurrentUserLikedGuidesQuery = gql(`
  query GetCurrentUserLikedGuides {
    currentUser {
      id
      likedListGuides {
        id
      }
    }
  }
`)

type UseGetUserLikesGuideQuery = {
  hasError: ApolloError
  hasLikedGuide: boolean
  isLoading: boolean
}

export const useGetUserLikesGuideQuery = (
  guideId?: string
): UseGetUserLikesGuideQuery => {
  const {
    data,
    loading: isLoading,
    error: hasError,
  } = useQuery(getCurrentUserLikedGuidesQuery, {
    skip: !guideId,
  })
  const hasLikedGuide = data?.currentUser?.likedListGuides.some(
    guide => guide.id == guideId
  )

  return {
    hasError,
    hasLikedGuide,
    isLoading,
  }
}
