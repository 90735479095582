import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'

const GET_LOCATIONS = gql(`
  query GetLocations {
    getLocations {
      id
      imageUrl
      displayName
      displayOrder
      name
      userRank
    }
  }
`)

export const useGetLocations = () =>
  useQuery(GET_LOCATIONS, {
    fetchPolicy: 'network-only',
  })
