import { Skeleton } from '@travelpass/design-system'
import { DashboardGuideDraftCardLoading } from './DashboardGuideDraftCardLoading'

export const DashboardGuideDraftsLoading = () => (
  <div className='space-y-6'>
    <div className='w-31 h-6.5'>
      <Skeleton variant='rounded' />
    </div>
    <div className='space-y-6'>
      <DashboardGuideDraftCardLoading />
      <DashboardGuideDraftCardLoading />
      <DashboardGuideDraftCardLoading />
    </div>
  </div>
)
