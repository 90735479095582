import {
  Button,
  Modal,
  ModalActions,
  ModalScrollContents,
  useSnackbar,
} from '@travelpass/design-system'
import { GuideStatus } from 'src/__generated__/graphql'
import { HorizontalStepper } from 'src/common/components'
import { getGuideDetailsUrl } from 'src/utils'
import { GuideDraftPublishButtonLoading } from './GuideDraftPublishButtonLoading'
import { GuideDraftPublishDetails } from './GuideDraftPublishDetails'
import { GuideDraftPublishImages } from './GuideDraftPublishImages'
import { GuideDraftPublishReview } from './GuideDraftPublishReview'
import { GuideDraftPublishSuccess } from './GuideDraftPublishSuccess'
import { GuideDraftPublishTags } from './GuideDraftPublishTags'
import { GuideDraftRepublishSettings } from './GuideDraftRepublishSettings'
import { useGuideDraftPublishForm } from './useGuideDraftPublishForm'
import type { GuideDraftData } from '../../types'

const steps = ['Details', 'Tags', 'Photos', 'Publish']

interface GuideDraftRepublishProps {
  guideDraftData: GuideDraftData
  onDismiss: VoidFunction
}

export const GuideDraftRepublish = ({
  guideDraftData,
  onDismiss,
}: GuideDraftRepublishProps) => {
  const {
    formValues,
    invalidFormValues,
    isLoading,
    isPresignedUploading,
    onFormValuesChange,
    onInvalidFormValuesChange,
    onNextStep,
    onPreviousStep,
    onStepChange,
    onSubmit,
    step,
  } = useGuideDraftPublishForm(guideDraftData)
  const { addSuccessSnack } = useSnackbar()
  const { guide } = guideDraftData ?? {}
  const isGuideDraft = formValues.status === GuideStatus.Draft

  const onConvertToDraft = () => {
    onSubmit(() => {
      addSuccessSnack({
        title: 'Your guide is no longer published.',
      })
      onDismiss()
    })
  }

  const onViewGuide = () => {
    window.open(
      getGuideDetailsUrl({
        id: guide?.id ?? '',
        name: guide?.name ?? '',
      })
    )
    onDismiss()
  }

  return (
    <>
      {step === 0 && (
        <Modal title='Edit Privacy' onDismiss={onDismiss}>
          <ModalScrollContents>
            <div className='p-x-2 md:p-x-0 space-y-5 md:space-y-6'>
              <GuideDraftRepublishSettings
                formValues={formValues}
                guideDraftData={guideDraftData}
                onChange={onFormValuesChange}
                onStepChange={onStepChange}
              />
            </div>
          </ModalScrollContents>
          <ModalActions>
            {isGuideDraft && <Button onClick={onConvertToDraft}>Save</Button>}
            {!isGuideDraft && <Button onClick={onNextStep}>Next</Button>}
            <Button variant='outlined' onClick={onDismiss}>
              Cancel
            </Button>
          </ModalActions>
        </Modal>
      )}
      {step === 1 && (
        <Modal title='Edit Details' onDismiss={onDismiss}>
          <ModalScrollContents>
            <div className='p-x-2 md:p-x-0 space-y-5 md:space-y-6'>
              <HorizontalStepper completedSteps={0} steps={steps} />
              <GuideDraftPublishDetails
                formValues={formValues}
                invalidFormValues={invalidFormValues}
                onChange={onFormValuesChange}
              />
            </div>
          </ModalScrollContents>
          <ModalActions>
            <Button autoFocus={true} onClick={onNextStep}>
              Next
            </Button>
            <Button variant='outlined' onClick={onPreviousStep}>
              Back
            </Button>
          </ModalActions>
        </Modal>
      )}
      {step === 2 && (
        <Modal title='Add Tags' onDismiss={onDismiss}>
          <ModalScrollContents>
            <div className='p-x-2 md:p-x-0 space-y-5 md:space-y-6'>
              <HorizontalStepper completedSteps={1} steps={steps} />
              <GuideDraftPublishTags
                formValues={formValues}
                invalidFormValues={invalidFormValues}
                onChange={onFormValuesChange}
              />
            </div>
          </ModalScrollContents>
          <ModalActions>
            <Button autoFocus={true} onClick={onNextStep}>
              Next
            </Button>
            <Button variant='outlined' onClick={onPreviousStep}>
              Back
            </Button>
          </ModalActions>
        </Modal>
      )}
      {step === 3 && (
        <Modal title='Add Photos' onDismiss={onDismiss}>
          <ModalScrollContents>
            <div className='p-x-2 md:p-x-0 space-y-5 md:space-y-6'>
              <HorizontalStepper completedSteps={2} steps={steps} />
              <GuideDraftPublishImages
                formValues={formValues}
                invalidFormValues={invalidFormValues}
                onChange={onFormValuesChange}
                onInvalidFormValuesChange={onInvalidFormValuesChange}
              />
            </div>
          </ModalScrollContents>
          <ModalActions>
            {isPresignedUploading && <GuideDraftPublishButtonLoading />}
            {!isPresignedUploading && (
              <Button autoFocus={true} onClick={onNextStep}>
                Next
              </Button>
            )}
            <Button variant='outlined' onClick={onPreviousStep}>
              Back
            </Button>
          </ModalActions>
        </Modal>
      )}
      {step === 4 && (
        <Modal title='Confirm Guide Details' onDismiss={onDismiss}>
          <ModalScrollContents>
            <div className='p-x-2 md:p-x-0 space-y-5 md:space-y-6'>
              <HorizontalStepper completedSteps={3} steps={steps} />
              <GuideDraftPublishReview
                formValues={formValues}
                onStepChange={onStepChange}
              />
            </div>
          </ModalScrollContents>
          <ModalActions>
            {isLoading && <GuideDraftPublishButtonLoading />}
            {!isLoading && (
              <Button autoFocus={true} onClick={onNextStep}>
                Confirm and Update
              </Button>
            )}
            <Button variant='outlined' onClick={onPreviousStep}>
              Back
            </Button>
          </ModalActions>
        </Modal>
      )}
      {step === 5 && (
        <Modal title='Success!' onDismiss={onDismiss}>
          <ModalScrollContents>
            <div className='p-x-2 md:p-x-0 space-y-5 md:space-y-6'>
              <GuideDraftPublishSuccess
                formValues={formValues}
                guideDraftData={guideDraftData}
              />
            </div>
          </ModalScrollContents>
          <ModalActions>
            <Button onClick={onViewGuide}>View Guide</Button>
            <Button variant='outlined' onClick={onDismiss}>
              Done
            </Button>
          </ModalActions>
        </Modal>
      )}
    </>
  )
}
