import classNames from 'classnames'
import { BookingDetailsContactCard } from 'src/common/components'
import { lucencyNumber } from 'src/config/analytics/lucencyUtils'
import { formatPhoneNumber } from 'src/utils'
import { bookingDetailsManageContactCss } from '../../../constants'
import type { BookingDetailsHotel } from '../bookingDetailsManageTypes'
interface BookingDetailsManageProps {
  bookingDetailsHotelData: BookingDetailsHotel
}

export const BookingDetailsManageContact = ({
  bookingDetailsHotelData,
}: BookingDetailsManageProps) => {
  const { phoneNumbers } = bookingDetailsHotelData ?? {}
  const constructedLucencyPhoneNumber = lucencyNumber()
  const constructedPhoneNumber = formatPhoneNumber(
    phoneNumbers?.slice()?.shift()
  )
  const showContact =
    !!constructedPhoneNumber || !!constructedLucencyPhoneNumber

  const constructContact = [
    {
      className: '',
      isHidden: !constructedPhoneNumber,
      phoneNumber: constructedPhoneNumber,
      subtitle:
        'For questions about check-in, property details, and amenities please contact your hotel directly.',
      title: 'Contact Hotel',
    },
    {
      className: 'hide-on-print',
      isHidden: !constructedLucencyPhoneNumber,
      phoneNumber: constructedLucencyPhoneNumber,
      subtitle:
        "For any reservation question, contact Travelpass directly or check out our FAQ's",
      title: 'Contact Travelpass',
    },
  ]

  const constructedContact = () =>
    constructContact.map(
      ({ className, isHidden, phoneNumber, subtitle, title }) =>
        !isHidden && (
          <div key={title} className={classNames('w-full', className)}>
            <BookingDetailsContactCard
              phoneNumber={phoneNumber}
              subtitle={subtitle}
              title={title}
            />
          </div>
        )
    )

  return (
    showContact && (
      <div
        className={bookingDetailsManageContactCss}
        data-testid='BookingDetailsManageContact'
      >
        {constructedContact()}
      </div>
    )
  )
}
