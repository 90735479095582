import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'
import {
  guideDraftGroupedEventFragmentOld,
  useUpdateGuideDraftQueryCache,
} from './useGetGuideDraftQuery'

const updateGuideDraftMutation = gql(`
  mutation UpdateGuideDraftMutationInGuideDraftOld(
    $input: UpdateGuideDraftInput!
  ) {
    updateGuideDraft(input: $input) {
      guideDraft {
        id
        addresses {
          id
          addressLine1
          city
          country
          googlePlaceId
          lat
          long
          state
        }
        description
        groupedEvents {
          ...GuideDraftGroupedEventFields
        }
        guide {
          id
          status
        }
        hasUnpublishedChanges
        images {
          id
          source
          type
          url
        }
        imageUrl
        name
        ownerProfile {
          id
          accountHandle
          displayName
          guides {
            id
          }
          isUserOwner
          profileImageUrl
          profileImageSource
          userId
        }
        status
        updatedAt
      }
    }
  }
`)

/** @todo hardcoding `hasUnpublishedChanges` for now until we have a better way to handle this */
export const useUpdateGuideDraftMutation = (hasUnpublishedChanges = true) => {
  const { updateGuideDraftQueryCache } = useUpdateGuideDraftQueryCache()

  return useMutation(updateGuideDraftMutation, {
    update: (cache, { data }) => {
      const updatedCache = updateGuideDraftQueryCache(
        data?.updateGuideDraft?.guideDraft,
        hasUnpublishedChanges
      )

      if (!updatedCache) return

      cache.modify(updatedCache)
    },
  })
}
