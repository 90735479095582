import { useRef, useState } from 'react'
import {
  IconButton,
  MenuItem,
  StopPropagation,
  useSnackbar,
} from '@travelpass/design-system'
import { Popover } from 'react-tiny-popover'
import type { GuideDraft } from 'src/__generated__/graphql'
import { GuideStatus } from 'src/__generated__/graphql'
import { useIncrementGuideShareCountMutation } from 'src/pages/guides'
import {
  GuideDraftDeleteModalOld,
  GuideDraftEditModalOld,
} from 'src/pages/guides/lists-old/common'
import { getGuideDetailsUrl, shareGuide } from 'src/utils'

/** @todo replace GuideDraft with GuideDraftData (when pagination is implemented) */
interface DashboardGuideDraftCardPopoverMenuProps {
  guideDraftData: Pick<GuideDraft, 'guide' | 'id' | 'name'>
  isUserOwner: boolean
}

export const DashboardGuideDraftCardPopoverMenu = ({
  guideDraftData,
  isUserOwner,
}: DashboardGuideDraftCardPopoverMenuProps) => {
  const [hasShared, setHasShared] = useState(false)
  const [isGuideDraftEditModalOldOpen, setIsGuideDraftEditModalOldOpen] =
    useState(false)
  const [isGuideDraftDeleteModalOldOpen, setIsGuideDraftDeleteModalOldOpen] =
    useState(false)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const { id, name, guide } = guideDraftData ?? {}
  const [incrementShareCount] = useIncrementGuideShareCountMutation()
  const { addSuccessSnack } = useSnackbar()
  const ref = useRef<HTMLDivElement>(null)

  const onDeleteGuide = () => {
    setIsGuideDraftDeleteModalOldOpen(true)
    setIsPopoverOpen(false)
  }

  const onEditGuide = () => {
    setIsPopoverOpen(false)
    setIsGuideDraftEditModalOldOpen(true)
  }

  const onShareGuide = async () => {
    await shareGuide({
      guideDraftTitle: name,
      isUserOwner,
      shareUrl: `${window.location.origin}${getGuideDetailsUrl({ id: guide?.id, name })}`,
      onSuccessfulShare: () => {
        setIsPopoverOpen(false)
        addSuccessSnack({ timeout: 1000, title: 'Guide URL copied' })
      },
    })
    if (!hasShared) {
      incrementShareCount({ variables: { guideId: id } })
      setHasShared(true)
    }
  }

  return (
    <>
      <Popover
        containerClassName='z-5'
        content={
          <StopPropagation>
            <div className='shadow-1 w-50 mr-6 mt-1 rounded-xl bg-white p-2'>
              {guideDraftData?.guide?.status === GuideStatus.Published && (
                <MenuItem
                  aria-label='Share Guide'
                  label='Share'
                  startIcon='iosShare'
                  value='Share'
                  onClick={onShareGuide}
                />
              )}
              <MenuItem
                aria-label='Edit Guide name/description'
                label='Edit'
                startIcon='modeEditOutline'
                value='Edit'
                onClick={onEditGuide}
              />
              <MenuItem
                aria-label='Delete Guide'
                label='Delete'
                startIcon='deleteOutline'
                value='Delete'
                onClick={onDeleteGuide}
              />
            </div>
          </StopPropagation>
        }
        isOpen={isPopoverOpen}
        positions={['bottom']}
        onClickOutside={() => setIsPopoverOpen(false)}
      >
        <div ref={ref}>
          <StopPropagation>
            <div className='[&>button]:color-grey-800'>
              <IconButton
                aria-label='Guide Settings'
                icon='moreHoriz'
                onClick={() => setIsPopoverOpen(!isPopoverOpen)}
              />
            </div>
          </StopPropagation>
        </div>
      </Popover>
      {isGuideDraftEditModalOldOpen && (
        <GuideDraftEditModalOld
          guideDraftData={guideDraftData}
          onDismiss={() => setIsGuideDraftEditModalOldOpen(false)}
        />
      )}
      {isGuideDraftDeleteModalOldOpen && (
        <GuideDraftDeleteModalOld
          guideDraftData={guideDraftData}
          onDismiss={() => setIsGuideDraftDeleteModalOldOpen(false)}
        />
      )}
    </>
  )
}
