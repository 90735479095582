import {
  Button,
  Divider,
  Icon,
  IconButton,
  Input,
  useSnackbar,
} from '@travelpass/design-system'
import { copyToClipboard, getGuideDetailsUrl } from 'src/utils'
import {
  GuideDraftPublishGroup,
  GuideDraftPublishGroupHeader,
} from './GuideDraftPublishGroup'
import { GuideDraftPublishHero } from './GuideDraftPublishHero'
import type { UseGuideDraftPublishForm } from './types'
import type { GuideDraftDataOld } from '../../types'

interface GuideDraftPublishSuccessProps {
  formValues: UseGuideDraftPublishForm['formValues']
  guideDraftData: GuideDraftDataOld
}

export const GuideDraftPublishSuccess = ({
  formValues,
  guideDraftData,
}: GuideDraftPublishSuccessProps) => {
  const { addSuccessSnack } = useSnackbar()
  const { guide } = guideDraftData ?? {}
  const { id, name } = guide ?? {}
  const guideUrl = `${window.location.origin}${getGuideDetailsUrl({ id, name })}`

  const onCopy = () => {
    copyToClipboard(guideUrl)
    addSuccessSnack({
      title: 'Copied to clipboard!',
    })
  }

  return (
    <GuideDraftPublishGroup>
      <div className='flex flex-col gap-4 md:flex-row md:gap-7'>
        <div className='md:min-w-62 h-40 md:h-auto lg:min-w-72'>
          <GuideDraftPublishHero formValues={formValues} />
        </div>
        <div className='space-y-4 md:space-y-6'>
          {/** @todo add the lock icon to the DS */}
          <div className='type-h6-desktop md:p-t-15 flex flex-row items-center gap-2'>
            <Icon name='visibility' />
            Your Guide is Live
            <span className='rounded-1/2 bg-valley h-3 w-3' />
          </div>
          <Divider />
          <GuideDraftPublishGroupHeader
            subtitle='Copy the link below to share your updated guide. You can also select View Guide to view your successfully published guide.'
            title='Copy To Share'
          />
          <Input
            fullWidth={true}
            readOnly={true}
            slotBefore={
              <IconButton
                aria-label='Copy guide URL'
                autoFocus={true}
                color='transparent'
                icon='contentCopy'
                onClick={onCopy}
              />
            }
            value={guideUrl}
          />
        </div>
      </div>
    </GuideDraftPublishGroup>
  )
}
