import { Skeleton } from '@travelpass/design-system'
import { useFlag } from 'src/common/hooks'

export const DashboardGuideDraftCardLoading = () => {
  const isGuideMetricsViewCountHideEnabled = useFlag(
    'guideMetricsViewCountHide'
  )

  return (
    <>
      <div className='h-77 p-x-6 p-y-4 rounded-3 shadow-1 hidden w-full bg-white md:block'>
        <div className='flex h-full flex-row gap-6'>
          <div className='flex grow flex-col space-y-2'>
            <div className='h-5.25 w-65 lg:h-9'>
              <Skeleton />
            </div>
            <div className='h-6 w-44 lg:h-6'>
              <Skeleton />
            </div>
            <div className='flex flex-row items-center'>
              <div className='p-l-0 p-1.5'>
                <div className='of-hidden rounded-1/2 h-9 w-9'>
                  <Skeleton />
                </div>
              </div>
              <div className='flex flex-col justify-center'>
                <div className='h-4 w-20 lg:h-3.5'>
                  <Skeleton />
                </div>
                <div className='w-25 h-3'>
                  <Skeleton />
                </div>
              </div>
            </div>
            <div className='w-full grow'>
              <Skeleton />
            </div>
            <div className='p-l-1.5 flex flex-row items-center gap-5 lg:gap-6'>
              <div className='h-4 w-20'>
                <Skeleton variant='rounded' />
              </div>
              {!isGuideMetricsViewCountHideEnabled && (
                <div className='w-21 h-4'>
                  <Skeleton variant='rounded' />
                </div>
              )}
              <div className='h-4 w-24'>
                <Skeleton variant='rounded' />
              </div>
              <div className='h-4 w-24'>
                <Skeleton variant='rounded' />
              </div>
            </div>
          </div>
          <div className='h-full min-w-60'>
            <Skeleton variant='rounded' />
          </div>
        </div>
      </div>
      <div className='m-.5 p-x-6 rd-4 shadow-1 block h-full w-full space-y-2 bg-white py-4 md:hidden'>
        <div className='h-60 w-full'>
          <Skeleton variant='rounded' />
        </div>
        <div className='space-y-3'>
          <div className='space-y-2'>
            <div className='h-5.25 w-52'>
              <Skeleton />
            </div>
            <div className='h-4.5 w-35'>
              <Skeleton />
            </div>
          </div>
          <div className='flex flex-row items-center'>
            <div className='p-l-0 p-1.5'>
              <div className='of-hidden rounded-1/2 h-9 w-9'>
                <Skeleton />
              </div>
            </div>
            <div>
              <div className='h-4 w-20'>
                <Skeleton />
              </div>
              <div className='w-25 h-3'>
                <Skeleton />
              </div>
            </div>
          </div>
          <div className='h-9 w-full'>
            <Skeleton />
          </div>
          <div className='flex flex-row items-center gap-9'>
            <div className='h-5.5 w-20'>
              <Skeleton variant='rounded' />
            </div>
            <div className='w-21 h-6'>
              <Skeleton variant='rounded' />
            </div>
            <div className='h-6 w-24'>
              <Skeleton variant='rounded' />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
