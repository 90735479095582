import { useState } from 'react'
import { Button } from '@travelpass/design-system'
import classNames from 'classnames'
import { PasswordForm } from './PasswordForm'

export const PasswordContainer = () => {
  const [showForm, setShowForm] = useState(false)

  return (
    <div className={classNames({ 'flex justify-between': !showForm })}>
      {showForm ? (
        <PasswordForm onClose={() => setShowForm(false)} />
      ) : (
        <>
          <div>
            <p className='type-body-1-desktop'>Password</p>
            <p>••••••••</p>
          </div>
          <Button
            label='update'
            variant='outlined'
            onClick={() => setShowForm(true)}
          />
        </>
      )}
    </div>
  )
}
