import { Skeleton } from '@travelpass/design-system'

export const GuideHeaderTagsLoading = () => (
  <div className='flex flex-row flex-wrap items-center gap-4'>
    <div className='min-w-30 rounded-12.5 h-7 overflow-hidden'>
      <Skeleton />
    </div>
    <div className='min-w-30 rounded-12.5 h-7 overflow-hidden'>
      <Skeleton />
    </div>
    <div className='min-w-30 rounded-12.5 h-7 overflow-hidden'>
      <Skeleton />
    </div>
    <div className='min-w-30 rounded-12.5 h-7 overflow-hidden'>
      <Skeleton />
    </div>
  </div>
)
