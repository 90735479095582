import { useState } from 'react'
import { useParams } from 'react-router-dom'
import type {
  AddHotelRecommendationMutation,
  UserProfile,
} from 'src/__generated__/graphql'
import { Geocoder } from 'src/common/components'
import type { GeocoderType } from 'src/constants/user'
import { initialGeocoder } from 'src/constants/user'
import { RecommendedHotelListItem } from './RecommendedHotelListItem'
import { RecommendedHotelsZeroState } from './RecommendedHotelsZeroState'
import { useAddHotelRecommendation } from './hooks/useAddHotelRecommendation'
import { useGetProfile } from '../../../hooks/useGetProfile'

interface FavoriteHotelsProps {
  profile: UserProfile
}

type RecommendedHotel =
  AddHotelRecommendationMutation['addHotelRecommendation']['hotelRecommendation']

export const RecommendedHotels = ({ profile }: FavoriteHotelsProps) => {
  const { accountHandle } = useParams()
  const { refetch } = useGetProfile(accountHandle)
  const { hotelRecommendations } = { ...profile }
  //State
  const [selectedHotel, setSelectedHotel] = useState<RecommendedHotel | null>(
    null
  )
  //GraphQl
  const [addHotelRecommendation] = useAddHotelRecommendation()

  const onResult = async (updatedGeocoder: GeocoderType) => {
    const { placeId } = { ...updatedGeocoder }
    if (placeId) {
      const { errors, data } = await addHotelRecommendation({
        variables: { input: { googlePlaceId: placeId } },
      })
      if (!errors) {
        setSelectedHotel(data.addHotelRecommendation.hotelRecommendation)
        refetch()
      }
    }
  }

  const sortHotels = () => {
    if (!hotelRecommendations) return

    const copy = [...hotelRecommendations]
    const i = copy.findIndex(({ id }) => id === selectedHotel?.id)

    if (i > -1) {
      const [objectToMove] = copy.splice(i, 1)
      copy.unshift(objectToMove) //Move selected hotel to the of the list
    }
    return copy
  }

  const displayList = sortHotels()

  return (
    <div>
      <Geocoder
        aria-label='Search your favorite hotels'
        config={{ requestOptions: { types: ['lodging'] } }}
        geocoder={initialGeocoder}
        placeholder='Search your favorite hotels'
        onResult={onResult}
      />
      {displayList?.length == 0 && <RecommendedHotelsZeroState />}

      {displayList?.length > 0 && (
        <>
          {displayList?.map(hotel => (
            <RecommendedHotelListItem key={hotel.id} hotel={hotel} />
          ))}
        </>
      )}
    </div>
  )
}
