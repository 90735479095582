import type { UserProfile } from 'src/__generated__/graphql'

/** @todo will be removed when implemented on the BE */
export const getDashboardAccountOldCompletionPercentage = (
  userProfile: UserProfile
) => {
  const {
    accountHandle,
    bio,
    introVideoEmbed,
    profileImageUrl,
    socialLinks,
    links,
  } = userProfile ?? {}
  const profileFieldsAsBooleans = [
    !!accountHandle,
    !!bio,
    !!introVideoEmbed,
    !!links?.length,
    !!profileImageUrl,
    !!socialLinks?.length,
  ]
  const totalFields = profileFieldsAsBooleans.length
  const completedFields = profileFieldsAsBooleans.filter(Boolean).length

  return Math.round((completedFields / totalFields) * 100)
}
