export const DefaultRecommendedHotelImage = () => (
  <svg fill='none' viewBox='0 0 152 127' xmlns='http://www.w3.org/2000/svg'>
    <path
      clipRule='evenodd'
      d='M129.784 4.904a6.618 6.618 0 0 0-8.102-4.68C99.639 6.133 26.946 25.61 4.904 31.517a6.614 6.614 0 0 0-4.016 3.082 6.613 6.613 0 0 0-.663 5.022c4.625 17.254 17.321 64.641 21.946 81.894a6.613 6.613 0 0 0 8.102 4.679c22.044-5.904 94.737-25.384 116.778-31.288a6.607 6.607 0 0 0 4.016-3.085 6.603 6.603 0 0 0 .663-5.02L129.784 4.904Z'
      fill='#E4E5EE'
      fillRule='evenodd'
    />
    <path
      clipRule='evenodd'
      d='M147.348 94.76a6.352 6.352 0 0 0 4.434-7.764l-3.906-14.576-6.758 24.074s3.129-.872 6.23-1.734Z'
      fill='#E4E5EE'
      fillRule='evenodd'
    />
    <path
      clipRule='evenodd'
      d='M149.968 15.343a6.301 6.301 0 0 0-6.301-6.302H22.14a6.298 6.298 0 0 0-6.302 6.302v85.417c0 1.669.664 3.274 1.845 4.455a6.305 6.305 0 0 0 4.457 1.844h121.527a6.302 6.302 0 0 0 6.301-6.299V15.343Z'
      fill='#F7F7FC'
      fillRule='evenodd'
    />
    <path
      clipRule='evenodd'
      d='M149.652 19.567a6.299 6.299 0 0 0-6.302-6.302H25.463a6.298 6.298 0 0 0-6.302 6.302v81.775a6.3 6.3 0 0 0 6.302 6.299H143.35a6.3 6.3 0 0 0 6.302-6.299V19.567Z'
      fill='#DADCE2'
      fillRule='evenodd'
    />
    <path
      clipRule='evenodd'
      d='M137.071 27.097a5.16 5.16 0 0 0-5.159-5.159H33.894a5.159 5.159 0 0 0-5.158 5.16v61.905a5.157 5.157 0 0 0 5.158 5.16h98.018a5.159 5.159 0 0 0 5.159-5.16V27.097Z'
      fill='#F7F7FC'
      fillRule='evenodd'
    />
    <path
      clipRule='evenodd'
      d='M28.736 82.555c19.232-13.751 46.112-28.975 72.223-12.897 4.071 2.507 16.377-16.834 36.112 2.579v16.766a5.159 5.159 0 0 1-5.159 5.16H33.894a5.157 5.157 0 0 1-5.158-5.16v-6.448Z'
      fill='#158481'
      fillRule='evenodd'
    />
    <path
      clipRule='evenodd'
      d='M39.239 53.467a2.586 2.586 0 0 1-2.17-1.241 2.58 2.58 0 0 1-.095-2.5c1.514-3.02 4.3-6.061 8.36-5.734-.093-12.438 19.41-13.663 21.86-4.333 10.427.382 7.437 6.247 19.501 12.255a1.18 1.18 0 0 1-.694 2.143c-11.899-.067-37.422-.448-46.762-.59Z'
      fill='#D9B59B'
      fillRule='evenodd'
    />
    <path
      d='M104.828 54.181a9.028 9.028 0 1 0 0-18.056 9.028 9.028 0 0 0 0 18.056Z'
      fill='#FFA614'
    />
  </svg>
)
