import { Divider, Skeleton } from '@travelpass/design-system'
import { PageLayoutContainer } from 'src/common/components'
import { GuideDraftTopNavMenuLoading } from './GuideDraftTopNavMenuLoading'

export const GuideDraftTopNavLoading = () => (
  <div className='lg:z-4 bg-white lg:sticky lg:top-0'>
    <PageLayoutContainer size='none'>
      <div className='p-y-5 lg:p-y-3.5 flex flex-row items-center gap-8'>
        <div className='flex min-w-0 grow flex-row gap-4 lg:items-center lg:gap-3.5'>
          <div className='h-7 w-7 lg:h-8 lg:w-8'>
            <Skeleton variant='rounded' />
          </div>
          <div className='flex grow flex-col gap-2 lg:flex-row lg:gap-6'>
            <div className='h-5.25 w-30 md:w-75 lg:h-9 lg:w-40'>
              <Skeleton />
            </div>
            <div className='flex flex-row items-center gap-6'>
              <div className='h-4 w-20 lg:h-5'>
                <Skeleton />
              </div>
            </div>
          </div>
        </div>
        <GuideDraftTopNavMenuLoading />
      </div>
    </PageLayoutContainer>
    <Divider className='bg-grey-200 h-1px w-full' />
  </div>
)
