import isEmpty from 'lodash.isempty'
import {
  type GetGuideEventsQueryInGuideDetailsQuery,
  type GetGuideDraftQueryInGuideDraftQuery,
  type GenericAddress,
  type PublishedEvent,
  type Guide,
} from 'src/__generated__/graphql'
import { constructAddress } from 'src/utils'
import type {
  GuideData,
  GuideEventsData,
  GuideMetaData,
  GuideOwner,
} from './types'
import type { GuideDraftData } from '../lists'

/** @todo remove when eventCount returns the correct value */
const getGuideEventCount = ({
  paginatedEventCategories,
  uncategorizedPublishedEvents,
}: {
  paginatedEventCategories: GuideData['paginatedEventCategories']
  uncategorizedPublishedEvents: GuideData['uncategorizedPublishedEvents']
}) => {
  const paginatedEventCount =
    paginatedEventCategories?.edges?.reduce(
      (total, current) => total + current.node.publishedEvents.totalCount,
      0
    ) ?? 0
  const uncategorizedEventCount = uncategorizedPublishedEvents?.totalCount ?? 0

  return paginatedEventCount + uncategorizedEventCount
}

const getGuideEventMarkers = ({
  paginatedEventCategories,
  uncategorizedPublishedEvents,
}: {
  paginatedEventCategories: GuideEventsData['paginatedEventCategories']
  uncategorizedPublishedEvents: GuideEventsData['uncategorizedPublishedEvents']
}): PublishedEvent[] => {
  if (
    isEmpty(paginatedEventCategories) &&
    isEmpty(uncategorizedPublishedEvents)
  )
    return []

  const flattenedPublishedEvents = paginatedEventCategories?.edges?.flatMap(
    paginatedEventCategory =>
      paginatedEventCategory?.node?.publishedEvents?.edges ?? []
  )

  return flattenedPublishedEvents
    .concat(uncategorizedPublishedEvents?.edges ?? [])
    ?.reduce((total, publishedEvent) => {
      const { addresses } = publishedEvent?.node ?? {}
      const [address] = addresses ?? []

      if (address?.lat && address?.long) total.push(publishedEvent?.node)

      return total
    }, [])
}

const getGuideEventMarkersOld = (
  groupedEvents: Guide['groupedEvents']
): PublishedEvent[] => {
  if (!groupedEvents?.length) return []

  return groupedEvents
    ?.flatMap(groupedEvent => groupedEvent?.events ?? [])
    ?.reduce((total, event) => {
      const { addresses } = event ?? {}
      const [address] = addresses ?? []

      if (address?.lat && address?.long) total.push(event)

      return total
    }, [] as PublishedEvent[])
}

const getGuideMetaData = ({
  address,
  image,
  name,
  numberEvents,
  ownerName,
}: {
  address: GenericAddress
  image: string
  name: Guide['name']
  numberEvents: number
  ownerName: GuideOwner['name']
}): GuideMetaData => {
  return {
    metaDescription: getGuideMetaDescription({
      address,
      numberEvents,
      ownerName,
    }),
    metaImage: image ?? '',
    pageName: name ?? '',
  }
}

const getGuideMetaDescription = ({
  address,
  numberEvents,
  ownerName,
}: {
  address: GenericAddress
  numberEvents: number
  ownerName: GuideOwner['name']
}) => {
  const addressFormatted = constructAddress({
    addressFirstLine: address?.addressLine1,
    city: address?.city,
    state: address?.state,
  })
  let description = ''

  if (addressFormatted)
    description += `Discover what to do in ${addressFormatted}! `

  if (ownerName) description += `${ownerName} recommends `

  if (numberEvents > 1) description += `${numberEvents} `

  return `${description}things to do, places to eat, hidden gems, and more.`
}

const getGuideUpdateQuery = ({
  fetchMoreResult,
  id,
  previousResult,
}: {
  fetchMoreResult:
    | GetGuideEventsQueryInGuideDetailsQuery
    | GetGuideDraftQueryInGuideDraftQuery
  id: string
  previousResult:
    | GetGuideEventsQueryInGuideDetailsQuery
    | GetGuideDraftQueryInGuideDraftQuery
}) => {
  const fetchMoreNode = fetchMoreResult?.node as
    | GuideDraftData
    | GuideEventsData
  const previousNode = previousResult?.node as GuideDraftData | GuideEventsData
  const uncategorizedEvents =
    fetchMoreNode?.uncategorizedPublishedEvents?.edges || []
  const fetchMoreCategory =
    fetchMoreNode?.paginatedEventCategories?.edges?.find(
      category => category?.node?.id === id
    )
  const updatedUncategorizedPublishedEvents = {
    ...previousNode.uncategorizedPublishedEvents,
  }

  // Update the uncategorized events
  if (!id && !!uncategorizedEvents.length) {
    updatedUncategorizedPublishedEvents.edges = [
      ...(previousNode?.uncategorizedPublishedEvents?.edges || []),
      ...uncategorizedEvents,
    ]
    updatedUncategorizedPublishedEvents.pageInfo =
      fetchMoreNode?.uncategorizedPublishedEvents?.pageInfo
  }

  // Update the paginated event categories
  const updatedPaginatedEventCategories = {
    ...previousNode.paginatedEventCategories,
    edges: previousNode.paginatedEventCategories.edges.map(category => {
      if (category?.node?.id === id) {
        return {
          ...category,
          node: {
            ...category.node,
            publishedEvents: {
              ...category.node.publishedEvents,
              edges: [
                ...(category.node.publishedEvents.edges || []),
                ...(fetchMoreCategory?.node?.publishedEvents?.edges || []),
              ],
              pageInfo: fetchMoreCategory?.node?.publishedEvents?.pageInfo,
            },
          },
        }
      }

      return category
    }),
  }

  // Return the updated result
  return {
    ...previousResult,
    node: {
      ...previousNode,
      paginatedEventCategories: updatedPaginatedEventCategories,
      uncategorizedPublishedEvents: updatedUncategorizedPublishedEvents,
    },
  }
}

export {
  getGuideEventCount,
  getGuideEventMarkers,
  getGuideEventMarkersOld,
  getGuideMetaData,
  getGuideUpdateQuery,
}
