import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'
import {
  guideDraftGroupedEventFragmentOld,
  useUpdateGuideDraftQueryCache,
} from './useGetGuideDraftQuery'

const archivePublishedEventMutation = gql(`
  mutation ArchivePublishedEventMutationInGuideDraftOld($archivePublishedEventInput: ArchivePublishedEventInput!) {
    archivePublishedEvent(archivePublishedEventInput: $archivePublishedEventInput) {
      guideDraft {
        id
        groupedEvents {
          ...GuideDraftGroupedEventFields
        }
        guide {
          id
          status
        }
        hasUnpublishedChanges
      }
    }
  }
`)

export const useArchiveGuideDraftEventMutation = () => {
  const { updateGuideDraftQueryCache } = useUpdateGuideDraftQueryCache()

  return useMutation(archivePublishedEventMutation, {
    update: (cache, { data }) => {
      const updatedCache = updateGuideDraftQueryCache(
        data?.archivePublishedEvent?.guideDraft
      )

      if (!updatedCache) return

      cache.modify(updatedCache)
    },
  })
}
