import { useState, useRef, useEffect } from 'react'
import { Carousel, useScreenQuery } from '@travelpass/design-system'
import {
  IMAGE_SIZE_MOBILE,
  IMAGE_SIZE_DESKTOP,
  MINI_IMAGE_SIZE_MOBILE,
  MINI_IMAGE_SIZE_DESKTOP,
} from 'src/pages/hotels/details/constants'
import { HotelModalImagesScroll } from './HotelModalImagesScroll'
import { constructImageLinks } from './hotelModalUtils'

interface HotelModalImagesProps {
  hotelName: string
  hotelImages: string[]
  initialSlideIndex?: number
}

export const HotelModalImages = ({
  hotelName,
  hotelImages,
  initialSlideIndex = 0,
}: HotelModalImagesProps) => {
  const { isMobileScreen } = useScreenQuery()
  const [hasFirstImageLoaded, setHasFirstImageLoaded] = useState(false)
  const [slideIndex, setSlideIndex] = useState(initialSlideIndex)
  const [imageLinks, setImageLinks] = useState(hotelImages)
  const scrollContainerRef = useRef(null)
  const carouselImages = constructImageLinks(
    imageLinks,
    isMobileScreen ? IMAGE_SIZE_MOBILE : IMAGE_SIZE_DESKTOP
  )
  const minifiedImagesList = constructImageLinks(
    imageLinks,
    isMobileScreen ? MINI_IMAGE_SIZE_MOBILE : MINI_IMAGE_SIZE_DESKTOP
  )

  const hasSingleImage = imageLinks.length === 1
  const onSetSlideIndex = (index: number) => setSlideIndex(index)

  const onLoad = () => {
    if (!hasFirstImageLoaded) setHasFirstImageLoaded(true)
  }
  const onError = (errorImage: string) =>
    setImageLinks(hotelImages =>
      hotelImages.filter(image => errorImage !== image)
    )

  useEffect(() => {
    document?.getElementById(`wheel-images-${slideIndex}`)?.scrollIntoView({
      behavior: 'smooth',
    })
  }, [slideIndex])

  return (
    <div className='md:h-400px grid h-auto grid-cols-1 gap-4 md:grid-cols-4 md:gap-3'>
      <div className='col-span-1 md:col-span-3'>
        <Carousel
          disableInfinite
          hideDots
          isLight
          keyBoardControl
          showArrowsOnHover
          beforeChange={nextSlide => setSlideIndex(nextSlide)}
          borderRadius={12}
          hideArrows={hasSingleImage}
          isDisabled={!hasFirstImageLoaded}
          size='small'
          slideIndex={slideIndex}
        >
          {carouselImages.map((image, index) => {
            const imageAlt = `${hotelName} hero modal carousel image ${
              index + 1
            }`

            return (
              <div key={imageAlt}>
                <img
                  alt={imageAlt}
                  className='md:h-400px h-auto w-full object-cover'
                  src={image}
                  onError={() => onError}
                  onLoad={onLoad}
                />
              </div>
            )
          })}
        </Carousel>
      </div>
      <HotelModalImagesScroll
        hotelName={hotelName}
        images={minifiedImagesList}
        scrollContainerRef={scrollContainerRef}
        slideIndex={slideIndex}
        onClick={onSetSlideIndex}
      />
    </div>
  )
}
