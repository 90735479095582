import { Helmet } from 'src/common/components'
import { HomeCompany } from './HomeCompany'
import { HomeDestinations } from './HomeDestinations'
import { HomeHero } from './HomeHero'
import { HomeTrip } from './HomeTrip'

export const Home = () => (
  <>
    <Helmet canonicalUrl='https://www.travelpass.com' />
    <HomeHero />
    <HomeTrip />
    <HomeDestinations />
    <HomeCompany />
  </>
)
