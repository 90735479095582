import { useEffect } from 'react'
import {
  Button,
  Modal,
  ModalActions,
  ModalScrollContents,
  useSnackbar,
} from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import { useNavigate } from 'react-router-dom'
import {
  GuideDraftStatus,
  type GetTripDetailsQueryInTripsQuery,
} from 'src/__generated__/graphql'
import { getTripDetailsQuery } from 'src/pages/trips/hooks'
import { getGuideDraftDetailsUrl } from 'src/utils'
import { GuideDraftPublishIntro } from './GuideDraftPublishIntro'
import { useCreateGuideDraftFromTripMutation } from '../../../useCreateGuideDraftFromTripMutation'

interface GuideDraftPublishFromTripProps {
  onDismiss: () => void
  tripDetailsData: GetTripDetailsQueryInTripsQuery['getTrip']
}

export const GuideDraftPublishFromTrip = ({
  onDismiss,
  tripDetailsData,
}: GuideDraftPublishFromTripProps) => {
  const [
    createGuideDraftFromTrip,
    { loading: isCreateGuideDraftFromTripLoading },
  ] = useCreateGuideDraftFromTripMutation()
  const navigate = useNavigate()
  const { addSuccessSnack } = useSnackbar()
  const { id, guideDraft } = tripDetailsData ?? {}

  useEffect(() => {
    if (
      !isEmpty(guideDraft) &&
      guideDraft?.status === GuideDraftStatus.Active
    ) {
      navigate(
        getGuideDraftDetailsUrl({
          id: guideDraft?.id ?? '',
          isUserOwner: true,
          name: guideDraft?.name ?? '',
        })
      )
    }
  }, [guideDraft])

  const onSubmit = async () => {
    if (isCreateGuideDraftFromTripLoading) return

    try {
      /** @todo remove refetch if possible */
      await createGuideDraftFromTrip({
        variables: {
          input: {
            tripId: id,
          },
        },
        refetchQueries: [
          {
            query: getTripDetailsQuery,
            variables: {
              tripId: id ?? '',
            },
          },
        ],
      })
      addSuccessSnack({
        title: 'Your guide is now being published.',
      })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Modal title='Publish to the Travelpass Community' onDismiss={onDismiss}>
      <ModalScrollContents>
        <GuideDraftPublishIntro
          subtitle='Creating a Guide will let you customize your details and share your experience.'
          title='Create a Guide From Your Trip'
        />
      </ModalScrollContents>
      <ModalActions>
        <Button disabled={isCreateGuideDraftFromTripLoading} onClick={onSubmit}>
          Create Guide
        </Button>
        <Button variant='outlined' onClick={onDismiss}>
          Cancel
        </Button>
      </ModalActions>
    </Modal>
  )
}
