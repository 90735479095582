import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'

const GET_ALL_AMENITIES = gql(`
  query GetAllAmenities {
    getAllAmenities {
      id
      displayName
      displayOrder
      iconName
      typeName
      isSelected
    }
  }
`)

export const useGetAllAmenities = () =>
  useQuery(GET_ALL_AMENITIES, {
    fetchPolicy: 'network-only',
  })
