import { Button } from '@travelpass/design-system'
import { useNavigate } from 'react-router-dom'
import { tripsPath } from 'src/constants'
import { HomeContainer } from '../HomeContainer'

export const HomeTripPlanner = () => {
  const navigate = useNavigate()

  return (
    <HomeContainer>
      <div className='h-663px sm:mt--169px md:mt--69.5 rounded-30px max-w-348 relative mx-auto w-full'>
        <img
          alt='trip planner'
          className='rounded-30px relative h-full w-full object-cover'
          id='trip-planner-hero-image'
          src='https://static.travelpass.com/assets/homev2-page/trip-planner.webp'
        />
        <div
          className='md:items-initial sm:top-50% md:top-25% sm:w-276px md:ml-50px lg:ml-87px absolute flex flex-col sm:left-0 sm:right-0 sm:m-auto sm:items-center sm:text-center md:m-0 md:text-left'
          id='home-trip-planner-text-container'
        >
          <h1 className='type-h1 c-white lg:w-370px'>
            Re-Think The Way You Plan Your Trips
          </h1>
          <div className='h-.5 w-65px bg-valley my-5' />
          <p className='type-body-1 c-white lg:w-80'>
            Get ready to be inspired. Curated recommendations and a seamless
            booking experience await you – all customizable and easy to share,
            so that you can stop planning and start experiencing.
          </p>
          <div className='mt-8 sm:self-center md:self-auto'>
            <Button
              label='Plan a trip'
              size='large'
              onClick={() => navigate(tripsPath)}
            />
          </div>
        </div>
        <img
          alt='trip planner phone'
          className='sm:w-293px md:w-431px sm:top--20px md:top-unset md:left-unset md:bottom--70px md:right-20px lg:right-80px absolute hidden sm:left-0 sm:right-0 sm:m-auto md:m-0 lg:block'
          src='https://static.travelpass.com/assets/homev2-page/trip-planner-phone.webp'
        />
      </div>
    </HomeContainer>
  )
}
