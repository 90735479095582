import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const FOLLOW_USER = gql(`
  mutation followProfile($input: FollowUserInput!){
    followUser(input: $input) {
      userProfile {
        ...FollowUserProfileFields
      }
    }
  }
`)

export const useFollowMutation = options => useMutation(FOLLOW_USER, options)
