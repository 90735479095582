import { Modal, ModalScrollContents } from '@travelpass/design-system'
import { EditingProfileOverview } from './EditingProfileOverview'
import type { Tab } from '../../types'
import { useCheckEditingMode } from '../hooks/useCheckEditingMode'
import { useGetProfile } from '../hooks/useGetProfile'

interface EditingProfileModalProps {
  tabs: Tab[]
  tabIndex: number
}

export const EditingProfileModal = ({
  tabIndex,
  tabs,
}: EditingProfileModalProps) => {
  const { refetch } = useGetProfile()
  const { handleCloseEditMode } = useCheckEditingMode()

  return (
    <Modal
      onDismiss={() => {
        refetch()
        handleCloseEditMode()
      }}
    >
      <ModalScrollContents>
        <EditingProfileOverview tabIndex={tabIndex} tabs={tabs} />
      </ModalScrollContents>
    </Modal>
  )
}
