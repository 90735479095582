import { TravelTrackerQuestion } from './TravelTrackerQuestion'

export const TravelTracker = () => {
  return (
    <div className='space-y-2'>
      <h6 className='type-h6 c-black'>Travel Tracker</h6>
      <p className='type-small-text-desktop c-grey800'>
        Oh the places you&apos;ve been! Show off how many places you&apos;ve
        visited.
      </p>
      <div className='flex flex-col gap-2 lg:flex-row'>
        <TravelTrackerQuestion
          maxNumber={7}
          name='bragContinents'
          question='Continents'
        />
        <TravelTrackerQuestion
          maxNumber={199}
          name='bragCountries'
          question='Countries'
        />
        <TravelTrackerQuestion
          maxNumber={50}
          name='bragStates'
          question='States'
        />
      </div>
    </div>
  )
}
