import { useState } from 'react'
import { Icon, IconButton, Input } from '@travelpass/design-system'
import { constructAddress } from 'src/utils'
import { GuideDraftPublishImagesSearchContent } from './GuideDraftPublishImagesSearchContent'
import type {
  GuideDraftPublishFormValues,
  GuideDraftPublishInvalidFormValues,
} from '../../types'

interface GuideDraftPublishImagesSearchProps {
  formValues: GuideDraftPublishFormValues
  invalidFormValues: GuideDraftPublishInvalidFormValues
  onImagesSelectedChange: (image: string) => void
}

export const GuideDraftPublishImagesSearch = ({
  formValues,
  invalidFormValues,
  onImagesSelectedChange,
}: GuideDraftPublishImagesSearchProps) => {
  const [searchString, setSearchString] = useState(
    constructAddress({
      addressFirstLine: formValues.address?.addressLine1,
      city: formValues.address?.city,
      state: formValues.address?.state,
      country: formValues.address?.country,
    })
  )
  const imagesSelected = formValues?.images ?? []

  const onClear = () => setSearchString('')

  return (
    <div className='space-y-3'>
      <Input
        aria-label='Search for images to add to your guide'
        fullWidth={true}
        slotAfter={
          searchString.trim() !== '' && (
            <IconButton icon='clear' onClick={onClear} />
          )
        }
        slotBefore={<Icon name='search' size='small' />}
        value={searchString}
        onChange={event => setSearchString(event.target.value)}
      />
      <GuideDraftPublishImagesSearchContent
        imagesSelected={imagesSelected}
        invalidFormValues={invalidFormValues}
        searchString={searchString}
        onImagesSelectedChange={onImagesSelectedChange}
      />
      <div className='gap-.5 flex flex-row items-center justify-end'>
        <span className='color-grey-800 type-small-text'>
          Photos supported by
        </span>
        <img
          alt='Pixabay'
          className='h-3.5'
          src='https://static.travelpass.com/assets/brands/pixabay/pixabay-logo.webp'
        />
      </div>
    </div>
  )
}
