import { Link } from '@travelpass/design-system'
import dayjs from 'dayjs'
import { useSearchParams } from 'react-router-dom'
import {
  ProductCard,
  PageLayoutContainer,
  ProductCardLoading,
} from 'src/common/components'
import { experiencesResultsPath } from 'src/constants'
import { generateExperienceDetailsUrl } from 'src/utils'
import { validateAndFormatImages } from '../experiences/results/common'
import { useExperienceSearchQuery } from '../experiences/search/useExperienceSearchQuery'

export const Experiences = () => {
  const [searchParams] = useSearchParams()
  const arrival = searchParams.get('arrival')
  const departure = searchParams.get('departure')
  const location = searchParams.get('location')
  const {
    isLoading,
    hasError,
    resultsData: rawResultsData,
  } = useExperienceSearchQuery()
  const resultsData = rawResultsData.slice(0, 4)
  const latitude = searchParams.get('latitude')
  const longitude = searchParams.get('longitude')
  const today = new Date()
  const tomorrow = new Date(today)
  tomorrow.setDate(tomorrow.getDate() + 1)
  // Format the dates to "YYYY-MM-DD" format
  const arrivalDate = dayjs().format('YYYY-MM-DD')
  const departureDate = dayjs().add(1, 'day').format('YYYY-MM-DD')
  const viewMoreUrl = `${experiencesResultsPath}?arrival=${arrivalDate}&departure=${departureDate}&location=${encodeURIComponent(location)}&latitude=${latitude}&longitude=${longitude}&sort=RECOMMENDED`
  const locationString = location.split(',')
  const locationTrimmed = locationString[0]

  const hasNoResults = !resultsData?.length && !isLoading && !hasError
  const hasResults = !!resultsData?.length && !isLoading && !hasError

  if (hasNoResults) return null

  return (
    <PageLayoutContainer>
      <section className='space-y-6'>
        {isLoading && (
          <div className='grid gap-5 md:grid-cols-2 lg:grid-cols-4'>
            <ProductCardLoading displayFrom />
            <ProductCardLoading displayFrom />
            <div className='max-lg:hidden'>
              <ProductCardLoading displayFrom />
            </div>
            <div className='max-lg:hidden'>
              <ProductCardLoading displayFrom />
            </div>
          </div>
        )}
        {hasError && (
          <>
            <p>Oops! There was a problem.</p>
          </>
        )}
        {hasResults && (
          <>
            <header className='flex flex-col items-center space-y-6 text-center'>
              <h6 className='c-grey-800 type-h6'>Experiences</h6>
              <div className='border-3px border-valley w-26 border-solid'></div>
              <h2 className='type-h1'>
                Unleash the Fun with Diverse Activities in {locationTrimmed}
              </h2>
            </header>
            <div className='group flex flex-col items-center gap-4 md:flex-row md:justify-center'>
              {resultsData?.map(resultData => {
                const { node } = resultData ?? {}
                const {
                  id,
                  cancellationPolicy,
                  images,
                  fromPrice,
                  readableDuration,
                  pricingInfo,
                  reviews,
                  title,
                  isFavorited,
                  isCollected,
                } = node ?? {}
                const imageLinks = validateAndFormatImages(images?.size360x240)
                const { combinedAverageRating, totalReviews } = reviews ?? {}
                const route = generateExperienceDetailsUrl({
                  arrival,
                  departure,
                  id,
                  name: title,
                })

                return (
                  <div
                    key={resultData.node.id}
                    className='w-2/3 bg-transparent shadow-none md:w-1/2 lg:w-1/3 lg:group-has-[>:nth-child(4)]:w-1/4 [&:nth-of-type(n+3)]:hidden lg:[&:nth-of-type(n+3)]:block'
                  >
                    <ProductCard
                      productCardData={{
                        id,
                        cancellationPolicy: cancellationPolicy?.type,
                        customerReviewScore: combinedAverageRating,
                        customerReviewScoreScale: 5,
                        displayFrom: true,
                        displayPriceUnit: true,
                        duration: readableDuration,
                        name: title,
                        images: imageLinks,
                        priceUnit: pricingInfo?.unitType,
                        primaryPrice: fromPrice?.amount,
                        route,
                        strikethroughPrice: null,
                        totalCustomerReviews: totalReviews,
                        isFavorited,
                        isCollected,
                      }}
                    />
                  </div>
                )
              })}
            </div>
            <section className='flex flex-row justify-center'>
              <Link href={viewMoreUrl} label='View More' target='_blank' />
            </section>
          </>
        )}
      </section>
    </PageLayoutContainer>
  )
}
