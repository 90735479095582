import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import type { UserProfile } from 'src/__generated__/graphql'
import { USER_PROFILE_FRAGMENT } from 'src/pages/profile/components/hooks/fragments/userProfileFragment'

interface CurrentUserProfileData {
  currentUser: { userProfile: UserProfile }
}

export const GET_CURRENT_USER_PROFILE = gql(`
  query CurrentUserProfile {
    currentUser {
      id
      userProfile {
        ...UserProfileFields
      }
    }
  } 
`)

export const useCurrentProfile = (skip = false) =>
  useQuery<CurrentUserProfileData>(GET_CURRENT_USER_PROFILE, {
    errorPolicy: 'all',
    skip,
  })
