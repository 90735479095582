import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'

const GET_BANNER_IMAGES = gql(`
  query GetBannerImages {
    getBannerImages {
      id
      extraSmallUrl
      smallUrl
      mediumUrl
      largeUrl
    }
  }
`)

export const useGetBannerImages = () => useQuery(GET_BANNER_IMAGES)
