import { Icon, IconButton, Input, TextArea } from '@travelpass/design-system'
import { Geocoder } from 'src/common/components'
import { initialGeocoder, type GeocoderType } from 'src/constants/user'
import {
  constructAddressInput,
  constructGeocoderFromAddress,
} from 'src/pages/trips/utils'
import {
  GuideDraftPublishGroupHeader,
  GuideDraftPublishGroup,
} from './GuideDraftPublishGroup'
import type {
  GuideDraftPublishFormValues,
  GuideDraftPublishInvalidFormValues,
  UseGuideDraftPublishForm,
} from './types'
import {
  guideDraftDescriptionMaxLength,
  guideDraftNameMaxLength,
} from '../../guideDraftConstants'

interface GuideDraftPublishDetailsProps {
  formValues: GuideDraftPublishFormValues
  invalidFormValues: GuideDraftPublishInvalidFormValues
  onChange: UseGuideDraftPublishForm['onFormValuesChange']
}

export const GuideDraftPublishDetails = ({
  invalidFormValues,
  formValues,
  onChange,
}: GuideDraftPublishDetailsProps) => {
  const { address, description, name } = formValues ?? {}
  const geocoder = constructGeocoderFromAddress(address) ?? initialGeocoder

  const onClear = () =>
    onChange({
      ...formValues,
      name: '',
    })

  const onGeocoderChange = (updatedGeocoder: GeocoderType) => {
    if (updatedGeocoder !== initialGeocoder) {
      onChange({
        ...formValues,
        address: constructAddressInput(updatedGeocoder),
      })
    }
  }

  return (
    <div className='space-y-3 md:space-y-4'>
      <GuideDraftPublishGroup>
        <GuideDraftPublishGroupHeader title='Title' />
        <Input
          aria-label='Add a title to your published guide'
          errorText={invalidFormValues.name && 'This field is required.'}
          fullWidth={true}
          helperText={`${name.length}/${guideDraftNameMaxLength} characters`}
          isInvalid={invalidFormValues.name}
          maxLength={guideDraftNameMaxLength}
          name='name'
          slotAfter={
            name?.trim() !== '' && <IconButton icon='clear' onClick={onClear} />
          }
          value={name}
          onChange={event =>
            onChange({
              ...formValues,
              name: event.target.value,
            })
          }
          onClear={onClear}
        />
      </GuideDraftPublishGroup>
      <GuideDraftPublishGroup>
        <GuideDraftPublishGroupHeader title='Location' />
        <Geocoder
          fullWidth
          aria-label='Search and add a destination for this guide'
          config={{
            requestOptions: {
              componentRestrictions: {
                country: null,
              },
            },
          }}
          errorText={invalidFormValues.address && 'This field is required.'}
          focusOnInput={false}
          geocoder={geocoder}
          isInvalid={invalidFormValues.address}
          name='destination'
          placeholder='Search Destination'
          slotBefore={
            <div className='color-new-forest'>
              <Icon name='search' />
            </div>
          }
          onResult={onGeocoderChange}
        />
      </GuideDraftPublishGroup>
      <GuideDraftPublishGroup>
        <GuideDraftPublishGroupHeader title='Description' />
        <TextArea
          aria-label='Add a description to your published guide'
          errorText={invalidFormValues.description && 'This field is required.'}
          helperText={`${description.length}/${guideDraftDescriptionMaxLength} characters`}
          maxLength={guideDraftDescriptionMaxLength}
          name='description'
          value={description}
          onInput={event =>
            onChange({
              ...formValues,
              description: event.currentTarget.value,
            })
          }
        />
      </GuideDraftPublishGroup>
      {/** @todo Privacy Settings */}
    </div>
  )
}
