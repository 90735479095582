import { useEffect, useState } from 'react'
import type { DragEndEvent, DragStartEvent } from '@dnd-kit/core'
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  restrictToFirstScrollableAncestor,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers'
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import isEmpty from 'lodash.isempty'
import { useParams } from 'react-router-dom'
import { useFlag } from 'src/common/hooks'
import type { GuideOwner } from 'src/pages/guides/details/types'
import { encodeGuideId } from 'src/utils'
import { GuideDraftSection } from './GuideDraftSection'
import { getGuideDraftPublishedEventCategorySortOrder } from '../../guideDraftUtils'
import type { GuideDraftDataOld } from '../../types'
import type { UseGetGuideDraftQuery } from '../../useGetGuideDraftQuery'
import { useUpdateGuideDraftMutation } from '../../useUpdateGuideDraftMutation'

interface GuideDraftSectionsProps {
  eventCount: number
  groupedEvents: GuideDraftDataOld['groupedEvents']
  onMapMarkerCenterChange: UseGetGuideDraftQuery['onMapMarkerCenterChange']
  owner: GuideOwner
  publishedEventCategories: GuideDraftDataOld['publishedEventCategories']
  selectedId: string
}

export const GuideDraftSections = ({
  eventCount,
  groupedEvents,
  onMapMarkerCenterChange,
  owner,
  selectedId,
  publishedEventCategories,
}: GuideDraftSectionsProps) => {
  const isGuideUrlShortEnabled = useFlag('guideURLShort')
  const [updateGuideDraft] = useUpdateGuideDraftMutation()
  const { guideDraftId } = useParams()
  const [activeGroupedEvent, setActiveGroupedEvent] =
    useState<GuideDraftDataOld['groupedEvents'][0]>()
  const [sortedGroupedEvents, setSortedGroupedEvents] = useState<
    GuideDraftDataOld['groupedEvents']
  >(groupedEvents ?? [])
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 15,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 300,
        tolerance: 10,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  useEffect(() => {
    setSortedGroupedEvents(groupedEvents ?? [])
  }, [groupedEvents])

  const onDragEnd = async ({ active, over }: DragEndEvent) => {
    if (over && active?.id !== over?.id) {
      try {
        const previousIndex = sortedGroupedEvents.findIndex(
          ({ id }) => id === active?.id
        )
        const nextIndex = sortedGroupedEvents.findIndex(
          ({ id }) => id === over?.id
        )
        const updatedSortedGroupedEvents = arrayMove(
          sortedGroupedEvents,
          previousIndex,
          nextIndex
        )
        const publishedEventCategorySortOrder =
          getGuideDraftPublishedEventCategorySortOrder(
            updatedSortedGroupedEvents
          )
        setSortedGroupedEvents(updatedSortedGroupedEvents)
        await updateGuideDraft({
          variables: {
            input: {
              guideDraftId: isGuideUrlShortEnabled
                ? encodeGuideId({
                    guideId: guideDraftId,
                    isGuideDraft: true,
                  })
                : guideDraftId,
              publishedEventCategorySortOrder,
            },
          },
        })
      } catch (error) {
        console.error(error)
      }
    }
  }

  return (
    <div className='space-y-4 lg:space-y-6'>
      <DndContext
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
        sensors={sensors}
        onDragCancel={() => setActiveGroupedEvent(null)}
        onDragEnd={onDragEnd}
        onDragStart={(event: DragStartEvent) => {
          const activeEventData = sortedGroupedEvents?.find(
            ({ id }) => id === event?.active?.id
          )
          setActiveGroupedEvent(activeEventData)
        }}
      >
        <SortableContext
          items={getGuideDraftPublishedEventCategorySortOrder(
            sortedGroupedEvents
          )}
          strategy={verticalListSortingStrategy}
        >
          {sortedGroupedEvents?.map(groupedEvent => (
            <GuideDraftSection
              key={groupedEvent?.id}
              eventCount={eventCount}
              groupedEvent={groupedEvent}
              groupedEvents={groupedEvents}
              owner={owner}
              publishedEventCategories={publishedEventCategories}
              selectedId={selectedId}
              onMapMarkerCenterChange={onMapMarkerCenterChange}
            />
          ))}
        </SortableContext>
        <DragOverlay>
          {!isEmpty(activeGroupedEvent) && (
            <GuideDraftSection
              eventCount={eventCount}
              groupedEvent={activeGroupedEvent}
              groupedEvents={groupedEvents}
              isOverlay={true}
              owner={owner}
              publishedEventCategories={publishedEventCategories}
              selectedId={selectedId}
              onMapMarkerCenterChange={onMapMarkerCenterChange}
            />
          )}
        </DragOverlay>
      </DndContext>
    </div>
  )
}
