import type { Dispatch, ReactNode, SetStateAction } from 'react'
import { Input, Button, useSnackbar } from '@travelpass/design-system'
import { AuthErrorCodes } from 'firebase/auth'
import { useForm } from 'react-hook-form'
import {
  firebaseCreateAccountWithEmailAndPassword,
  firebasePasswordSignIn,
} from 'src/config/firebase/firebaseUtils'
import { emailValidationRegex } from 'src/constants/validation'
import { LinkCredentials } from './LinkCredentials'
import type { UpdateUserInfoProps, alternateModal } from './types'

export const CreateAccount = ({
  createAccountOrSignInMessage,
  errorText,
  isLoading,
  onChangeCreatedByEmail,
  onCloseModal,
  setErrorText,
  setIsCreatingAccount,
  onOpenAlternateModal,
  updateUserInfo,
}: {
  createAccountOrSignInMessage: ReactNode
  errorText: string
  isLoading: boolean
  onChangeCreatedByEmail(): void
  onCloseModal(): void
  onOpenAlternateModal(modal: alternateModal): void
  setErrorText: Dispatch<SetStateAction<string>>
  setIsCreatingAccount: Dispatch<SetStateAction<boolean>>
  updateUserInfo(arg0: UpdateUserInfoProps): void
}): JSX.Element => {
  const { addSuccessSnack } = useSnackbar()
  const { formState, handleSubmit, register } = useForm()
  const { errors } = formState
  const { email: emailError, password: passwordError } = errors

  const onSubmit = async ({ email, firstName, lastName, password }, event) => {
    event.preventDefault()
    const response = await firebaseCreateAccountWithEmailAndPassword(
      email,
      password
    )
    if (response.status === 'error') {
      if (response?.err?.code === AuthErrorCodes.EMAIL_EXISTS) {
        const tryLogin = await firebasePasswordSignIn(email, password)
        if (tryLogin?.status === 'success') {
          onCloseModal()
          addSuccessSnack({ title: 'Successfully signed in' })
        } else if (
          tryLogin?.status === 'error' &&
          (tryLogin?.err?.code === 'auth/user-not-found' ||
            tryLogin?.err?.code === 'auth/wrong-password' ||
            tryLogin?.err?.code === 'auth/too-many-requests')
        ) {
          setErrorText('Please check your email address and password')
          setIsCreatingAccount(false)
        } else {
          setErrorText('An error has occured, please try again later')
        }
      }
      return
    }

    const externalId = response?.user?.uid ?? ''
    await updateUserInfo({
      externalId,
      email,
      firstName,
      lastName,
    })
    onChangeCreatedByEmail()
  }

  const constructEmailErrorText = () => {
    if (emailError?.type === 'required') return 'Email is required'
    if (emailError?.type === 'validate')
      return 'Please enter a valid email address'
  }

  const constructEmailPasswordText = () => {
    if (passwordError?.type === 'required') return 'Password is required'
    if (passwordError?.type === 'minLength')
      return 'Password must be at least 6 characters in length'
  }

  return (
    <form
      noValidate
      className='mt-3 space-y-7'
      onSubmit={handleSubmit(onSubmit)}
    >
      <LinkCredentials
        updateUserInfo={updateUserInfo}
        onCloseModal={onCloseModal}
      />
      {errorText && <div className='c-error'>{errorText}</div>}
      <section className='space-y-4'>
        <Input
          fullWidth
          errorText={constructEmailErrorText()}
          label='Email'
          placeholder='Email'
          type='text'
          {...register('email', {
            required: true,
            validate: (value: string) => {
              const regex = new RegExp(emailValidationRegex)
              return regex.test(value)
            },
          })}
        />
        <Input
          fullWidth
          errorText={constructEmailPasswordText()}
          helperText='Password must be at least 6 characters in length.'
          label='Password'
          placeholder='Password'
          type='password'
          {...register('password', { required: true, minLength: 6 })}
        />
      </section>
      <Button
        fullWidth
        isDisabled={isLoading}
        label='Create Account'
        size='large'
        type='submit'
      />
      <div className='type-body-2 [&_span]:c-new-forest flex flex-row items-center justify-center gap-4'>
        {createAccountOrSignInMessage}
      </div>
    </form>
  )
}
