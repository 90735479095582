//TODO: REPLACE WITH NEW RADIO BUTTON
import { useState } from 'react'
import { Link, Radio } from '@travelpass/design-system'
import classNames from 'classnames'
import type { RefundType } from 'src/__generated__/graphql'
import { HotelCancellationModal } from 'src/common/components'
import { useFlag } from 'src/common/hooks'
import {
  getCancelationPolicyTitle,
  getLowestGrandTotalFromPaymentTypeOptions,
  getPaymentTypeOptionsAvailable,
} from './hotelRoomRateUtils'
import type { HotelRoom } from '../hotelRoomsTypes'

interface RoomRatesCancelationPolicyRadioProps {
  refundType: RefundType
  selectedCancelationPolicy: string
  onClick: () => void
  selectedRateGrandTotal: number
  rates: HotelRoom['rates']
}

export const RoomRatesCancelationPolicyRadio = ({
  refundType,
  selectedCancelationPolicy,
  onClick,
  selectedRateGrandTotal,
  rates,
}: RoomRatesCancelationPolicyRadioProps) => {
  const enableAllInPricing = useFlag('enableAllInPricing')

  const [isHotelCancellationModalOpen, setIsHotelCancellationModalOpen] =
    useState(false)
  const isChecked = selectedCancelationPolicy === refundType
  const paymentTypeOptions = getPaymentTypeOptionsAvailable(
    rates,
    enableAllInPricing
  )

  const priceDifference = !isChecked
    ? Math.round(
        Number(getLowestGrandTotalFromPaymentTypeOptions(paymentTypeOptions))
      ) - Math.round(selectedRateGrandTotal)
    : 0
  const {
    fullyRefundableUntil,
    nonRefundableAfter,
    partiallyRefundableUntil,
    policyText,
  } = rates[0].cancelationPolicy ?? {}

  const onSeeDetailsButtonClick = () =>
    refundType !== 'NONE' && setIsHotelCancellationModalOpen(true)

  return (
    <>
      {isHotelCancellationModalOpen && (
        <HotelCancellationModal
          fullyRefundableUntil={fullyRefundableUntil}
          nonRefundableAfter={nonRefundableAfter}
          partiallyRefundableUntil={partiallyRefundableUntil}
          policies={policyText}
          onClose={() => setIsHotelCancellationModalOpen(false)}
        />
      )}
      <div
        className={classNames(
          'rd-2 border-1 b-grey300 grid-items-center flex border-solid p-3',
          {
            'bg-#F0F6F7 color-forest-dark b-transparent': isChecked,
          }
        )}
      >
        <div className='flex w-full flex-col'>
          <Radio
            isChecked={isChecked}
            label={getCancelationPolicyTitle(refundType)}
            onClick={onClick}
          />
          <div
            className={classNames('pl-7', { hidden: refundType === 'NONE' })}
          >
            <Link label='See Details' onClick={onSeeDetailsButtonClick} />
          </div>
        </div>
        <div
          className={classNames('flex-grow text-right', {
            'c-valleyDark': priceDifference < 0,
          })}
        >
          {isChecked || isNaN(priceDifference)
            ? null
            : `${priceDifference >= 0 ? '+' : '-'}$${Math.abs(
                priceDifference
              )}`}
        </div>
      </div>
    </>
  )
}
