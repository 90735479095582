import { useEffect, useState } from 'react'
import {
  useApolloClient,
  useQuery,
  type ApolloError,
  type Cache,
} from '@apollo/client'
import isEmpty from 'lodash.isempty'
import { useNavigate } from 'react-router-dom'
import type { LatLng } from 'use-places-autocomplete'
import { gql } from 'src/__generated__'
import {
  type GetGuideDraftQueryInGuideDraftOldQuery,
  type GetGuideDraftQueryInGuideDraftOldQueryVariables,
  type GuideDraft,
  GuideStatus,
} from 'src/__generated__/graphql'
import {
  getGuideDetailsUrl,
  getGuideOwnerImage,
  getGuideOwnerName,
  getGuideOwnerUrl,
} from 'src/utils'
import type { GuideDraftDataOld } from './types'
import { getGuideMetaData } from '../details/guideUtils'
import type { GuideMetaData, GuideOwner } from '../details/types'

const getGuideDraftOldQuery = gql(`
  query GetGuideDraftQueryInGuideDraftOld($id: ID!) {
    currentUser {
      id
      needsGuideOnboarding
      userRoles
    }
    node(id: $id) {
      ... on GuideDraft {
        id
        addresses {
          id
          addressLine1
          city
          country
          googlePlaceId
          lat
          long
          state
        }
        description
        eventCounts {
          numberEvents
        }
        groupedEvents {
          ...GuideDraftGroupedEventFields
        }
        guide {
          id
          isCollected
          name
          numberOfLikes
          shareCount
          status
          viewCount
        }
        hasUnpublishedChanges
        images {
          id
          source
          type
          url
        }
        imageUrl
        insertedAt
        name
        owner {
          id
        }
        ownerProfile {
          id
          accountHandle
          displayName
          isUserOwner
          profileImageUrl
          profileImageSource
          userId
        }
        publishedEventCategories {
          id
          description
          name
        }
        status
        tags {
          id
          name
        }
        timeZone
        updatedAt
      }
    }
  }
`)

const guideDraftGroupedEventFragmentOld = gql(`
  fragment GuideDraftGroupedEventFields on GroupedEventCategory {
    id
    description
    events {
      id
      addresses {
        id
        addressLine1
        city
        country
        googlePlaceId
        lat
        long
        state
        zipcode
      }
      description
      externalTypeId
      imageUrl
      name
      notes
      productId
      type
    }
    name
  }
`)

type UseGetGuideDraftQuery = {
  data: GetGuideDraftQueryInGuideDraftOldQuery
  hasError: ApolloError
  isLoading: boolean
  eventCount: number
  guideDraftData: GuideDraftDataOld
  guideDraftMetaData: GuideMetaData
  mapMarkerCenter: google.maps.LatLngLiteral
  onMapMarkerCenterChange: (updatedMapMarkerCenter: LatLng) => void
  owner: GuideOwner
}

const useGetGuideDraftQuery = (
  guideDraftId: GetGuideDraftQueryInGuideDraftOldQueryVariables['id']
): UseGetGuideDraftQuery => {
  const navigate = useNavigate()
  const [mapMarkerCenter, setMapMarkerCenter] =
    useState<UseGetGuideDraftQuery['mapMarkerCenter']>(null)
  const { data, error, loading } = useQuery(getGuideDraftOldQuery, {
    skip: !guideDraftId,
    variables: {
      id: guideDraftId,
    },
  })
  const guideDraftData = data?.node as GuideDraftDataOld
  const {
    addresses,
    eventCounts,
    images,
    imageUrl,
    groupedEvents,
    name,
    ownerProfile,
  } = guideDraftData ?? {}
  const { numberEvents } = eventCounts ?? {}
  const { accountHandle, displayName, isUserOwner, profileImageUrl } =
    ownerProfile ?? {}
  /** @todo necessary until BE fixes deduping in TMNT-3072. */
  const eventCount =
    groupedEvents?.flatMap(groupedEvent => groupedEvent?.events)?.length ?? 0
  const owner: GuideOwner = {
    image: getGuideOwnerImage(profileImageUrl),
    isUserOwner,
    name: getGuideOwnerName({
      accountHandle,
      displayName,
    }),
    url: getGuideOwnerUrl(accountHandle),
  }
  const guideDraftMetaData = getGuideMetaData({
    address: addresses?.[0],
    image: images?.[0]?.url ?? imageUrl,
    name,
    numberEvents,
    ownerName: owner?.name,
  })

  // When the user is not the owner of the guide draft, redirect to the guide or set the map center
  useEffect(() => {
    if (isEmpty(data)) return

    const currentUserId = data?.currentUser?.id
    const guideDraftOwnerId = (data?.node as GuideDraftDataOld)?.owner?.id

    if (currentUserId === guideDraftOwnerId) return

    if (guideDraftData?.guide?.status === GuideStatus.Published)
      return navigate(
        getGuideDetailsUrl({
          id: guideDraftData?.guide?.id,
          name: name,
        })
      )
  }, [data])

  const onMapMarkerCenterChange = (
    updatedMapMarkerCenter: UseGetGuideDraftQuery['mapMarkerCenter']
  ) => setMapMarkerCenter(updatedMapMarkerCenter)

  return {
    data,
    hasError: error,
    isLoading: loading,
    eventCount,
    guideDraftData,
    guideDraftMetaData,
    mapMarkerCenter,
    onMapMarkerCenterChange,
    owner,
  }
}

const useUpdateGuideDraftQueryCache = () => {
  const apolloClient = useApolloClient()

  const updateGuideDraftQueryCache = (
    guideDraft: GuideDraft,
    hasUnpublishedChanges = true
  ): Cache.ModifyOptions<GuideDraft> => {
    const { id, guide } = guideDraft ?? {}

    if (!id) return

    return {
      id: apolloClient.cache.identify({
        id,
        __typename: 'GuideDraft',
      }),
      /** @todo test to see if we need also update groupedEvents */
      fields: {
        hasUnpublishedChanges() {
          return (
            hasUnpublishedChanges && guide?.status === GuideStatus.Published
          )
        },
      },
    }
  }

  return {
    updateGuideDraftQueryCache,
  }
}

export type { UseGetGuideDraftQuery }
export {
  getGuideDraftOldQuery,
  guideDraftGroupedEventFragmentOld,
  useGetGuideDraftQuery,
  useUpdateGuideDraftQueryCache,
}
