import { useGetCurrentUserProfileAccountHandleAndAvatar } from './useGetCurrentUserProfileAccountHandleAndAvatar'
import { useProfileByAccountHandle } from './useProfileByAccountHandle'

export const useGetProfile = (accountHandle?: string) => {
  const { data } = useGetCurrentUserProfileAccountHandleAndAvatar()

  const {
    data: profileByAccountHandle,
    loading,
    error,
    refetch,
  } = useProfileByAccountHandle(
    accountHandle || data?.currentUser.userProfile.accountHandle
  )

  return {
    profile: profileByAccountHandle?.viewUserProfile,
    loading,
    error,
    refetch,
  }
}
