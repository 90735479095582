import { Skeleton } from '@travelpass/design-system'
import { GuideEventCardLoading } from '../GuideEventCard'
import { GuideSectionLoading } from '../GuideSection'

export const GuideEventsOldLoading = () => (
  <div className='space-y-6' data-testid='GuideEventsLoading'>
    <div className='flex flex-col items-end md:hidden'>
      <div className='h-8 w-8'>
        <Skeleton variant='circular' />
      </div>
    </div>
    <GuideSectionLoading>
      <GuideEventCardLoading />
      <GuideEventCardLoading />
    </GuideSectionLoading>
    <GuideSectionLoading>
      <GuideEventCardLoading />
      <GuideEventCardLoading />
      <GuideEventCardLoading />
    </GuideSectionLoading>
  </div>
)
