import { Divider, Skeleton } from '@travelpass/design-system'
import { GuideMetricsLoading } from 'src/pages/guides/common/GuideMetrics'
import { GuideProfileLoading } from '../../GuideProfile'
import { GuideHeaderTagsLoading } from '../GuideHeaderTags'

export const GuideHeaderContentLoading = () => (
  <div className='p-x-4 relative w-full space-y-4'>
    <div className='space-y-2'>
      <div className='w-50 h-7'>
        <Skeleton />
      </div>
      <Divider className='w-17.5 block h-1' />
      <GuideProfileLoading />
    </div>
    <div className='h-18 w-full'>
      <Skeleton />
    </div>
    <div className='p-y-.5'>
      <GuideMetricsLoading />
    </div>
    <GuideHeaderTagsLoading />
  </div>
)
