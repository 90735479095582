import { IconButton } from '@travelpass/design-system'
import classNames from 'classnames'
import { createPortal } from 'react-dom'
import { useGuideSessionStorageIds } from 'src/pages/guides/details'
import { onGuideSessionStorageSelectedIdChange } from 'src/pages/guides/details/useGuideSessionStorageIds'
import { GuideDraftDrawerContent } from './GuideDraftDrawerContent'
import type { GuideDraftMapPoint } from '../../types'

interface GuideDraftDrawerProps {
  points: GuideDraftMapPoint[]
}

export const GuideDraftDrawer = ({ points }: GuideDraftDrawerProps) => {
  const { selectedId } = useGuideSessionStorageIds()
  const selectedEvent = points?.find(({ id }) => id === selectedId)
  const isOpen = !!selectedId

  const onClose = () => onGuideSessionStorageSelectedIdChange('')

  return (
    <>
      {createPortal(
        <div
          className={classNames(
            'h-100dvh fixed bottom-0 left-0 right-0 top-0 w-full bg-white lg:hidden',
            {
              hidden: !isOpen,
              'visible fixed bottom-0 flex flex-col bg-white': isOpen,
            }
          )}
        >
          <div className='flex justify-end'>
            <IconButton icon='clear' size='large' onClick={onClose} />
          </div>
          <GuideDraftDrawerContent selectedEvent={selectedEvent} />
        </div>,
        document.body
      )}
      <div
        className={classNames(
          'transition-transform-175 w-119 z-2 lg:b-r-grey-400 lg:b-r-solid lg:b-r-1 lg:top-77px fixed relative bottom-0 hidden bg-white ease-[cubic-bezier(0,0,0.2,1)] lg:sticky lg:flex lg:h-[calc(100svh-77px)] lg:flex-col',
          {
            '-translate-x-119': !isOpen,
          }
        )}
      >
        <div className='flex justify-end'>
          <IconButton
            aria-label='Close the modal'
            icon='clear'
            size='large'
            onClick={onClose}
          />
        </div>
        <GuideDraftDrawerContent selectedEvent={selectedEvent} />
      </div>
    </>
  )
}
