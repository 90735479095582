const bookingDetailsMaxWith = ['450px', '450px', '791px']

const bookingDetailsStatusExpired = '*You have passed the cancellation date.'

const bookingDetailsTabs = ['Overview', 'Details', 'Manage', 'Payment']

export {
  bookingDetailsMaxWith,
  bookingDetailsStatusExpired,
  bookingDetailsTabs,
}
