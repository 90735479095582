import { Button, Illustration } from '@travelpass/design-system'
import { ProfileTabs } from '../../components/EditProfile/types'
import { useCheckEditingMode } from '../../components/hooks/useCheckEditingMode'

export const RecommendedHotelsZeroState = () => {
  const { handleOpenEditMode } = useCheckEditingMode()

  return (
    <div className='bg-beach rounded-13px flex flex-col gap-10 p-10 lg:flex-row lg:gap-0'>
      <div className='order-first w-full'>
        <Illustration name='resting' />
      </div>
      <div className='flex w-full flex-col items-center justify-center gap-y-5 md:order-first md:items-start'>
        <p className='type-h3-desktop text-center md:text-left'>
          Missing Your Top Hotels!
        </p>
        <p className='type-body-1 text-center md:text-left'>
          Search for and share the stays that made your travels unforgettable.
        </p>
        <Button
          label='Add hotels'
          onClick={() => handleOpenEditMode(ProfileTabs.FavoriteHotels)}
        />
      </div>
    </div>
  )
}
