import {
  Button,
  IconButton,
  ProgressBar,
  useScreenQuery,
} from '@travelpass/design-system'
import classNames from 'classnames'
import { TravelpassLogo } from 'src/common/components'

const wrapperStyles = {
  fullWidthQuestion: 'w-full h-full flex flex-col mx-20 overflow-y-auto',
  halfWidthQuestion: 'w-1/2 h-full flex flex-col ml-20 overflow-y-auto',
}

const buttonStyles = {
  hasBackButton: 'mb-20 p-6 flex justify-between items-center',
  noBackButton: 'mb-20 p-6 flex justify-end items-center',
}

export const QuizWrapper = ({
  onClose,
  goBack,
  goNext,
  steps,
  currentStep,
  isAModal,
}) => {
  const { isMobileOrTablet } = useScreenQuery()
  const question = steps[currentStep]
  const isFirstQuestion = currentStep === 0

  return isMobileOrTablet ? (
    <div className='fixed inset-0 z-10 flex flex-col bg-white'>
      <div className='flex items-center justify-between p-4'>
        <TravelpassLogo />
        <IconButton icon='clear' onClick={() => onClose()} />
      </div>
      <div className='flex-grow overflow-auto'>
        <div className='m-4'>
          <ProgressBar percentage={(currentStep / steps.length) * 100} />
        </div>
        <div className='my-4 flex-grow px-10'>{question.questionComponent}</div>
      </div>
      <div className='flex-grow-.5 flex justify-between p-4'>
        <Button
          isDisabled={isFirstQuestion}
          label='Back'
          variant='outlined'
          onClick={goBack}
        />
        <Button className='w-full' label='Next' onClick={goNext} />
      </div>
    </div>
  ) : (
    <div
      className={classNames('flex h-full w-full flex-row', {
        'pb-10': isAModal,
      })}
    >
      {question.questionImage && (
        <div className='h-full w-1/2 self-center'>
          <img
            alt='quiz-illustration'
            className='h-full w-full object-cover'
            src={question.questionImage}
          />
        </div>
      )}
      <div
        className={
          question.questionImage
            ? wrapperStyles.halfWidthQuestion
            : wrapperStyles.fullWidthQuestion
        }
      >
        {!isAModal && (
          <div
            className={classNames('flex content-start justify-end', {
              'mr-20': question.questionImage,
            })}
          >
            <IconButton
              color='transparent'
              icon='clear'
              size='large'
              onClick={onClose}
            />
          </div>
        )}
        <div
          className={classNames('flex flex-grow flex-col justify-between', {
            'mr-20': question.questionImage,
          })}
        >
          <div className='mb-10 flex h-20 items-center justify-center'>
            <TravelpassLogo />
          </div>
          <div className='mb-20 flex w-1/2 self-center'>
            <ProgressBar percentage={(currentStep / steps.length) * 100} />
          </div>
          <div className='my-4 flex-grow'>{question.questionComponent}</div>
          <div
            className={
              !isFirstQuestion
                ? buttonStyles.hasBackButton
                : buttonStyles.noBackButton
            }
          >
            {!isFirstQuestion && (
              <Button
                label='Back'
                size='large'
                variant='outlined'
                onClick={goBack}
              />
            )}
            <Button label='Next' size='large' onClick={goNext} />
          </div>
        </div>
      </div>
    </div>
  )
}
