import dayjs from 'dayjs'
import { isMobile } from 'react-device-detect'
import {
  getArrivalDate,
  getDepartureDate,
  getGeocoderPlaceName,
} from 'src/utils'

const constructResultsSearchDates = ({ arrival, departure }) => {
  const updatedArrival = getArrivalDate({ date: arrival, format: 'MMM D' })
  const isSameDate = dayjs(departure).isSame(dayjs(arrival), 'day')
  const isSameMonth = dayjs(departure).isSame(dayjs(arrival), 'month')
  const updatedDeparture = getDepartureDate({
    date: departure,
    format: isSameMonth ? 'D, YYYY' : 'MMM D, YYYY',
  })
  const sameDateArrival = getArrivalDate({
    date: arrival,
    format: 'MMM D, YYYY',
  })
  const searchDates = isSameDate
    ? sameDateArrival
    : `${updatedArrival} - ${updatedDeparture}`

  return searchDates
}

const constructResultsSearchLocation = ({ location }) =>
  getGeocoderPlaceName(location)

export { constructResultsSearchDates, constructResultsSearchLocation }
