import { useLocation, useNavigate } from 'react-router-dom'
import type { ProfileTabs } from '../EditProfile/types'
import { getProfilePageUrl } from '../profileUtils'

export const useCheckEditingMode = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const isEditingMode = location?.pathname?.endsWith('/profile')

  const handleOpenEditMode = (tab?: ProfileTabs) => {
    navigate(getProfilePageUrl(undefined, tab))
  }

  // accountHandle means user has profile to go to
  const handleCloseEditMode = (accountHandle?: string) => {
    // Do we have a profile to go to?
    if (!accountHandle) {
      // No, go to previous page
      navigate(-1)
    } else navigate(getProfilePageUrl(accountHandle))
  }

  return { isEditingMode, handleOpenEditMode, handleCloseEditMode }
}
