import { Link } from 'react-router-dom'
import type {
  FollowersQuery,
  SearchUserProfilesQueryQuery,
} from 'src/__generated__/graphql'
import { DEFAULT_PROFILE_IMAGE_URL } from '../../profileConstants'
import { FollowButton } from '../FollowButton/FollowButton'
import { ImageWithFallback } from '../ImageWithFallback'
import { getProfilePageUrl } from '../profileUtils'

interface FollowerListItemProps {
  searchBarMode?: boolean
  follower:
    | FollowersQuery['viewUserProfile']['followedUsers'][0]
    | SearchUserProfilesQueryQuery['searchUserProfiles'][0]
}

export const FollowerListItem = ({
  follower,
  searchBarMode = false,
}: FollowerListItemProps) => {
  const {
    accountHandle,
    profileImageUrl,
    displayName,
    isUserOwner,
    userId,
    isFollowed,
  } = follower
  const URL = getProfilePageUrl(accountHandle)

  return (
    <div className='my-5 flex'>
      <Link to={URL}>
        <ImageWithFallback
          className='rounded-1/2 h-25 w-25 object-cover'
          displayName={displayName}
          fallbackSrc={DEFAULT_PROFILE_IMAGE_URL}
          src={profileImageUrl}
        />
      </Link>
      <div className='flex w-full place-content-between items-center pl-10 md:pr-10'>
        <Link className='no-underline' to={URL}>
          <p className='type-h6'>{displayName}</p>
          <p className='type-small-text c-gray font-100 mt-1'>
            {`@${accountHandle}`}
          </p>
          {searchBarMode && isFollowed && (
            <p className='type-small-text mt-5'>Following</p>
          )}
        </Link>
        <div className='min-w-25 max-w-25 mt-1 md:min-w-32 md:max-w-32'>
          {!isUserOwner && !searchBarMode && (
            <FollowButton
              fullWidth
              displayName={displayName}
              isFollowed={isFollowed}
              label={isFollowed ? 'unfollow' : 'follow'}
              userId={userId}
              variant={isFollowed ? 'outlined' : 'filled'}
            />
          )}
        </div>
      </div>
    </div>
  )
}
