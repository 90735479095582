import { useEffect, useState } from 'react'
import type { User } from 'firebase/auth'
import { signInAnonymously } from 'firebase/auth'
import Cookies from 'js-cookie'
import { LDProvider } from 'launchdarkly-react-client-sdk'
import { BrowserRouter } from 'react-router-dom'
import { TpgThemeProvider } from 'src/common/components'
import { clientSideID } from 'src/config/feature-flags'
import { auth } from 'src/config/firebase/firebaseUtils'
import { useInitializeGoogleMaps } from './common/hooks'
import { lucencyNumber } from './config/analytics/lucencyUtils'
import { useVwoUserData } from './config/analytics/useVwoUserData'
import { anonymousUserKey } from './config/feature-flags/launchDarklyConstants'
import { GenerateAccessToken } from './config/firebase/GenerateAccessToken'
import { GoogleOneTapLoginWrapper } from './config/googleCloud/GoogleOneTapLoginWrapper'
import { ForterProtection } from './config/security/forter/ForterProtection'
import { extendDayjs } from './utils'
import '@travelpass/design-system/dist/style.css'
import 'rc-slider/assets/index.css'
import 'virtual:uno.css'
import 'src/config/analytics/lucencyIntegration'
import 'src/config/datadog'
import './global.css'

extendDayjs()

export const App = () => {
  const [hasAccessToken, setHasAccessToken] = useState(false)
  const [user, setUser] = useState<User | null>(null)
  useVwoUserData()

  useInitializeGoogleMaps()

  useEffect(() => {
    lucencyNumber({ vwo_uuid: Cookies.get('_vwo_uuid') })

    const unsubscribeUser = auth.onAuthStateChanged(user => {
      if (user) {
        setUser(user)
        setHasAccessToken(true)
      } else {
        setHasAccessToken(false)
        signInAnonymously(auth)
      }
    })

    return () => unsubscribeUser()
  }, [])

  return (
    <LDProvider
      clientSideID={clientSideID}
      context={{ key: anonymousUserKey, anonymous: true }}
    >
      <TpgThemeProvider>
        <GoogleOneTapLoginWrapper />
        <BrowserRouter>
          <ForterProtection />
          {user && (
            <GenerateAccessToken hasAccessToken={hasAccessToken} user={user} />
          )}
        </BrowserRouter>
      </TpgThemeProvider>
    </LDProvider>
  )
}
