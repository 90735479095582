import type { AvailableIcons } from '@travelpass/design-system'
import { Icon } from '@travelpass/design-system'
import { Link } from 'react-router-dom'
import { ButtonLink } from 'src/common/components'
import { TermsAndConditionsModal } from './TermsAndConditionsModal'
import { useGetCurrentUserCompetitionInfo } from './hooks/useGetCurrentUserCompetitionInfo'

const JobLine = ({
  iconName,
  title,
  children,
}: {
  iconName: AvailableIcons
  title: string
  children?: React.ReactNode
}) => {
  return (
    <div className='flex items-start gap-2'>
      <Icon name={iconName} />
      <p className='m-0'>
        <span className='font-bold'>{title}:&nbsp;</span>
        {children}
      </p>
    </div>
  )
}

const TimelineCard = props => {
  return (
    <div
      {...props}
      className={`grid-col-span-1 b-t-4 b-t-solid flex flex-col items-center gap-2 rounded bg-white p-6 text-center shadow ${props?.className || ''}`}
    >
      {props?.children}
    </div>
  )
}

export const CompetitionAbout = () => {
  const { data } = useGetCurrentUserCompetitionInfo()

  const info = data?.currentUser?.userProfile?.competitionInfo
  const hasEnteredCompetition = info?.enteredCompetition === true

  return (
    <div className='md:space-y-34 space-y-16'>
      <section className='flex flex-col items-center gap-8 md:flex-row'>
        <img
          alt=''
          className='max-w-500px block w-full object-cover'
          loading='lazy'
          src='https://static.travelpass.com/assets/job-campaign-2024/creator-quest-thumb-2-2x.webp'
        />
        <div className='space-y-5'>
          <h1>
            Get paid $15,000 a month to travel and share your&nbsp;experience!
          </h1>
          <p className={bulletPointsCss}>
            <Icon name='accessTime' />
            <span className='font-700'>Job Length: </span> 6 Months (full time)
          </p>

          <p className='flex items-center gap-x-2'>
            <span className='mb-3px'>
              <Icon name='TripleDollarSign' />
            </span>
            <span className='font-700'>Monthly Pay:</span> $15,000
          </p>

          <p className={bulletPointsCss}>
            <Icon name='eventNote' />
            <span className='font-700'>Job Timing: </span> Jan-Jun &apos;25
          </p>
          <p className={bulletPointsCss}>
            <Icon name='placeOutline' />
            <span className='font-700'>Job Structure: </span> Full time,
            travelling city to city
          </p>
          <div className='flex gap-x-2'>
            <Icon name='laptop' />
            <p className='m-0'>
              <span className='font-700 mr-2'>Content Creation:</span>
              Destination guides & itineraries featuring key elements around
              what to do and where to stay
            </p>
          </div>
          <div>
            {!hasEnteredCompetition && (
              <ButtonLink to='../apply' variant='forest'>
                Apply now
              </ButtonLink>
            )}
            {hasEnteredCompetition && (
              <ButtonLink to='/dashboard?tab=guides' variant='forest'>
                See My Progress
              </ButtonLink>
            )}
          </div>
          <Link
            className='c-forest-light block inline-flex items-center gap-2 no-underline hover:underline'
            state={{ preventScroll: true }}
            to='../leaderboard?sort=POPULAR'
          >
            See others in the running
            <Icon name='arrowForward' />
          </Link>
        </div>
      </section>
      {/* <section className='space-y-16' id='job-descriptions'>
        <h2 className='type-h2 text-center'>Job Descriptions</h2>
        <div className='grid grid-cols-3 gap-4'>
          <div className='grid-col-start-2 grid-col-span-1 space-y-2'>
            <div>
              <h3>Chief Sports Officer</h3>
              <h4>Focus: Activities</h4>
            </div>
            <JobLine iconName='accessTime' title='Job Length'>
              7&nbsp;months (part-time)
            </JobLine>
            <JobLine iconName='accessTime' title='Monthly Pay'>
              $10,000
            </JobLine>
            <JobLine iconName='eventNote' title='Job Timing'>
              <time dateTime='2024-10'>Oct &apos;24</time>&ndash;
              <time dateTime='2025-04'>Apr &apos;25</time>
            </JobLine>
            <JobLine iconName='placeOutline' title='Job Structure'>
              Home-based (out and back) traveling to key sporting events
              throughout the year
            </JobLine>
            <JobLine iconName='placeOutline' title='Content Creation'>
              Sport/Activity focused guides and itineraries + what to do, see
              while traveling for the event
            </JobLine>
          </div>
        </div>
      </section>
      <section className='mx-auto mt-16 md:mt-32' id='timeline'>
        <div className='flex'>
          <div className='w-7/15'>
            <img
              alt=''
              className='max-w-200px float-right block w-full object-cover'
              loading='lazy'
              src='https://static.travelpass.com/assets/job-campaign-2024/choose-topic.svg'
            />
          </div>
          <span className='c-valley font-archivo w-1/15 mt--3 px-6 text-6xl font-bold'>
            1
          </span>
          <div className='w-7/15'>
            <h3 className='type-h3 mb-4'>Why you?</h3>
            <ul className='m-0 list-none p-0'>
              <li>Create a Travelpass.com profile</li>
              <li>Build at least 1 Travelpass guide</li>
              <li>
                Film a 1-minute video on why you&apos;d be a great fit for the role
              </li>
              <li>Share your profile to encourage votes</li>
            </ul>
          </div>
        </div>
        <div className='flex'>
          <div className='w-7/15'>
            <img
              alt=''
              className='max-w-200px float-right block w-full object-cover'
              loading='lazy'
              src='https://static.travelpass.com/assets/job-campaign-2024/prove-yourself.svg'
            />
          </div>
          <span className='c-forest font-archivo w-1/15 mt--3 px-6 text-6xl font-bold'>
            2
          </span>
          <div className='w-7/15'>
            <h3 className='type-h3 mb-4'>Prove Yourself</h3>
            <ul className='m-0 list-none p-0'>
              <li>Top 100 applicants selected for this round</li>
              <li>Build a guide highlighting your hometown</li>
              <li>
                Film a 3-5 minute video of how you&apos;d feature your hometown{' '}
              </li>
              <li>Share your profile to encourage votes</li>
            </ul>
          </div>
        </div>
        <div className='flex'>
          <div className='w-7/15'>
            <img
              alt=''
              className='max-w-300px float-right block w-full object-cover'
              loading='lazy'
              src='https://static.travelpass.com/assets/job-campaign-2024/airplane.svg'
            />
          </div>
          <span className='c-canyon font-archivo w-1/15 mt--3 px-6 text-6xl font-bold'>
            3
          </span>
          <div className='w-7/15'>
            <h3 className='type-h3 mb-4'>Impress Us</h3>
            <ul className='m-0 list-none p-0'>
              <li>The finals: Top 10 applicants selected for this round</li>
              <li>Fly to a secret destination for activities and interviews</li>
              <li>Meet the Travelpass Team </li>
              <li>Top applicant(s) (1-3) will be offered the job(s)</li>
            </ul>
          </div>
        </div>
      </section> */}
      <section
        className='mx-auto mt-16 md:mt-32'
        id='candidate-search-timeline'
      >
        <div className='space-y-16'>
          <h2 className='type-h2 text-center'>The Candidate Search Process</h2>
          <div className='grid grid-cols-1 gap-6 md:grid-cols-3'>
            <TimelineCard className='b-t-valley'>
              <h4 className='c-valley font-archivo m-0 text-3xl font-bold'>
                Phase 1
              </h4>
              <h5 className='type-overline'>Aug 20 - Sept 23</h5>
              <img
                alt=''
                className='max-h-200px my-10 block w-full object-contain'
                loading='lazy'
                src='https://static.travelpass.com/assets/job-campaign-2024/choose-topic.svg'
              />
              <h3 className='type-h3'>Why you?</h3>
              <ul className='c-grey-900 m-0 list-none space-y-2 p-0'>
                <li>Create a Travelpass.com profile</li>
                <li>Build at least 1 Travelpass guide</li>
                <li>
                  Film a 1-minute video on why you&apos;d be a great fit for the
                  role
                </li>
                <li>Share your profile to encourage votes</li>
              </ul>
            </TimelineCard>
            <TimelineCard className='b-t-forest-light'>
              <h4 className='c-forest-light font-archivo m-0 text-3xl font-bold'>
                Phase 2
              </h4>
              <h5 className='type-overline'>Sept 30 - Oct 13</h5>
              <img
                alt=''
                className='max-h-200px my-10 block w-full object-contain'
                loading='lazy'
                src='https://static.travelpass.com/assets/job-campaign-2024/prove-yourself.svg'
              />
              <h3 className='type-h3'>Prove Yourself</h3>
              <ul className='c-grey-900 m-0 list-none space-y-2 p-0'>
                <li>Top 100 applicants selected for this round</li>
                <li>Build a guide highlighting your hometown</li>
                <li>
                  Film a 3-5 minute video of how you&apos;d feature your
                  hometown
                </li>
                <li>Share your profile to encourage votes</li>
              </ul>
            </TimelineCard>
            <TimelineCard className='b-t-canyon'>
              <h4 className='c-canyon font-archivo m-0 text-3xl font-bold'>
                Phase 3
              </h4>
              <h5 className='type-overline'>Oct 28 - Nov 4</h5>
              <img
                alt=''
                className='max-h-200px md:my-15 my-10 block w-full object-cover'
                loading='lazy'
                src='https://static.travelpass.com/assets/job-campaign-2024/airplane.svg'
              />
              <h3 className='type-h3'>Impress Us</h3>
              <ul className='c-grey-900 m-0 list-none space-y-2 p-0'>
                <li>The finals: Top 10 applicants selected for this round</li>
                <li>
                  Fly to a secret destination for activities and interviews
                </li>
                <li>Meet the Travelpass Team</li>
                <li>Top applicant(s) (1-3) will be offered the job(s)</li>
              </ul>
            </TimelineCard>
          </div>
        </div>
      </section>
      <section id='contest-rules'>
        <h2 className='type-h2 mb-12 text-center'>Contest Rules</h2>
        <div className='grid gap-4 md:grid-cols-2 md:grid-rows-2'>
          <div className='col-span-1 flex items-start'>
            <span className='c-forest font-archivo mr-6 h-10 rounded-full bg-[#158481]/20 p-2'>
              <Icon name='personAltOutline' />
            </span>
            <div>
              <h4 className='type-h4 mb-4'>Eligibility</h4>
              <p>
                Open to anyone aged 18 and above. No prior professional
                experience required, but a passion for travel and storytelling
                is a must.
              </p>
            </div>
          </div>
          <div className='col-span-1 flex items-start'>
            <span className='c-valley font-archivo mr-6 h-10 rounded-full bg-[#A1FF3E]/20 p-2'>
              <Icon name='taskAlt' />
            </span>
            <div>
              <h4 className='type-h4 mb-4'>Judging Criteria</h4>
              <p>
                Submissions will be evaluated based on storytelling, visual
                appeal, and usefulness of the travel guides. Voting matters but
                isn&apos;t the sole criteria
              </p>
            </div>
          </div>
          <div className='col-span-1 flex items-start'>
            <span className='c-orange font-archivo bg-orange/20 mr-6 h-10 rounded-full p-2'>
              <Icon name='event' />
            </span>
            <div>
              <h4 className='type-h4 mb-4'>Submission Deadline</h4>
              <p>
                All entries must be submitted by{' '}
                <strong className='font-bold'>Sept 22, 2024 11:55pm MT</strong>
              </p>
            </div>
          </div>
          <div className='col-span-1 flex items-start'>
            <span className='c-canyon font-archivo bg-canyon/20 mr-6 h-10 rounded-full p-2'>
              <Icon name='thumbUpOffAlt' />
            </span>
            <div>
              <h4 className='type-h4 mb-4'>Winner Selection</h4>
              <p>
                Winners will be announced on{' '}
                <strong className='font-bold'>Nov 4, 2024 11:55pm MT</strong>{' '}
                via our website and social media.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className='flex justify-center'>
        <TermsAndConditionsModal />
      </section>
    </div>
  )
}

const bulletPointsCss = 'flex flex-row items-center gap-x-2'
