import { useState } from 'react'
import {
  Button,
  Icon,
  Illustration,
  useSnackbar,
} from '@travelpass/design-system'
import { useParams } from 'react-router-dom'
import { useFirebaseUser } from 'src/common/hooks/useFirebaseUser'
import { VoteModalListener } from 'src/pages/competition/VoteModalListener'
import { dispatchToggleVoteModal } from 'src/pages/competition/dispatchToggleVoteModal'
import { useCreateVoteMutation } from 'src/pages/competition/hooks/useCreateVoteMutation'
import { SignInModal } from 'src/pages/signin'
import { getTracker } from 'src/utils'
import { useGetUserCompetitionInfo } from './useGetUserCompetitionInfo'

export const CompetitionVoteCta = () => {
  const { accountHandle } = useParams()
  const { data } = useGetUserCompetitionInfo({
    accountHandle,
  })
  const [createVote] = useCreateVoteMutation()
  const { isAnonymous } = useFirebaseUser()
  const [isSignInModalOpen, setIsSignInModalOpen] = useState(false)
  const { addErrorSnack, addSuccessSnack } = useSnackbar()

  const profile = data?.viewUserProfile
  const { competitionInfo, displayName, isUserOwner } = profile ?? {}
  const { enteredCompetition, isCompetitionReady, isEligible } =
    competitionInfo ?? {}
  const showCta =
    enteredCompetition && isCompetitionReady && isEligible && !isUserOwner

  const onClose = () => {
    setIsSignInModalOpen(false)
    !isAnonymous &&
      addSuccessSnack({
        title: 'Successfully signed in!',
        subTitle: 'You may now vote for contestants',
      })
  }

  const onVoteClick = async userProfile => {
    if (isAnonymous) return setIsSignInModalOpen(true)
    else {
      try {
        const response = await createVote({
          variables: {
            input: {
              tracker: getTracker(''),
              userProfileId: userProfile.id,
            },
          },
        })
        if (response?.data) {
          dispatchToggleVoteModal(userProfile)
        }
      } catch (e) {
        addErrorSnack({ title: 'Error', subTitle: e.message })
      }
    }
  }

  if (!showCta) return null

  return (
    <>
      <div className='bg-mint flex flex-col items-center gap-8 rounded-lg p-8 md:ml-24 md:flex-row lg:ml-0'>
        <div className='w-45 h-28'>
          <Illustration name='scooter' />
        </div>
        <div className='[&_button]:bg-mint flex flex-col items-center space-y-4 md:items-start'>
          <h3 className='type-h4'>
            {displayName} is in the running to represent Travelpass
          </h3>
          <p className='type-body-1'>
            Help {displayName} be in the final running by voting and following
          </p>
          <section className='flex flex-col items-center gap-4 md:flex-row md:gap-6'>
            <Button
              label='Vote'
              size='large'
              startIcon='thumbUpOffAlt'
              variant='outlined'
              onClick={() => {
                onVoteClick({ accountHandle, ...profile })
              }}
            />
            <a
              className='decoration-none type-button c-newForest inline-flex items-center'
              href='/competition/about'
              target='_blank'
            >
              What is this?
              <Icon name='arrowForward' />
            </a>
          </section>
        </div>
      </div>
      <VoteModalListener />
      {isSignInModalOpen && (
        <SignInModal initiallyShowCreateAccount={true} onClose={onClose} />
      )}
    </>
  )
}
