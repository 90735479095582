import { Chip } from '@travelpass/design-system'

export const Tags = ({ tags }) => {
  if (!tags || tags?.length === 0) return null

  return (
    <div className='flex flex-col items-center'>
      <p className='type-h4 text-center'>Focuses & Specialities</p>
      <div className='pointer-events-none flex max-w-80 flex-wrap place-content-center gap-2 pt-4'>
        {tags?.map(({ id, name }) => (
          <Chip key={id} label={name} onClick={() => {}} />
        ))}
      </div>
    </div>
  )
}
