import { useLazyQuery } from '@apollo/client'
import { gql } from 'src/__generated__'

const getPlaceDetailsQuery = gql(`
  query GetPlaceDetailsImagesInTrips($placeDetailsRequest: PlaceDetailsRequest!) {
    getPlaceDetails(placeDetailsRequest: $placeDetailsRequest) {
      imageLinks
    }
  }
`)

export const useGetTripPlaceDetailsImagesLazyQuery = () =>
  useLazyQuery(getPlaceDetailsQuery)
