import { IconButton } from '@travelpass/design-system'
import classNames from 'classnames'
import { createPortal } from 'react-dom'
import type { PublishedEvent } from 'src/__generated__/graphql'
import { GuideDrawerContent } from './GuideDrawerContent'
import type { GuideOwner } from '../../types'
import { onGuideSessionStorageSelectedIdChange } from '../../useGuideSessionStorageIds'

interface GuideDrawerProps {
  owner: GuideOwner
  points: PublishedEvent[]
  selectedId: string
}

export const GuideDrawer = ({
  owner,
  points,
  selectedId,
}: GuideDrawerProps) => {
  const isOpen = !!selectedId
  const selectedEvent = points?.find(({ id }) => id === selectedId)

  const onClose = () => onGuideSessionStorageSelectedIdChange('')

  return (
    <>
      {createPortal(
        <div
          className={classNames(
            'h-100dvh z-5 fixed bottom-0 left-0 right-0 top-0 block w-full bg-white lg:hidden',
            {
              hidden: !isOpen,
              'visible fixed bottom-0 bg-white': isOpen,
            }
          )}
        >
          <div className='flex justify-end'>
            <IconButton icon='clear' size='large' onClick={onClose} />
          </div>
          <GuideDrawerContent owner={owner} selectedEvent={selectedEvent} />
        </div>,
        document.body
      )}
      <div
        className={classNames(
          'transition-transform-175 w-119 z-2 lg:h-100svh lg:b-r-grey-400 lg:b-r-solid lg:b-r-1 fixed relative bottom-0 hidden bg-white ease-[cubic-bezier(0,0,0.2,1)] lg:sticky lg:top-0 lg:block',
          {
            '-translate-x-119': !isOpen,
          }
        )}
      >
        <div className='flex justify-end'>
          <IconButton
            aria-label='Close the modal'
            icon='clear'
            size='large'
            onClick={onClose}
          />
        </div>
        <GuideDrawerContent owner={owner} selectedEvent={selectedEvent} />
      </div>
    </>
  )
}
