import { Icon, Illustration } from '@travelpass/design-system'
import { ButtonLink, Helmet, PageLayoutContainer } from 'src/common/components'
import { baseUrl } from 'src/constants'
import paperPlaneIcon from 'src/pages/guides/details/assets/paper-plane.svg'
import { GuideLandingPageCard } from './GuideLandingPageCard'
import { featuredGuides } from './guideLandingPageCardConstants'

export const GuidesLandingPage = () => {
  return (
    <>
      <Helmet
        canonicalUrl={canonicalUrl}
        metaDescription={metaDescription}
        pageName={pageName}
      />
      <div className='space-y-4'>
        <div className='px-20px pt-3rem pb-9rem md:pt-5rem md:pb-17rem relative flex h-auto flex-col justify-center space-y-6 bg-black/45 bg-[url(https://brxcdn.com/us1-app-storage/e46e08a8-2006-11ed-af7b-6a9a40df772f/media/original/99030eaa-60ae-11ef-9211-f626d79ea305)] bg-cover bg-[position:center_95%] bg-blend-darken'>
          <div className='absolute bottom-0 left-0 right-0 top-0 bg-gradient-to-t from-white from-5% to-transparent to-45%'></div>
          <PageLayoutContainer>
            <header className='space-y-6'>
              <h1 className='type-h1 c-white text-9 md:leading-12 md:text-12 lg:leading-16 lg:text-16 text-balance text-center leading-9 drop-shadow-lg'>
                15 Travel Guides For The Best Fall Destinations
              </h1>
              <h2 className='type-subtitle-2 font-500 md:font-400 text-balanced c-white text-center drop-shadow-lg'>
                Explore Guides for our favorite fall spots, filled with tips and
                advice from travelers just like you.
              </h2>
            </header>
          </PageLayoutContainer>
        </div>
        <PageLayoutContainer>
          <section className='mt--7rem md:mt--14rem p-x-4 p-y-8 space-y-8'>
            <div className='grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3'>
              {featuredGuides.map(
                ({ id, owner, title, image, city, state, href }) => (
                  <GuideLandingPageCard
                    key={id}
                    city={city}
                    href={href}
                    image={image}
                    owner={owner}
                    state={state}
                    title={title}
                  />
                )
              )}
            </div>
          </section>
        </PageLayoutContainer>
        <section className='bg-warm-grey p-x-2'>
          <PageLayoutContainer>
            <div className='of-hidden p-y-12 lg:of-initial relative'>
              <img
                alt=''
                className='bottom--6.5 m-a w-90 lg:left--51 lg:right-a absolute left-0 right-0 h-auto max-w-full lg:bottom-0 lg:top-0'
                src={paperPlaneIcon}
              />
              <div className='relative flex flex-col gap-4 text-center lg:flex-row lg:items-center lg:gap-12 lg:text-left'>
                <header className='space-y-1 lg:space-y-2'>
                  <h3 className='type-h4-desktop md:type-h3-desktop'>
                    Always know where to stay, what to do, and where to eat for
                    every trip.
                  </h3>
                  <p className='type-body-1 p-t-2 md:p-t-0 text-balance'>
                    Find travel tips and advice about countless destinations
                    with <b>Travelpass Guides</b>.
                  </p>
                </header>
                <div className='m-a min-w-fit'>
                  <ButtonLink to='/guides/all' variant='valley'>
                    Explore More Guides
                    <Icon name='arrowForward' size='medium' />
                  </ButtonLink>
                </div>
                <div className='w-47 hidden lg:block'>
                  <Illustration name='suitcase' />
                </div>
              </div>
            </div>
          </PageLayoutContainer>
        </section>
      </div>
    </>
  )
}

const canonicalUrl = baseUrl + window.location.pathname
const metaDescription =
  'Discover 15 of the top places to visit this fall with expert tips and recommendations from travelers just like you.'
const pageName = '15 Travel Guides For The Best Fall Destinations'
