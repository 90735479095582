import { IconButton } from '@travelpass/design-system'
import { Scroller } from 'src/common/components'
import { GuideImage } from 'src/pages/guides/details/common'
import { GuideDraftPublishImagesUpload } from './GuideDraftPublishImagesUpload'
import type { UseGuideDraftPublishForm } from '../types'

const filledArray = Array(3).fill(0)

interface GuideDraftPublishImagesSelectedProps {
  getObjectUrl: (updatedImageFiles: FileList) => string
  imagesSelected: string[]
  invalidFormValues: UseGuideDraftPublishForm['invalidFormValues']
  onImagesSelectedChange: (updatedImagesSelected: string) => void
  onInvalidFormValuesChange: UseGuideDraftPublishForm['onInvalidFormValuesChange']
}

export const GuideDraftPublishImagesSelected = ({
  getObjectUrl,
  imagesSelected,
  invalidFormValues,
  onImagesSelectedChange,
  onInvalidFormValuesChange,
}: GuideDraftPublishImagesSelectedProps) => (
  <>
    <div className='lg:p-x-3 hidden lg:block'>
      {/** @todo replace scroller with a grid */}
      <Scroller hideScrollBar={true} scrollContainerClassName='gap-1 lg:gap-3'>
        {filledArray?.map((_, index) => {
          if (!imagesSelected[index])
            return (
              <GuideDraftPublishImagesUpload
                key={index}
                getObjectUrl={getObjectUrl}
                invalidFormValues={invalidFormValues}
                onImagesSelectedChange={onImagesSelectedChange}
                onInvalidFormValuesChange={onInvalidFormValuesChange}
              />
            )

          return (
            <div
              key={index}
              className='b-solid transition-opacity-200 rounded-2 b-forest-light p-1.25 lg:w-45.7 h-30 relative w-40 bg-transparent ease-linear hover:cursor-pointer lg:h-40'
            >
              <GuideImage className='rounded-1' src={imagesSelected[index]} />
              <div className='absolute right--2 top--2'>
                <IconButton
                  color='white'
                  icon='clear'
                  outline='round'
                  size='small'
                  onClick={() => onImagesSelectedChange(imagesSelected[index])}
                />
              </div>
            </div>
          )
        })}
      </Scroller>
    </div>
    <div className='flex flex-row flex-wrap justify-center gap-1 lg:hidden'>
      {filledArray?.map((_, index) => {
        if (!imagesSelected[index])
          return (
            <GuideDraftPublishImagesUpload
              key={index}
              getObjectUrl={getObjectUrl}
              invalidFormValues={invalidFormValues}
              onImagesSelectedChange={onImagesSelectedChange}
              onInvalidFormValuesChange={onInvalidFormValuesChange}
            />
          )

        return (
          <div
            key={index}
            className='b-solid transition-opacity-200 rounded-2 b-forest-light p-1.25 lg:w-45.7 h-30 relative w-40 bg-transparent ease-linear hover:cursor-pointer lg:h-40'
          >
            <GuideImage className='rounded-1' src={imagesSelected[index]} />
            <div className='absolute right--2 top--2'>
              <IconButton
                color='white'
                icon='clear'
                outline='round'
                size='small'
                onClick={() => onImagesSelectedChange(imagesSelected[index])}
              />
            </div>
          </div>
        )
      })}
    </div>
  </>
)
