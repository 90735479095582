import { UnderlineTab, useScreenQuery } from '@travelpass/design-system'

interface AccountSettingsTabsProps {
  activeTab: number
  onTabChange(tabIndex: number): void
}

export const AccountSettingsTabs = ({
  activeTab,
  onTabChange,
}: AccountSettingsTabsProps) => {
  const { isMobileScreen } = useScreenQuery()
  const tabs = [
    {
      label: isMobileScreen ? 'Personal Info' : 'Personal Information',
      onClick: () => onTabChange(0),
    },
    {
      label: 'Sign-in and security',
      onClick: () => onTabChange(1),
    },
  ]

  return <UnderlineTab activeIndex={activeTab} tabs={tabs} willScroll={false} />
}
