import { useEffect } from 'react'
import { Tile } from '@travelpass/design-system'
import classNames from 'classnames'
import { useFormContext } from 'react-hook-form'
import { BudgetEnum } from 'src/__generated__/graphql'
import type { ExtendedUpsertSearchPreferencesInput } from '../ExtendedUpsertInputType'
import { useCurrentUserSearchPreferences } from '../hooks/useCurrentUserSearchPreferences'

const budgets = [
  {
    moneySign: '$',
    title: 'Low-budget',
    description:
      'I aim for low-cost activities, transport, and simple accommodations that don’t break the bank. Walking tours and free cultural activities are always the play.',
    budget: BudgetEnum.Low,
  },
  {
    moneySign: '$$',
    title: 'Mid-budget',
    description:
      'I might splurge on important or once and a lifetime things, but am still mindful of the total cost.',
    budget: BudgetEnum.Mid,
  },
  {
    moneySign: '$$$',
    title: 'High-budget',
    description:
      'I will always treat myself. Money spent while I’m on vacation doesn’t count. Fine dining, luxury stays, and high end activities are my go to.',
    budget: BudgetEnum.High,
  },
]

export const QuestionBudget = () => {
  const { watch, setValue } =
    useFormContext<ExtendedUpsertSearchPreferencesInput>()

  const { data } = useCurrentUserSearchPreferences()
  const initialBudget =
    data?.currentUser?.searchPreferences?.budgetSelection?.budgetType

  const budgetSelection = watch('budget') || initialBudget

  useEffect(() => {
    // This useEffect helps to set up the initial value of the form based on the user's current search preferences
    if (initialBudget) {
      setValue('budget', initialBudget, { shouldDirty: false })
    }
  }, [initialBudget])

  const onClick = targetBudget => {
    setValue('budget', targetBudget, { shouldDirty: true })
  }

  return (
    <div className='lg:gap-y-15 flex w-full flex-col gap-y-5'>
      <h2 className='type-h2-desktop hidden lg:block'>
        When traveling I try to keep my spending...
      </h2>
      <h2 className='type-h2-mobile lg:hidden'>When traveling, I...</h2>

      <div className='lg:flex'>
        {budgets.map(({ title, description, moneySign, budget }, index) => (
          <div
            key={title}
            className='lg:w-33% mb-6 flex w-full flex-col items-center justify-start gap-y-2 lg:mb-0 lg:gap-y-8'
          >
            <Tile
              pressed={budget === budgetSelection}
              onClick={() => onClick(budget)}
            >
              <p
                className={classNames('type-body-1', {
                  'c-forest': budget !== budgetSelection,
                  'c-white': budget === budgetSelection,
                })}
              >
                {moneySign}
              </p>
              <p
                className={classNames('type-body-1', {
                  'c-forest': budget !== budgetSelection,
                  'c-white': budget === budgetSelection,
                })}
              >
                {title}
              </p>
            </Tile>
            <p className='lg:w-90% type-body-1'>{description}</p>
          </div>
        ))}
      </div>
    </div>
  )
}
