import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'

const HAS_USER_TAKEN_QUIZ = gql(`
  query hasUserTakenQuiz {
    currentUser {
      id
      searchPreferences {
        id
      }
    }
  }
`)

export const useHasUserTakenQuiz = () => useQuery(HAS_USER_TAKEN_QUIZ)
