import { useEffect, useState } from 'react'
import {
  useApolloClient,
  useQuery,
  type ApolloError,
  type Cache,
} from '@apollo/client'
import isEmpty from 'lodash.isempty'
import { useNavigate } from 'react-router-dom'
import type { LatLng } from 'use-places-autocomplete'
import { gql } from 'src/__generated__'
import {
  type GetGuideDraftQueryInGuideDraftQuery,
  type GetGuideDraftQueryInGuideDraftQueryVariables,
  type PublishedEventCategory,
  type Scalars,
  GuideStatus,
} from 'src/__generated__/graphql'
import {
  getGuideDetailsUrl,
  getGuideOwnerImage,
  getGuideOwnerName,
  getGuideOwnerUrl,
} from 'src/utils'
import type { GuideDraftData } from './types'
import { getGuideEventCount, getGuideMetaData } from '../details/guideUtils'
import type { GuideMetaData, GuideOwner } from '../details/types'

const guideDraftPublishedEventFragment = gql(`
  fragment GuideDraftPublishedEventFields on PublishedEvent {
    id
    addresses {
      id
      addressLine1
      city
      country
      googlePlaceId
      lat
      long
      state
      zipcode
    }
    description
    externalTypeId
    imageUrl
    name
    notes
    productId
    status
    type
  }
`)

const getGuideDraftQuery = gql(`
  query GetGuideDraftQueryInGuideDraft($first: Int = 100, $id: ID!) {
    currentUser {
      id
      needsGuideOnboarding
      userRoles
    }
    node(id: $id) {
      ... on GuideDraft {
        id
        addresses {
          id
          addressLine1
          city
          country
          googlePlaceId
          lat
          long
          state
        }
        description
        guide {
          id
          isCollected
          name
          numberOfLikes
          shareCount
          status
          viewCount
        }
        hasUnpublishedChanges
        images {
          id
          source
          type
          url
        }
        imageUrl
        insertedAt
        name
        owner {
          id
        }
        ownerProfile {
          id
          accountHandle
          displayName
          isUserOwner
          profileImageUrl
          profileImageSource
          userId
        }
        paginatedEventCategories(first: 100) {
          edges {
            node {
              id
              description
              name
              publishedEvents(first: $first) {
                edges {
                  node {
                    ...GuideDraftPublishedEventFields
                  }
                }
                pageInfo {
                  endCursor
                  hasNextPage
                }
                totalCount
              }
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
          totalCount
        }
        status
        tags {
          id
          name
        }
        timeZone
        uncategorizedPublishedEvents(first: $first) {
          edges {
            node {
              ...GuideDraftPublishedEventFields
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
          totalCount
        }
        updatedAt
      }
    }
  }
`)

type UseGetGuideDraftQuery = {
  data: GetGuideDraftQueryInGuideDraftQuery
  hasError: ApolloError
  isLoading: boolean
  guideDraftData: GuideDraftData
  guideDraftMetaData: GuideMetaData
  mapMarkerCenter: google.maps.LatLngLiteral
  onGetMoreResults: ({
    after,
    id,
  }: {
    after: Scalars['String']['input']
    id: PublishedEventCategory['id']
  }) => Promise<void>
  onMapMarkerCenterChange: (updatedMapMarkerCenter: LatLng) => void
  owner: GuideOwner
}

const useGetGuideDraftQuery = (
  guideDraftId: GetGuideDraftQueryInGuideDraftQueryVariables['id']
): UseGetGuideDraftQuery => {
  const navigate = useNavigate()
  const [mapMarkerCenter, setMapMarkerCenter] =
    useState<UseGetGuideDraftQuery['mapMarkerCenter']>(null)
  const {
    data,
    error,
    fetchMore: onGetMoreResultsQuery,
    loading,
  } = useQuery(getGuideDraftQuery, {
    errorPolicy: 'all',
    skip: !guideDraftId,
    variables: {
      id: guideDraftId,
    },
  })
  const guideDraftData = data?.node as GuideDraftData
  const {
    addresses,
    images,
    imageUrl,
    name,
    ownerProfile,
    paginatedEventCategories,
    uncategorizedPublishedEvents,
  } = guideDraftData ?? {}
  const { accountHandle, displayName, isUserOwner, profileImageUrl } =
    ownerProfile ?? {}
  const eventCount = getGuideEventCount({
    paginatedEventCategories,
    uncategorizedPublishedEvents,
  })
  const owner: GuideOwner = {
    image: getGuideOwnerImage(profileImageUrl),
    isUserOwner,
    name: getGuideOwnerName({
      accountHandle,
      displayName,
    }),
    url: getGuideOwnerUrl(accountHandle),
  }
  const guideDraftMetaData = getGuideMetaData({
    address: addresses?.[0],
    image: images?.[0]?.url ?? imageUrl,
    name,
    numberEvents: eventCount,
    ownerName: owner?.name,
  })

  // When the user is not the owner of the guide draft, redirect to the guide or set the map center
  useEffect(() => {
    if (isEmpty(data)) return

    const currentUserId = data?.currentUser?.id
    const guideDraftOwnerId = (data?.node as GuideDraftData)?.owner?.id

    if (currentUserId === guideDraftOwnerId) return

    if (guideDraftData?.guide?.status === GuideStatus.Published)
      return navigate(
        getGuideDetailsUrl({
          id: guideDraftData?.guide?.id,
          name: name,
        })
      )
  }, [data])

  const onMapMarkerCenterChange = (
    updatedMapMarkerCenter: UseGetGuideDraftQuery['mapMarkerCenter']
  ) => setMapMarkerCenter(updatedMapMarkerCenter)

  // Fetch more events for a specific category
  const onGetMoreResults = async ({
    after,
    id,
  }: {
    after: Scalars['String']['input']
    id: PublishedEventCategory['id']
  }) => {
    try {
      await onGetMoreResultsQuery({
        variables: {
          after,
        },
        /** @todo updateQuery */
      })
    } catch (error) {
      console.error('Server error')
    }
  }

  return {
    data,
    hasError: error,
    isLoading: loading,
    guideDraftData,
    guideDraftMetaData,
    mapMarkerCenter,
    onMapMarkerCenterChange,
    onGetMoreResults,
    owner,
  }
}

const useUpdateGuideDraftQueryCache = () => {
  const apolloClient = useApolloClient()

  const updateGuideDraftQueryCache = (
    guideDraft: GuideDraftData,
    hasUnpublishedChanges = true
  ): Cache.ModifyOptions<GuideDraftData> => {
    const { id, guide } = guideDraft ?? {}

    if (!id) return

    const fields: Cache.ModifyOptions<GuideDraftData>['fields'] = {
      hasUnpublishedChanges() {
        return hasUnpublishedChanges && guide?.status === GuideStatus.Published
      },
    }

    return {
      id: apolloClient.cache.identify({
        id,
        __typename: 'GuideDraft',
      }),
      fields,
    }
  }

  return {
    updateGuideDraftQueryCache,
  }
}

export type { UseGetGuideDraftQuery }
export {
  getGuideDraftQuery,
  guideDraftPublishedEventFragment,
  useGetGuideDraftQuery,
  useUpdateGuideDraftQueryCache,
}
