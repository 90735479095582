import type {
  ExperienceActivityItinerary,
  ExperienceHopOnHopOffItinerary,
  ExperienceItinerary,
  ExperienceStandardItinerary,
  ExperienceUnstructuredItinerary,
} from 'src/__generated__/graphql'
import { ItineraryType } from 'src/__generated__/graphql'

const validateAndFormatImages = (imageArray: string[]): string[] => {
  return imageArray
    ?.reduce<string[]>((validURLs, imgStr) => {
      try {
        const url = new URL(imgStr)
        validURLs.push(url.href)
      } catch (error) {
        // Invalid URL, skip and continue
      }

      return validURLs
    }, [])
    ?.slice(0, 5)
}

const findDuration = (itineraryNode: ExperienceItinerary) => {
  const duration = { minutes: '0' }
  const { itineraryType } = itineraryNode ?? {}

  if (itineraryType === ItineraryType.Standard) {
    const standardItinerary = itineraryNode as ExperienceStandardItinerary
    const { durationRange } = standardItinerary?.duration ?? {}
    duration.minutes = durationRange
  } else if (itineraryType === ItineraryType.Activity) {
    const activityItinerary = itineraryNode as ExperienceActivityItinerary
    const { durationRange } = activityItinerary?.duration ?? {}
    duration.minutes = durationRange
  } else if (itineraryType === ItineraryType.Unstructured) {
    const unstructuredItinerary =
      itineraryNode as ExperienceUnstructuredItinerary
    const { durationRange } = unstructuredItinerary?.duration ?? {}
    duration.minutes = durationRange
  } else if (itineraryType === ItineraryType.HopOnHopOff) {
    const hohoItinerary = itineraryNode as ExperienceHopOnHopOffItinerary
    const { durationRange } = hohoItinerary?.duration ?? {}
    duration.minutes = durationRange
  }

  return parseInt(duration.minutes) ?? 0
}

export { findDuration, validateAndFormatImages }
