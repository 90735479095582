import { useEffect, useState } from 'react'
import { Checkbox, Icon, SkeletonDots } from '@travelpass/design-system'
import {
  CollectionItemIdType,
  type GuideDraft,
} from 'src/__generated__/graphql'
import { useFlag } from 'src/common/hooks'
import { getGuideDraftQuery } from 'src/pages/guides/lists'
import { getGuideDraftOldQuery } from 'src/pages/guides/lists-old'
import { useAddEventToGuide } from './useAddEventToGuide'
import { useAddExperienceToGuide } from './useAddExperienceToGuide'
import { useAddHotelToGuide } from './useAddHotelToGuide'
import { useRemoveItemFromGuideDraft } from './useRemoveItemFromGuide'
import { StateBadge } from '../StateBadge'

interface GuideDraftWithItemChecks extends GuideDraft {
  existingExperience?: GuideDraft['existingGuideDraftItem']
  existingHotel?: GuideDraft['existingGuideDraftItem']
  existingEvent?: GuideDraft['existingGuideDraftItem']
}

interface AddToGuidesListProps {
  guides: GuideDraftWithItemChecks[]
  guidesLoading: boolean
  item: AddToItem
  refetch: () => void
}

export const AddToGuidesList = ({
  guides,
  guidesLoading,
  item: { id, type },
  refetch,
}: AddToGuidesListProps) => {
  // Feature flags
  const enableGuidePagination = useFlag('guidePagination')

  // GraphQL hooks
  const [addExperienceToGuide, { loading: experienceLoading }] =
    useAddExperienceToGuide()
  const [addHotelToGuide, { loading: hotelLoading }] = useAddHotelToGuide()
  const [addEventToGuide, { loading: eventLoading }] = useAddEventToGuide()
  const [removeItem] = useRemoveItemFromGuideDraft()

  // Variables
  const loading = experienceLoading || hotelLoading || eventLoading
  const guideDraftNodes = guides?.map(node => node)

  // State
  const [statusBadgeState, setStatusBadgeState] = useState(null)

  // Helper functions
  // the refetch query is needed so that navigating to the Guide details page will show the updated guide
  const generateIndividualGuideRefetch = (guideDraftId: string) => {
    return enableGuidePagination
      ? { query: getGuideDraftQuery, variables: { id: guideDraftId } }
      : { query: getGuideDraftOldQuery, variables: { id: guideDraftId } }
  }

  useEffect(() => {
    if (!guidesLoading) {
      setStatusBadgeState(
        guideDraftNodes?.map(({ id }) => ({
          id,
          loading: false,
          message: '',
          state: false,
        }))
      )
    }
  }, [guideDraftNodes?.length, guidesLoading])

  const clean = (guideDraftId: string) => {
    setStatusBadgeState(prevState =>
      prevState?.map(badge =>
        badge.id === guideDraftId
          ? { ...badge, loading: false, message: '', state: false }
          : badge
      )
    )
  }

  const handleRemovals = async ({
    guideDraftId,
    publishedEventIds,
  }: {
    guideDraftId: string
    publishedEventIds: string[]
  }) => {
    if (!publishedEventIds.length) return
    const mutationErrors = []
    const { errors } = await removeItem({
      refetchQueries:
        publishedEventIds.length === 1
          ? [generateIndividualGuideRefetch(guideDraftId)]
          : null,
      variables: {
        archivePublishedEventInput: {
          publishedEventId: publishedEventIds.pop(),
        },
      },
    })
    return mutationErrors
      .concat(errors)
      .concat(await handleRemovals({ guideDraftId, publishedEventIds }))
  }

  const handleMutation = async ({
    guideDraftId,
    existingGuideDraftItem,
    publishedEventIds,
  }: {
    guideDraftId: string
    existingGuideDraftItem: GuideDraft['existingGuideDraftItem']
    publishedEventIds: string[]
  }) => {
    if (type == CollectionItemIdType.Experience) {
      return existingGuideDraftItem.isExistingItem
        ? await handleRemovals({ guideDraftId, publishedEventIds })
        : await addExperienceToGuide({
            refetchQueries: [generateIndividualGuideRefetch(guideDraftId)],
            variables: {
              input: { guideDraftId, productId: id },
            },
          })
    }

    if (type == CollectionItemIdType.Hotel) {
      return existingGuideDraftItem.isExistingItem
        ? await handleRemovals({ guideDraftId, publishedEventIds })
        : await addHotelToGuide({
            refetchQueries: [generateIndividualGuideRefetch(guideDraftId)],
            variables: { input: { guideDraftId, hotelId: id } },
          })
    }

    if (type == CollectionItemIdType.Event) {
      return existingGuideDraftItem.isExistingItem
        ? await handleRemovals({ guideDraftId, publishedEventIds })
        : await addEventToGuide({
            refetchQueries: [generateIndividualGuideRefetch(guideDraftId)],
            variables: { input: { guideDraftId, publishedEventId: id } },
          })
    }
  }

  const showLoading = (guideDraftId: string) => {
    setStatusBadgeState(prev =>
      prev.map(badge =>
        badge.id === guideDraftId
          ? { ...badge, loading: true, message: '' }
          : badge
      )
    )
  }

  const showSuccessMessage = (
    guideDraftId: string,
    existingDraftGuideItem: boolean
  ) => {
    setStatusBadgeState(prevState =>
      prevState?.map(badge =>
        badge.id === guideDraftId
          ? {
              ...badge,
              loading: false,
              message: existingDraftGuideItem ? 'Deleted' : 'Saved',
              state: true,
            }
          : badge
      )
    )
  }

  const showErrorMessage = (guideDraftId: string) => {
    setStatusBadgeState(prevState =>
      prevState?.map(badge =>
        badge.id === guideDraftId
          ? {
              ...badge,
              loading: false,
              message: 'Failed',
              state: true,
            }
          : badge
      )
    )
  }

  const onClick = async ({
    guideDraftId,
    existingGuideDraftItem,
    publishedEventIds,
  }: {
    guideDraftId: string
    existingGuideDraftItem: GuideDraft['existingGuideDraftItem']
    publishedEventIds: string[]
  }) => {
    if (loading) return
    try {
      showLoading(guideDraftId)
      const { errors } = await handleMutation({
        guideDraftId,
        existingGuideDraftItem,
        publishedEventIds,
      })
      if (!errors) {
        showSuccessMessage(guideDraftId, existingGuideDraftItem.isExistingItem)
      }
    } catch (e) {
      showErrorMessage(guideDraftId)
    } finally {
      setTimeout(() => clean(guideDraftId), 3000)
      refetch()
    }
  }

  const onMouseDown = ({
    id: guideDraftId,
    existingExperience,
    existingHotel,
    existingEvent,
  }: GuideDraftWithItemChecks) => {
    const existingGuideDraftItem =
      existingExperience || existingHotel || existingEvent
    const publishedEventIds =
      existingExperience?.publishedEvents?.map(({ id }) => id) ||
      existingHotel?.publishedEvents?.map(({ id }) => id) ||
      existingEvent?.publishedEvents?.map(({ id }) => id)

    onClick({
      guideDraftId,
      existingGuideDraftItem,
      publishedEventIds,
    })
  }

  return (
    <>
      {guidesLoading ? (
        <SkeletonDots />
      ) : (
        <div className='flex flex-col gap-y-2'>
          {guideDraftNodes?.length == 0 && (
            <p className='type-body-1 m-0'>
              It seems you haven&apos;t created any guides yet
            </p>
          )}
          {guideDraftNodes?.map((guideDraft, index) => (
            <button
              key={guideDraft.id}
              className='hover:bg-warmGrey type-body-1 b-none flex cursor-pointer justify-between rounded bg-transparent p-2 text-start transition-colors'
              onMouseDown={() => onMouseDown(guideDraft)}
            >
              <span className='inline-flex w-full items-center justify-between gap-4 [&_i]:block'>
                <span className='c-forest-light'>
                  <Icon name='listAlt' />
                </span>
                <span className='inline-flex w-full justify-between pr-4'>
                  <span className='max-w-4/5 w-4/5'>{guideDraft.name}</span>
                  {statusBadgeState?.find(
                    stateEntry => stateEntry.id === guideDraft.id
                  )?.state && (
                    <StateBadge message={statusBadgeState?.[index]?.message} />
                  )}
                </span>
              </span>
              {statusBadgeState?.find(
                stateEntry => stateEntry.id === guideDraft.id
              )?.loading ? (
                <StateBadge loading />
              ) : (
                <Checkbox
                  isChecked={
                    guideDraft.existingExperience?.isExistingItem ||
                    guideDraft.existingHotel?.isExistingItem ||
                    guideDraft.existingEvent?.isExistingItem
                  }
                  label={null}
                />
              )}
            </button>
          ))}
        </div>
      )}
    </>
  )
}
