import { Button, Illustration, useScreenQuery } from '@travelpass/design-system'
import { useHasUserTakenQuiz } from './useHasUserTakenQuiz'

const TITLE = 'Find the stay that fits you!'
const SUBTITLE = 'Always find the right hotel with personalized search results.'

const QuizCallToAction = ({ showQuiz, hasUserTakenQuiz }) => (
  <div className='flex flex-col items-center'>
    <Button
      label={`${hasUserTakenQuiz ? 'Retake Quiz' : 'Take Our Quiz'}`}
      onClick={() => showQuiz()}
    />
  </div>
)

export const QuizBanner = ({ showQuiz }) => {
  const { isMobileOrTablet } = useScreenQuery()
  const { data } = useHasUserTakenQuiz()
  const hasUserTakenQuiz = data?.currentUser?.searchPreferences?.id

  if (isMobileOrTablet) {
    return (
      <div className='bg-beach rounded-11px my-5 flex w-full flex-col justify-center gap-5 px-2 py-5 text-center'>
        <div className='flex'>
          <div className='w-60%'>
            <Illustration name='jeep' />
          </div>
          <div className='flex flex-col justify-center gap-3'>
            <h4 className='type-h5-mobile'>{TITLE}</h4>
            <p className='type-body-1-mobile'>{SUBTITLE}</p>
          </div>
        </div>
        <QuizCallToAction
          hasUserTakenQuiz={hasUserTakenQuiz}
          showQuiz={showQuiz}
        />
      </div>
    )
  }

  return (
    <div className='w-25% my-0 mr-10 flex flex-col justify-center gap-10 text-center'>
      <div className='bg-beach rounded-11px flex flex-row justify-center py-10'>
        <div className='w-full'>
          <Illustration name='jeep' />
        </div>
      </div>
      <h4 className='type-h4-desktop'>{TITLE}</h4>
      <p className='type-body-1-desktop'>{SUBTITLE}</p>
      <QuizCallToAction
        hasUserTakenQuiz={hasUserTakenQuiz}
        showQuiz={showQuiz}
      />
    </div>
  )
}
