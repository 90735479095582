import type { UnderlineTabProps } from '@travelpass/design-system'
import { Divider, UnderlineTab } from '@travelpass/design-system'
import { ScrollMenu } from 'react-horizontal-scrolling-menu'

const accountSettingsTabs = [
  'Personal Information',
  'Sign-in and security',
  'Notifications',
]

interface DashboardAccountSettingsFormNavProps {
  activeIndex: number
  onActiveIndexChange: (updatedActiveIndex: number) => void
}

export const DashboardAccountSettingsFormNav = ({
  activeIndex,
  onActiveIndexChange,
}: DashboardAccountSettingsFormNavProps) => {
  const tabs: UnderlineTabProps['tabs'] = accountSettingsTabs.map(
    (tab, index) => ({
      label: tab,
      onClick: () => onActiveIndexChange(index),
    })
  )

  return (
    <div className='m-x--2 lg:m-x-0'>
      <ScrollMenu scrollContainerClassName='hide-scrollbar'>
        <UnderlineTab
          activeIndex={activeIndex}
          tabs={tabs}
          tabsNotCapitalized={true}
          willScroll={false}
        />
      </ScrollMenu>
      <Divider className='m-t--1px' />
    </div>
  )
}
