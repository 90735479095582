import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const ADD_HOTEL_RECOMMENDATION_MUTATION = gql(`
    mutation AddHotelRecommendation($input: AddHotelRecommendationInput!) {
        addHotelRecommendation(input: $input) {
            hotelRecommendation {
                id
                name
                notes
                isKnownHotel
                lastUpdated
                hotelInfo {
                    latlon
                    amenities
                    city
                    customerReviewScore
                    totalCustomerReviews
                    image
                    name
                    rating
                }
            }
        }
    }
`)

export const useAddHotelRecommendation = () =>
  useMutation(ADD_HOTEL_RECOMMENDATION_MUTATION)
