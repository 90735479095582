import { useState } from 'react'
import { Divider, Icon, Popover } from '@travelpass/design-system'
import { OwnerBadge } from 'src/common/components'
import { useFlag } from 'src/common/hooks'
import { firebaseSignOut } from 'src/config/firebase/firebaseUtils'
import {
  accountSettingsPath,
  dashboardPath,
  myAccountPath,
  notificationsPath,
} from 'src/constants'
import { DashboardTab } from 'src/pages/dashboard/dashboardConstants'
import { useGetCurrentUserProfileAccountHandleAndAvatar } from 'src/pages/profile/components/hooks/useGetCurrentUserProfileAccountHandleAndAvatar'
import { getProfilePageUrl } from 'src/pages/profile/components/profileUtils'
import { SignInModal } from 'src/pages/signin'
import { AnonymousProfileMenu } from './AnonymousProfileMenu'
import { MenuButton } from './MenuButton'
import { MenuSubtitle } from './MenuSubtitle'
import { MenuTitle } from './MenuTitle'

const ProfileImage = props => (
  <img
    {...props}
    alt='avatar'
    className={`aspect-square rounded-full object-cover ${props?.className || ''}`}
  />
)

export const ProfileMenu = ({ isAnonymous }) => {
  const [isSignInModalOpen, setIsSignInModalOpen] = useState<boolean>(false)
  const { data } = useGetCurrentUserProfileAccountHandleAndAvatar()
  const profile = data?.currentUser?.userProfile
  const isCompetition2024Enabled = useFlag('enableCompetition2024')

  const enablePlanPage = useFlag('enablePlanPage')
  const pathSegment = enablePlanPage ? 'book' : 'search'

  return (
    <>
      <Popover
        placement='bottom-end'
        trigger={
          <button
            aria-label='Open site menu'
            className='border-1 border-grey-300 c-forest group-[.clear]:c-white hover:shadow-1 inline-flex items-center rounded-full border-solid bg-transparent p-1.5 transition-colors group-[.clear]:hover:bg-black/15'
          >
            {!profile?.profileImageUrl ? (
              <Icon name='person' />
            ) : (
              <ProfileImage className='h-8 w-8' src={profile.profileImageUrl} />
            )}
            <Icon name='expandMore' />
          </button>
        }
      >
        <div className='w-75'>
          {isAnonymous ? (
            <AnonymousProfileMenu
              openSignInModal={() => setIsSignInModalOpen(true)}
            />
          ) : (
            <div>
              <section className='space-y-4 p-4' id='profile-submenu'>
                {profile && (
                  <OwnerBadge
                    accountHandle={profile.accountHandle}
                    border='none'
                    displayName={profile.displayName}
                    interactivity='none'
                    profileImageUrl={profile.profileImageUrl}
                    size='sm'
                    supplementalContent={
                      <p className='type-body-1 line-clamp-1'>
                        @{profile.accountHandle}
                      </p>
                    }
                  />
                )}
              </section>
              <Divider />

              <section id='account-submenu'>
                <MenuTitle
                  icon='listAlt'
                  title='Dashboard'
                  to={dashboardPath}
                />
                <div className='children:pl-12'>
                  <MenuSubtitle
                    title='Guides'
                    to={`${dashboardPath}?tab=${DashboardTab.guides}`}
                  />
                  <MenuSubtitle
                    title='Bookings'
                    to={`${dashboardPath}?tab=${DashboardTab.bookings}`}
                  />
                  <MenuSubtitle
                    title='Collections'
                    to={`${dashboardPath}?tab=${DashboardTab.collections}`}
                  />
                </div>
                <Divider />
              </section>

              {isCompetition2024Enabled && (
                <section id='account-submenu'>
                  <MenuTitle
                    icon='businessCenter'
                    title='Job Promotion'
                    to='/competition/about'
                  />
                  <div className='children:pl-12'>
                    <MenuSubtitle title='About' to='/competition/about' />
                    <MenuSubtitle
                      title='Leaderboard'
                      to='/competition/leaderboard'
                    />
                    <MenuSubtitle title={"FAQ's"} to='/competition/faq' />
                  </div>
                  <Divider />
                </section>
              )}

              <section id='account-submenu'>
                <MenuTitle
                  icon='personOutline'
                  title='Profile'
                  to={getProfilePageUrl(profile?.accountHandle)}
                />
                <Divider />
              </section>

              <section id='account-submenu'>
                <MenuTitle icon='settings' title='Account' to={myAccountPath} />
                <div className='children:pl-12'>
                  <MenuSubtitle
                    title='Notifications Settings'
                    to={notificationsPath}
                  />
                  <MenuSubtitle
                    title='Account Settings'
                    to={accountSettingsPath}
                  />
                </div>
              </section>

              <div className='md:hidden'>
                <Divider />
              </div>

              <section className='md:hidden' id='guide-submenu'>
                {enablePlanPage ? (
                  <>
                    <MenuTitle
                      icon='mapOutlined'
                      title='Explore'
                      to='/guides/all'
                    />
                    <Divider />
                  </>
                ) : (
                  <>
                    <MenuTitle
                      icon='mapOutlined'
                      title='Guides'
                      to='/guides/all'
                    />
                    <Divider />
                  </>
                )}
              </section>

              <div className='hidden md:block'>
                <Divider />
              </div>

              <section className='md:hidden' id='search-submenu'>
                {enablePlanPage ? (
                  <>
                    <MenuTitle icon='menuBook' title='Plan' to='/plan' />
                    <Divider />
                    <MenuTitle
                      icon='search'
                      title='Book'
                      to={`/${pathSegment}`}
                    />
                    <div className='children:pl-12'>
                      <MenuSubtitle
                        title='Hotels'
                        to={`/${pathSegment}/hotels`}
                      />
                      <MenuSubtitle
                        title='Experiences'
                        to={`/${pathSegment}/experiences`}
                      />
                    </div>
                    <Divider />
                  </>
                ) : (
                  <>
                    <MenuTitle
                      icon='search'
                      title='Search'
                      to={`/${pathSegment}`}
                    />
                    <div className='children:pl-12'>
                      <MenuSubtitle
                        title='Hotels'
                        to={`/${pathSegment}/hotels`}
                      />
                      <MenuSubtitle
                        title='Experiences'
                        to={`/${pathSegment}/experiences`}
                      />
                    </div>
                  </>
                )}
              </section>

              <div className='md:hidden'>
                <Divider />
              </div>

              <MenuButton onClick={firebaseSignOut}>
                <span className='c-grey800 contents'>
                  <Icon name='logout' />
                </span>{' '}
                <h5 className='type-h5'>Log Out</h5>
              </MenuButton>
            </div>
          )}
        </div>
      </Popover>
      {isSignInModalOpen && (
        <SignInModal
          initiallyShowCreateAccount={true}
          onClose={() => setIsSignInModalOpen(false)}
        />
      )}
    </>
  )
}
