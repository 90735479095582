import type { ProfileFields } from 'src/pages/profile/types'

interface BioSectionProps {
  bio: ProfileFields['bio']
  textCenter?: boolean
}

export const BioSection = ({ bio, textCenter = false }: BioSectionProps) => {
  const isBioEmpty = !bio || bio?.length == 0

  if (isBioEmpty) return null

  return (
    <div
      className={`flex flex-col gap-4 ${textCenter ? 'items-center self-center' : ''}`}
    >
      <p
        className={`type-h4 ${textCenter ? 'self-center' : 'block self-start'}`}
      >
        Bio
      </p>
      <p
        className={`c-grey-700 type-body-1 text-wrap break-words ${textCenter ? 'text-center' : 'text-left'}`}
      >
        {bio}
      </p>
    </div>
  )
}
