import { useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { Button, useSnackbar } from '@travelpass/design-system'
import { gql } from 'src/__generated__'
import { useFirebaseUser } from 'src/common/hooks/useFirebaseUser'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { SignInModal } from 'src/pages/signin'
import { getImageSrcSet } from 'src/utils'

const GET_USER_EMAIL = gql(`
  query BookingDetailsCurrentUser {
    currentUser {
      id
      email
    }
  }
`)

export const PlanHeader = () => {
  const { isAnonymous, email } = useFirebaseUser()
  const { addSuccessSnack, addErrorSnack } = useSnackbar()
  const [getUserEmail] = useLazyQuery(GET_USER_EMAIL)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [hasSignedUp, setHasSignedUp] = useState(false)

  const sendEvent = async () => {
    try {
      await getUserEmail()
      if (email) pushDataToDataLayer('trips_beta_tester', { email })

      addSuccessSnack({
        title: "You're on the waitlist!",
        subTitle: 'You have early access to new products and features.',
      })
      setHasSignedUp(true)
    } catch (error) {
      addErrorSnack({ title: 'Please try again later' })
    }
  }

  const onClick = () => {
    if (isAnonymous) {
      setIsModalOpen(true)
      return
    }

    sendEvent()
  }

  return (
    <>
      <div className='max-h-185 gap-3% lg:gap-6% of-hidden bg-[url(https://static.travelpass.com/assets/plan/mountain-with-farmland.webp)] bg-[position:50%_36%] md:flex md:flex-row md:bg-cover'>
        <section className='max-w-90% lg:max-w-40% md:pl-8% mx-auto space-y-5 px-4 pt-10 md:pr-0 lg:pt-40 [&_button]:mx-auto max-md:[&_button]:w-full md:[&_button]:mx-0'>
          <h1 className='type-h2 [&>span]:c-valley text-center md:text-left'>
            <span>Coming Soon:</span>
            <br></br>
            Your Best Trip Yet
          </h1>
          <p className='c-black type-body-1'>
            Create a custom itinerary with Trips, where you can plan,
            coordinate, and organize every detail of your adventure!
          </p>
          <p className='c-black type-body-1'>
            Explore and book recommended hotels and experiences that match your
            travel style, then collaborate with friends and family. Add notes,
            outline a budget, and keep all of your plans organized in an
            easy-to-follow itinerary.
          </p>
          <Button
            aria-label='Get Early Access'
            isDisabled={hasSignedUp}
            label='Get Early Access'
            onClick={onClick}
          />
        </section>
        <section className='md:pr-11% md:pt-42.5 max-w-90% lg:max-w-50% my-8 text-center md:my-0'>
          <img
            alt='travel itinerary'
            className='w-full'
            src='https://static.travelpass.com/assets/plan/trip-timeline.png'
            srcSet={getImageSrcSet({
              desktop:
                'https://static.travelpass.com/assets/plan/trip-timeline.png',
              mobile:
                'https://static.travelpass.com/assets/plan/trip-timeline-mobile.png',
            })}
          />
        </section>
      </div>
      {isModalOpen && (
        <SignInModal
          initiallyShowCreateAccount
          onClose={() => {
            setIsModalOpen(false)
            if (!isAnonymous) sendEvent()
          }}
        />
      )}
    </>
  )
}
