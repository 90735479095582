import { Skeleton } from '@travelpass/design-system'
import { ProgressCircleLoading } from 'src/common/components'

export const DashboardAchievementsLoading = () => (
  <div className='rounded-3'>
    <div className='rounded-t-3 inline-flex flex-row gap-1 bg-white px-3 py-4'>
      <div className='h-4 w-4'>
        <Skeleton />
      </div>
      <div className='w-22 h-4'>
        <Skeleton />
      </div>
    </div>
    <div className='rounded-3 shadow-3 rounded-tl-0 space-y-4 bg-white p-3'>
      <div className='lg:h-4.5 w-25'>
        <Skeleton />
      </div>
      <div className='flex flex-row gap-6'>
        <div>
          <ProgressCircleLoading />
          <div className='m-a h-4 w-10'>
            <Skeleton />
          </div>
        </div>
        <div>
          <ProgressCircleLoading />
          <div className='m-a h-4 w-10'>
            <Skeleton />
          </div>
        </div>
        <div>
          <ProgressCircleLoading />
          <div className='m-a h-4 w-10'>
            <Skeleton />
          </div>
        </div>
      </div>
    </div>
  </div>
)
