import { useEffect } from 'react'
import { useSnackbar } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import isEqual from 'lodash.isequal'
import type { SubmitHandler, UseFormReturn } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import {
  type SocialNetwork,
  VisibilityLevel,
  type ProfileByAccountHandleQuery,
} from 'src/__generated__/graphql'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { useUpsertCurrentUserProfile } from 'src/pages/profile/components/hooks/useUpsertCurrentUserProfile'
import { identifySocialLink } from 'src/pages/profile/components/profileUtils'
import type { DashboardProfileFields } from './types'

const getCombinedSocialLinks = (
  socialLinks: DashboardProfileFields['socialLinks'],
  identifier: DashboardProfileFields['socialLink']
): DashboardProfileFields['socialLinks'] => {
  return [
    ...(socialLinks ?? []),
    ...(!isEmpty(identifier)
      ? [
          {
            network: identifySocialLink(identifier) as SocialNetwork,
            identifier,
          },
        ]
      : []),
  ]
}

const getCombinedLinks = (
  links: DashboardProfileFields['links'],
  url: DashboardProfileFields['linkURL'],
  title: DashboardProfileFields['linkTitle']
) => [
  ...(links ?? []),
  ...(!isEmpty(url) && !isEmpty(title) ? [{ url, title }] : []),
]

type UseDashboardProfileInfo = {
  methods: UseFormReturn<DashboardProfileFields>
  onSubmit: SubmitHandler<DashboardProfileFields>
}

const useDashboardProfileInfo = ({
  onDismiss,
  profile,
}: {
  onDismiss: VoidFunction
  profile: ProfileByAccountHandleQuery['viewUserProfile']
}): UseDashboardProfileInfo => {
  const methods = useForm<DashboardProfileFields>({
    mode: 'onSubmit',
  })
  const [updateProfileInfo] = useUpsertCurrentUserProfile()
  const { addSuccessSnack } = useSnackbar()
  const {
    bio,
    socialLinks,
    tags,
    links,
    displayName,
    accountHandle,
    bragStates,
    bragContinents,
    bragCountries,
    introVideoEmbed,
    profileImageSource,
    profileImageUrl,
  } = profile ?? {}
  const formatLinks = links?.map(({ url, title }) => ({ url, title })) ?? []
  const formatSocialLinks =
    socialLinks?.map(({ identifier, network }) => ({
      identifier,
      network,
    })) ?? []

  useEffect(() => {
    methods.reset({
      accountHandle: accountHandle || '',
      bio: bio || '',
      bragContinents: bragContinents || 0,
      bragCountries: bragCountries || 0,
      bragStates: bragStates || 0,
      displayName: displayName || '',
      introVideoEmbed: introVideoEmbed || '',
      links: links ? formatLinks : [],
      linkTitle: '',
      linkURL: '',
      socialLink: '',
      socialLinks: socialLinks ? formatSocialLinks : [],
      tags: tags ?? [],
    })
  }, [profile])

  const isLinkValid = (linkURL: string, linkTitle: string) => {
    if (isEmpty(linkURL) && !isEmpty(linkTitle)) {
      methods.setError('linkURL', {
        type: 'manual',
        message: 'A URL is required',
      })
      return false
    }

    if (!isEmpty(linkURL) && isEmpty(linkTitle)) {
      methods.setError('linkTitle', {
        type: 'manual',
        message: 'A title is required',
      })
      return false
    }

    return true
  }

  /**
   * It's meant to track the changes user made on its profile
   * @param profile
   * @param socialLinks
   * @param links
   * @param bio
   * @param bragContinents
   * @param bragCountries
   * @param bragStates
   */
  const pushProfileUpdateDataToDataLayer = ({
    profile,
    socialLinks,
    links,
    bio,
    bragContinents,
    bragCountries,
    bragStates,
    accountHandle,
  }: {
    profile: ProfileByAccountHandleQuery['viewUserProfile']
    socialLinks
    links
    bio: string
    bragContinents: number
    bragCountries: number
    bragStates: number
    accountHandle: string
  }) => {
    // If profile is null, it means user is creating a new profile
    if (!profile) {
      pushDataToDataLayer('create_profile', { accountHandle })
      return
    }

    const orginalSocialLinks = profile.socialLinks.map(
      ({ identifier, network }) => ({ identifier, network })
    )
    const originalLinks = profile.links.map(({ title, url }) => ({
      title,
      url,
    }))

    if (!isEqual(orginalSocialLinks, socialLinks)) {
      pushDataToDataLayer('add_social_media', {
        social_medias: socialLinks,
      })
    }
    if (!isEqual(originalLinks, links)) {
      pushDataToDataLayer('add_links', { links })
    }

    if (profile.bio !== bio) pushDataToDataLayer('add_bio', { bio })
    if (
      profile.bragContinents !== bragContinents ||
      profile.bragCountries !== bragCountries ||
      profile.bragStates !== bragStates
    ) {
      pushDataToDataLayer('add_travel_tracker', {
        bragContinents,
        bragCountries,
        bragStates,
      })
    }
  }

  const onSubmit = async ({
    bio,
    displayName,
    socialLinks,
    socialLink,
    links,
    linkURL,
    linkTitle,
    accountHandle,
    bragStates,
    bragContinents,
    bragCountries,
    introVideoEmbed,
  }: DashboardProfileFields) => {
    if (!isLinkValid(linkURL, linkTitle)) return

    const combinedSocialLinks = getCombinedSocialLinks(socialLinks, socialLink)
    const combinedLinks = getCombinedLinks(links, linkURL, linkTitle)

    try {
      await updateProfileInfo({
        variables: {
          input: {
            userProfileRequest: {
              accountHandle,
              bio,
              displayName,
              bragContinents: parseInt(bragContinents.toString()),
              bragCountries: parseInt(bragCountries.toString()),
              bragStates: parseInt(bragStates.toString()),
              links: combinedLinks || [],
              introVideoEmbed: introVideoEmbed || '',
              profileImageSource,
              profileImageUrl,
              socialLinks: combinedSocialLinks || [],
              visibilityLevel: VisibilityLevel.Public,
            },
          },
        },
      })
      pushProfileUpdateDataToDataLayer({
        profile,
        socialLinks: combinedSocialLinks,
        links: combinedLinks,
        bio,
        bragContinents,
        bragCountries,
        bragStates,
        accountHandle,
      })
      addSuccessSnack({ title: 'Profile updated!' })
      onDismiss()
    } catch (error) {
      console.error('An error occurred, try again later.')
    }
  }

  return {
    methods,
    onSubmit,
  }
}

export type { UseDashboardProfileInfo }
export { useDashboardProfileInfo }
