import { useLazyQuery } from '@apollo/client'
import { gql } from 'src/__generated__'

const getPlaceDetailsWebsiteQuery = gql(`
  query GetPlaceDetailsWebsiteInTrips($placeDetailsRequest: PlaceDetailsRequest!) {
    getPlaceDetails(placeDetailsRequest: $placeDetailsRequest) {
      website
    }
  }
`)

export const useGetTripPlaceDetailsWebsiteLazyQuery = () =>
  useLazyQuery(getPlaceDetailsWebsiteQuery)
