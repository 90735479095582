import { useRef, useState } from 'react'
import {
  Autocomplete,
  AutocompleteOption,
  IconButton,
  KeyCode,
  useScreenQuery,
} from '@travelpass/design-system'
import { useSearchParams } from 'react-router-dom'
import { getGeocode, getLatLng, type Suggestion } from 'use-places-autocomplete'
import { GeocoderOption } from 'src/common/components/Geocoder/GeocoderOption'
import { useGeocoderSearch } from 'src/common/components/Geocoder/useGeocoderSearch'
import { COUNTRY_STATE_SEARCH_TYPES } from 'src/pages/guides/guideConstants'

export const ExploreSearchBar = () => {
  const { value, setValue, suggestions, clearSuggestions, ready } =
    useGeocoderSearch({
      requestOptions: { componentRestrictions: { country: [] } },
    })
  const [_, setSearchParams] = useSearchParams()
  const options = suggestions?.data || []
  const showOptions = Boolean(options?.length)
  const { isMobileScreen } = useScreenQuery()
  const autocompleteRef = useRef<HTMLInputElement>(null)
  const autocompleteParentRef = useRef<HTMLDivElement>(null)
  const searchTerm = value?.trim()
  //initially false, then true when the user types in the input, then false when the user selects an option
  const [showSearchTerm, setShowSearchTerm] = useState(false)

  const handleClearAndFocus = ({
    newValue,
    focusesInput,
    shouldFetchData = false,
  }: {
    newValue: string | null
    focusesInput: boolean
    shouldFetchData?: boolean
  }) => {
    newValue !== null && setValue(newValue, shouldFetchData)
    clearSuggestions()
    setShowSearchTerm(false)
    // hacky way to force the functionality to work with ESC key
    autocompleteRef?.current?.blur()
    focusesInput && autocompleteRef?.current?.focus()
  }

  const handleOptionSelection = async (selection: Suggestion | string) => {
    if (typeof selection === 'string') {
      // do not set a new value for the input since we want the user's search term to remain
      handleClearAndFocus({ newValue: null, focusesInput: !isMobileScreen })
      return setSearchParams(sp => {
        sp.set('similarName', selection)
        sp.set('displayName', selection)
        sp.delete('latitude')
        sp.delete('longitude')
        sp.delete('location')
        sp.delete('country')
        sp.delete('state')
        return sp
      })
    } else {
      const [result] = await getGeocode({ placeId: selection.place_id })
      const { lat, lng } = getLatLng(result)
      const isLatLongSearch =
        !result.address_components ||
        !result.address_components?.length ||
        result.address_components?.some(({ types }) =>
          types.some(type => !COUNTRY_STATE_SEARCH_TYPES.has(type))
        )

      if (!isLatLongSearch) {
        handleClearAndFocus({
          newValue: result.formatted_address,
          focusesInput: !isMobileScreen,
        })

        setSearchParams(sp => {
          const country = result.address_components?.find(
            ({ types }) => types[0] === 'country'
          )?.long_name
          const state = result.address_components?.find(
            ({ types }) => types[0] === 'administrative_area_level_1'
          )?.long_name
          country ? sp.set('country', country) : sp.delete('country')
          state ? sp.set('state', state) : sp.delete('state')
          sp.set('displayName', result.formatted_address)
          sp.set('location', result.formatted_address)
          sp.delete('latitude')
          sp.delete('longitude')
          sp.delete('similarName')
          return sp
        })
      }

      if (isLatLongSearch) {
        handleClearAndFocus({
          newValue: result.formatted_address,
          focusesInput: !isMobileScreen,
        })
        return setSearchParams(sp => {
          sp.set('displayName', result.formatted_address)
          sp.set('location', result.formatted_address)
          sp.set('latitude', lat.toString())
          sp.set('longitude', lng.toString())
          sp.delete('similarName')
          sp.delete('country')
          sp.delete('state')
          return sp
        })
      }
    }
  }

  const handleSearchTermEnter = e => {
    if (e.key === KeyCode.ENTER) {
      if (showSearchTerm) handleOptionSelection(searchTerm)
      else if (showOptions) handleOptionSelection(options[0])
    }
    if (e.key === KeyCode.ESC)
      handleClearAndFocus({ newValue: '', focusesInput: true })
  }

  const handleSearchButtonClick = () => {
    if (showSearchTerm) handleOptionSelection(searchTerm)
    else if (showOptions) handleOptionSelection(options[0])
    else document.getElementById('guides-autocomplete-input')?.focus()
  }

  const ClearButton = () => {
    if (!value) return null

    return (
      <span className='mr--3'>
        <IconButton
          icon='clear'
          onClick={() =>
            handleClearAndFocus({ newValue: '', focusesInput: true })
          }
        />
      </span>
    )
  }

  return (
    <div
      className='lg:w-40% max-w-300 mx-auto flex w-full items-center rounded-2xl border-b-0 px-3 py-1 md:px-9 md:py-3'
      ref={autocompleteParentRef}
    >
      <div className='w-full'>
        <Autocomplete
          autoExpand
          fullWidth
          id='guides-autocomplete-input'
          isDisabled={!ready}
          placeholder='Search'
          ref={autocompleteRef}
          slotAfter={<ClearButton />}
          value={value}
          onChange={e => {
            setShowSearchTerm(true)
            setValue(e.target.value)
          }}
          onFocus={
            isMobileScreen
              ? () => {
                  autocompleteParentRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                  })
                }
              : null
          }
          onKeyDown={handleSearchTermEnter}
          onOptionSelect={handleOptionSelection}
        >
          {showSearchTerm && value.trim() && (
            <AutocompleteOption
              className='type-body-1 b-none c-black hover:bg-grey-100 focus:bg-grey-100 aria-selected:bg-warm-grey flex w-full cursor-pointer flex-row items-center gap-2 bg-white px-4 py-1.5 text-left outline-none'
              value={searchTerm}
            >
              Search for &quot;{searchTerm}&quot;
            </AutocompleteOption>
          )}
          {showOptions &&
            options.map(option => (
              <GeocoderOption key={option.place_id} option={option} />
            ))}
        </Autocomplete>
      </div>
      <div className='-ml-2'>
        <IconButton
          color='valley'
          icon='search'
          size='large'
          onClick={handleSearchButtonClick}
        />
      </div>
    </div>
  )
}
