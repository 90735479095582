import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'

const userNotificationPreferencesFragment = gql(`
  fragment UserNotificationPreferencesFragment on CommunicationPreference {
    id
    sendFeatureUpdatesEmail
    sendInspirationEmail
    sendFeatureUpdatesSms
    sendInspirationSms
    sendPromotionEmail
    sendPromotionSms
    sendRecentReviewsEmail
    sendRecentReviewsSms
    sendSurveyRequestsEmail
    sendSurveyRequestsSms
    user {
      id
    }
  }
`)

const getUserNotificationPreferencesQuery = gql(`
  query GetUserNotificationPreferences($userId: ID!) {
    getCommunicationPreferencesForUser(userId: $userId) {
      ...UserNotificationPreferencesFragment
    }
  }
`)

export const useGetUserNotificationPreferences = (userId: string) =>
  useQuery(getUserNotificationPreferencesQuery, {
    variables: {
      userId,
    },
  })
