import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'

const GET_VIBES = gql(`
  query GetVibes {
    getVibes {
      id
      displayName
      typeName
      imageUrl
      isSelected
    }
  }
`)

export const useGetVibes = () =>
  useQuery(GET_VIBES, {
    fetchPolicy: 'network-only',
  })
