import { Button } from '@travelpass/design-system'

interface AddButtonProps {
  text: string
  onClick(): void
}

export const AddButton = ({ onClick, text }: AddButtonProps) => {
  return (
    <div>
      <p className='type-h6 c-grey700 font-400 mb-3 text-center'>{text}</p>
      <div className='flex justify-center'>
        <Button
          label='Add'
          startIcon='add'
          variant='outlined'
          onClick={onClick}
        />
      </div>
    </div>
  )
}
