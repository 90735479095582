import classNames from 'classnames'
import { Link } from 'react-router-dom'

const PolaroidProfileCard = ({
  data: {
    name,
    imageSource,
    guidesCount,
    statesCount,
    countriesCount,
    // followersCount,
    profileURL,
  },
  className,
}: {
  data: {
    name: string
    imageSource: string
    guidesCount: number | string
    statesCount: number | string
    countriesCount: number | string
    // followersCount: number | string,
    profileURL: string
  }
  className?: string
}) => (
  <div
    className={classNames(
      'relative overflow-hidden rounded-lg bg-white px-3.5 pb-3 pt-3.5 shadow',
      className
    )}
  >
    <picture className='relative block h-3/4 w-full overflow-hidden rounded-md bg-zinc-200'>
      <img
        alt={`${name} - Profile Pic`}
        className='absolute left-0 top-0 h-full w-full object-cover object-center'
        src={imageSource}
      />
    </picture>
    <header className='py-4.5 flex flex-col justify-between gap-2'>
      <span className='max-md:text-h3 md:text-h5 block text-start'>{name}</span>
      <div className='flex flex-row gap-4 text-start'>
        <p className='c-forestLight whitespace-nowrap font-medium leading-none md:text-xs'>
          <span className='c-forestLight font-semibold leading-none md:text-sm'>
            {guidesCount}
          </span>{' '}
          <span className='inline-block'>Guides</span>
        </p>
        <p
          //  className='hidden md:block md:text-xs c-forestLight font-medium whitespace-nowrap leading-none'
          className='c-forestLight whitespace-nowrap font-medium leading-none md:text-xs'
        >
          <span className='c-forestLight font-semibold leading-none md:text-sm'>
            {statesCount}
          </span>{' '}
          <span className='mb-1'>States</span>
        </p>
        {/* <p className='md:hidden md:text-xs c-forestLight font-medium whitespace-nowrap leading-none'>
          <span className='md:text-sm c-forestLight font-semibold leading-none'>
            {followersCount}
          </span>{' '}
          <span className='mb-1'>Followers</span>
        </p> */}
        <p className='c-forestLight whitespace-nowrap font-medium leading-none md:text-xs'>
          <span className='c-forestLight font-semibold leading-none md:text-sm'>
            {countriesCount}
          </span>{' '}
          <span className='mb-1'>Countries</span>
        </p>
      </div>
    </header>
    <Link className='absolute inset-0' to={profileURL}>
      <span className='sr-only'>Go to {name}&lsquo;s &#40;Profile&#41;</span>
    </Link>
  </div>
)

export default PolaroidProfileCard
