import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import { IdType } from 'src/__generated__/graphql'
import { useGetTripDetailsQuery } from 'src/pages/trips/hooks'
import type { RecommendedHotel } from './hotelRecommendedConstants'

const nodeQuery = gql(`
  query NodeQueryInTripsDetailsOverviewHotelRecommended(
    $nodeId: ID!
    $hotelId: ID!
    $hotelIdType: IdType!
  ) {
    node(id: $nodeId) {
      ... on Trip {
        id
        endDate
        startDate
        timeZone
      }
    }
    standardHotel(id: $hotelId, idType: $hotelIdType) {
      id
      address
      amenities
      city
      descriptions {
        text
      }
      googlePlaceId
      imageUrls
      latitude
      longitude
      name
      postalCode
      rating
      state
      stateCode
    }
  }
`)

export const useHotelRecommended = ({ hotelId, tripId }) => {
  const {
    hasError: hasTripDetailsError,
    isLoading: isTripDetailsLoading,
    tripDetailsData,
  } = useGetTripDetailsQuery(tripId)
  const {
    data,
    error: hasHotelRecommendedError,
    loading: isHotelRecommendedLoading,
    refetch,
  } = useQuery(nodeQuery, {
    skip: !hotelId || !tripId,
    variables: {
      hotelId,
      hotelIdType: IdType.Standard,
      nodeId: tripId,
    },
  })
  const recommendedHotelData = data?.standardHotel as RecommendedHotel

  return {
    hasError: hasHotelRecommendedError || hasTripDetailsError,
    isLoading: isHotelRecommendedLoading || isTripDetailsLoading,
    recommendedHotelData,
    refetch,
    tripDetailsData,
  }
}
