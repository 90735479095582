import { useState } from 'react'
import { Button } from '@travelpass/design-system'
import { useNavigate } from 'react-router-dom'
import { findMyBookingPath } from 'src/constants'
import { SignInModal } from 'src/pages/signin'
import './myTripsAnon.css'

export const MyTripsAnon = () => {
  const [isSigninModalOpen, setIsSigninModalOpen] = useState(false)

  const navigate = useNavigate()

  const onCloseSigninModal = () => setIsSigninModalOpen(!isSigninModalOpen)
  const onPlanATripClick = () => setIsSigninModalOpen(true)
  const onFindMyBookingClick = () => navigate(findMyBookingPath)

  return (
    <section className='max-w-1440px mx-auto my-0 flex w-full flex-row items-center justify-center'>
      <div className='myTrips-anon-hero max-w-1200px h-146 mb-20 mt-10 w-full overflow-hidden rounded-3xl bg-center md:mb-60 md:mt-20'>
        <div className='max-w-104 m-5 flex h-full w-full flex-col items-start justify-center gap-5 md:ml-20'>
          <header>
            <h1 className='c-white type-h1'>Elevate Your Journey</h1>
            <div className='h-.5 bg-valley mt-5 w-16' />
          </header>
          <span className='w-full max-w-80'>
            <p className='c-white type-body-1'>
              Unlock the ultimate ease in planning, organizing, and embarking on
              your journeys. Trips is your free ticket to a world of
              possibilities, and we&apos;re gearing up to introduce even more
              powerful features. Don&apos;t miss out – start your adventure
              today!
            </p>
          </span>
          <div className='flex flex-col items-center justify-center gap-4 pt-5 md:flex-row md:items-start md:justify-start'>
            <Button
              fullWidth
              aria-label='Plan a trip'
              size='large'
              onClick={onPlanATripClick}
            >
              Plan a Trip
            </Button>
            <Button
              fullWidth
              aria-label='Find my booking'
              size='large'
              variant='outlined'
              onClick={onFindMyBookingClick}
            >
              Find My Booking
            </Button>
          </div>
        </div>
      </div>
      {isSigninModalOpen && <SignInModal onClose={onCloseSigninModal} />}
    </section>
  )
}
