import { Link } from 'react-router-dom'
import { FooterContact } from './FooterContact'
import { FooterLegal } from './FooterLegal'
import { FooterNav } from './FooterNav'
import FooterPartners from './FooterPartners'
import { PageLayoutContainer } from '../PageLayoutContainer'
import { TravelpassLogo } from '../TravelpassLogo'

export const Footer = () => {
  return (
    <footer className='rebrand bg-forest c-warmGrey pb-17 pt-19.5 w-full'>
      <PageLayoutContainer>
        <div className='flex flex-col space-y-14 text-center md:flex-row md:justify-between md:space-y-0 md:text-start'>
          <section className='flex flex-col space-y-9'>
            <Link
              className='pointer max-w-44 border-none no-underline max-md:mx-auto'
              to='/'
            >
              <TravelpassLogo showPrimaryLogo={false} />
            </Link>
            <FooterContact />
          </section>

          <section className='flex flex-col justify-between space-y-14'>
            <FooterPartners />
            <FooterNav className='md:justify-end' />
            <FooterLegal className='md:items-end' />
          </section>
        </div>
      </PageLayoutContainer>
    </footer>
  )
}
