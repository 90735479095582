import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'

const GET_EXPERIENCE_TYPES = gql(`
  query GetExperienceTypes {
    getExperienceTypes {
      id
      displayName
      typeName
      imageUrl
      isSelected
    }
  }
`)

export const useGetExperienceTypes = () =>
  useQuery(GET_EXPERIENCE_TYPES, {
    fetchPolicy: 'network-only',
  })
