import type { UserProfile } from 'src/__generated__/graphql'

export const TOGGLE_VOTE_MODAL = 'toggleVoteModal'

export type ToggleVoteModalProps = Pick<
  UserProfile,
  'accountHandle' | 'displayName' | 'isFollowed' | 'profileImageUrl' | 'userId'
>

export const dispatchToggleVoteModal = (userProfile: ToggleVoteModalProps) => {
  const event = new CustomEvent(TOGGLE_VOTE_MODAL, {
    detail: { ...userProfile },
  })
  window?.dispatchEvent(event)
}
