import { Chip } from '@travelpass/design-system'
import { useFormContext } from 'react-hook-form'
import type { Tag } from 'src/__generated__/graphql'
import { useCurrentUserProfileId } from 'src/pages/profile/components/hooks/useGetCurrentUserProfileId'
import { useTags } from 'src/pages/profile/components/hooks/useTags'
import type { DashboardProfileFields } from './types'

const maxSelectedTags = 5

export const DashboardProfileInfoTags = () => {
  const { setValue, getValues } = useFormContext<DashboardProfileFields>()
  const { data: profile } = useCurrentUserProfileId()
  const { availableTags, addTagToProfile, removeTagFromProfile } = useTags()
  const selectedTags: DashboardProfileFields['tags'] = getValues('tags') ?? []

  const getSelectedTag = (tag: Tag) =>
    selectedTags?.find(selectedTag => selectedTag.id === tag?.id)

  const onTagClick = (tag: Tag) => {
    if (getSelectedTag(tag)) {
      setValue(
        'tags',
        selectedTags.filter(selectedTag => selectedTag.id !== tag?.id)
      )

      // remove tag
      removeTagFromProfile({
        variables: {
          input: {
            userProfileId: profile?.currentUser?.userProfile?.id,
            tagId: tag?.id,
          },
        },
      })
    } else if (selectedTags?.length < maxSelectedTags) {
      setValue('tags', [...selectedTags, tag])
      // add tag
      addTagToProfile({
        variables: {
          input: {
            userProfileId: profile?.currentUser?.userProfile?.id,
            tagId: tag?.id,
          },
        },
      })
    }
  }

  return (
    <section className='space-y-6' id='dashboard-profile-info-tags'>
      <header className='space-y-2'>
        <h6 className='type-h6-desktop c-black'>Describe Your Travel Style</h6>
        <p className='type-body-2-desktop c-grey-800'>
          Select up to five tags.
        </p>
      </header>
      <div className='flex flex-wrap gap-4'>
        {availableTags?.map(tag => (
          <Chip
            key={tag?.id}
            isDisabled={
              selectedTags?.length >= maxSelectedTags && !getSelectedTag(tag)
            }
            isSelected={!!getSelectedTag(tag)}
            label={tag?.name}
            onClick={() => onTagClick(tag)}
          />
        ))}
      </div>
    </section>
  )
}
