import { useState } from 'react'
import { Modal, ModalScrollContents } from '@travelpass/design-system'
import { FormProvider, useForm } from 'react-hook-form'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { CURRENT_USER_FULL_SEARCH_PREFERENCES } from 'src/pages/hotels/results/common/HotelResultsFilters/getUserFullSearchPreferences'
import type { ExtendedUpsertSearchPreferencesInput } from './ExtendedUpsertInputType'
import { QuizSuccess } from './QuizSuccess'
import { QuizWrapper } from './QuizWrapper'
import { defaultPreferences } from './default-preferences'
import { useUpsertSearchPreferences } from './hooks/useUpsertSearchPreferences'
import { QuestionAmenities } from './questions/QuestionAmenities'
import { QuestionBrands } from './questions/QuestionBrands'
import { QuestionBudget } from './questions/QuestionBudget'
import { QuestionExperiences } from './questions/QuestionExperiences'
import { QuestionLocationSelections } from './questions/QuestionLocationSelections'
import { QuestionPartyType } from './questions/QuestionPartyType'
import { QuestionStarRating } from './questions/QuestionStarRating'
import { QuestionVibes } from './questions/QuestionVibes'

const steps = [
  {
    questionImage:
      'https://static.travelpass.com/assets/search-preferences/party-type-left.webp',
    questionComponent: <QuestionPartyType key={1} />,
    dirtyCheck: 'partyType',
  },
  {
    questionImage:
      'https://static.travelpass.com/assets/search-preferences/budget-left.webp',
    questionComponent: <QuestionBudget key={2} />,
    dirtyCheck: 'budget',
  },
  {
    questionImage:
      'https://static.travelpass.com/assets/search-preferences/location-selection-left.webp',
    questionComponent: <QuestionLocationSelections key={3} />,
    dirtyCheck: 'locationSelections',
  },
  {
    questionImage:
      'https://static.travelpass.com/assets/search-preferences/star-rating-left.webp',
    questionComponent: <QuestionStarRating key={4} />,
    dirtyCheck: 'hotelStarRating',
  },
  {
    questionImage:
      'https://static.travelpass.com/assets/search-preferences/amenities-left.webp',
    questionComponent: <QuestionAmenities key={5} />,
    dirtyCheck: 'amenitySelections',
  },
  {
    questionImage:
      'https://static.travelpass.com/assets/search-preferences/brands-left.webp',
    questionComponent: <QuestionBrands key={6} />,
    dirtyCheck: 'brandSelections',
  },
  {
    questionImage:
      'https://static.travelpass.com/assets/search-preferences/vibes-left.webp',
    questionComponent: <QuestionVibes key={7} />,
    dirtyCheck: 'vibeSelections',
  },
  {
    questionImage: null,
    questionComponent: <QuestionExperiences key={8} />,
    dirtyCheck: 'experienceSelections',
  },
]

const hasCurrentStepChanged = (currentStep, methods) =>
  methods?.formState?.dirtyFields[steps[currentStep]?.dirtyCheck]

const getInputFields = (currentStep, methods) => {
  switch (currentStep) {
    case 0:
      return { partyType: methods.getValues('partyType') }
    case 1:
      return { budget: methods.getValues('budget') }
    case 2:
      return {
        locationSelections: methods
          .getValues('fullLocationSelections')
          ?.map(({ id, userRank }) => ({
            locationTypeId: id,
            userRank: userRank,
          })),
      }
    case 3:
      return { hotelStarRating: methods.getValues('hotelStarRating') }
    case 4:
      return { amenitySelections: methods.getValues('amenitySelections') }
    case 5:
      return { brandSelections: methods.getValues('brandSelections') }
    case 6:
      return { vibeSelections: methods.getValues('vibeSelections') }
    case 7:
      return { experienceSelections: methods.getValues('experienceSelections') }
    default:
      return {}
  }
}

const sendQuizDataToBloomreach = methods => {
  const allFields = methods.getValues()

  const filteredFields = Object.entries(allFields).reduce(
    (acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = value // Include the field if its value is not undefined
      }
      return acc
    },
    {}
  )

  pushDataToDataLayer('updateSearchPreferences', {
    searchPreferences: { ...filteredFields },
  })
}

export const SearchPreferencesQuiz = ({ onClose, useModal = false }) => {
  const [upsertSearchPreferences] = useUpsertSearchPreferences({})
  const [currentStep, setCurrentStep] = useState(0)
  const atTheEnd = currentStep === steps.length
  const methods = useForm<ExtendedUpsertSearchPreferencesInput>({
    defaultValues: { ...defaultPreferences },
  })

  const sendAnswers = () => {
    if (hasCurrentStepChanged(currentStep, methods)) {
      upsertSearchPreferences({
        variables: {
          input: { ...getInputFields(currentStep, methods) },
        },
        refetchQueries: [
          { query: CURRENT_USER_FULL_SEARCH_PREFERENCES },
          'GetUserFullSearchPreferences',
        ],
      })
    }
  }

  const goNext = () => {
    sendAnswers()
    setCurrentStep(currentStep + 1)
  }

  const goBack = () => {
    sendAnswers()
    setCurrentStep(Math.max(currentStep - 1, 0))
  }

  const handleClose = () => {
    sendQuizDataToBloomreach(methods)
    sendAnswers()
    onClose()
  }

  const coreForm = ({ isAModal = false }) => (
    <FormProvider {...methods}>
      {atTheEnd ? (
        <QuizSuccess isAModal={isAModal} onClose={handleClose} />
      ) : (
        <QuizWrapper
          currentStep={currentStep}
          goBack={goBack}
          goNext={goNext}
          isAModal={isAModal}
          steps={steps}
          onClose={handleClose}
        />
      )}
    </FormProvider>
  )

  return useModal ? (
    <Modal className='lg:min-w-400' onDismiss={handleClose}>
      {coreForm({ isAModal: true })}
    </Modal>
  ) : (
    <div className='fixed inset-0 z-10 flex bg-white pt-20'>
      {coreForm({ isAModal: false })}
    </div>
  )
}
