import { Button, Illustration } from '@travelpass/design-system'

interface DashboardCtaQuizProps {
  hasUserTakenQuiz: boolean
  onShowModalQuiz: VoidFunction
}

export const DashboardCtaQuiz = ({
  hasUserTakenQuiz,
  onShowModalQuiz,
}: DashboardCtaQuizProps) => (
  <div className='my-0 flex flex-col justify-center gap-10 text-center'>
    <div className='bg-beach rounded-11px flex flex-row justify-center py-10'>
      <div className='w-full'>
        <Illustration name='jeep' />
      </div>
    </div>
    <h4 className='type-h4-desktop'>Find the stay that fits you!</h4>
    <p className='type-body-1-desktop'>
      Always get the best results and recommendations with personalized search
      results.
    </p>
    <Button fullWidth={true} onClick={onShowModalQuiz}>
      {hasUserTakenQuiz ? 'Retake Quiz' : 'Take Our Quiz'}
    </Button>
  </div>
)
