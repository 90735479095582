import { Skeleton } from '@travelpass/design-system'

export const DashboardHeaderLoading = () => (
  <div className='dashboard-header-background h-35.5 space-y-3 bg-[radial-gradient(61.11%_100%_at_50.03%_100%,_#125E5C_26%,_#042E30_100%)] pb-8 pl-9 pr-5 pt-9'>
    <div className='mx-a max-w-452 relative'>
      <div className='dashboard-header-heading w-70 hidden h-12'>
        <Skeleton />
      </div>
      {/** Mobile Header */}
      <div className='dashboard-header-mobile flex flex-row items-center gap-4'>
        <div className='h-12 w-12'>
          <Skeleton variant='circular' />
        </div>
        <div className='space-y-3'>
          <div className='space-y-1'>
            <div className='w-17 h-5'>
              <Skeleton />
            </div>
            <div className='flex flex-row items-center gap-4'>
              <div className='flex flex-row items-center gap-1'>
                <div className='h-5 w-2.5'>
                  <Skeleton />
                </div>
                <div className='w-17.5 h-4'>
                  <Skeleton />
                </div>
              </div>
              <div className='[&>button]:p-x-0 [&>button>div]:color-white [&>button>div]:type-small-text flex flex-row items-center gap-1'>
                <div className='flex flex-row items-center gap-1'>
                  <div className='h-5 w-2.5'>
                    <Skeleton />
                  </div>
                  <div className='w-16.5 h-4'>
                    <Skeleton />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)
