import { useEffect, useState } from 'react'
import { IconButton } from '@travelpass/design-system'
import classNames from 'classnames'
import { isbot } from 'isbot'
import isEmpty from 'lodash.isempty'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { GuideDraftStatus, GuideStatus } from 'src/__generated__/graphql'
import { Helmet, PageLayoutContainer } from 'src/common/components'
import { useFlag } from 'src/common/hooks'
import {
  decodeGuideId,
  encodeGuideId,
  getGuideDraftDetailsUrl,
  stringToBoolean,
} from 'src/utils'
import {
  GuideDraftDrawer,
  GuideDraftEmpty,
  GuideDraftGeocoder,
  GuideDraftHeader,
  GuideDraftLoading,
  GuideDraftMap,
  GuideDraftMapEvents,
  GuideDraftMapMarkers,
  GuideDraftMapSearch,
  GuideDraftOnboarding,
  GuideDraftPublishQuickBanner,
  GuideDraftSections,
  GuideDraftStickyActionButtons,
  GuideDraftTopNav,
} from './common'
import { getGuideDraftEventMarkers } from './guideDraftUtils'
import { useGetGuideDraftQuery } from './useGetGuideDraftQuery'
import { GuideSearchParam, useGuideSessionStorageIds } from '../details'

export const GuideDraftOld = () => {
  const isGuideUrlShortEnabled = useFlag('guideURLShort')
  const { selectedId } = useGuideSessionStorageIds()
  const navigate = useNavigate()
  const { guideDraftId, guideDraftName } = useParams()
  const guideDraftIdDecoded = decodeGuideId(guideDraftId)
  const guideDraftIdEncoded = encodeGuideId({
    guideId: guideDraftIdDecoded,
    isGuideDraft: true,
  })
  const {
    data,
    eventCount,
    guideDraftData,
    guideDraftMetaData,
    hasError,
    isLoading,
    mapMarkerCenter,
    onMapMarkerCenterChange,
    owner,
  } = useGetGuideDraftQuery(
    isGuideUrlShortEnabled ? guideDraftIdEncoded : guideDraftId
  )
  const [placePoints, setPlacePoints] = useState<
    google.maps.places.PlaceResult[]
  >([])
  const [searchParams] = useSearchParams()
  const [searchValue, setSearchValue] = useState('')
  const mapFullView = stringToBoolean(
    searchParams.get(GuideSearchParam.mapFullView) ?? 'false'
  )
  const points = getGuideDraftEventMarkers(guideDraftData?.groupedEvents ?? [])
  const { groupedEvents, hasUnpublishedChanges, id, publishedEventCategories } =
    guideDraftData ?? {}
  const { isUserOwner } = owner ?? {}
  const showQuickPublish =
    hasUnpublishedChanges &&
    guideDraftData?.guide?.status === GuideStatus.Published

  useEffect(() => {
    if (
      isGuideUrlShortEnabled &&
      guideDraftName &&
      guideDraftId !== guideDraftIdDecoded
    ) {
      navigate(
        getGuideDraftDetailsUrl({
          id: guideDraftIdDecoded,
          isUserOwner: true,
          name: guideDraftName,
        }),
        {
          replace: true,
        }
      )
    }
  }, [guideDraftIdDecoded, guideDraftName, isGuideUrlShortEnabled])

  const onPlacePointsChange = (
    updatedPlacePoints: google.maps.places.PlaceResult[]
  ) => setPlacePoints(updatedPlacePoints)

  const onSearchValueChange = (updatedSearchValue: string) =>
    setSearchValue(updatedSearchValue)

  if (isLoading || isEmpty(guideDraftData)) return <GuideDraftLoading />

  if (
    hasError ||
    guideDraftData?.status === GuideDraftStatus.Archived ||
    !isUserOwner
  )
    return <GuideDraftEmpty />

  return (
    <>
      {!isEmpty(guideDraftData) && <Helmet {...guideDraftMetaData} />}
      <GuideDraftTopNav
        guideDraftData={guideDraftData}
        isUserOwner={isUserOwner}
      />
      <div className='lg:flex lg:flex-row'>
        <div
          className={classNames(
            'p-b-5 lg:w-100vw lg:b-r-grey-400 lg:b-r-solid lg:b-r-1 lg:p-b-30 lg:w-202.5 lg:z-3 bg-white',
            {
              'hidden lg:block': mapFullView,
              'p-t-0': showQuickPublish,
              'lg:p-t-3 p-t-5': !showQuickPublish,
            }
          )}
        >
          <div className='space-y-6.5'>
            {showQuickPublish && (
              <GuideDraftPublishQuickBanner guideDraftData={guideDraftData} />
            )}
            <PageLayoutContainer size='none'>
              <div className='space-y-4 lg:space-y-8'>
                <GuideDraftHeader guideDraftData={guideDraftData} />
                <div className='space-y-4'>
                  <h3 className='color-forest-dark type-body-1-medium-desktop'>
                    Add places, experiences, and more to your Guide!
                  </h3>
                  <GuideDraftGeocoder
                    searchValue={searchValue}
                    slotAfter={
                      <div className='pointer-events-none absolute right-0'>
                        <IconButton
                          color='valley'
                          icon='search'
                          onClick={() => {}}
                        />
                      </div>
                    }
                    onMapMarkerCenterChange={onMapMarkerCenterChange}
                    onPlacePointsChange={onPlacePointsChange}
                    onSearchValueChange={onSearchValueChange}
                  />
                </div>
                <GuideDraftSections
                  eventCount={eventCount}
                  groupedEvents={groupedEvents}
                  owner={owner}
                  publishedEventCategories={publishedEventCategories}
                  selectedId={selectedId}
                  onMapMarkerCenterChange={onMapMarkerCenterChange}
                />
                <GuideDraftStickyActionButtons guideDraftId={id} />
              </div>
              <GuideDraftOnboarding currentUser={data?.currentUser} />
            </PageLayoutContainer>
          </div>
        </div>
        <GuideDraftDrawer points={points} />
        {!isbot(navigator.userAgent) && (
          <div
            className={classNames(
              'bg-grey-100 lg:top-77px lg:transition-margin-175 lg:z-4 relative h-[calc(100svh-89px)] grow lg:sticky lg:h-[calc(100svh-77px)] lg:ease-[cubic-bezier(0,0,0.2,1)]',
              {
                'hidden lg:block': !mapFullView,
                'lg:-m-l-119': !selectedId,
              }
            )}
          >
            <GuideDraftMap data={data}>
              <GuideDraftMapEvents
                data={data}
                mapMarkerCenter={mapMarkerCenter}
              />
              <GuideDraftMapMarkers
                owner={owner}
                placePoints={placePoints}
                points={points}
                onMapMarkerCenterChange={onMapMarkerCenterChange}
              />
              <GuideDraftMapSearch
                searchValue={searchValue}
                onMapMarkerCenterChange={onMapMarkerCenterChange}
                onPlacePointsChange={onPlacePointsChange}
                onSearchValueChange={onSearchValueChange}
              />
            </GuideDraftMap>
          </div>
        )}
      </div>
    </>
  )
}
