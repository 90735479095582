import { useState, type FormEvent } from 'react'
import {
  Button,
  IconButton,
  Input,
  Link,
  useScreenQuery,
} from '@travelpass/design-system'
import {
  lucencyNumber,
  onPhoneNumberClick,
} from 'src/config/analytics/lucencyUtils'
import { getImageSrcSet } from 'src/utils'
import { SupportContainer } from './common'

interface SupportHeroProps {
  searchTerm: string
  onChange?(updatedSearchTerm: string): void
  onSubmit?(willReset?: boolean): void
}

export const SupportHero = ({
  searchTerm,
  onChange = () => {},
  onSubmit = () => {},
}: SupportHeroProps) => {
  const [showReset, setShowReset] = useState(false)
  const { isMobileScreen } = useScreenQuery()
  const phoneNumber = lucencyNumber()

  const onFormReset = () => {
    setShowReset(false)
    onSubmit(true)
  }

  const onFormSubmit = (event: FormEvent) => {
    event.preventDefault()
    setShowReset(true)
    onSubmit()
  }

  return (
    <div className='relative'>
      <img
        alt='Support'
        className='absolute left-0 top-0 h-full w-full object-cover'
        src='//static.travelpass.com/assets/support/hero.webp'
        srcSet={getImageSrcSet({
          desktop: '//static.travelpass.com/assets/support/hero.webp',
          mobile: '//static.travelpass.com/assets/support/hero-mobile.webp',
        })}
      />
      <SupportContainer>
        <div className='p-t-57 md:p-t-83 lg:p-t-64 relative bottom--16 space-y-5 md:bottom--10 lg:bottom--16 lg:space-y-14'>
          <div className='space-y-2 text-center'>
            <p className='type-overline uppercase text-white'>Need Help?</p>
            <h1 className='font-archivo type-h1-mobile lg:leading-16 lg:text-16 text-white'>
              Travelpass Support
            </h1>
          </div>
          <div className='p-x-6 p-y-8 rounded-3 shadow-2 lg:p-x-14 lg:p-y-10 space-y-6 bg-white'>
            <p className='type-body-1 text-center'>
              For help from our dedicated customer support team, please call{' '}
              <Link
                href={`tel:${phoneNumber}`}
                label={phoneNumber}
                onClick={() => onPhoneNumberClick(phoneNumber)}
              />
              . We
              <br className='hidden lg:block' /> are available 24 hours per day,
              7 days a week.
            </p>
            <form noValidate className='relative' onSubmit={onFormSubmit}>
              <Input
                fullWidth
                aria-label='Search support articles by topic'
                placeholder='Search for Topic'
                slotAfter={
                  isMobileScreen ? (
                    <IconButton
                      aria-label='submit'
                      color='forestLight'
                      icon='search'
                      type='submit'
                    />
                  ) : (
                    <Button
                      aria-label='submit'
                      label='Search'
                      size='large'
                      startIcon='search'
                      type='submit'
                    />
                  )
                }
                value={searchTerm}
                onChange={event => onChange(event.currentTarget.value)}
              />
              {showReset && (
                <div className='absolute left-0 top-full'>
                  <Button
                    aria-label='clear'
                    endIcon='clear'
                    label='Clear Search'
                    size='small'
                    type='reset'
                    variant='text'
                    onClick={onFormReset}
                  />
                </div>
              )}
            </form>
          </div>
        </div>
      </SupportContainer>
    </div>
  )
}
