import { Tile } from '@travelpass/design-system'
import { CheckBubble } from './CheckBubble'

const sizes = {
  small:
    'flex items-center justify-center bg-white rounded-md min-h-50 min-w-50 relative',
  large:
    'flex items-center justify-center bg-white rounded-md min-h-75 min-w-75 relative',
  unsetHeight: 'flex items-center justify-center bg-white rounded-md relative',
  minHeight:
    'flex items-center justify-center bg-white rounded-md min-h-50 relative',
}

export const CheckedTile = ({ pressed, onClick, children, size = 'large' }) => (
  <Tile pressed={pressed} style={{ padding: '.25rem' }} onClick={onClick}>
    <div className={`${sizes[size]}`}>
      {pressed && <CheckBubble />}
      {children}
    </div>
  </Tile>
)
