import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__/gql'
import type { GetUserCompetitionInfoQueryVariables } from 'src/__generated__/graphql'

/**@todo re-add competitionInfo => canVoteLeaderboard when it is working on the backend */
export const GET_USER_COMPETITION_INFO = gql(`
  query GetUserCompetitionInfo($accountHandle: String!) {
    viewUserProfile(accountHandle: $accountHandle) {
      id
      displayName
      competitionInfo {
        id
        enteredCompetition
        isCompetitionReady
        isEligible
      }
      isFollowed
      isUserOwner
      profileImageUrl
      userId
    }
  }
`)

export const useGetUserCompetitionInfo = (
  variables: GetUserCompetitionInfoQueryVariables
) =>
  useQuery(GET_USER_COMPETITION_INFO, {
    variables,
  })
