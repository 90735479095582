import { useEffect } from 'react'
import isEmpty from 'lodash.isempty'
import { useParams } from 'react-router-dom'
import { type PublishedEvent } from 'src/__generated__/graphql'
import { AddToCollectionsModalListener } from 'src/common/components/Collections/AddToCollectionsModalListener'
import { AddToGuideModalListener } from 'src/common/components/Guides/AddToGuideModalListener'
import { useFlag } from 'src/common/hooks'
import { encodeGuideId } from 'src/utils'
import { GuideSection } from './GuideSection'
import { GuideSectionsEmpty } from './GuideSectionsEmpty'
import { GuideSectionsLoading } from './GuideSectionsLoading'
import { getGuideEventMarkers } from '../../guideUtils'
import type { GuideOwner } from '../../types'
import { useGetGuideEventsQuery } from '../../useGetGuideEventsQuery'
import { GuideEvents } from '../GuideEvents'

interface GuideSectionsProps {
  eventCount: number
  onPointsChange: (updatedPoints: PublishedEvent[]) => void
  owner: GuideOwner
}

export const GuideSections = ({
  eventCount,
  onPointsChange,
  owner,
}: GuideSectionsProps) => {
  const isGuideUrlShortEnabled = useFlag('guideURLShort')
  const { guideId } = useParams()
  const guideIdEncoded = encodeGuideId({
    guideId,
    isGuideDraft: false,
  })
  const { data, guideEventsData, hasError, isLoading, onGetMoreResults } =
    useGetGuideEventsQuery(isGuideUrlShortEnabled ? guideIdEncoded : guideId)
  const { paginatedEventCategories, uncategorizedPublishedEvents } =
    guideEventsData ?? {}

  useEffect(() => {
    if (!isEmpty(data) && !isLoading) {
      const updatedPoints = getGuideEventMarkers({
        paginatedEventCategories,
        uncategorizedPublishedEvents,
      })
      onPointsChange(updatedPoints)
    }
  }, [data, isLoading])

  if (isLoading) return <GuideSectionsLoading />

  if (hasError || !eventCount) return <GuideSectionsEmpty />

  return (
    <div className='space-y-6'>
      {!!uncategorizedPublishedEvents?.edges?.length && (
        <div className='space-y-3 md:space-y-4'>
          <GuideEvents
            owner={owner}
            paginatedEventCategoryId={null}
            publishedEventConnection={uncategorizedPublishedEvents}
            onGetMoreResults={onGetMoreResults}
          />
        </div>
      )}
      {paginatedEventCategories?.edges?.map(({ node }) => {
        return (
          <GuideSection
            key={node?.id}
            owner={owner}
            paginatedEventCategory={node}
            onGetMoreResults={onGetMoreResults}
          />
        )
      })}
      <AddToCollectionsModalListener />
      <AddToGuideModalListener />
    </div>
  )
}
