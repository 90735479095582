import { Carousel } from '@travelpass/design-system'
import { StarIcon } from './StarIcon'
import { reviewsData } from './reviewsData'

// TODO: remove when supportV2 is released.
export const Reviews = () => {
  return (
    <div>
      <img
        alt='Animated reviews'
        aria-label='animated reviews'
        className={animatedReviewsCss}
        src='https://static.travelpass.com/assets/reviews.gif'
      />
      <Carousel>
        {reviewsData.data.map(({ content, date, name, rating }) => (
          <div key={name} className={contentCss}>
            <div className='text-subtitle-1'>&quot;{content}&quot;</div>
            <div className={authorCss}>
              <p className='text-body-1'>
                {name}
                <br />
                {date}
              </p>
              <div>
                {Array(rating)
                  .fill(0)
                  .map((_item, index) => (
                    <StarIcon key={index} />
                  ))}
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  )
}

const animatedReviewsCss = 'block m-auto max-w-full md:max-w-81'

const authorCss = 'flex flex-col gap-4 md:flex-row md:justify-between'

const contentCss = 'flex flex-col gap-10 text-left py-0 px-12 md:px-24'
