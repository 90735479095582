import type { ContextType } from 'react'
import { useRef, useState } from 'react'
import { Button, Divider, IconButton } from '@travelpass/design-system'
import type { VisibilityContext } from 'react-horizontal-scrolling-menu'
import { Scroller, ScrollerButton } from 'src/common/components'
import { tripTimelinePath } from 'src/constants'
import {
  TripCreateModal,
  TripDeleteModal,
  TripEditModal,
} from 'src/pages/trips/components'
import { TripCard } from 'src/pages/trips/home/components/MyTripsList/components'
import { DashboardTripsEmpty } from './DashboardTripsEmpty'
import { DashboardTripsLoading } from './DashboardTripsLoading'
import { useGetDashboardTripsQuery } from '../../useGetDashboardTripsQuery'

type ScrollVisibilityApiType = ContextType<typeof VisibilityContext>

export const DashboardTrips = () => {
  const apiRef = useRef({} as ScrollVisibilityApiType)
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [currentTripId, setCurrentTripId] = useState<null | string>(null)
  const [currentTripName, setCurrentTripName] = useState('')
  const { tripsPastData, tripsUpcomingData, hasError, isLoading } =
    useGetDashboardTripsQuery()
  const hasNoTrips = !tripsPastData?.length && !tripsUpcomingData?.length

  const onCardClick = (tripId: string, tripName: string) =>
    window.open(`${tripTimelinePath}/${tripId}`)

  const onCreateClose = () => setIsCreateModalOpen(false)

  const onCreateOpen = () => setIsCreateModalOpen(true)

  const onDeleteClose = () => {
    setCurrentTripId(null)
    setIsEditModalOpen(true)
    setIsDeleteModalOpen(false)
  }

  const onDeleteOpen = (tripId: string, tripName: string) => {
    setCurrentTripId(tripId)
    setCurrentTripName(tripName)
    setIsEditModalOpen(false)
    setIsDeleteModalOpen(true)
  }

  const onEditClose = () => {
    setCurrentTripId(null)
    setIsEditModalOpen(false)
  }

  const onEditOpen = (tripId: string, tripName: string) => {
    setCurrentTripId(tripId)
    setCurrentTripName(tripName)
    setIsEditModalOpen(true)
  }

  if (isLoading) return <DashboardTripsLoading />

  if (hasError || hasNoTrips) return <DashboardTripsEmpty />

  return (
    <>
      <section className='space-y-6 lg:space-y-12'>
        <div className='flex flex-row items-center justify-between'>
          <h2 className='c-black type-h3-mobile type-h4-desktop'>My Trips</h2>
          <div className='hidden md:block'>
            <Button
              aria-label='Create a trip'
              startIcon='add'
              onClick={onCreateOpen}
            >
              New Trip
            </Button>
          </div>
          <div className='md:hidden'>
            <IconButton
              color='forestLight'
              icon='add'
              outline='round'
              size='small'
              onClick={onCreateOpen}
            />
          </div>
        </div>
        <div className='space-y-9'>
          <div className='space-y-6'>
            <div className='space-y-3'>
              <p className='type-overline'>
                Upcoming Trips
                {!!tripsUpcomingData?.length &&
                  ` (${tripsUpcomingData?.length})`}
              </p>
              <Divider />
            </div>
            {tripsUpcomingData?.length ? (
              <div className='w-full max-w-full'>
                <Scroller
                  LeftArrow={
                    <span className='block pr-5'>
                      <ScrollerButton icon='arrowBackIos' size='small' />
                    </span>
                  }
                  RightArrow={
                    <span className='block pl-5'>
                      <ScrollerButton
                        isNext
                        icon='arrowForwardIos'
                        size='small'
                      />
                    </span>
                  }
                  apiRef={apiRef}
                  scrollContainerClassName='gap-2 p-5 m-l--5 rounded-2'
                >
                  {tripsUpcomingData?.map((trip, index) => {
                    const tripId = trip?.node?.id ?? ''
                    const tripName = trip?.node?.name ?? ''
                    return (
                      <div key={index} className='ws-nowrap'>
                        <TripCard
                          className='w-55 md:min-w-55 md:min-w-66'
                          userTripData={trip?.node}
                          onClick={() => onCardClick(tripId, tripName)}
                          onDeleteClick={() => onDeleteOpen(tripId, tripName)}
                          onEditClick={() => onEditOpen(tripId, tripName)}
                        />
                      </div>
                    )
                  })}
                </Scroller>
              </div>
            ) : (
              <p className='color-grey-700 p-l-2 type-body-2'>
                No upcoming Trips
              </p>
            )}
          </div>
          <div className='space-y-6'>
            <div className='space-y-3'>
              <p className='type-overline'>
                Past Trips
                {!!tripsPastData?.length && ` (${tripsPastData?.length})`}
              </p>
              <Divider />
            </div>
            {tripsPastData?.length ? (
              <div className='w-full max-w-full'>
                <Scroller
                  LeftArrow={
                    <span className='block pr-5'>
                      <ScrollerButton icon='arrowBackIos' size='small' />
                    </span>
                  }
                  RightArrow={
                    <span className='block pr-5'>
                      <ScrollerButton
                        isNext
                        icon='arrowForwardIos'
                        size='small'
                      />
                    </span>
                  }
                  apiRef={apiRef}
                  scrollContainerClassName='gap-2 p-5 m-l--5 rounded-2'
                >
                  {tripsPastData?.map((trip, index) => {
                    const tripId = trip?.node?.id ?? ''
                    const tripName = trip?.node?.name ?? ''
                    return (
                      <div key={index} className='ws-nowrap'>
                        <TripCard
                          className='w-55 md:min-w-55 md:min-w-66'
                          userTripData={trip?.node}
                          onClick={() => onCardClick(tripId, tripName)}
                          onDeleteClick={() => onDeleteOpen(tripId, tripName)}
                          onEditClick={() => onEditOpen(tripId, tripName)}
                        />
                      </div>
                    )
                  })}
                </Scroller>
              </div>
            ) : (
              <p className='color-grey-700 p-l-2 type-body-2'>No past Trips</p>
            )}
          </div>
        </div>
      </section>
      {isCreateModalOpen && <TripCreateModal onDismiss={onCreateClose} />}
      {isEditModalOpen && currentTripId && (
        <TripEditModal
          tripId={currentTripId}
          onClose={onEditClose}
          onDeleteOpen={() => onDeleteOpen(currentTripId, currentTripName)}
        />
      )}
      {isDeleteModalOpen && currentTripId && (
        <TripDeleteModal
          tripId={currentTripId}
          tripName={currentTripName}
          onClose={onDeleteClose}
        />
      )}
    </>
  )
}
