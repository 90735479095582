import { useRef, useState } from 'react'
import { Icon, Input } from '@travelpass/design-system'
import classNames from 'classnames'
import {
  guideDraftPublishImageAcceptedTypes,
  guideDraftPublishImageMaxSize,
} from '../guideDraftPublishConstants'
import type { UseGuideDraftPublishForm } from '../types'

/** @todo look into using a library for this (possibly https://github.com/sindresorhus/file-type) */
const isWebp = (bytes: Uint8Array): boolean =>
  bytes[0] === 0x52 &&
  bytes[1] === 0x49 &&
  bytes[2] === 0x46 &&
  bytes[3] === 0x46 &&
  bytes[8] === 0x57 &&
  bytes[9] === 0x45 &&
  bytes[10] === 0x42 &&
  bytes[11] === 0x50

interface GuideDraftPublishImagesUploadProps {
  getObjectUrl: (updatedImageFiles: FileList) => string
  invalidFormValues: UseGuideDraftPublishForm['invalidFormValues']
  onImagesSelectedChange: (updatedImageSelected: string) => void
  onInvalidFormValuesChange: UseGuideDraftPublishForm['onInvalidFormValuesChange']
}

export const GuideDraftPublishImagesUpload = ({
  getObjectUrl,
  invalidFormValues,
  onImagesSelectedChange,
  onInvalidFormValuesChange,
}: GuideDraftPublishImagesUploadProps) => {
  const ref = useRef<HTMLInputElement>(null)
  const [isDragging, setIsDragging] = useState(false)

  const onChange = (updatedImagesFiles: FileList) => {
    const imageFile = updatedImagesFiles?.[0]

    if (!imageFile) return

    const reader = new FileReader()
    reader.onloadend = function (event) {
      if (event?.target?.result) {
        // Necessary because a file could have differing extensions/mime types
        const arrayBuffer = event.target.result as ArrayBuffer
        const bytes = new Uint8Array(arrayBuffer).subarray(0, 12)
        const isSizeValid = imageFile.size <= guideDraftPublishImageMaxSize
        const isTypeValid =
          guideDraftPublishImageAcceptedTypes.indexOf(imageFile.type) !== -1 &&
          !isWebp(bytes)
        onInvalidFormValuesChange({
          ...invalidFormValues,
          imagesSize: !isSizeValid,
          imagesType: !isTypeValid,
        })

        if (isSizeValid && isTypeValid)
          onImagesSelectedChange(getObjectUrl(updatedImagesFiles))
      }
    }
    reader.readAsArrayBuffer(imageFile)
  }

  return (
    <div>
      <div className='hidden'>
        <Input
          accept={guideDraftPublishImageAcceptedTypes}
          ref={ref}
          type='file'
          onChange={events => onChange(events?.target?.files)}
        />
      </div>
      <button
        className={classNames(
          'b-2 b-solid transition-border-color-100 rounded-2 focus:b-canyon over:b-canyon lg:w-45.7 h-30 w-40 w-full cursor-pointer select-none bg-white p-2 text-center outline-none ease-linear lg:h-40',
          {
            'b-canyon': isDragging,
            'b-grey-200': !isDragging,
          }
        )}
        onClick={() => ref?.current?.click()}
        onDragEnter={event => {
          event.stopPropagation()
          event.preventDefault()
          setIsDragging(true)
        }}
        onDragLeave={() => {
          setIsDragging(false)
        }}
        onDragOver={event => {
          event.stopPropagation()
          event.preventDefault()
        }}
        onDrop={event => {
          event.stopPropagation()
          event.preventDefault()
          onChange(event?.dataTransfer?.files)
          setIsDragging(false)
        }}
      >
        <div className='pointer-events-none space-y-1'>
          <div className='c-canyon op-50'>
            <Icon name='upload' size='large' />
          </div>
          <p className='c-grey-700 type-body-1'>
            Choose from below or upload file
          </p>
          <p className='c-grey-500 type-body-2'>jpg, png (5mb max)</p>
        </div>
      </button>
    </div>
  )
}
