import { Skeleton } from '@travelpass/design-system'
import { PageLayoutContainer } from 'src/common/components'
import { DashboardAccountOldLoading } from './DashboardAccountOld'
import { DashboardAchievementsOldLoading } from './DashboardAchievementsOld'
import { DashboardBookingsLoading } from './DashboardBookings'
import { DashboardCollectionsLoading } from './DashboardCollections'
import { DashboardGuideDraftsLoading } from './DashboardGuideDrafts'
import { DashboardNavLoading } from './DashboardNav'
import { DashboardProfileLoading } from './DashboardProfile'
import { DashboardTripsLoading } from './DashboardTrips'
import { DashboardTab } from '../dashboardConstants'

interface DashboardLoadingProps {
  isDashboardV2ProfileEnabled: boolean
  isTripsHideEnabled: boolean
  tab: string
}

export const DashboardLoading = ({
  isDashboardV2ProfileEnabled,
  isTripsHideEnabled,
  tab,
}: DashboardLoadingProps) => (
  <PageLayoutContainer>
    <div className='lg:px-none flex flex-col py-6 lg:min-h-[calc(100vh-78px)] lg:flex-row lg:gap-12 lg:py-0'>
      <div className='bg-warm-grey min-w-89 w-89 p-x-6 p-y-12 hidden space-y-6 lg:block'>
        {isDashboardV2ProfileEnabled && <DashboardProfileLoading />}
        {!isDashboardV2ProfileEnabled && (
          <div className='shadow-3 rounded-3 bg-white p-3'>
            <DashboardAccountOldLoading />
          </div>
        )}
        <div className='shadow-3 rounded-3 bg-white p-3'>
          <DashboardAchievementsOldLoading />
        </div>
      </div>
      <div className='lg:p-y-12 grow space-y-3 lg:space-y-9'>
        <header className='lg:space-y-12'>
          <div className='hidden lg:flex lg:flex-row lg:items-center lg:gap-1'>
            <div className='h-10 w-20'>
              <Skeleton />
            </div>
          </div>
          <DashboardNavLoading />
        </header>
        {tab === DashboardTab.overview && (
          <div className='block space-y-6 lg:hidden'>
            {isDashboardV2ProfileEnabled && <DashboardProfileLoading />}
            {!isDashboardV2ProfileEnabled && <DashboardAccountOldLoading />}
            <div className='shadow-3 rounded-3 bg-white p-3'>
              <DashboardAchievementsOldLoading />
            </div>
          </div>
        )}
        {tab === DashboardTab.guides && <DashboardGuideDraftsLoading />}
        {tab === DashboardTab.trips && !isTripsHideEnabled && (
          <DashboardTripsLoading />
        )}
        {tab === DashboardTab.bookings && <DashboardBookingsLoading />}
        {tab === DashboardTab.collections && <DashboardCollectionsLoading />}
      </div>
    </div>
  </PageLayoutContainer>
)
