import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const createGuideDraftMutation = gql(`
  mutation CreateGuideDraftMutationInGuideDraft($createGuideDraftInput: CreateGuideDraftInput!, $userGuideDraftsArgs: UserGuideDraftsArgs!) {
    createGuideDraft(input: $createGuideDraftInput) {
      guideDraft {
        id
        addresses {
          id
          addressLine1
          city
          country
          state
          zipcode
        }
        description
        insertedAt
        images {
          id
          source
          url
        }
        imageUrl
        name
        owner {
          id
          userGuideDrafts(first: 1, userGuideDraftsArgs: $userGuideDraftsArgs) {
              totalCount
          }
        }
        ownerProfile {
          id
        }
      }
    }  
  }
`)

export const useCreateGuideDraftMutation = () =>
  useMutation(createGuideDraftMutation)
