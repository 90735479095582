import { Divider, Skeleton } from '@travelpass/design-system'

export const GuideDraftDrawerContentLoading = () => (
  <div className='relative flex h-[calc(100%-48px)] flex-col'>
    <div className='p-b-8 grow space-y-8 overflow-y-auto md:space-y-12'>
      <div className='h-55 p-x-8 w-full'>
        <Skeleton variant='rounded' />
      </div>
      <section className='p-x-8 space-y-8 md:space-y-10'>
        <header className='space-y-2'>
          <div className='space-y-1'>
            <div className='lg:h-4.5 h-4 w-40'>
              <Skeleton />
            </div>
            <div className='w-100 lg:h-4.5 h-4'>
              <Skeleton />
            </div>
          </div>
          <div className='w-25 lg:h-4.5 h-4'>
            <Skeleton />
          </div>
          <Divider className='m-t-1' />
        </header>
        <div className='h-32.5 lg:w-98.5 w-full'>
          <Skeleton />
        </div>
      </section>
    </div>
  </div>
)
