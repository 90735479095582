import { Icon } from '@travelpass/design-system'
import {
  auth,
  firebaseCheckConnectedProviders,
} from 'src/config/firebase/firebaseUtils'
import { PasswordContainer } from './PasswordContainer'

export const SignInMethods = () => {
  const { email } = auth?.currentUser
  const { isConnectedToEmail, isConnectedToGoogle } =
    firebaseCheckConnectedProviders()

  return (
    <section className='mb-8 flex flex-col gap-5 p-6'>
      <h5 className='text-h5 mb-2'>
        {isConnectedToEmail ? 'Login' : 'Sign-in method'}
      </h5>
      {isConnectedToEmail ? (
        <div className='flex flex-col gap-5'>
          <p className='type-body-1-desktop c-grey700'>{email}</p>
          <PasswordContainer />
        </div>
      ) : (
        <div className='gap-x-3 lg:flex'>
          <div className='mb-3 flex gap-x-3 lg:mb-0'>
            <Icon
              name={isConnectedToGoogle ? 'coloredGoogle' : 'coloredFacebook'}
              size='large'
            />
            <p className='type-body-1-desktop c-grey700 flex flex-col justify-center'>
              Signed in with {isConnectedToGoogle ? 'Google' : 'Facebook'}
            </p>
          </div>
          <p className='type-body-1-desktop c-black flex flex-col justify-center'>
            {email}
          </p>
        </div>
      )}
    </section>
  )
}
