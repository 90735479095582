import { Skeleton, SkeletonDots } from '@travelpass/design-system'

export const GuideDraftHeaderLoading = () => (
  <div className='space-y-3'>
    <div className='flex flex-row items-center justify-between'>
      <div className='h-5 w-20'>
        <Skeleton />
      </div>
      <div className='h-8 w-8 flex-col items-center'>
        <Skeleton variant='rounded' />
      </div>
    </div>
    <div className='h-4.5'>
      <Skeleton />
    </div>
  </div>
)
