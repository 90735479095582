import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { gql } from 'src/__generated__'

const HOTEL_POLICIES = gql(`
  query HotelPoliciesStandardHotel($hotelId: ID!) {
    standardHotel(id: $hotelId, idType: STANDARD) {
      id
      checkinInstructions
      checkinSpecialInstructions
      fees {
        type
        text
      }
      policies {
        title
        text
      }
    }
  }
`)

export const useHotelPoliciesStandardHotelQuery = () => {
  const { hotelId } = useParams()

  const {
    data,
    error: hasError,
    loading: isLoading,
  } = useQuery(HOTEL_POLICIES, {
    skip: !hotelId,
    variables: { hotelId: hotelId ?? '' },
  })

  const { standardHotel: hotelPoliciesData } = data ?? {}

  return {
    hasError,
    hotelPoliciesData,
    isLoading,
  }
}
