import { Icon } from '@travelpass/design-system'
import { Outlet, NavLink } from 'react-router-dom'
import { ButtonLink } from 'src/common/components'
import { useGetCurrentUserCompetitionInfo } from './hooks/useGetCurrentUserCompetitionInfo'

const NavlinkTab = props => {
  return (
    <NavLink
      {...props}
      className='text-body-1 c-grey-800 border-b-solid border-b-grey-200 [&.active]:border-b-valley inline-flex items-center gap-2 border-b-2 bg-transparent p-4 no-underline transition-all hover:bg-black/5 md:px-8 md:py-6 [&.active]:border-b-2'
      state={{ preventScroll: true }}
    />
  )
}

const PageContainer = props => {
  return (
    <div
      {...props}
      className={`max-w-340 mx-auto w-full px-8 ${props?.className || ''}`}
    >
      {props?.children}
    </div>
  )
}

export const CompetitionPage = () => {
  const { data } = useGetCurrentUserCompetitionInfo()
  const info = data?.currentUser?.userProfile?.competitionInfo
  const hasEnteredCompetition = info?.enteredCompetition === true

  return (
    <div className='bg-warm-grey'>
      <div className='mx-auto w-full bg-[url(https://static.travelpass.com/assets/job-campaign-2024/hero-bg.webp)] bg-cover py-8'>
        <PageContainer className='flex flex-col gap-8 md:flex-row-reverse md:items-center md:gap-24'>
          <button
            className='cursor-pointer border-none bg-transparent p-0'
            type='button'
            onClick={() => alert('waiting on video asset')}
          >
            <img
              alt='Click to view video'
              className='max-h-470px w-full object-cover'
              loading='eager'
              src='https://static.travelpass.com/assets/job-campaign-2024/creator-quest-thumb-1-2x.webp'
            />
          </button>
          <div className='space-y-8'>
            <h1 className='c-white type-h1'>
              Join the Travelpass Creator&nbsp;Quest
            </h1>
            <h2 className='c-white type-h2 hidden md:block'>
              Your Ticket to Becoming a Travel&nbsp;Influencer!
            </h2>
            <p className='c-white type-body-1 hidden md:block'>
              Showcase your storytelling skills and passion for travel. Win a
              dream job creating captivating travel&nbsp;content!
            </p>
            {!hasEnteredCompetition && (
              <ButtonLink to='/competition/apply' variant='valley'>
                Apply now
                <Icon name='arrowForward' />
              </ButtonLink>
            )}
            {hasEnteredCompetition && (
              <ButtonLink to='/dashboard?tab=guides' variant='valley'>
                See My Progress
              </ButtonLink>
            )}
          </div>
        </PageContainer>
      </div>
      <PageContainer>
        <nav aria-label='Competition pages' className='mt-17 text-center'>
          <NavlinkTab to='/competition/about'>About</NavlinkTab>
          <NavlinkTab to='/competition/leaderboard'>Leaderboard</NavlinkTab>
          <NavlinkTab to='/competition/faq'>FAQ</NavlinkTab>
        </nav>
      </PageContainer>
      <PageContainer className='py-18'>
        <Outlet />
      </PageContainer>
    </div>
  )
}
