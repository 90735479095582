import { useState } from 'react'
import {
  Button,
  Modal,
  ModalActions,
  ModalScrollContents,
} from '@travelpass/design-system'
import { DashboardAccountSettingsFormInfo } from './DashboardAccountSettingsFormInfo'
import { DashboardAccountSettingsFormNav } from './DashboardAccountSettingsFormNav'
import { DashboardAccountSettingsFormNotifications } from './DashboardAccountSettingsFormNotifications'
import { DashboardAccountSettingsFormSecurity } from './DashboardAccountSettingsFormSecurity'

interface DashboardAccountSettingsFormProps {
  onDismiss: () => void
}

export const DashboardAccountSettingsForm = ({
  onDismiss,
}: DashboardAccountSettingsFormProps) => {
  const [activeIndex, setActiveIndex] = useState(0)

  const onActiveIndexChange = (updatedActiveIndex: number) =>
    setActiveIndex(updatedActiveIndex)

  /** @todo */
  const onSubmit = () => {
    onDismiss()
  }

  return (
    <Modal title='Account Settings' onDismiss={onDismiss}>
      <ModalScrollContents>
        <div className='p-x-2 md:p-x-0 space-y-9'>
          <DashboardAccountSettingsFormNav
            activeIndex={activeIndex}
            onActiveIndexChange={onActiveIndexChange}
          />
          {activeIndex === 0 && <DashboardAccountSettingsFormInfo />}
          {activeIndex === 1 && <DashboardAccountSettingsFormSecurity />}
          {activeIndex === 2 && <DashboardAccountSettingsFormNotifications />}
        </div>
      </ModalScrollContents>
      <ModalActions>
        <Button autoFocus={true} onClick={onSubmit}>
          Save Changes
        </Button>
        <Button variant='outlined' onClick={onDismiss}>
          Cancel
        </Button>
      </ModalActions>
    </Modal>
  )
}
