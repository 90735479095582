import { useEffect, useState } from 'react'
import { YouVotedModal } from './YouVotedModal'
import type { ToggleVoteModalProps } from './dispatchToggleVoteModal'
import {
  dispatchToggleVoteModal,
  TOGGLE_VOTE_MODAL,
} from './dispatchToggleVoteModal'

const VoteModalListener = () => {
  const [userProfile, setUserProfile] = useState<ToggleVoteModalProps | null>(
    null
  )
  const isVoteModalOpen =
    !!userProfile?.accountHandle &&
    !!userProfile?.displayName &&
    !!userProfile?.userId

  useEffect(() => {
    const listen = (event: CustomEvent) => {
      setUserProfile(event?.detail)
    }
    window?.addEventListener(TOGGLE_VOTE_MODAL, listen)
    return () => {
      window?.removeEventListener(TOGGLE_VOTE_MODAL, listen)
    }
  }, [])

  if (!isVoteModalOpen) return <></>

  return (
    <YouVotedModal
      userProfile={userProfile}
      onClose={() =>
        dispatchToggleVoteModal({
          accountHandle: null,
          displayName: null,
          isFollowed: null,
          profileImageUrl: null,
          userId: null,
        })
      }
    />
  )
}

export { VoteModalListener }
