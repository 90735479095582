import { useEffect, useState } from 'react'
import { Accordion } from '@reach/accordion'
import { useNavigate } from 'react-router-dom'
import { DestinationFaqCard } from './DestinationFaqCard'
import type { DestinationType } from '../../destinationConstants'

interface DestinationFaqDetailsProps {
  destination: DestinationType
}

export const DestinationFaqDetails = ({
  destination,
}: DestinationFaqDetailsProps) => {
  return (
    <div className='lg:max-w-97% m-auto w-full'>
      <div className='flex flex-col sm:gap-8 md:gap-16'>
        <Accordion
          collapsible
          multiple
          className='border-rounded-8 shadow-1 p-y-3 overflow-hidden'
        >
          <DestinationFaqCard destination={destination} />
        </Accordion>
      </div>
    </div>
  )
}
