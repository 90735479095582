import { type GetCurrentUserQueryInDashboardOldQuery } from 'src/__generated__/graphql'
import { profilePath } from 'src/constants'
import { getDateFromUTC, getGuideDetailsUrl } from 'src/utils'
import { type DashboardFeedItem } from './types'
import { getProfilePageUrl } from '../profile/components/profileUtils'
import { DEFAULT_PROFILE_IMAGE_URL } from '../profile/profileConstants'

const getDashboardFeedItem = (
  feedItem: GetCurrentUserQueryInDashboardOldQuery['currentUser']['activityFeed'][0]
): DashboardFeedItem => {
  const { follower, guide, likedListGuide, hotelRecommendation, title } =
    feedItem ?? {}

  if (follower?.id) {
    const {
      accountHandle,
      followCount,
      id: productId,
      profileImageUrl,
    } = follower ?? {}
    const { countOfFollowed, countOfFollowing } = followCount ?? {}

    return {
      countOfFollowed: countOfFollowed ?? 0,
      countOfFollowing: countOfFollowing ?? 0,
      description: '',
      productId,
      profileImageUrl: profileImageUrl ?? DEFAULT_PROFILE_IMAGE_URL,
      title,
      url: `${window.location.origin}${profilePath}/${accountHandle}`,
    }
  }

  if (guide?.id) {
    const {
      description,
      guideType,
      id,
      insertedAt,
      name,
      ownerProfile,
      timeZone,
    } = guide ?? {}
    const { displayName, profileImageUrl } = ownerProfile ?? {}
    const date = getDateFromUTC(insertedAt, timeZone).format('DD MMMM YYYY')
    const url = `${window.location.origin}${getGuideDetailsUrl({
      id,
      name,
    })}`

    return {
      date,
      description,
      productId: id,
      profileImageUrl: profileImageUrl ?? DEFAULT_PROFILE_IMAGE_URL,
      title,
      url,
    }
  }

  if (hotelRecommendation?.id) {
    const {
      hotelInfo,
      notes,
      standardHotelId: productId,
      userProfile,
    } = hotelRecommendation ?? {}
    const { city, description, name, state } = hotelInfo ?? {}
    const { displayName, profileImageUrl, accountHandle } = userProfile ?? {}
    const location = city && state ? `${city}, ${state}` : ''
    const url = `${getProfilePageUrl(accountHandle)}?tab=topHotels`

    return {
      description: notes ?? description,
      location,
      productId: productId?.toString(),
      profileImageUrl: profileImageUrl ?? DEFAULT_PROFILE_IMAGE_URL,
      title,
      url,
    }
  }

  const {
    addresses,
    description,
    id: productId,
    name,
  } = likedListGuide?.guide ?? {}
  const { displayName, profileImageUrl } = likedListGuide?.likerProfile ?? {}
  const { city, state } = addresses?.[0] ?? {}
  const location = city && state ? `${city}, ${state}` : ''
  const url = `${window.location.origin}${getGuideDetailsUrl({
    id: productId,
    name,
  })}`

  return {
    description,
    location,
    productId,
    profileImageUrl: profileImageUrl ?? DEFAULT_PROFILE_IMAGE_URL,
    title,
    url,
  }
}

type GetDashboardHeaderGreeting = {
  firstName: string
  isMobile: boolean
  isProfileComplete: boolean
}

const getDashboardHeaderGreeting = ({
  firstName,
  isMobile,
  isProfileComplete,
}: GetDashboardHeaderGreeting) => {
  const firstNameWithSpace = firstName ? ` ${firstName}` : ''

  if (isMobile)
    return `Hi${firstNameWithSpace}${
      isProfileComplete ? '!' : ", you're almost there!"
    }`

  return `Hello${firstNameWithSpace}`
}

export { getDashboardFeedItem, getDashboardHeaderGreeting }
