import { ExploreSearchBar } from './components/ExploreSearchBar'
import { Foods } from './components/Foods'
import { Outdoors } from './components/Outdoors'
import { Park } from './components/Park'
import { Tags } from './components/Tags'
import { Trending } from './components/Trending'

export const Explore = () => {
  return (
    <div className='space-y-10 md:space-y-20'>
      <div className='py-34 space-y-10 bg-[url(https://static.travelpass.com/assets/guides/guides-search-header.webp)] bg-cover bg-[position:50%_70%] bg-no-repeat md:py-44 lg:bg-[position:50%_36%]'>
        <h1 className='type-h1 text-center'>
          <span className='hidden lg:block'>
            Get Inspired by Travelers Like You
          </span>
          <span className='block lg:hidden'>Where are you headed next?</span>
        </h1>
        <ExploreSearchBar />
      </div>
      <Trending />
      <Foods />
      <Outdoors />
      <div className='bg-top-center md:space-y-30 space-y-16 bg-[url(https://static.travelpass.com/assets/explore/footer-bg.webp)] bg-cover bg-no-repeat py-12 text-center md:bg-[url(https://static.travelpass.com/assets/explore/footer-bg.webp)] md:py-20'>
        <Park />
        <Tags />
      </div>
    </div>
  )
}
