import { Carousel } from '@travelpass/design-system'
import type { ExperienceImage } from 'src/__generated__/graphql'
import { HeroImage } from 'src/pages/hotels/details/components/HotelHero/HotelHeroImages/HeroImage'
import type { HeroImagesProps } from './HeroImages'
import { HeroModal } from '../HeroModal'

interface HeroImagesMobileProps extends HeroImagesProps {
  galleryIndex: number
  hasMultipleImages: boolean
  hasTwoImages: boolean
  mobileImages: ExperienceImage['size674x446']
  openGallery: (index: number) => void
}

export const HeroImagesMobile = ({
  galleryIndex,
  hasMultipleImages,
  hasTwoImages,
  images,
  imagesSrcSet,
  mobileImages,
  title,
  openGallery,
}: HeroImagesMobileProps) => {
  const twoImagesIndexModifier = hasTwoImages ? 0 : 1

  return (
    <>
      {galleryIndex >= 0 && (
        <HeroModal
          experienceImages={images}
          experienceName={title}
          initialSlideIndex={galleryIndex}
          onCloseModal={() => openGallery(-1)}
        />
      )}
      <div
        className='flex w-full items-center justify-center'
        data-testid='hero-image-gallery'
      >
        <div className='max-w-100vw grid w-full grid-cols-1 gap-2'>
          <div className='h-auto overflow-hidden'>
            {hasMultipleImages ? (
              <Carousel hideDots isLight>
                {mobileImages?.map((image, index) => {
                  return (
                    <HeroImage
                      key={`mobile-carousel-${index}`}
                      alt={`${title} photo ${index + 1}`}
                      src={image}
                      srcSet={imagesSrcSet[index]}
                      onClick={() => openGallery(index)}
                    />
                  )
                })}
              </Carousel>
            ) : (
              <HeroImage
                alt={`${title} photo 1`}
                src={mobileImages[0]}
                srcSet={imagesSrcSet[0]}
                onClick={() => null}
              />
            )}
          </div>
          {hasMultipleImages && (
            <div className='flex h-[70px] flex-row justify-center gap-2'>
              {mobileImages
                ?.slice(hasTwoImages ? 0 : 1, 5)
                .map((image, index) => {
                  return (
                    <div key={index} className='aspect-[16/9] overflow-hidden'>
                      <HeroImage
                        alt={`${title} photo ${index + twoImagesIndexModifier + 1}`}
                        src={image}
                        srcSet={imagesSrcSet[index + twoImagesIndexModifier]}
                        onClick={() =>
                          openGallery(index + twoImagesIndexModifier)
                        }
                      />
                    </div>
                  )
                })}
            </div>
          )}
        </div>
      </div>
    </>
  )
}
