import type { ApolloError } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import type { GetGuideDraftsForUserQueryInDashboardQuery } from 'src/__generated__/graphql'
import { useGetCurrentUserId } from 'src/common/hooks'

export const getGuideDraftsForUserQuery = gql(`
  query GetGuideDraftsForUserQueryInDashboard($userId: ID!) {
    getGuideDraftsForUser(userId: $userId) {
      id
      addresses {
        id
        addressLine1
        city
        country
        googlePlaceId
        lat
        long
        state
      }
      description
      guide {
        id
        collectedCount
        isCollected
        name
        numberOfLikes
        shareCount
        status
        viewCount
      }
      images {
        id
        source
        url
      }
      imageUrl
      name
      owner {
        id
      }
      ownerProfile {
        id
        accountHandle
        displayName
        isUserOwner
        profileImageUrl
        profileImageSource
        userId
      }
      status
      tags {
        id
        name
      }
      timeZone
      updatedAt
    }
  }
`)

type UseGetDashboardGuideDraftsQuery = {
  hasError: ApolloError
  guideDraftsData: GetGuideDraftsForUserQueryInDashboardQuery['getGuideDraftsForUser']
  isLoading: boolean
}

export const useGetDashboardGuideDraftsQuery =
  (): UseGetDashboardGuideDraftsQuery => {
    const { data: currentUserData } = useGetCurrentUserId()
    const userId = currentUserData?.currentUser?.id ?? ''
    const {
      data,
      error: hasError,
      loading: isLoading,
    } = useQuery(getGuideDraftsForUserQuery, {
      /** @todo remove errorPolicy when BE errors are fixed. */
      errorPolicy: 'ignore',
      skip: !userId,
      variables: {
        userId,
      },
    })
    const guideDraftsData = data?.getGuideDraftsForUser ?? []

    return {
      guideDraftsData,
      hasError,
      isLoading,
    }
  }
