import { Skeleton } from '@travelpass/design-system'

export const DashboardNavLoading = () => (
  <div className='h-9.5 md:h-13.5 flex flex-row items-center gap-5'>
    <div className='w-19 h-4'>
      <Skeleton />
    </div>
    <div className='h-4 w-16'>
      <Skeleton />
    </div>
    <div className='w-23 h-4'>
      <Skeleton />
    </div>
  </div>
)
