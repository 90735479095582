import './homeHeroCustomStyles.css'
import { useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { EventType } from 'src/__generated__/graphql'
import { useFlag } from 'src/common/hooks'
import { MAX_MOBILE_WIDTH, MIN_TABLET_WIDTH } from 'src/component-library/theme'
import { HomeHeroBenefits } from './HomeHeroBenefits'
import { HomeHeroContents } from './HomeHeroContents'
import { HomeContainer } from '../HomeContainer'

const heroCloudsImage =
  'https://static.travelpass.com/assets/homev2-page/hero-clouds.webp'
const heroCloudsMobileImage =
  'https://static.travelpass.com/assets/homev2-page/hero-clouds-mobile.webp'
const heroImage = 'https://static.travelpass.com/assets/homev2-page/hero.webp'
const heroMobileImage =
  'https://static.travelpass.com/assets/homev2-page/hero-mobile.webp'
const sizes = `(max-width: ${MAX_MOBILE_WIDTH}px) ${MAX_MOBILE_WIDTH}px, ${MIN_TABLET_WIDTH}px`

const ChipLink = props => (
  <NavLink
    {...props}
    className='text-link border-1 border-grey-400 [&.active]:border-forest-light [&.active]:bg-forest [&.active]:c-white hover:bg-forest-dark hover:c-white rounded-full border-solid bg-white px-3 py-2 no-underline transition ease-in-out'
  />
)

const ROUTE_TO_EVENT_TYPE = {
  experiences: EventType.Experience,
  hotels: EventType.Stay,
}

export const HomeHero = () => {
  const params = useParams()
  const [showHeroImage, setShowHeroImage] = useState(true)
  const [showHeroCloudsImage, setShowHeroCloudsImage] = useState(true)
  const productType: string = ROUTE_TO_EVENT_TYPE[params?.type] || 'ALL'

  const enablePlanPage = useFlag('enablePlanPage')
  const pathSegment = enablePlanPage ? 'book' : 'search'

  return (
    <section className='bg-forest of-hidden pb-42 md:pb-90 relative pt-10 md:pt-14'>
      {showHeroImage && (
        <img
          alt=''
          className='h-150 md:h-190 sm:mt--25 absolute left-0 top-0 w-full object-cover lg:mt-0'
          data-testid='hero-image'
          sizes={sizes}
          src={heroImage}
          srcSet={`${heroMobileImage} ${MAX_MOBILE_WIDTH}w, ${heroImage} ${MIN_TABLET_WIDTH}w`}
          onError={() => setShowHeroImage(false)}
        />
      )}
      {showHeroCloudsImage && (
        <img
          alt=''
          className='lg:scale-100% absolute left-0 top--16 w-full object-cover lg:top-[calc(-100vw/4.5)] lg:animate-[heroCloud_2.5s_ease-in-out]'
          data-testid='hero-image-animation'
          sizes={sizes}
          src={heroCloudsImage}
          srcSet={`${heroCloudsMobileImage} ${MAX_MOBILE_WIDTH}w, ${heroCloudsImage} ${MIN_TABLET_WIDTH}w`}
          onError={() => setShowHeroCloudsImage(false)}
        />
      )}
      <div className='from-forest top-110 md:top-123 absolute left-0 h-[calc(100%-440px)] w-full bg-gradient-to-t from-80% to-transparent md:h-[calc(100%-492px)] lg:h-[calc(100%+492px)]' />
      <HomeContainer size='md'>
        <div className='gap-71 md:gap-41 relative flex flex-col'>
          <header className='flex flex-col'>
            <h1 className='color-forest font-archivo font-not-oblique font-700 tracking-5% m-0 flex flex-col text-center'>
              <span className='leading-34px mb--17px text-21px lg:leading-19 lg:mb--38px lg:text-12'>
                Where&apos;s Your Next
              </span>
              <span className='leading-22 text-16 lg:leading-53 lg:text-39'>
                Adventure?
              </span>
            </h1>
          </header>
          <div className='md:gap-19 flex flex-col gap-14'>
            <div className='flex flex-col gap-6'>
              <div className='flex flex-col gap-4'>
                <div className='of-x-scroll flex gap-4 [scrollbar-width:none]'>
                  <ChipLink end to={`/${pathSegment}`}>
                    All
                  </ChipLink>
                  <ChipLink to={`/${pathSegment}/hotels`}>Hotels</ChipLink>
                  <ChipLink to={`/${pathSegment}/experiences`}>
                    Experiences
                  </ChipLink>
                </div>
              </div>
              <HomeHeroContents type={productType} />
            </div>
            <HomeHeroBenefits />
          </div>
        </div>
      </HomeContainer>
    </section>
  )
}
