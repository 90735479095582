import { useEffect, useState } from 'react'
import { Button, Illustration } from '@travelpass/design-system'
import Confetti from 'react-confetti'
import { TravelpassLogo } from 'src/common/components'
import { useWindowSize } from 'src/common/hooks/useWindowSize'

const wrapperStyles = {
  modal: 'flex flex-col w-full h-full',
  fullPage:
    'fixed inset-0 z-10 bg-white flex flex-col items-center overflow-y-scroll',
}

export const QuizSuccess = ({ onClose, isAModal }) => {
  const { width, height } = useWindowSize()
  const [runTheConfetti, setRunTheConfetti] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setRunTheConfetti(false)
    }, 3000)

    return () => clearTimeout(timer)
  }, [])

  return (
    <div className={isAModal ? wrapperStyles.modal : wrapperStyles.fullPage}>
      <Confetti
        height={height}
        numberOfPieces={500}
        run={runTheConfetti}
        width={width}
      />
      <div
        className='mt-15 md:h-75 z-10 flex w-full flex-col items-center'
        style={{
          background:
            'linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)',
        }}
      ></div>
      <div className='inset-10 z-10 flex h-full w-full flex-col items-center gap-y-5'>
        <div className='flex items-center justify-between p-4'>
          <TravelpassLogo />
        </div>
        <div className='type-h3 c-forest-500'>Congratulations!</div>
        <div className='type-h3 c-forest-500 text-center md:w-5/12'>
          We’re so excited to help you find hotels and experiences that fit your
          unique travel style.
        </div>
        <div className='md:min-w-105 md:max-w-105 md:max-h-105 w-100%'>
          <Illustration name='jeepNoPics' />
        </div>
        <div className='my-5'>
          <Button
            label='Done'
            size='large'
            variant='filled'
            onClick={onClose}
          />
        </div>
      </div>
    </div>
  )
}
