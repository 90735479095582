import { Skeleton } from '@travelpass/design-system'
import { GuideSectionLoading } from './GuideSectionLoading'
import { GuideEventCardLoading } from '../GuideEventCard'

export const GuideSectionsLoading = () => (
  <div className='space-y-6' data-testid='GuideSectionsLoading'>
    <div className='flex flex-col items-end md:hidden'>
      <div className='h-8 w-8'>
        <Skeleton variant='circular' />
      </div>
    </div>
    <GuideSectionLoading>
      <GuideEventCardLoading />
      <GuideEventCardLoading />
    </GuideSectionLoading>
    <GuideSectionLoading>
      <GuideEventCardLoading />
      <GuideEventCardLoading />
      <GuideEventCardLoading />
    </GuideSectionLoading>
  </div>
)
