import type { ChangeEvent, FocusEvent, FormEvent } from 'react'
import { useRef, useState } from 'react'
import { useMutation } from '@apollo/client'
import {
  Button,
  Dropdown,
  DropdownOption,
  ModalNext,
  TextArea,
  useSnackbar,
} from '@travelpass/design-system'
import { useParams } from 'react-router-dom'
import { gql } from 'src/__generated__'
import { UserProfileFlagReason } from 'src/__generated__/graphql'
import { useMutationReportUser } from './useMutationReportUser'
import { useGetProfile } from '../../components/hooks/useGetProfile'

const reportOptions = Object.values(UserProfileFlagReason)

const ReportOptionsDictionary = {
  [UserProfileFlagReason.Harassment]: 'Harassment',
  [UserProfileFlagReason.InappropriateContent]: 'Inappropriate Content',
  [UserProfileFlagReason.OffensiveLanguage]: 'Offensive Language',
  [UserProfileFlagReason.Other]: 'Other',
  [UserProfileFlagReason.Spam]: 'Spam',
}

export const ReportProfileModal = ({ onClose }: { onClose?: () => void }) => {
  const { accountHandle = '' } = useParams()
  const { profile } = useGetProfile(accountHandle)
  const { addSuccessSnack, addErrorSnack } = useSnackbar()

  const submitRef = useRef<HTMLInputElement>(null)

  const [textAreaLength, setTextAreaLength] = useState(0)
  const [selectedReason, setSelectedReason] = useState<string>('')
  const [textAreaError, setTextAreaError] = useState<string>('')
  const [dropdownError, setDropdownError] = useState<string>('')

  const [reportMutation] = useMutationReportUser()

  const onClickSubmit = () => submitRef?.current?.click()

  const checkDropdown = reason =>
    reason
      ? setDropdownError('')
      : setDropdownError('Reason for reporting must be selected. ')

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const formData = new FormData(e.currentTarget)
    const reason = formData.get('reason')?.toString()
    const details = formData.get('details')?.toString()

    checkDropdown(reason)

    if (!reason || (reason === UserProfileFlagReason.Other && !details)) return

    await reportMutation({
      variables: {
        input: {
          reason: reason as UserProfileFlagReason,
          message: details,
          userProfileId: profile?.id,
        },
      },
    })
      .then(() => addSuccessSnack({ title: 'Report sent!' }))
      .catch(e => {
        addErrorSnack({
          title: e?.message || 'Unable to send the report, try again later.',
        })
      })

    onClose()
  }

  const handleDropdownChange = e => {
    setSelectedReason(e)
    setTextAreaError('')
    checkDropdown(checkDropdown)
  }

  const checkTextArea = (
    e: FocusEvent<HTMLTextAreaElement> | ChangeEvent<HTMLTextAreaElement>
  ) => {
    if (
      !e.currentTarget.value.length &&
      selectedReason === UserProfileFlagReason.Other
    ) {
      setTextAreaError('You must provide details to the report')
    } else {
      setTextAreaError('')
    }
  }

  const handleTextAreaBlur = (e: FocusEvent<HTMLTextAreaElement>) => {
    checkTextArea(e)
  }

  const handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setTextAreaLength(e.currentTarget.value.length)
    checkTextArea(e)
  }

  return (
    <ModalNext
      actions={
        <div className='flex w-full flex-row justify-center gap-2'>
          <Button variant='outlined' onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={onClickSubmit}>Submit</Button>
        </div>
      }
      className='isolate md:px-20 [&_h2]:text-center'
      size='medium'
      title='Report User'
      onClose={onClose}
    >
      <form className='space-y-6 md:px-20' onSubmit={onSubmit}>
        <Dropdown
          required
          defaultValue='' // needed to prevent the first option to be the default value
          errorText={dropdownError}
          label='What is your reason for reporting?'
          name='reason'
          placeholder='Select reason'
          onChange={handleDropdownChange}
        >
          {reportOptions.map(option => (
            <DropdownOption key={option} value={option}>
              {ReportOptionsDictionary[option]}
            </DropdownOption>
          ))}
        </Dropdown>
        <TextArea
          autogrow={false}
          className='h-44'
          errorText={textAreaError}
          helperText={`${textAreaLength}/1000`}
          label='Give more details'
          maxLength={1000}
          name='details'
          placeholder='Please provide specific details about why you are reporting this user.'
          required={selectedReason === UserProfileFlagReason.Other}
          onBlur={handleTextAreaBlur}
          onChange={handleTextAreaChange}
        ></TextArea>
        <input className='hidden' ref={submitRef} type='submit' />
      </form>
    </ModalNext>
  )
}
