import { useEffect, useState } from 'react'
import type { DragEndEvent, DragStartEvent } from '@dnd-kit/core'
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  restrictToFirstScrollableAncestor,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers'
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { useScreenQuery } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import { useParams } from 'react-router-dom'
import { useFlag } from 'src/common/hooks'
import {
  onGuideSessionStorageHoverIdChange,
  useGuideSessionStorageIds,
} from 'src/pages/guides/details'
import type { GuideOwner } from 'src/pages/guides/details/types'
import { encodeGuideId } from 'src/utils'
import { GuideDraftEvent } from './GuideDraftEvent'
import { GuideDraftEventCard } from './GuideDraftEventCard'
import type { GuideDraftDataOld } from '../../types'
import type { UseGetGuideDraftQuery } from '../../useGetGuideDraftQuery'
import { useUpdateGuideDraftMutation } from '../../useUpdateGuideDraftMutation'

interface GuideDraftEventsProps {
  groupedEvent: GuideDraftDataOld['groupedEvents'][0]
  groupedEvents: GuideDraftDataOld['groupedEvents']
  onMapMarkerCenterChange: UseGetGuideDraftQuery['onMapMarkerCenterChange']
  owner: GuideOwner
  publishedEventCategories?: GuideDraftDataOld['publishedEventCategories']
  selectedId: string
}

export const GuideDraftEvents = ({
  groupedEvent,
  groupedEvents,
  onMapMarkerCenterChange,
  owner,
  publishedEventCategories,
  selectedId,
}: GuideDraftEventsProps) => {
  const isGuideUrlShortEnabled = useFlag('guideURLShort')
  const { hoverId } = useGuideSessionStorageIds()
  const [activeEvent, setActiveEvent] =
    useState<GuideDraftDataOld['groupedEvents'][0]['events'][0]>()
  const [sortedEvents, setSortedEvents] = useState<
    GuideDraftDataOld['groupedEvents'][0]['events']
  >(groupedEvent?.events ?? [])
  const { guideDraftId } = useParams()
  const { isMobileOrTablet } = useScreenQuery()
  const [updateGuideDraft] = useUpdateGuideDraftMutation()
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 15,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 300,
        tolerance: 10,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  useEffect(() => {
    setSortedEvents(groupedEvent?.events ?? [])
  }, [groupedEvent?.events])

  const onDragEnd = async ({ active, over }: DragEndEvent) => {
    if (over && active?.id !== over?.id) {
      try {
        const previousIndex = sortedEvents.findIndex(
          ({ id }) => id === active?.id
        )
        const nextIndex = sortedEvents.findIndex(({ id }) => id === over?.id)
        const updatedSortedEvents = arrayMove(
          sortedEvents,
          previousIndex,
          nextIndex
        )
        const updatedGroupedEvents = groupedEvents?.reduce(
          (total, current) => {
            total.push({
              ...current,
              events:
                current.id === groupedEvent?.id
                  ? updatedSortedEvents
                  : current?.events,
            })

            return total
          },
          [] as GuideDraftDataOld['groupedEvents']
        )
        const updatedGroupedEventIds = updatedGroupedEvents
          ?.flatMap(groupedEvent => groupedEvent?.events)
          ?.map(({ id }) => id)
        setSortedEvents(updatedSortedEvents)
        await updateGuideDraft({
          variables: {
            input: {
              guideDraftId: isGuideUrlShortEnabled
                ? encodeGuideId({
                    guideId: guideDraftId,
                    isGuideDraft: true,
                  })
                : guideDraftId,
              publishedEventSortOrder: updatedGroupedEventIds,
            },
          },
        })
      } catch (error) {
        console.error(error)
      }
    }
  }

  const onMouseEnter = (updatedHoverId: string) => {
    if (isMobileOrTablet) return

    onGuideSessionStorageHoverIdChange(updatedHoverId)
  }

  const onMouseLeave = () => {
    if (isMobileOrTablet) return

    onGuideSessionStorageHoverIdChange('')
  }

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
      sensors={sensors}
      onDragCancel={() => setActiveEvent(null)}
      onDragEnd={onDragEnd}
      onDragStart={(event: DragStartEvent) => {
        const activeEventData = sortedEvents?.find(
          ({ id }) => id === event?.active?.id
        )
        setActiveEvent(activeEventData)
      }}
    >
      <SortableContext
        items={sortedEvents}
        strategy={verticalListSortingStrategy}
      >
        <div className='space-y-4'>
          {sortedEvents?.map((event, index) => (
            <GuideDraftEvent key={index} eventId={event?.id}>
              <GuideDraftEventCard
                categoryId={groupedEvent?.id}
                event={event}
                isHovered={hoverId === event?.id}
                isSelected={selectedId === event?.id}
                owner={owner}
                publishedEventCategories={publishedEventCategories}
                onMapMarkerCenterChange={onMapMarkerCenterChange}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
              />
            </GuideDraftEvent>
          ))}
        </div>
      </SortableContext>
      <DragOverlay>
        {!isEmpty(activeEvent) && (
          <GuideDraftEvent eventId={activeEvent?.id}>
            <GuideDraftEventCard
              categoryId={groupedEvent?.id}
              event={activeEvent}
              isHovered={false}
              isSelected={false}
              owner={owner}
              publishedEventCategories={publishedEventCategories}
              onMapMarkerCenterChange={onMapMarkerCenterChange}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            />
          </GuideDraftEvent>
        )}
      </DragOverlay>
    </DndContext>
  )
}
