import { useState } from 'react'
import { Button, IconButton } from '@travelpass/design-system'
import type { Collection } from 'src/__generated__/graphql'
import { CollectionFormModal } from 'src/common/components/CollectionFormModal'
import { DeleteCollectionModal } from 'src/common/components/DeleteCollectionModal'
import { CollectionCard } from './CollectionCard'
import { DashboardCollectionsLoading } from './DashboardCollectionsLoading'
import { useGetCollectionsForDashboard } from './useGetCollectionsForDashboard'

export const DashboardCollections = () => {
  const [selectedCollection, setSelectedCollection] = useState<Collection>()
  const {
    collections,
    loading,
    error,
    refetch,
    hasMoreResults,
    fetchMore,
    data,
  } = useGetCollectionsForDashboard()
  const [showFormModal, setShowFormModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const ariaLabel = 'Create a collection'

  const pageInfo = data?.currentUser?.collections?.pageInfo
  const cursor = pageInfo?.hasNextPage && pageInfo?.endCursor

  const closeFormModal = () => setShowFormModal(false)

  const closeDeleteModal = () => setShowDeleteModal(false)

  return (
    <>
      <section className='space-y-6 lg:space-y-12'>
        <div className='flex items-center justify-between gap-y-5'>
          <h2 className='c-black type-h3-mobile type-h4-desktop'>
            My Collections
          </h2>
          <div className='hidden md:block'>
            <Button
              aria-label={ariaLabel}
              startIcon='add'
              onClick={() => {
                selectedCollection && setSelectedCollection(null)
                setShowFormModal(true)
              }}
            >
              New Collection
            </Button>
          </div>
          <div className='md:hidden'>
            <IconButton
              aria-label={ariaLabel}
              color='forestLight'
              icon='add'
              outline='round'
              size='small'
              onClick={() => setShowFormModal(true)}
            />
          </div>
        </div>
        {loading || collections?.length === 0 ? (
          <DashboardCollectionsLoading />
        ) : error ? (
          <p className='type-h6-desktop mt-10'>
            Error while loading Collections...
          </p>
        ) : (
          <>
            <div className='grid grid-cols-1 content-center gap-5 px-5 pt-3 md:grid-cols-2 md:px-0'>
              {collections?.map((collection, i) => (
                <CollectionCard
                  key={i}
                  collection={collection?.node}
                  openDeleteModal={collection => {
                    setSelectedCollection(collection)
                    setShowDeleteModal(true)
                  }}
                  openFormModal={collection => {
                    setSelectedCollection(collection)
                    setShowFormModal(true)
                  }}
                />
              ))}
            </div>
            {hasMoreResults && (
              <div className='flex flex-col items-center'>
                <Button
                  label='View More'
                  variant='outlined'
                  onClick={async () => {
                    await fetchMore({
                      variables: {
                        after: cursor,
                      },
                    })
                  }}
                />
              </div>
            )}
          </>
        )}
      </section>
      {showFormModal && (
        <CollectionFormModal
          collection={selectedCollection}
          onClose={closeFormModal}
          onConfirm={shouldRefetch => {
            closeFormModal()
            shouldRefetch && refetch()
          }}
          onDelete={collection => {
            setSelectedCollection(collection)
            closeFormModal()
            setShowDeleteModal(true)
          }}
        />
      )}
      {showDeleteModal && (
        <DeleteCollectionModal
          collection={selectedCollection}
          onClose={closeDeleteModal}
          onConfirm={shouldRefetch => {
            closeDeleteModal()
            shouldRefetch && refetch()
          }}
        />
      )}
    </>
  )
}
