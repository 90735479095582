import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const CREATE_VOTE_MUTATION = gql(`
  mutation createVoteMutation($input: CreateVoteInput!) {
    createVote(input: $input) {
      vote {
        id
        user {
          id
        }
      }
      userProfileCompetition {
          id
          canVoteLeaderboard
          voteCount
        }
    }
  }`)

export const useCreateVoteMutation = () => useMutation(CREATE_VOTE_MUTATION)
