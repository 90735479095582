import { Link } from '@travelpass/design-system'
import type { CurrentUserProfileQuery } from 'src/__generated__/graphql'
import { AddButton } from './AddButton'
import { useCheckEditingMode } from '../components/hooks/useCheckEditingMode'
import { getSocialPlatformIcon } from '../components/profileUtils'

interface SocialMediaLinksProps {
  openEditingMode?: () => void
  socialLinks: CurrentUserProfileQuery['currentUser']['userProfile']['socialLinks']
  isUserOwner?: boolean
}

export const SocialLinks = ({
  socialLinks,
  openEditingMode,
  isUserOwner,
}: SocialMediaLinksProps) => {
  const { isEditingMode } = useCheckEditingMode()
  const noSocialLinks = socialLinks?.length == 0 || !socialLinks

  if (noSocialLinks && (isEditingMode || !isUserOwner)) return null

  return (
    <div
      className={`flex flex-col items-center self-center ${isEditingMode ? 'gap-y-6' : 'gap-y-2'}`}
    >
      <span className='type-h4 pb-4 sm:hidden md:block'>Social Media</span>
      <span className='flex flex-wrap items-center justify-center'>
        {noSocialLinks && isUserOwner && (
          <AddButton
            text='Ready to inspire? Add your social profiles now.'
            onClick={openEditingMode}
          />
        )}
        {socialLinks?.map(({ network, identifier }, i) => {
          const platformIcon = getSocialPlatformIcon(network)
          return (
            <div key={i} className='px-2'>
              <Link
                aria-label={`${network}-link`}
                href={identifier}
                label=''
                rel='noopener noreferrer'
                startIcon={platformIcon}
                target='_blank'
              />
            </div>
          )
        })}
      </span>
    </div>
  )
}
