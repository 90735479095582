import { useEffect, useRef } from 'react'
import Cookies from 'js-cookie'
import isEmpty from 'lodash.isempty'
import { FormProvider } from 'react-hook-form'
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { Helmet, PageLayoutContainer } from 'src/common/components'
import { useFraudProtection } from 'src/common/hooks/useFraudProtection'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import {
  baseUrl,
  bookPath,
  bookSegment,
  hotelsSegment,
  tripTimelinePath,
} from 'src/constants'
import { TripSearchParams, DrawerType } from 'src/pages/trips/constants'
import { BookingError, BookingLayout, ConfirmationLoading } from './components'
import {
  useBookingForm,
  useBookingHotelDetailsQuery,
  useBookingRateQuery,
  useCreateHotelBookingMutation,
} from './hooks'
import {
  generateCreateHotelBookingVariables,
  type CreateHotelBookingInputData,
} from './utils'

export const Booking = () => {
  const gtmEventRef = useRef({
    add_guest_details: false,
    add_payment_info: false,
  })
  const { rateToken } = useParams()
  const [searchParams] = useSearchParams()
  const hotelId = searchParams.get('hotelId')
  const navigate = useNavigate()
  const methods = useBookingForm()
  const braintreePayment = methods.watch('braintreePayment')
  const dirtyFieldsDep = JSON.stringify(methods.formState.dirtyFields)

  useEffect(() => {
    if (!gtmEventRef.current.add_guest_details) {
      const detailsAdded =
        methods.formState.isDirty &&
        ['firstName', 'lastName', 'email', 'phone'].every(
          key => methods.formState.dirtyFields[key]
        )

      if (detailsAdded) {
        pushDataToDataLayer('add_guest_details')
        gtmEventRef.current.add_guest_details = true
      }
    }
  }, [methods.formState.isDirty, dirtyFieldsDep])

  const bookingHotelDetails = useBookingHotelDetailsQuery({ hotelId })
  const bookingRateQuery = useBookingRateQuery({ rateToken })
  const [createHotelBooking, hotelBookingMutation] =
    useCreateHotelBookingMutation()

  const { webbedsDevicePayload } = useFraudProtection()
  const hotel = bookingHotelDetails.data?.standardHotel

  const selectedRate = bookingRateQuery.data?.validatedRate?.room?.rates?.[0]
  const price = selectedRate?.price
  const grandTotal = price?.grandtotal?.amount
  const nightlyAverage =
    price?.nightly?.reduce((acc, night) => {
      return acc + parseFloat(night.amount.amount)
    }, 0) / price?.nightly?.length

  useEffect(() => {
    if (bookingRateQuery.data) {
      const { available, provider, room } = bookingRateQuery.data.validatedRate
      pushDataToDataLayer('hotelBookingCheckout', {
        hotel,
        provider,
        room: { available, ...room },
      })
    }
  }, [bookingRateQuery.data, hotel])

  useEffect(() => {
    if (braintreePayment) {
      // once payment is authorized, create hotel booking
      const values = methods.getValues()
      handleCreateHotelBooking(values)
    }
  }, [braintreePayment])

  useEffect(() => {
    if (!gtmEventRef.current.add_payment_info) {
      const paymentInfoAdded =
        methods.formState.isDirty &&
        [
          'number',
          'expiration',
          'cvc',
          'cardHolder',
          'address1',
          'city',
          'postalCode',
          'stateProvince',
        ].every(key => methods.formState.dirtyFields[key])

      if (paymentInfoAdded) {
        pushDataToDataLayer('add_payment_info', {
          currency: selectedRate?.price?.dueNow?.currency,
          item_category: 'Hotel',
          item_id: hotel?.id,
          item_name: hotel?.name,
          item_variant: bookingRateQuery?.data?.validatedRate?.room?.name,
          price: nightlyAverage,
          value: grandTotal,
        })
        gtmEventRef.current.add_payment_info = true
      }
    }
  }, [
    methods.formState.isDirty,
    dirtyFieldsDep,
    hotel,
    selectedRate,
    bookingRateQuery.data,
  ])

  useEffect(() => {
    const refererURL = Cookies.get('refererUrl')

    if (!refererURL) {
      Cookies.set('refererUrl', window.location.href, { expires: 1 })
    }
  }, [])

  const hasErrors = [
    bookingHotelDetails.error,
    hotelBookingMutation.error,
    bookingRateQuery.error,
  ].some(Boolean)

  const hasBookingFailedError =
    !isEmpty(bookingRateQuery.data?.validatedRate?.available) &&
    !bookingRateQuery.data?.validatedRate?.available

  if (hasErrors) return <BookingError failed />
  if (hasBookingFailedError) return <BookingError failed={false} />
  if (hotelBookingMutation.loading) return <ConfirmationLoading />

  const handleCreateHotelBooking = async (
    data: CreateHotelBookingInputData
  ) => {
    try {
      const { frontEndRequirements, room } =
        bookingRateQuery.data?.validatedRate ?? {}
      const { rateToken: updatedRateToken } = room?.rates?.[0] ?? {}
      const tripIdSearchParam = searchParams.get('tripId')

      const result = await createHotelBooking({
        variables: generateCreateHotelBookingVariables({
          data,
          eventId: searchParams.get(TripSearchParams.eventId),
          rateToken: updatedRateToken ?? rateToken,
          frontEndRequirements,
          tripId: tripIdSearchParam,
          webbedsDevicePayload,
        }),
      })

      const { booking, trip } = result.data?.createHotelBooking ?? {}
      const { id, email, externalConfirmationId } = booking ?? {}
      const { id: hotelId } = bookingHotelDetails.data?.standardHotel ?? {}
      const { events, id: tripId } = trip ?? {}

      pushDataToDataLayer('hotelRoomPurchased', {
        hotel: bookingHotelDetails.data.standardHotel,
        room: bookingRateQuery.data?.validatedRate.room,
        booking,
      })

      const { id: eventId } = events.find(({ booking }) =>
        booking.some(
          item => item?.externalConfirmationId === externalConfirmationId
        )
      )

      if (eventId && tripIdSearchParam) {
        navigate({
          pathname: `${tripTimelinePath}/${trip?.id}`,
          search: `?${createSearchParams({
            eventId,
            eventType: DrawerType.InternalHotelBooked,
            showBookingConfirmationModal: 'true',
          })}`,
        })
      } else {
        navigate({
          pathname: `/${hotelsSegment}/${bookSegment}/confirmation/${externalConfirmationId}/${id}`,
          search: `?${createSearchParams({
            email,
            hotelId,
            tripId,
          })}`,
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <PageLayoutContainer>
      <Helmet
        canonicalUrl={`${baseUrl}${bookPath}/${rateToken}`}
        pageName='Booking'
      />
      <FormProvider {...methods}>
        <BookingLayout
          bookingHotelData={bookingHotelDetails.data}
          bookingHotelLoading={bookingHotelDetails.loading}
          bookingRateData={bookingRateQuery.data}
          bookingRateLoading={bookingRateQuery.loading}
          createHotelBooking={methods.handleSubmit(handleCreateHotelBooking)}
        />
      </FormProvider>
    </PageLayoutContainer>
  )
}
