import { useEffect } from 'react'
import { today } from '@travelpass/design-system'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { PageLayoutContainer } from 'src/common/components'
import { useFlag } from 'src/common/hooks'
import { useFirebaseUser } from 'src/common/hooks/useFirebaseUser'
import {
  DashboardAccountOld,
  DashboardAchievementsOld,
  DashboardBookings,
  DashboardCollections,
  DashboardFeed,
  DashboardGuideDrafts,
  DashboardLoading,
  DashboardNav,
  DashboardProfile,
  DashboardTrips,
} from './common'
import { DashboardSearchParam, DashboardTab } from './dashboardConstants'
import { useGetDashboardQuery } from './useGetDashboardQuery'

export const DashboardOld = () => {
  const { isAnonymous } = useFirebaseUser()
  const isDashboardV2ProfileEnabled = useFlag('dashboardV2Profile')
  const isTripsHideEnabled = useFlag('tripsHide')
  const { activityFeed, dashboardData, isLoading } = useGetDashboardQuery()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { firstName, userProfile } = dashboardData?.currentUser ?? {}
  const { guideCount } = userProfile ?? {}
  const tab = searchParams.get(DashboardSearchParam.tab) ?? 'guides'
  const greeting = firstName ? `Hello, ${firstName}` : 'Hello'

  useEffect(() => {
    if (isAnonymous)
      navigate('/', {
        replace: true,
      })
  }, [isAnonymous])

  if (isLoading)
    return (
      <DashboardLoading
        isDashboardV2ProfileEnabled={isDashboardV2ProfileEnabled}
        isTripsHideEnabled={isTripsHideEnabled}
        tab={tab}
      />
    )

  return (
    <PageLayoutContainer>
      <section className='lg:px-none flex flex-col py-6 lg:min-h-[calc(100vh-78px)] lg:flex-row lg:gap-6 lg:py-0'>
        <aside className='bg-warm-grey min-w-89 w-89 p-x-6 p-y-12 hidden space-y-6 lg:block'>
          {isDashboardV2ProfileEnabled && (
            <DashboardProfile dashboardData={dashboardData} />
          )}
          {!isDashboardV2ProfileEnabled && (
            <div className='shadow-3 rounded-3 bg-white p-3'>
              <DashboardAccountOld userProfile={userProfile} />
            </div>
          )}
          <DashboardAchievementsOld numberOfCreatedGuides={guideCount} />
          <div className='shadow-3 rounded-3 bg-white p-3'>
            <DashboardFeed activityFeed={activityFeed} />
          </div>
        </aside>
        <section className='lg:p-y-12 min-w-0 grow space-y-6 lg:space-y-9'>
          <header className='lg:space-y-12'>
            <div className='hidden lg:flex lg:flex-row lg:items-center lg:gap-1'>
              <h1 className='type-h2-desktop c-black grow'>{greeting}</h1>
              <time
                className='type-h5-desktop c-black'
                dateTime={today.toString()}
                itemProp='date'
              >
                {today.format('MMM DD, YYYY')}
              </time>
            </div>
            <DashboardNav />
          </header>
          {tab === DashboardTab.overview && (
            <div className='block space-y-6 lg:hidden'>
              {isDashboardV2ProfileEnabled && (
                <DashboardProfile dashboardData={dashboardData} />
              )}
              {!isDashboardV2ProfileEnabled && (
                <div className='shadow-3 rounded-3 bg-white p-3'>
                  <DashboardAccountOld userProfile={userProfile} />
                </div>
              )}
              <DashboardAchievementsOld numberOfCreatedGuides={guideCount} />
              <div className='shadow-3 rounded-3 bg-white p-3'>
                <DashboardFeed activityFeed={activityFeed} />
              </div>
            </div>
          )}
          {tab === DashboardTab.guides && <DashboardGuideDrafts />}
          {tab === DashboardTab.trips && !isTripsHideEnabled && (
            <DashboardTrips />
          )}
          {tab === DashboardTab.bookings && <DashboardBookings />}
          {tab === DashboardTab.collections && <DashboardCollections />}
        </section>
      </section>
    </PageLayoutContainer>
  )
}
