import { Button, useSnackbar } from '@travelpass/design-system'
import type { UserProfile } from 'src/__generated__/graphql'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { copyToClipboard } from 'src/utils'
import { FollowButton } from '../components/FollowButton/FollowButton'
import type { ProfileFields } from '../types'

interface ProfileActionButtonsProps {
  isUserOwner: boolean
  onEdit?: () => void
  profile: ProfileFields
}

export const ProfileActionButtons = ({
  isUserOwner,
  onEdit,
  profile,
}: ProfileActionButtonsProps) => {
  const { addSuccessSnack } = useSnackbar()
  const { isFollowed } = profile ?? {}

  const onShare = async () => {
    const url = document.location.href
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Traveler Profile',
          text: 'Come explore with me!',
          url,
        })
        pushDataToDataLayer('profile_shared', { url })
      } catch (error) {
        console.error('Error sharing content', error)
      }
    } else {
      copyToClipboard(url)
      addSuccessSnack({ title: 'Successfully copied to the clipboard' })
      pushDataToDataLayer('profile_shared', { url })
    }
  }

  return (
    <div className='flex flex-row justify-center gap-2'>
      {isUserOwner && onEdit ? (
        <Button
          label='Edit'
          size='small'
          startIcon='modeEdit'
          type='button'
          variant='outlined'
          onClick={onEdit}
        />
      ) : (
        <FollowButton
          displayName={profile?.displayName}
          isFollowed={isFollowed}
          label={isFollowed ? 'unfollow' : 'follow'}
          userId={profile?.userId}
          variant={isFollowed ? 'outlined' : 'filled'}
        />
      )}
      {onShare && (
        <Button
          label='Share'
          size='small'
          startIcon='iosShare'
          type='button'
          variant='outlined'
          onClick={onShare}
        />
      )}
    </div>
  )
}
