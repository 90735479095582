import { useEffect, useState } from 'react'
import { GuideDraftPublishImagesSearch } from './GuideDraftPublishImagesSearch'
import { GuideDraftPublishImagesSelected } from './GuideDraftPublishImagesSelected'
import {
  GuideDraftPublishGroup,
  GuideDraftPublishGroupHeader,
} from '../GuideDraftPublishGroup'
import { getGuideDraftPublishImagesErrorMessage } from '../guideDraftPublishUtils'
import type {
  GuideDraftPublishFormValues,
  GuideDraftPublishInvalidFormValues,
  UseGuideDraftPublishForm,
} from '../types'

interface GuideDraftPublishImagesProps {
  formValues: GuideDraftPublishFormValues
  invalidFormValues: GuideDraftPublishInvalidFormValues
  onChange: UseGuideDraftPublishForm['onFormValuesChange']
  onInvalidFormValuesChange: UseGuideDraftPublishForm['onInvalidFormValuesChange']
}

export const GuideDraftPublishImages = ({
  formValues,
  invalidFormValues,
  onChange,
  onInvalidFormValuesChange,
}: GuideDraftPublishImagesProps) => {
  const [objectUrls, setObjectUrls] = useState<string[]>([])
  const { images } = formValues ?? {}
  const imagesSelected = formValues?.images ?? []
  const errorMessage = getGuideDraftPublishImagesErrorMessage(invalidFormValues)

  useEffect(() => {
    return () => {
      objectUrls.forEach(url => URL.revokeObjectURL(url))
    }
  }, [])

  const getObjectUrl = (updatedImagesFiles: FileList): string => {
    const objectUrl = URL.createObjectURL(updatedImagesFiles?.[0])
    setObjectUrls(previousObjectUrls => [...previousObjectUrls, objectUrl])

    return objectUrl
  }

  const onImagesSelectedChange = (image: string) => {
    let updatedImages = []

    if (images.includes(image)) {
      updatedImages = images.filter(updatedImage => updatedImage !== image)
    } else {
      updatedImages = [...images, image]
    }

    if (updatedImages.length > 3) return

    onChange({
      ...formValues,
      images: updatedImages,
    })
  }

  return (
    <div className='space-y-3'>
      <section className='md:p-x-6 md:p-y-4 md:rounded-3 space-y-3 md:space-y-6'>
        <GuideDraftPublishGroupHeader
          subtitle='Select up to three images from our photos or upload your own!'
          title='Add Banner Images to This Guide'
        />
        <div className='space-y-2'>
          <GuideDraftPublishImagesSelected
            getObjectUrl={getObjectUrl}
            imagesSelected={imagesSelected}
            invalidFormValues={invalidFormValues}
            onImagesSelectedChange={onImagesSelectedChange}
            onInvalidFormValuesChange={onInvalidFormValuesChange}
          />
          {!!errorMessage && (
            <p className='c-red font-2.5 type-subtext pl-3.5'>{errorMessage}</p>
          )}
        </div>
      </section>
      <GuideDraftPublishGroup>
        <GuideDraftPublishGroupHeader
          subtitle='Search for images of your favorite spots, delicious dishes, or must-see landmarks.'
          title='Images'
        />
        <GuideDraftPublishImagesSearch
          formValues={formValues}
          invalidFormValues={invalidFormValues}
          onImagesSelectedChange={onImagesSelectedChange}
        />
      </GuideDraftPublishGroup>
    </div>
  )
}
