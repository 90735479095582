import { useState } from 'react'
import { Button, IconButton } from '@travelpass/design-system'
import { useSearchParams } from 'react-router-dom'
import type { Bucket } from 'src/__generated__/graphql'
import { ResultsDot } from 'src/common/components'
import { SearchPreferencesQuiz } from 'src/pages/account-settings/components/search-preferences-quiz/SearchPreferencesQuiz'
import { HotelResultsFiltersBadge } from './HotelResultsFiltersBadge'
import { HotelResultsFiltersDrawer } from './HotelResultsFiltersDrawer'
import { getHotelResultsFiltersFromSearchParams } from '../../hotelResultsUtils'

interface HotelResultsFiltersProps {
  hotelResultsBrandData?: Bucket[]
}

export const HotelResultsFilters = ({
  hotelResultsBrandData = [],
}: HotelResultsFiltersProps) => {
  const [searchParams] = useSearchParams()
  const [isOpen, setIsOpen] = useState(false)
  const [showQuiz, setShowQuiz] = useState(false)
  const count = Object.keys(
    getHotelResultsFiltersFromSearchParams(searchParams)
  )?.length

  return (
    <>
      <HotelResultsFiltersDrawer
        handleShowQuiz={newVal => setShowQuiz(newVal)}
        hotelResultsBrandData={hotelResultsBrandData}
        isOpen={isOpen}
        showQuiz={showQuiz}
        onDismiss={() => setIsOpen(false)}
      />
      <div className='relative block lg:hidden'>
        {!!count && (
          <div className='top-0.25 absolute right-2'>
            <HotelResultsFiltersBadge count={count} variant='secondary' />
          </div>
        )}
        <IconButton
          data-testid='HotelResultsFilters-icon-button'
          icon='tune'
          size='large'
          onClick={() => setIsOpen(true)}
        />
      </div>
      <div className='relative hidden items-center gap-1 lg:flex'>
        <Button
          label='Filters'
          size='small'
          startIcon='tune'
          variant='outlined'
          onClick={() => setIsOpen(true)}
        />
        {!!count && (
          <div className='absolute right-4 top-0.5'>
            <ResultsDot label={count.toString()} />
          </div>
        )}
      </div>
      {showQuiz && (
        <SearchPreferencesQuiz useModal onClose={() => setShowQuiz(false)} />
      )}
    </>
  )
}
