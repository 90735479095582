import { DeleteAccount } from './DeleteAccount'
import { SignInMethods } from './SignInMethods'

export const SignInAndSecurity = () => {
  return (
    <>
      <SignInMethods />
      <DeleteAccount />
    </>
  )
}
