import { useEffect } from 'react'
import {
  Input,
  Button,
  useSnackbar,
  Autocomplete,
  KeyCode,
} from '@travelpass/design-system'
import type { SubmitHandler } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { getDetails, type Suggestion } from 'use-places-autocomplete'
import { GeocoderOption } from 'src/common/components/Geocoder/GeocoderOption'
import { useGeocoderSearch } from 'src/common/components/Geocoder/useGeocoderSearch'
import { useFirebaseUser } from 'src/common/hooks/useFirebaseUser'
import { SignInModal } from 'src/pages/signin/SignInModal'
import { rules } from 'src/utils/rules'
import { TermsAndConditionsModal } from './TermsAndConditionsModal'
import { useEnterCompetitionMutation } from './hooks/useEnterCompetitionMutation'
import { useGetCurrentUserCompetitionInfo } from './hooks/useGetCurrentUserCompetitionInfo'

type CompetitionApplyInputFields = {
  firstName: string
  lastName: string
  phoneNumber: string
  email: string
  addressLine1: string
  city: string
  state: string
  country: string
  zipcode: string
  acceptedTermsAndConditions: boolean
}

export const CompetitionApply = () => {
  const { addErrorSnack } = useSnackbar()
  const { isAnonymous } = useFirebaseUser()
  const { data } = useGetCurrentUserCompetitionInfo()
  const to = useNavigate()
  const [enterCompetition, { loading }] = useEnterCompetitionMutation()

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue: setFormValue,
  } = useForm<CompetitionApplyInputFields>()

  const { value, setValue, suggestions, clearSuggestions, ready } =
    useGeocoderSearch({
      requestOptions: {
        types: ['address'],
        componentRestrictions: {
          country: [],
        },
      },
    })

  const info = data?.currentUser?.userProfile?.competitionInfo
  const hasEnteredCompetition = info?.enteredCompetition === true
  const profileId = data?.currentUser?.userProfile?.id
  const options = suggestions?.data

  useEffect(() => {
    if (hasEnteredCompetition) {
      to('/dashboard?tab=guides')
    }
  }, [hasEnteredCompetition])

  const handleOptionSelection = async (selection: Suggestion) => {
    const result = await getDetails({
      placeId: selection.place_id,
      fields: ['address_components'],
    })

    if (typeof result === 'string') return
    const addressLine1 = selection.structured_formatting?.main_text || ''
    const addressComponents = result?.address_components

    setFormValue('addressLine1', addressLine1)
    setValue(addressLine1)

    addressComponents.forEach(({ types, short_name }) => {
      // https://developers.google.com/maps/documentation/javascript/geocoding#GeocodingAddressTypes
      if (types.includes('locality')) {
        setFormValue('city', short_name)
      }
      if (types.includes('administrative_area_level_1')) {
        setFormValue('state', short_name)
      }
      if (types.includes('postal_code')) {
        setFormValue('zipcode', short_name)
      }
      if (types.includes('country')) {
        setFormValue('country', short_name)
      }
    })

    clearSuggestions()
  }

  const onSubmit: SubmitHandler<CompetitionApplyInputFields> = async data => {
    try {
      const result = await enterCompetition({
        variables: {
          input: {
            userProfileId: profileId,
            ...data,
          },
        },
      })
      const success =
        result?.data?.enterCompetition?.userProfileCompetition
          ?.enteredCompetition

      if (success) {
        /** @todo: should there be a success message on the UI? */
        to('/dashboard?tab=guides')
      }
    } catch (e) {
      addErrorSnack({
        title: 'Oops, something went wrong! Try again later',
      })
    }
  }

  return (
    <>
      {isAnonymous && (
        <SignInModal
          initiallyShowCreateAccount={true}
          onClose={() => {
            to('/')
          }}
        />
      )}
      <section>
        <div className='bg-forest flex justify-center py-8'>
          <iframe
            allowFullScreen
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            className='max-w-325 of-hidden mx-4 block aspect-video w-full rounded-xl border-none md:mx-8'
            referrerPolicy='strict-origin-when-cross-origin'
            src={`https://www.youtube.com/embed/iUtnZpzkbG8?list=PLGoWuvyH709vpTCVrjaJtaaFfite9U6u8&autoplay=${isAnonymous ? 0 : 1}`}
            title='No Copyright Drone Shots | Royalty free drone shots | free stock videos | Drone footage nature shots'
          ></iframe>
        </div>
      </section>
      <section className='py-20'>
        <h1 className='type-h1 mb-8 text-center'>Personal Information</h1>
        <form
          className='max-w-650px mx-auto space-y-8 px-4'
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className='flex flex-col gap-4 md:flex-row'>
            <Input
              {...register('firstName', { required: rules.required })}
              fullWidth
              required
              errorText={errors?.['firstName']?.message}
              label='First name'
            />
            <Input
              {...register('lastName', {
                required: rules.required,
              })}
              fullWidth
              required
              errorText={errors?.['lastName']?.message}
              label='Last name'
            />
          </div>
          <div className='grid grid-cols-1 items-baseline gap-4 md:grid-cols-5'>
            <div className='md:grid-col-span-2'>
              <Input
                {...register('phoneNumber', {
                  required: rules.required,
                  pattern: rules.internationalPhone,
                })}
                fullWidth
                required
                errorText={errors?.['phoneNumber']?.message}
                label='Phone number'
              />
            </div>
            <div className='md:grid-col-span-3'>
              <Input
                {...register('email', {
                  required: rules.required,
                  pattern: rules.email,
                })}
                fullWidth
                required
                errorText={errors?.['email']?.message}
                label='Email'
              />
            </div>
          </div>
          <div className='space-y-2'>
            <Autocomplete
              {...register('addressLine1', { required: rules.required })}
              autoExpand
              fullWidth
              required
              autoComplete='off'
              errorText={errors?.['addressLine1']?.message}
              isDisabled={!ready}
              label='Street address'
              value={value}
              onChange={e => {
                setValue(e.target.value)
              }}
              onKeyDown={e => {
                // prevent enter from submitting form
                if (e.key === KeyCode.ENTER) e.preventDefault()
              }}
              onOptionSelect={handleOptionSelection}
            >
              {options.map(option => (
                <GeocoderOption key={option.place_id} option={option} />
              ))}
            </Autocomplete>
            <div className='flex flex-col gap-4 md:flex-row'>
              <Input
                {...register('city', { required: rules.required })}
                fullWidth
                required
                errorText={errors?.['city']?.message}
                label='City'
              />
              {/** @todo: replace with Dropdown of states, same as hotels */}
              <Input
                {...register('state', { required: rules.required })}
                fullWidth
                required
                errorText={errors?.['state']?.message}
                label='State'
              />
            </div>
            <div className='flex flex-col gap-4 md:flex-row'>
              <Input
                {...register('zipcode', { required: rules.required })}
                fullWidth
                required
                errorText={errors?.['zipcode']?.message}
                label='ZIP code'
              />
              <Input
                {...register('country', { required: rules.required })}
                fullWidth
                required
                errorText={errors?.['country']?.message}
                label='Country'
              />
            </div>
          </div>
          <div className='bg-warm-grey rounded-lg p-6'>
            <h3 className='type-body-1 font-bold'>
              TERMS AND CONDITIONS FOR “Travelpass Ultimate Job Share” CAMPAIGN
            </h3>
            <ol className='list-decimal'>
              <li>
                These terms and conditions of application, including information
                on how to participate and job details (Terms), apply to the
                Campaign and application into the Campaign is deemed acceptance
                of these Terms.
              </li>
              <li>
                Submission into the Campaign is also deemed as acceptance of the
                Travelpass&apos;s Website Terms and Conditions, which can be
                viewed at https://ultimatejob.travelpass.com.
              </li>
              <li>
                The Campaign is being organized and managed by Travelpass, which
                is a part of Travelpass Group, located at{' '}
                <address className='inline not-italic'>
                  4700 W Daybreak Pkwy Ste 100N, South Jordan, UT 84009
                </address>
                . Travelpass and the Company are used synonymously in these
                terms and conditions.
              </li>
            </ol>
            <TermsAndConditionsModal />
          </div>
          <div>
            {errors?.['acceptedTermsAndConditions']?.message && (
              <p className='type-body-1 c-error m-0 text-center'>
                You must agree to the Terms and Conditions.
              </p>
            )}
            <label className='type-body-1 inline-flex gap-2 p-2'>
              <input
                type='checkbox'
                {...register('acceptedTermsAndConditions', {
                  required: 'You must agree to the rules and regulations',
                })}
                required
              />
              <span>I have read and understand the rules and regulations.</span>
            </label>
          </div>
          <div className='flex justify-center'>
            <Button
              disabled={loading}
              endIcon='arrowForward'
              label='Next'
              type='submit'
            />
          </div>
        </form>
      </section>
    </>
  )
}
