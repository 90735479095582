import type { AvailableIcons } from '@travelpass/design-system'
import { Icon } from '@travelpass/design-system'

const TAGS: {
  label: string
  icon?: AvailableIcons
}[] = [
  {
    label: 'Adventure',
    icon: 'mapOutlined',
  },
  {
    label: 'Sports',
    icon: 'downhillSkiing',
  },
  {
    label: 'Outdoors',
    // icon: '',
  },
  {
    label: 'City',
    icon: 'business',
  },
  {
    label: 'Water',
    icon: 'pool',
  },
  {
    label: 'Beaches',
    icon: 'beachAccess',
  },
  {
    label: 'Mountain',
    // icon: '',
  },
  {
    label: 'Relaxing',
    icon: 'hottub',
  },
  {
    label: 'Family Friendly',
    icon: 'peopleOutline',
  },
  {
    label: 'Food',
    icon: 'restaurant',
  },
  {
    label: 'Art',
    // icon: '',
  },
  {
    label: 'Museums',
    // icon: '',
  },
  {
    label: 'Music',
    // icon: '',
  },
  {
    label: 'Nightlife',
    icon: 'star',
  },
  {
    label: 'Shopping',
    // icon: '',
  },
  {
    label: 'Scenic',
    icon: 'spa',
  },
  {
    label: 'Hidden Gems',
    // icon: '',
  },
]

export const Tags = () => (
  <section className='max-w-300 mx-auto space-y-8'>
    <header className='space-y-3 text-balance text-center text-white max-md:px-4 md:space-y-6'>
      <h2 className='type-h2 c-current capitalize'>Explore Travel Guides </h2>
      <p>
        Checkout some of our favorite guide options, created by users just like
        you!
      </p>
    </header>
    <div className='flex flex-wrap justify-center gap-2 max-md:px-4'>
      {TAGS.map(({ label, icon }) => (
        <a
          key={label}
          className='text-forestLight border-warmGray decoration-none flex appearance-none items-center justify-center gap-1.5 rounded-lg border-2 bg-white px-3 py-2 text-sm font-bold'
          href='#a'
        >
          <div className='grid place-items-center'>
            <Icon name={icon} size='small' />
          </div>
          {label}
        </a>
      ))}
    </div>
  </section>
)
