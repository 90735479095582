import { useEffect } from 'react'
import classNames from 'classnames'
import isEmpty from 'lodash.isempty'
import { useIncrementGuideViewCountMutation } from 'src/pages/guides'
import { GuideHeaderContent } from './GuideHeaderContent'
import type { GuideData, GuideOwner } from '../../types'
import { GuideImage } from '../GuideImage'

interface GuideHeaderProps {
  guideData: GuideData
  owner: GuideOwner
}

export const GuideHeader = ({ guideData, owner }: GuideHeaderProps) => {
  const guideId = guideData?.id
  const [incrementGuideViewCount] = useIncrementGuideViewCountMutation()
  const { images: initialImages, imageUrl } = guideData ?? {}
  const images = !isEmpty(initialImages) ? initialImages : [{ url: imageUrl }]

  useEffect(() => {
    incrementGuideViewCount({ variables: { guideId } })
  }, [])

  return (
    <header className='space-y-6'>
      <div className='h-37.5 lg:h-100 m-a lg:[&>*:first-child]:rounded-bl-0 lg:[&>*:first-child]:rounded-tl-0 lg:[&>*:last-child]:rounded-br-0 lg:[&>*:last-child]:rounded-tr-0 grid grid-cols-10 gap-2'>
        <div
          className={classNames(
            'of-hidden lg:rounded-3 relative flex flex-col justify-center',
            {
              'col-span-10': !images?.[1],
              'rounded-tr-3 rounded-br-3 col-span-5 lg:col-span-6': images?.[1],
            }
          )}
        >
          <GuideImage
            alt=''
            className='absolute inset-0'
            src={images?.[0]?.url}
          />
          <div className='of-hidden to-black/64 via-41% absolute inset-0 hidden bg-gradient-to-bl from-black/0 from-10% via-black/40 to-85% lg:block' />
          <div className='lg:max-w-250 hidden lg:block'>
            <GuideHeaderContent guideData={guideData} owner={owner} />
          </div>
        </div>
        {images?.[1] && (
          <div
            className={classNames(
              'of-hidden rounded-tl-3 rounded-bl-3 lg:rounded-3 relative col-span-5',
              {
                'lg:col-span-4': !images?.[2],
                'lg:col-span-2': images?.[2],
              }
            )}
          >
            <GuideImage
              alt=''
              className='absolute inset-0'
              src={images[1]?.url}
            />
          </div>
        )}
        {images?.[2] && (
          <div className='of-hidden rounded-3 relative hidden lg:col-span-2 lg:block'>
            <GuideImage
              alt=''
              className='absolute inset-0'
              src={images[2]?.url}
            />
          </div>
        )}
      </div>
      <div className='block w-full lg:hidden'>
        <GuideHeaderContent guideData={guideData} owner={owner} />
      </div>
    </header>
  )
}
