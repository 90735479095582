import { InfoBox } from '@react-google-maps/api'
import isEmpty from 'lodash.isempty'
import type { GetPlaceDetailsInTripsQuery } from 'src/__generated__/graphql'
import { GuideDraftMapPlaceMarkerPopup } from './GuideDraftMapPlaceMarkerPopup'

interface GuideDraftMapInfoWindowProps {
  infoWindowPosition: google.maps.LatLng
  isLoading: boolean
  onAddEvent: (placeDetailsData: GetPlaceDetailsInTripsQuery) => void
  onDismiss: () => void
  placeDetailsData: GetPlaceDetailsInTripsQuery
}

export const GuideDraftMapInfoWindow = ({
  infoWindowPosition,
  isLoading,
  onAddEvent,
  onDismiss,
  placeDetailsData,
}: GuideDraftMapInfoWindowProps) =>
  !isLoading &&
  !isEmpty(placeDetailsData) && (
    <>
      <InfoBox
        options={{
          closeBoxURL: '',
          disableAutoPan: true,
          enableEventPropagation: true,
          boxClass: 'm-l--37 md:m-l--64 w-75 md:w-136',
        }}
        position={infoWindowPosition}
        onCloseClick={onDismiss}
      >
        <div className='relative left--4 top--4 hidden p-4 md:block'>
          <GuideDraftMapPlaceMarkerPopup
            placeDetailsData={placeDetailsData}
            onAddEvent={() => onAddEvent(placeDetailsData)}
          />
        </div>
      </InfoBox>
      <div className='z-2 absolute bottom-0 left-0 right-0 md:hidden'>
        <GuideDraftMapPlaceMarkerPopup
          placeDetailsData={placeDetailsData}
          onAddEvent={() => onAddEvent(placeDetailsData)}
        />
      </div>
    </>
  )
