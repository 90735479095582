import { useLazyQuery } from '@apollo/client'
import { gql } from 'src/__generated__'

const SEARCH_USER_PROFILES_QUERY = gql(`
  query SearchUserProfilesQuery ($searchString: String!) {
    searchUserProfiles(searchString: $searchString) {
      id
      profileImageUrl
      profileImageSource
      accountHandle
      displayName
      isFollowed
      userId
      isUserOwner
    }
  }  
`)

export const useSearchProfiles = () => {
  const [searchProfiles, { data, loading, error }] = useLazyQuery(
    SEARCH_USER_PROFILES_QUERY
  )

  return {
    searchProfiles,
    data,
    loading,
    error,
  }
}
