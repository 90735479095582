import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const DELETE_HOTEL_RECOMMENDATION = gql(`
    mutation testDeleteHotelRecommendation($id: ID!) {
        deleteHotelRecommendation(id: $id) {
        deleted
        }
    }
`)

export const useDeleteHotelRecommendation = () =>
  useMutation(DELETE_HOTEL_RECOMMENDATION)
