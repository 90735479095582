import { Skeleton } from '@travelpass/design-system'
import { useFlag } from 'src/common/hooks'

export const GuideMetricsLoading = () => {
  const isGuideMetricsViewCountHideEnabled = useFlag(
    'guideMetricsViewCountHide'
  )
  return (
    <div className='relative flex flex-row items-center gap-5 overflow-hidden lg:gap-6'>
      <div className='p-l-1.5 flex flex-row items-center gap-1'>
        <div className='h-4 w-4'>
          <Skeleton variant='rounded' />
        </div>
        <div className='w-13 h-4'>
          <Skeleton />
        </div>
      </div>
      {!isGuideMetricsViewCountHideEnabled && (
        <div className='p-l-1.5 flex flex-row items-center gap-1'>
          <div className='h-4 w-4'>
            <Skeleton variant='rounded' />
          </div>
          <div className='h-4 w-20'>
            <Skeleton />
          </div>
        </div>
      )}
      <div className='p-l-1.5 flex flex-row items-center gap-1'>
        <div className='h-4 w-4'>
          <Skeleton variant='rounded' />
        </div>
        <div className='h-4 w-20'>
          <Skeleton />
        </div>
      </div>
      <div className='p-l-1.5 flex flex-row items-center gap-1'>
        <div className='h-4 w-4'>
          <Skeleton variant='rounded' />
        </div>
        <div className='w-19.5 h-4'>
          <Skeleton />
        </div>
      </div>
    </div>
  )
}
