enum DashboardSearchParam {
  tab = 'tab',
}

enum DashboardTab {
  bookings = 'bookings',
  collections = 'collections',
  guides = 'guides',
  overview = 'overview',
  trips = 'trips',
}

export { DashboardSearchParam, DashboardTab }
