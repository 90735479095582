import {
  Checkbox,
  SkeletonDots,
  Button,
  Divider,
} from '@travelpass/design-system'
import type {
  CommunicationPreference,
  UpdateCommunicationPreferencesInput,
} from 'src/__generated__/graphql'
import { Helmet } from 'src/common/components'
import { Card } from 'src/common/components/Card'
import { useGetUserNotificationPreferences } from './useGetUserNotificationPreferences'
import { useUpdateUserNotificationPreferences } from './useUpdateUserNotificationPreferences'
import { useCurrentProfile } from '../profile/components/hooks/useCurrentProfile'
import { useGetCurrentUserProfileAccountHandleAndAvatar } from '../profile/components/hooks/useGetCurrentUserProfileAccountHandleAndAvatar'

interface NotificationsFormProps {
  user: {
    id: string
  }
}

const ifAnyFieldsEnabled = (fields, obj) =>
  !fields.some(field => obj[field] === true)

const toInputObj = (userPrefValues: CommunicationPreference) => {
  const values: UpdateCommunicationPreferencesInput = {
    sendFeatureUpdatesEmail: false,
    sendInspirationEmail: false,
    sendPromotionEmail: false,
    sendSurveyRequestsEmail: false,
    sendFeatureUpdatesSms: false,
    sendInspirationSms: false,
    sendPromotionSms: false,
    sendSurveyRequestsSms: false,
  }

  if (userPrefValues) {
    Object.keys(values).forEach(key => {
      if (key in userPrefValues) {
        values[key] = userPrefValues[key]
      }
    })
  }

  return values
}

export const NotificationsForm = ({ user }: NotificationsFormProps) => {
  const { data, loading } = useGetUserNotificationPreferences(user.id)
  const [updateNotificationPreferences] = useUpdateUserNotificationPreferences()

  const { data: userData } = useGetCurrentUserProfileAccountHandleAndAvatar()
  const displayName = userData?.currentUser?.userProfile?.displayName

  const userPref = data?.getCommunicationPreferencesForUser
    ? toInputObj(data.getCommunicationPreferencesForUser)
    : {}

  if (loading) return <SkeletonDots />

  const onChange = async e => {
    const updatedPreference = {
      [e.target.name]: !userPref[e.target.name],
    }
    await updateNotificationPreferences({
      variables: {
        communicationPreferencesInput: updatedPreference,
      },
    })
  }

  const unsubscribeAllEmail = async () => {
    await updateNotificationPreferences({
      variables: {
        communicationPreferencesInput: {
          sendFeatureUpdatesEmail: false,
          sendInspirationEmail: false,
          sendPromotionEmail: false,
          sendSurveyRequestsEmail: false,
        },
      },
    })
  }
  const unsubscribeAllSms = async () => {
    await updateNotificationPreferences({
      variables: {
        communicationPreferencesInput: {
          sendFeatureUpdatesSms: false,
          sendInspirationSms: false,
          sendPromotionSms: false,
          sendSurveyRequestsSms: false,
        },
      },
    })
  }

  const enableUnsubAllEmail = ifAnyFieldsEnabled(
    [
      'sendFeatureUpdatesEmail',
      'sendInspirationEmail',
      'sendPromotionEmail',
      'sendSurveyRequestsEmail',
    ],
    userPref
  )

  const enableUnsubAllSms = ifAnyFieldsEnabled(
    [
      'sendFeatureUpdatesSms',
      'sendInspirationSms',
      'sendPromotionSms',
      'sendSurveyRequestsSms',
    ],
    userPref
  )

  return (
    <>
      <Helmet
        pageName={`Notification Preferences ${displayName ? '|' : ''} ${displayName || ''}`}
      />
      <Card>
        <h3 className='text-h6'>Email Preferences</h3>
        <section className='grid grid-cols-1 gap-x-0 gap-y-1 md:gap-x-4 md:gap-y-2'>
          <Checkbox
            isChecked={userPref.sendPromotionEmail}
            label='Deals & Promotions'
            name='sendPromotionEmail'
            onChange={onChange}
          />
          <Checkbox
            isChecked={userPref.sendInspirationEmail}
            label='Travel tips and inspiration'
            name='sendInspirationEmail'
            onChange={onChange}
          />
          <Checkbox
            isChecked={userPref.sendFeatureUpdatesEmail}
            label='Product updates and features'
            name='sendFeatureUpdatesEmail'
            onChange={onChange}
          />
          <Checkbox
            isChecked={userPref.sendSurveyRequestsEmail}
            label='Invitations to share a quick review or participate in a brief survey about your recent travel experience'
            name='sendSurveyRequestsEmail'
            onChange={onChange}
          />
        </section>
        <section className='space-y-4'>
          <Divider />
          <p className='text-body-2'>
            You will still receive service emails for your bookings, account
            updates, loyalty program if you are a member of the <br /> program
            and other important information. Please note that this may take up
            to 10 business days to be effective.
          </p>
          <Button
            isDisabled={enableUnsubAllEmail}
            label='Unsubscribe all emails'
            size='small'
            type='button'
            variant='outlined'
            onClick={unsubscribeAllEmail}
          />
        </section>
      </Card>
      <div className='my-8' id='notifications-divider'></div>
      <Card>
        <h3 className='text-h6'>SMS Preferences</h3>
        <section className='grid grid-cols-1 gap-x-0 gap-y-1 md:gap-x-4 md:gap-y-2'>
          <Checkbox
            isChecked={userPref.sendPromotionSms}
            label='Deals & Promotions'
            name='sendPromotionSms'
            onChange={onChange}
          />
          <Checkbox
            isChecked={userPref.sendInspirationSms}
            label='Travel tips and inspiration'
            name='sendInspirationSms'
            onChange={onChange}
          />
          <Checkbox
            isChecked={userPref.sendFeatureUpdatesSms}
            label='Product updates and features'
            name='sendFeatureUpdatesSms'
            onChange={onChange}
          />
          <Checkbox
            isChecked={userPref.sendSurveyRequestsSms}
            label='Invitations to share a quick review or participate in a brief survey about your recent travel experience'
            name='sendSurveyRequestsSms'
            onChange={onChange}
          />
        </section>
        <section className='space-y-4'>
          <Divider />
          <p className='text-body-2'>
            You will still receive notifications for bookings, account updates,
            and other important communication. Please note that this may take up
            to 10 business days to be effective.
          </p>
          <Button
            isDisabled={enableUnsubAllSms}
            label='Unsubscribe all SMS'
            size='small'
            variant='outlined'
            onClick={unsubscribeAllSms}
          />
        </section>
      </Card>
    </>
  )
}
