import { useState } from 'react'
import { Button, Illustration, Modal } from '@travelpass/design-system'
import { useNavigate } from 'react-router-dom'
import { SearchBooking } from 'src/common/components'
import { useFlag } from 'src/common/hooks'

export const DashboardBookingsEmpty = () => {
  const navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const onModalClose = () => setIsModalOpen(false)

  const onModalOpen = () => setIsModalOpen(true)

  const enablePlanPage = useFlag('enablePlanPage')
  const pathSegment = enablePlanPage ? 'book' : 'search'

  return (
    <>
      <section className='space-y-10'>
        <div className='flex flex-row items-center justify-between'>
          <h2 className='type-h3'>My Bookings</h2>
          <Button
            aria-label='Find a booking'
            startIcon='search'
            onClick={onModalOpen}
          >
            Find Booking
          </Button>
        </div>
        <div className='bg-warm-grey p-x-11 p-t-18.5 p-b-11 rounded-3 b-1 b-solid b-canyon-light'>
          <div className='flex flex-row items-center'>
            <div className='space-y-5'>
              <div className='m-a block w-64 md:hidden'>
                <Illustration name='suitcase' />
              </div>
              <h3 className='c-forest-light type-h3'>Make a Booking</h3>
              <p className='type-body-1'>
                Book your next hotel or travel experience with Travelpass to
                keep everything organized in one place.
              </p>
              <div className='m-a flex flex-row justify-center md:block'>
                <Button
                  aria-label='Find a booking'
                  startIcon='add'
                  variant='text'
                  onClick={() => navigate(`/${pathSegment}`)}
                >
                  Book Now
                </Button>
              </div>
            </div>
            <div className='hidden min-w-64 md:block'>
              <Illustration name='suitcase' />
            </div>
          </div>
        </div>
      </section>
      {isModalOpen && (
        <Modal size='medium' title='Find Booking' onDismiss={onModalClose}>
          <SearchBooking showTitle={false} />
        </Modal>
      )}
    </>
  )
}
