import type { ContextType } from 'react'
import { useRef, useState } from 'react'
import { Button, Divider, IconButton, Modal } from '@travelpass/design-system'
import type { VisibilityContext } from 'react-horizontal-scrolling-menu'
import { Scroller, ScrollerButton, SearchBooking } from 'src/common/components'
import { ExperienceBooking } from 'src/pages/bookings/MyBookings/ExperienceBookings/ExperienceBooking'
import { HotelBooking } from 'src/pages/bookings/MyBookings/HotelBookings/HotelBooking'
import { DashboardBookingsEmpty } from './DashboardBookingsEmpty'
import { DashboardBookingsLoading } from './DashboardBookingsLoading'
import { useGetDashboardBookingsQuery } from '../../useGetDashboardBookingsQuery'

type ScrollVisibilityApiType = ContextType<typeof VisibilityContext>

export const DashboardBookings = () => {
  const {
    experienceBookingsUpcomingData,
    experienceBookingsPastData,
    hasError,
    hotelBookingsPastData,
    hotelBookingsUpcomingData,
    isLoading,
  } = useGetDashboardBookingsQuery()
  const apiRef = useRef({} as ScrollVisibilityApiType)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const hasNoBookings =
    !experienceBookingsPastData?.length &&
    !experienceBookingsUpcomingData?.length &&
    !hotelBookingsPastData?.length &&
    !hotelBookingsUpcomingData?.length

  const onModalClose = () => setIsModalOpen(false)

  const onModalOpen = () => setIsModalOpen(true)

  if (isLoading) return <DashboardBookingsLoading />

  if (hasError || hasNoBookings) return <DashboardBookingsEmpty />

  return (
    <>
      <section className='space-y-6 lg:space-y-12'>
        <div className='flex flex-row items-center justify-between'>
          <h2 className='c-black type-h3-mobile type-h4-desktop'>
            My Bookings
          </h2>
          <div className='hidden md:block'>
            <Button
              aria-label='Find a booking'
              startIcon='search'
              onClick={onModalOpen}
            >
              Find Booking
            </Button>
          </div>
          <div className='md:hidden'>
            <IconButton
              color='forestLight'
              icon='add'
              outline='round'
              size='small'
              onClick={onModalOpen}
            />
          </div>
        </div>
        {/** @todo break up into smaller components */}
        <div className='space-y-9'>
          <div className='space-y-6'>
            <div className='space-y-3'>
              <p className='type-overline'>
                Upcoming Hotel Bookings
                {!!hotelBookingsUpcomingData?.length &&
                  ` (${hotelBookingsUpcomingData?.length})`}
              </p>
              <Divider />
            </div>
            {hotelBookingsUpcomingData?.length ? (
              <div className='w-full max-w-full'>
                <Scroller
                  LeftArrow={
                    <span className='block pr-5'>
                      <ScrollerButton icon='arrowBackIos' size='small' />
                    </span>
                  }
                  RightArrow={
                    <span className='block pr-5'>
                      <ScrollerButton
                        isNext
                        icon='arrowForwardIos'
                        size='small'
                      />
                    </span>
                  }
                  apiRef={apiRef}
                  scrollContainerClassName='gap-2 p-5 m-l--5 rounded-2'
                >
                  {hotelBookingsUpcomingData?.map((item, index) => (
                    <div key={index} className='ws-nowrap'>
                      <HotelBooking
                        booking={item}
                        className='min-w-55 w-55 md:min-w-66 md:w-66'
                      />
                    </div>
                  ))}
                </Scroller>
              </div>
            ) : (
              <p className='color-grey-700 p-l-2 type-body-2'>
                No upcoming hotel bookings
              </p>
            )}
          </div>
          <div className='space-y-6'>
            <div className='space-y-3'>
              <p className='type-overline'>
                Upcoming Experiences
                {!!experienceBookingsUpcomingData?.length &&
                  ` (${experienceBookingsUpcomingData?.length})`}
              </p>
              <Divider />
            </div>
            {experienceBookingsUpcomingData?.length ? (
              <div className='w-full max-w-full'>
                <Scroller
                  LeftArrow={
                    <span className='block pr-5'>
                      <ScrollerButton icon='arrowBackIos' size='small' />
                    </span>
                  }
                  RightArrow={
                    <span className='block pr-5'>
                      <ScrollerButton
                        isNext
                        icon='arrowForwardIos'
                        size='small'
                      />
                    </span>
                  }
                  apiRef={apiRef}
                  scrollContainerClassName='gap-2 p-5 m-l--5 rounded-2'
                >
                  {experienceBookingsUpcomingData?.map((item, index) => (
                    <div key={index} className='ws-nowrap'>
                      <ExperienceBooking
                        booking={item}
                        className='min-w-55 w-55 md:min-w-66 md:w-66'
                      />
                    </div>
                  ))}
                </Scroller>
              </div>
            ) : (
              <p className='color-grey-700 p-l-2 type-body-2'>
                No upcoming experience bookings
              </p>
            )}
          </div>
          <div className='space-y-6'>
            <div className='space-y-3'>
              <p className='type-overline'>
                Past Hotel Bookings
                {!!hotelBookingsPastData?.length &&
                  ` (${hotelBookingsPastData?.length})`}
              </p>
              <Divider />
            </div>
            {hotelBookingsPastData?.length ? (
              <div className='w-full max-w-full'>
                <Scroller
                  LeftArrow={
                    <span className='block pr-5'>
                      <ScrollerButton icon='arrowBackIos' size='small' />
                    </span>
                  }
                  RightArrow={
                    <span className='block pr-5'>
                      <ScrollerButton
                        isNext
                        icon='arrowForwardIos'
                        size='small'
                      />
                    </span>
                  }
                  apiRef={apiRef}
                  scrollContainerClassName='gap-2 p-5 m-l--5 rounded-2'
                >
                  {hotelBookingsPastData?.map((item, index) => (
                    <div key={index} className='ws-nowrap'>
                      <HotelBooking
                        booking={item}
                        className='min-w-55 w-55 md:min-w-66 md:w-66'
                      />
                    </div>
                  ))}
                </Scroller>
              </div>
            ) : (
              <p className='color-grey-700 p-l-2 type-body-2'>
                No past hotel bookings
              </p>
            )}
          </div>
          <div className='space-y-6'>
            <div className='space-y-3'>
              <p className='type-overline'>
                Past Experience Bookings
                {!!experienceBookingsPastData?.length &&
                  ` (${experienceBookingsPastData?.length})`}
              </p>
              <Divider />
            </div>
            {experienceBookingsPastData?.length ? (
              <div className='w-full max-w-full'>
                <Scroller
                  LeftArrow={
                    <span className='block pr-5'>
                      <ScrollerButton icon='arrowBackIos' size='small' />
                    </span>
                  }
                  RightArrow={
                    <span className='block pr-5'>
                      <ScrollerButton
                        isNext
                        icon='arrowForwardIos'
                        size='small'
                      />
                    </span>
                  }
                  apiRef={apiRef}
                  scrollContainerClassName='gap-2 p-5 m-l--5 rounded-2'
                >
                  {experienceBookingsPastData?.map((item, index) => (
                    <div key={index} className='ws-nowrap'>
                      <ExperienceBooking
                        booking={item}
                        className='min-w-55 w-55 md:min-w-66 md:w-66'
                      />
                    </div>
                  ))}
                </Scroller>
              </div>
            ) : (
              <p className='color-grey-700 p-l-2 type-body-2'>
                No past experience bookings
              </p>
            )}
          </div>
        </div>
      </section>
      {isModalOpen && (
        <Modal size='medium' title='Find Booking' onDismiss={onModalClose}>
          <SearchBooking showTitle={false} />
        </Modal>
      )}
    </>
  )
}
