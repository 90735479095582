import type { LatLng } from 'use-places-autocomplete'
import type { PointOfInterest } from 'src/__generated__/graphql'
import {
  POINTS_OF_INTEREST_TO_DISPLAY,
  HOTEL_PIN_ID,
} from 'src/pages/hotels/details/constants'

type EventPoint = {
  name: string
  id: string
} & LatLng

const constructedHotelMapPoints = (
  points: PointOfInterest[],
  lat: number,
  lng: number
) => {
  // TO-DO enable map pointers when we start geting the point.geocode from backend
  /* const randomizerGeocoder = geocoder => {
    const addOrRemove = Math.floor(Math.random() * 2)
    const newGeocoder = addOrRemove
      ? geocoder + (Math.random() * 3) / 1000
      : geocoder - (Math.random() * 3) / 1000

    return newGeocoder
  }
  // TO-DO enable map pointers when we start geting the point.geocode from backend
  const eventPoints: EventPoint[] = points.map((point, index) => ({
    name: point.name,
    id: `${point.name}${index}`,
    lat: parseFloat(point.geocode) || randomizerGeocoder(lat),
    lng: parseFloat(point.geocode) || randomizerGeocoder(lng),
  })) */

  // Add the Hotel marker
  const eventPoints: EventPoint[] = []
  eventPoints.push({
    id: HOTEL_PIN_ID,
    name: 'My Hotel',
    lat: lat,
    lng: lng,
  })

  return eventPoints
}

const filteredPointsOfInterest = (points: PointOfInterest[]) => {
  // TO-DO Filter the ponints per type
  // return only the first 5 points of interest
  return points?.slice(0, POINTS_OF_INTEREST_TO_DISPLAY)
}

export { constructedHotelMapPoints, filteredPointsOfInterest }
