import { useEffect } from 'react'
import type { UnderlineTabProps } from '@travelpass/design-system'
import {
  Divider,
  UnderlineTab,
  useScreenQuery,
} from '@travelpass/design-system'
import { ScrollMenu } from 'react-horizontal-scrolling-menu'
import { useSearchParams } from 'react-router-dom'
import { useFlag } from 'src/common/hooks'
import { DashboardSearchParam } from '../../../dashboard/dashboardConstants'
import { DashboardTab } from '../../dashboardConstants'
export const DashboardNav = () => {
  const isTripsHideEnabled = useFlag('tripsHide')
  const { isMobileOrTablet } = useScreenQuery()
  const [searchParams, setSearchParams] = useSearchParams()
  const tab = searchParams.get(DashboardSearchParam.tab)
  const tabs: UnderlineTabProps['tabs'] = [
    {
      icon: 'dashboard',
      label: 'Overview',
      onClick: () => setSearchParams({ tab: DashboardTab.overview }),
    },
    {
      icon: 'viewList',
      label: 'Guides',
      onClick: () => setSearchParams({ tab: DashboardTab.guides }),
    },
    !isTripsHideEnabled && {
      icon: 'luggage',
      label: 'Trips',
      onClick: () => setSearchParams({ tab: DashboardTab.trips }),
    },
    {
      icon: 'starBorder',
      label: 'Bookings',
      onClick: () => setSearchParams({ tab: DashboardTab.bookings }),
    },
    {
      icon: 'bookmarkBorder',
      label: 'Collections',
      onClick: () => setSearchParams({ tab: DashboardTab.collections }),
    },
  ]

  useEffect(() => {
    if ((!isMobileOrTablet && tab === DashboardTab.overview) || !tab)
      setSearchParams({ tab: DashboardTab.guides })
  }, [isMobileOrTablet, tab])

  const getActiveIndex = () => {
    if (isTripsHideEnabled) {
      if (tab === DashboardTab.overview) return 0

      if (tab === DashboardTab.guides) return isMobileOrTablet ? 1 : 0

      if (tab === DashboardTab.collections) return isMobileOrTablet ? 3 : 2

      return isMobileOrTablet ? 2 : 1
    }

    if (tab === DashboardTab.overview) return 0

    if (tab === DashboardTab.guides) return isMobileOrTablet ? 1 : 0

    if (tab === DashboardTab.trips) return isMobileOrTablet ? 2 : 1

    if (tab === DashboardTab.collections) return isMobileOrTablet ? 4 : 3

    return isMobileOrTablet ? 3 : 2
  }

  return (
    <div className='m-x--2 lg:m-x-0'>
      <ScrollMenu scrollContainerClassName='hide-scrollbar'>
        <UnderlineTab
          activeIndex={getActiveIndex()}
          fullWidth={true}
          tabs={tabs.filter(
            (tab, index) =>
              (isMobileOrTablet || (!isMobileOrTablet && index !== 0)) && tab
          )}
          tabsNotCapitalized={true}
          willScroll={false}
        />
      </ScrollMenu>
      <Divider className='m-t--1px' />
    </div>
  )
}
