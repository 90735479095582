import { Helmet } from 'src/common/components'
import { GuideDraftTopNav } from './GuideDraftTopNav'
import { GuideEmpty } from '../../details/common'

export const GuideDraftEmpty = () => (
  <>
    <Helmet
      metaDescription='Discover must-see attractions, hidden gems, and the best places to eat with detailed info. Plan your unforgettable trip with our expert tips and personalized itineraries.'
      pageName='This guide could not be found'
    />
    <GuideDraftTopNav guideDraftData={null} isUserOwner={false} />
    <GuideEmpty />
  </>
)
