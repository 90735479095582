import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'
import { useUpdateGuideDraftQueryCache } from './useGetGuideDraftQuery'

const updatePublishedEventCategoryMutation = gql(`
  mutation UpdatePublishedEventCategoryMutationInGuideDraft($updateCategoryInput: UpdatePublishedEventCategoryInput!) {
    updatePublishedEventCategory(updateCategoryInput: $updateCategoryInput) {
      guideDraft {
        id
        guide {
          id
          status
        }
        hasUnpublishedChanges
        paginatedEventCategories(first: 100) {
          edges {
            node {
              id
              description
              name
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
          totalCount
        }
      }
    }
  }
`)

export const useUpdateGuideDraftEventCategoryMutation = () => {
  const { updateGuideDraftQueryCache } = useUpdateGuideDraftQueryCache()

  return useMutation(updatePublishedEventCategoryMutation, {
    update: (cache, { data }) => {
      const updatedCache = updateGuideDraftQueryCache(
        data?.updatePublishedEventCategory?.guideDraft
      )

      if (!updatedCache) return

      cache.modify(updatedCache)
    },
  })
}
