import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'
import {
  guideDraftGroupedEventFragmentOld,
  useUpdateGuideDraftQueryCache,
} from './useGetGuideDraftQuery'

const updatePublishedEventCategoryMutation = gql(`
  mutation UpdatePublishedEventCategoryMutationInGuideDraftOld($updateCategoryInput: UpdatePublishedEventCategoryInput!) {
    updatePublishedEventCategory(updateCategoryInput: $updateCategoryInput) {
      guideDraft {
        id
        groupedEvents {
          ...GuideDraftGroupedEventFields
        }
        guide {
          id
          status
        }
        hasUnpublishedChanges
        publishedEventCategories {
          id
          description
          name
        }
      }
    }
  }
`)

export const useUpdateGuideDraftEventCategoryMutation = () => {
  const { updateGuideDraftQueryCache } = useUpdateGuideDraftQueryCache()

  return useMutation(updatePublishedEventCategoryMutation, {
    update: (cache, { data }) => {
      const updatedCache = updateGuideDraftQueryCache(
        data?.updatePublishedEventCategory?.guideDraft
      )

      if (!updatedCache) return

      cache.modify(updatedCache)
    },
  })
}
