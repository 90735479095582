import { useEffect } from 'react'
import type { User } from 'firebase/auth'
import { FooterContainer } from 'src/common/components/Footer/FooterContainer'
import { ApolloProvider } from 'src/config/apollo'
import { FeatureFlagsList, useLDClient } from 'src/config/feature-flags'

import { PageRoutes } from 'src/pages'
import { TopNav } from 'src/top-nav/TopNav'
import {
  initializeTagManager,
  pushDataToDataLayer,
} from '../analytics/googleTagManagerIntegration'
import { anonymousUserKey } from '../feature-flags/launchDarklyConstants'

export const GenerateAccessToken = ({
  hasAccessToken,
  user,
}: {
  hasAccessToken: boolean
  user: User
}) => {
  const client = useLDClient()
  const { uid, isAnonymous, displayName, email } = user ?? {}

  useEffect(() => {
    if (uid) {
      initializeTagManager(uid)

      if (!import.meta.env.IS_LOCAL_ENV) {
        pushDataToDataLayer('initialize_user', { user })
      }
    }
  }, [uid])

  useEffect(() => {
    if (user) {
      if (isAnonymous) {
        client?.identify({ key: anonymousUserKey, anonymous: true })
      }

      if (!isAnonymous) {
        client?.identify({
          key: email,
          name: displayName,
          email,
          custom: { id: uid },
        })
      }
    }
  }, [isAnonymous])

  if (!hasAccessToken) return null

  return (
    <ApolloProvider>
      <TopNav isAnonymous={isAnonymous} />
      <main className='min-h-[calc(100vh-400px)]' id='main-content'>
        <PageRoutes />
      </main>
      <FooterContainer />
      <FeatureFlagsList />
    </ApolloProvider>
  )
}
