import { Chip } from '@travelpass/design-system'
import { useFormContext } from 'react-hook-form'
import type { Tag } from 'src/__generated__/graphql'
import { useFlag } from 'src/common/hooks'
import type { ProfileFields } from '../../../../types'
import { useCurrentUserProfileId } from '../../../hooks/useGetCurrentUserProfileId'
import { useTags } from '../../../hooks/useTags'

const MAX_SELECTION = 5

export const Tags = () => {
  const { data: profile } = useCurrentUserProfileId()
  const { availableTags, addTagToProfile, removeTagFromProfile } = useTags()

  const { setValue, watch } = useFormContext<ProfileFields>()
  const tags =
    watch('tags')?.length > 0 ? JSON.parse(watch('tags') as string) : []

  const handleTagClick = (tag: Tag) => {
    if (tags.find(selectedTag => selectedTag.id === tag.id)) {
      setValue(
        'tags',
        JSON.stringify(tags.filter(selectedTag => selectedTag.id !== tag.id))
      )
      // remove tag
      removeTagFromProfile({
        variables: {
          input: {
            userProfileId: profile?.currentUser?.userProfile?.id,
            tagId: tag.id,
          },
        },
      })
    } else if (tags.length < MAX_SELECTION) {
      setValue('tags', JSON.stringify([...tags, tag]))
      // add tag
      addTagToProfile({
        variables: {
          input: {
            userProfileId: profile?.currentUser?.userProfile?.id,
            tagId: tag.id,
          },
        },
      })
    }
  }

  if (!availableTags || availableTags?.length === 0) return null

  return (
    <div className='space-y-2'>
      <h6 className='type-h6 c-black'>Focuses & Specialities </h6>
      <p className='type-small-text-desktop c-grey800'>
        Select up to 5 tags that best describe your travel style!
      </p>
      <div className='flex flex-wrap gap-2 pt-4'>
        {availableTags?.map(tag => (
          <Chip
            key={tag.id}
            isDisabled={
              tags.length >= MAX_SELECTION &&
              !tags.find(selectedTag => selectedTag.id === tag.id)
            }
            isSelected={tags.find(selectedTag => selectedTag.id === tag.id)}
            label={tag.name}
            onClick={() => handleTagClick(tag)}
          />
        ))}
      </div>
    </div>
  )
}
