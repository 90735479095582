type featureFlagNames =
  | 'alwaysRefundable'
  | 'dashboardV2Profile'
  | 'dashboardV3'
  | 'dashboardV3AccountSettings'
  | 'dashboardV3Management'
  | 'enableAddGuideOnGuideSearch'
  | 'enableAllInPricing'
  | 'enableApplySearchPrefFilters'
  | 'enableCompetition2024'
  | 'enableExplore'
  | 'enableGuideNavigatorShare'
  | 'enableHotelRateConfidenceLevel'
  | 'enableImageCropper'
  | 'enableLoggedInHomeRedirect'
  | 'enableProfileFavoriteHotels'
  | 'enableQuickResponsePrototype'
  | 'enablePlanPage'
  | 'guideDraftOnboardingUserRoles'
  | 'guideMetricsViewCountHide'
  | 'guidePagination'
  | 'guideURLShort'
  | 'guideURLV2'
  | 'isBookingSendSmsEnabled'
  | 'isDestinationCarouselEnabled'
  | 'isHotelRateProviderNameEnabled'
  | 'isInternationalGeocoderEnabled'
  | 'manageGuidesInProfile'
  | 'newSearchPage'
  | 'profileEditModal'
  | 'profileV1Socials'
  | 'publishingV2ModalAsyncPhotoUpload'
  | 'tripExploreV2'
  | 'tripsHide'

const featureFlags: Record<featureFlagNames, boolean> = {
  alwaysRefundable: false,
  dashboardV2Profile: false,
  dashboardV3: false,
  dashboardV3AccountSettings: false,
  dashboardV3Management: false,
  enableAddGuideOnGuideSearch: false,
  enableAllInPricing: false,
  enableApplySearchPrefFilters: false,
  enableCompetition2024: false,
  enableExplore: false,
  enableGuideNavigatorShare: false,
  enableHotelRateConfidenceLevel: false,
  enableImageCropper: false,
  enableLoggedInHomeRedirect: false,
  enableProfileFavoriteHotels: false,
  enableQuickResponsePrototype: false,
  enablePlanPage: false,
  guideDraftOnboardingUserRoles: false,
  guideMetricsViewCountHide: false,
  guidePagination: false,
  guideURLShort: false,
  guideURLV2: false,
  isBookingSendSmsEnabled: false,
  isDestinationCarouselEnabled: false,
  isHotelRateProviderNameEnabled: false,
  isInternationalGeocoderEnabled: false,
  manageGuidesInProfile: false,
  newSearchPage: false,
  profileEditModal: false,
  profileV1Socials: false,
  publishingV2ModalAsyncPhotoUpload: false,
  tripExploreV2: false,
  tripsHide: false,
}

export type { featureFlagNames }
export { featureFlags }
