import { MAX_MOBILE_WIDTH, MIN_TABLET_WIDTH } from '@travelpass/design-system'

const sizes = `(max-width: ${MAX_MOBILE_WIDTH}px) ${MAX_MOBILE_WIDTH}px, ${MIN_TABLET_WIDTH}px`

const initialSubtitle =
  'Customize your details and publish your Guide with our community of travelers.'

const initialTitle = 'Share your Guide'

interface GuideDraftPublishIntroProps {
  subtitle?: string
  title?: string
}

export const GuideDraftPublishIntro = ({
  subtitle = initialSubtitle,
  title = initialTitle,
}: GuideDraftPublishIntroProps) => (
  <section className='space-y-3 md:space-y-4'>
    <div className='md:p-x-11 space-y-3'>
      <h4 className='color-black type-h6-desktop'>{title}</h4>
      <p>{subtitle}</p>
    </div>
    <img
      alt=''
      className='m-a block h-auto max-w-full'
      sizes={sizes}
      src='https://static.travelpass.com/assets/guides/publish-guide.webp'
      srcSet={`https://static.travelpass.com/assets/guides/publish-guide-vertical.webp ${MAX_MOBILE_WIDTH}w, https://static.travelpass.com/assets/guides/publish-guide.webp ${MIN_TABLET_WIDTH}w`}
    />
  </section>
)
