import { Divider, SkeletonDots } from '@travelpass/design-system'
import type {
  FollowersQuery,
  SearchUserProfilesQueryQuery,
} from 'src/__generated__/graphql'
import { EmptyList } from './EmptyList'
import { FollowerListItem } from './FollowerListItem'

interface FollowersListProps {
  loading: boolean
  list:
    | FollowersQuery['viewUserProfile']['followedUsers']
    | SearchUserProfilesQueryQuery['searchUserProfiles']
  emptyListMessage: string
}

export const FollowersList = ({
  loading,
  list,
  emptyListMessage,
}: FollowersListProps) => {
  return (
    <div className='min-h-100 max-h-100 overflow-auto'>
      {loading && !list ? (
        <div className='min-h-63 flex flex-col content-center justify-center'>
          <SkeletonDots numberOfDots={3} />
        </div>
      ) : !loading && (!list || list.length == 0) ? (
        <EmptyList text={emptyListMessage} />
      ) : (
        list?.map(follower => (
          <span key={follower.id}>
            <FollowerListItem follower={follower} />
            <Divider />
          </span>
        ))
      )}
    </div>
  )
}
