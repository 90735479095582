import { useLazyQuery } from '@apollo/client'
import { gql } from 'src/__generated__'

const standardHotelQuery = gql(`
  query GetListHotelLazyQueryInGuide($hotelId: ID!) {
    standardHotel(id: $hotelId, idType: STANDARD) {
      id
      city
      name
      state
      stateCode
    }
  }
`)

export const useGetGuideHotelLazyQuery = () => useLazyQuery(standardHotelQuery)
