import isEmpty from 'lodash.isempty'
import { useFormContext } from 'react-hook-form'
import type { ProfileFields } from 'src/pages/profile/types'
import { ProfileInformationSection } from '../../information-section/ProfileInformationSection'
import { useGetProfile } from '../hooks/useGetProfile'
import { identifySocialLink } from '../profileUtils'

export const EditingProfileVisualizer = () => {
  const { watch } = useFormContext<ProfileFields>()
  const { profile } = useGetProfile()

  const socialLinks = watch('socialLinks')
  const socialLink = watch('socialLink')
  const links = watch('links')
  const linkURL = watch('linkURL')
  const linkTitle = watch('linkTitle')
  const tags = watch('tags')

  const combinedSocialLinks = [
    ...(socialLinks ? JSON.parse(socialLinks as string) : []),
    ...(!isEmpty(socialLink)
      ? [
          {
            network: identifySocialLink(socialLink),
            identifier: socialLink,
          },
        ]
      : []),
  ]

  const combinedLinks = [
    ...(links ? JSON.parse(links as string) : []),
    ...(!isEmpty(linkURL) && !isEmpty(linkTitle)
      ? [{ url: linkURL, title: linkTitle }]
      : []),
  ]

  return (
    <div className='hidden w-full justify-center md:overflow-auto lg:flex'>
      <ProfileInformationSection
        profile={{
          ...profile,
          accountHandle: watch('accountHandle'),
          displayName: watch('displayName'),
          bio: watch('bio'),
          activeBannerImage: watch('activeBannerImage'),
          bragStates: watch('bragStates'),
          bragCountries: watch('bragCountries'),
          bragContinents: watch('bragContinents'),
          profileImageUrl: watch('profileImageUrl'),
          profileImageSource: watch('profileImageSource'),
          introVideoEmbed: watch('introVideoEmbed'),
          links: combinedLinks,
          tags: tags ? JSON.parse(tags as string) : [],
          socialLinks: combinedSocialLinks,
        }}
      />
    </div>
  )
}
