import { useCallback, useEffect, useRef } from 'react'
import classNames from 'classnames'
import debounce from 'lodash.debounce'
import isEmpty from 'lodash.isempty'
import { useGetImageSearchQuery } from 'src/common/hooks'
import { GuideImage } from 'src/pages/guides/details/common'
import { GuideDraftPublishImagesSearchEmpty } from './GuideDraftPublishImagesSearchEmpty'
import { GuideDraftPublishImagesSearchLoading } from './GuideDraftPublishImagesSearchLoading'
import type { GuideDraftPublishInvalidFormValues } from '../../types'

interface GuideDraftPublishImagesSearchContentProps {
  imagesSelected: string[]
  invalidFormValues: GuideDraftPublishInvalidFormValues
  onImagesSelectedChange: (updatedImagesSelected: string) => void
  searchString: string
}

export const GuideDraftPublishImagesSearchContent = ({
  imagesSelected,
  invalidFormValues,
  onImagesSelectedChange,
  searchString,
}: GuideDraftPublishImagesSearchContentProps) => {
  const [getImageSearchQuery, { data, loading: isLoading, error: hasError }] =
    useGetImageSearchQuery()
  const isInitialRender = useRef(true)
  const debouncedSearchImages = useCallback(
    debounce(searchString => {
      getImageSearchQuery({
        variables: {
          limit: 12,
          searchString,
        },
      })
    }, 250),
    [getImageSearchQuery]
  )
  const imagesAvailable = data?.imageSearch ?? []

  useEffect(() => {
    if (isInitialRender.current) {
      getImageSearchQuery({
        variables: {
          limit: 12,
          searchString,
        },
      })
      isInitialRender.current = false
    } else {
      debouncedSearchImages(searchString)
    }
  }, [searchString, debouncedSearchImages])

  if (isLoading) return <GuideDraftPublishImagesSearchLoading />

  if (hasError || isEmpty(imagesAvailable) || searchString.trim() === '')
    return <GuideDraftPublishImagesSearchEmpty />

  return (
    <div className='md:p-x-3 space-y-3'>
      <div
        className={classNames(
          'grid grid-cols-2 gap-1 md:grid-cols-3 md:gap-2',
          {
            'b-error': invalidFormValues.images,
            'b-grey-300': !invalidFormValues.images,
          }
        )}
      >
        {imagesAvailable?.map((image, index) => {
          const isSelected = imagesSelected?.includes(image)

          return (
            <button
              key={index}
              aria-pressed={isSelected}
              className={classNames(
                'b-1 b-grey-200 b-solid transition-opacity-200 rounded-2 aria-pressed:b-forest-light aria-pressed:b-2 aria-pressed:op-50 p-1.25 relative h-24 bg-white ease-linear hover:cursor-pointer md:h-40'
              )}
              onClick={() => onImagesSelectedChange(image)}
            >
              <GuideImage
                className='rounded-1.5'
                data-testid='image'
                draggable={false}
                src={image}
              />
            </button>
          )
        })}
      </div>
    </div>
  )
}
