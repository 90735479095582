import { useEffect, useState } from 'react'
import { Autocomplete, Icon } from '@travelpass/design-system'
import debounce from 'lodash.debounce'
import { useNavigate } from 'react-router-dom'
import type { SearchUserProfilesQueryQuery } from 'src/__generated__/graphql'
import { ProfileSearchBarOption } from './ProfileSearchBarOption'
import { useSearchProfiles } from './useSearchProfiles'
import { getProfilePageUrl } from '../profileUtils'

interface ProfileSearchBarProps {
  onSelection?: () => void
}

export const ProfileSearchBar = ({ onSelection }: ProfileSearchBarProps) => {
  const navigate = useNavigate()
  const { searchProfiles } = useSearchProfiles()
  const [searchString, setSearchString] = useState('')
  const [profiles, setProfiles] = useState<
    SearchUserProfilesQueryQuery['searchUserProfiles']
  >([])

  useEffect(() => {
    if (searchString.length > 2) debouncedSearchProfiles(searchString)
    else setProfiles([])

    return () => debouncedSearchProfiles.cancel()
  }, [searchString])

  const debouncedSearchProfiles = debounce((searchString: string) => {
    searchProfiles({ variables: { searchString } }).then(({ data }) =>
      setProfiles(data.searchUserProfiles)
    )
  }, 300)

  const onOptionSelect = ({
    accountHandle,
  }: SearchUserProfilesQueryQuery['searchUserProfiles'][0]) => {
    onSelection && onSelection()
    const URL = getProfilePageUrl(accountHandle)
    navigate(URL)
  }

  return (
    <div className='md:w-33% lg:w-25% w-full'>
      <Autocomplete
        autoExpand
        aria-label='Search friends and travelers'
        autoComplete='on'
        placeholder='Search friends and travelers'
        slotBefore={
          <div className='color-new-forest flex'>
            <Icon name='search' />
          </div>
        }
        value={searchString}
        onChange={event => setSearchString(event?.target?.value)}
        onOptionSelect={onOptionSelect}
      >
        <div className='max-h-50vh overflow-y-auto'>
          <p className='type-body-1-desktop c-grey700 px-4'>Accounts</p>
          {profiles.map(profile => (
            <ProfileSearchBarOption key={profile.id} profile={profile} />
          ))}
        </div>
      </Autocomplete>
    </div>
  )
}
