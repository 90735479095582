import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'
import {
  guideDraftGroupedEventFragmentOld,
  useUpdateGuideDraftQueryCache,
} from './useGetGuideDraftQuery'

const createPublishedEventCategoryMutation = gql(`
  mutation CreatePublishedEventCategoryMutationInGuideDraftOld($createCategoryInput: CreatePublishedEventCategoryInput!) {
    createPublishedEventCategory(createCategoryInput: $createCategoryInput) {
      guideDraft {
        id
        groupedEvents {
          ...GuideDraftGroupedEventFields
        }
        guide {
          id
          status
        }
        hasUnpublishedChanges
        publishedEventCategories {
          id
          description
          name
        }
      }
    }
  }
`)

export const useCreateGuideDraftEventCategoryMutation = () => {
  const { updateGuideDraftQueryCache } = useUpdateGuideDraftQueryCache()

  return useMutation(createPublishedEventCategoryMutation, {
    update: (cache, { data }) => {
      const updatedCache = updateGuideDraftQueryCache(
        data?.createPublishedEventCategory?.guideDraft
      )

      if (!updatedCache) return

      cache.modify(updatedCache)
    },
  })
}
