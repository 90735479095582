import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const addGuideDraftImagesMutation = gql(`
  mutation AddGuideImages($input: AddGuideDraftImagesInput!) {
    addGuideDraftImages(input: $input) {
      addedImages {
        id
        isFlagged
        url
      }
      errorImages {
        reason
        url
      }
      guideDraft {
        id
        guide {
          id
          images {
            id
            url
          }
        }
      }
    }
  }
`)

export const useAddGuideDraftImagesMutation = () =>
  useMutation(addGuideDraftImagesMutation)
