import type { FormEvent } from 'react'
import { useEffect, useState } from 'react'
import {
  Icon,
  IconButton,
  Popover,
  useScreenQuery,
} from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import { useSearchParams } from 'react-router-dom'
import {
  PageLayoutContainer,
  ResultsGeocoder,
  SearchProductsModal,
} from 'src/common/components'
import { lucencyNumber } from 'src/config/analytics/lucencyUtils'
import type { DatesType, GeocoderType } from 'src/constants/user'
import { initialArrivalDate, initialGeocoder } from 'src/constants/user'
import { HotelResultsSearchDates } from 'src/pages/hotels/results/HotelResultsSearch/HotelResultsSearchDates'
import { HotelResultsSearchDivider } from 'src/pages/hotels/results/HotelResultsSearch/common'
import {
  constructDates,
  constructGeocoder,
  stringToNumber,
  getArrivalDate,
  getDepartureDate,
} from 'src/utils'
import { constructResultsSearchDates } from '../results/ExperiencesResultsSearch/experiencesResultsSearchUtils'

const PulseDot = () => (
  <span className='relative grid p-1' data-testid='PulseDot'>
    <span className='bg-valley/80 absolute inline-flex h-full w-full animate-ping rounded-full opacity-75'></span>
    <span className='bg-valley relative inline-flex h-4 w-4 rounded-full'></span>
  </span>
)

// TODO: add <Divider /> before div when topNavV2 is ready
export const ExperiencesResultsSearchNew = () => {
  const { isMobileOrTablet } = useScreenQuery()
  const [searchParams, setSearchParams] = useSearchParams()
  const arrival = searchParams.get('arrival')
  const departure = searchParams.get('departure')
  const latitude = searchParams.get('latitude')
  const longitude = searchParams.get('longitude')
  const location = searchParams.get('location')
  const [shouldPromptForDates, togglePromptForDates] = useState(
    !searchParams.has('arrival') || !searchParams.has('departure')
  )
  // if dates are missing & on mobile, automatically show tooltip
  const displayDatesTooltip = isMobileOrTablet && shouldPromptForDates
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [dates, setDates] = useState<DatesType>(
    constructDates({
      arrival,
      departure,
    })
  )
  const [geocoder, setGeocoder] = useState<GeocoderType>(
    constructGeocoder({
      latitude: stringToNumber(latitude),
      longitude: stringToNumber(longitude),
      placeId: '',
      placeName: location,
    })
  )
  const [hasGeocoderError, setHasGeocoderError] = useState(false)

  useEffect(() => {
    if (arrival && departure) {
      setDates(
        constructDates({
          arrival,
          departure,
        })
      )
    }
  }, [searchParams])

  const showSearch = !!dates && !!geocoder

  const onDateSelect = range => {
    // track checkin and checkout dates to lucency
    lucencyNumber({
      checkin: range.from,
      checkout: range?.to,
    })
    setDates([range.from, range?.to])
  }

  const onSubmit = (event: FormEvent) => {
    event.preventDefault()
    const arrival = dates?.[0] ?? initialArrivalDate
    const departure = dates?.[1] ?? arrival?.add(1, 'day')
    const updatedHasDatesError = !dates?.[0] || !dates?.[1]
    const updatedHasGeocoderError =
      isEmpty(geocoder) || geocoder === initialGeocoder
    setHasGeocoderError(updatedHasGeocoderError)

    if (updatedHasDatesError)
      onDateSelect({
        from: arrival,
        to: departure,
      })

    if (!updatedHasGeocoderError) {
      setSearchParams(sp => {
        sp.set('arrival', getArrivalDate({ date: arrival }))
        sp.set('departure', getDepartureDate({ date: departure }))
        sp.set('latitude', geocoder?.center[0]?.toString())
        sp.set('longitude', geocoder?.center[1]?.toString())
        sp.set('location', geocoder?.placeName)
        return sp
      })
    }
  }

  useEffect(() => {
    setGeocoder(s => ({
      ...s,
      placeName: location,
    }))
  }, [location])

  const searchDates = constructResultsSearchDates({
    arrival,
    departure,
  })

  const SearchButton = (
    <button
      key='search-button'
      className='b-grey-300 b-solid b-1 transition-border-color-200 rounded-7 active:b-grey-500 focus:b-grey-500 hover:b-grey-500 [&>i]:color-new-forest flex w-full min-w-0 grow cursor-pointer items-center gap-2 bg-transparent px-4 py-2 text-left ease-linear lg:hidden'
      type='button'
      onClick={() => {
        setIsModalOpen(true)
        togglePromptForDates(false)
      }}
    >
      {displayDatesTooltip ? <PulseDot /> : <Icon name='search' />}
      <span className='min-w-0 grow space-y-0.5'>
        <span className='color-grey-900 type-h4-mobile block truncate'>
          {location}
        </span>
        <span className='color-grey-900 type-body-2-mobile block'>
          {searchDates}
        </span>
      </span>
    </button>
  )

  return (
    showSearch && (
      <>
        {isMobileOrTablet && isModalOpen && (
          <SearchProductsModal
            checkSearchParams
            checkTypes
            initialTabIndex={1}
            onClose={() => setIsModalOpen(false)}
          />
        )}
        {displayDatesTooltip ? (
          <div className='w-full'>
            <Popover
              className='text-body-1 bg-mint shadow-2 border-1 focus-visible:outline-emerald border-grey-300 mx-6 rounded border-solid px-4 text-center'
              initialOpen={true}
              placement='bottom-end'
              trigger={SearchButton}
            >
              <p>Select travel dates to further refine your&nbsp;search</p>
            </Popover>
          </div>
        ) : (
          SearchButton
        )}
        <div className='hidden lg:block lg:py-4'>
          <PageLayoutContainer>
            <form
              noValidate
              className='flex items-center lg:gap-3'
              onSubmit={onSubmit}
            >
              <div className='search-gap flex grow items-center justify-center gap-4 md:gap-3'>
                <div className='search-geocoder w-45 max-w-full'>
                  <ResultsGeocoder
                    checkTypes
                    errorText={
                      !!hasGeocoderError && 'Please select a destination.'
                    }
                    focusOnInput={false}
                    geocoder={geocoder}
                    isInvalid={hasGeocoderError}
                    label='Destination'
                    placeholder='Where are you going?'
                    onClick={event =>
                      event.currentTarget.querySelector('input')?.select()
                    }
                    onResult={updatedGeocoder => setGeocoder(updatedGeocoder)}
                  />
                </div>
                <HotelResultsSearchDivider />
                <HotelResultsSearchDates
                  arrivalLabel='Start Date'
                  canSelectSameDay={true}
                  dates={dates}
                  departureLabel='End Date'
                  onSelect={onDateSelect}
                />
                <HotelResultsSearchDivider />
              </div>
              <IconButton
                color='forestLight'
                icon='search'
                size='large'
                type='submit'
              />
            </form>
          </PageLayoutContainer>
        </div>
      </>
    )
  )
}
