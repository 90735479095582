import {
  IconButton,
  Input,
  Button,
  useSnackbar,
} from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import { useNavigate } from 'react-router-dom'
import type { Booking, StandardHotel } from 'src/__generated__/graphql'
import { hotelsPath } from 'src/constants'
import { constructImageLinks, copyToClipboard } from 'src/utils'
import { OccupancySummary } from '../OccupancySummary'

interface HotelConfirmationSummaryProps {
  arrival: Booking['arrival']
  bookingId: Booking['id']
  departure: Booking['arrival']
  externalConfirmationId: Booking['externalConfirmationId']
  imageLinks: StandardHotel['imageLinks']
  hideButton?: boolean
  hotelName: StandardHotel['name']
  occupancies: Booking['bookingDetails']['occupancies']
  roomName: Booking['bookingDetails']['hotelDetails']['room']['name']
}

export const HotelConfirmationSummary = ({
  arrival,
  bookingId,
  departure,
  externalConfirmationId,
  imageLinks,
  hideButton = false,
  hotelName,
  occupancies,
  roomName,
}: HotelConfirmationSummaryProps) => {
  const navigate = useNavigate()
  const { addSuccessSnack } = useSnackbar()
  const showImage = !isEmpty(imageLinks)
  const constructedImage = showImage && constructImageLinks(imageLinks).shift()

  const onSnackbarOpen = () => {
    copyToClipboard(externalConfirmationId)
    addSuccessSnack({ title: 'Successfully copied to the clipboard' })
  }

  return (
    !!hotelName && (
      <section className='flex flex-col gap-9'>
        <div className='flex flex-col items-center gap-5 md:flex-row'>
          {showImage && (
            <img
              alt={`${hotelName} hero - Booking Confirmation`}
              className='rd-3 h-30 md:max-w-45 w-full max-w-full object-cover md:h-auto'
              src={constructedImage}
            />
          )}
          <div className='md:items-initial flex w-full grow flex-col items-center gap-4 md:gap-3'>
            <h2 className='text-h6'>{hotelName}</h2>
            <p className='text-body-1'>{roomName}</p>
            <OccupancySummary
              arrival={arrival}
              departure={departure}
              occupancies={occupancies}
            />
          </div>
        </div>
        <div className='flex flex-col items-center justify-center gap-5 md:flex-row md:items-end md:gap-12'>
          <div className='w-full grow md:w-auto'>
            <Input
              fullWidth
              readOnly
              id='confirmation-number'
              label='Confirmation number'
              slotAfter={
                <IconButton icon='contentCopy' onClick={onSnackbarOpen} />
              }
              value={externalConfirmationId}
              onFocus={event => {
                event.target.blur()
                onSnackbarOpen()
              }}
            />
          </div>
          {!hideButton && (
            <div className='w-full md:w-auto md:pb-1'>
              <Button
                fullWidth
                label='Booking Details'
                size='large'
                onClick={() =>
                  navigate(`${hotelsPath}/confirmation/${bookingId}`)
                }
              />
            </div>
          )}
        </div>
      </section>
    )
  )
}
