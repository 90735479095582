import type { ChangeEvent } from 'react'
import { useCallback, useRef, useEffect, useState } from 'react'
import type { AutocompleteProps } from '@travelpass/design-system'
import {
  Autocomplete,
  Icon,
  IconButton,
  KeyCode,
} from '@travelpass/design-system'
import classNames from 'classnames'
import debounce from 'lodash.debounce'
import { useSearchParams } from 'react-router-dom'
import { getGeocode, getLatLng } from 'use-places-autocomplete'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import type { GeocoderType } from 'src/constants/user'
import { initialGeocoder } from 'src/constants/user'
import { getGuidePlaceLatLng } from 'src/utils'
import { GuideGeocoderOption } from './GuideGeocoderOption'
import { GuideSearchParam } from '../../details'

const PlaceOutlineIcon = () => (
  <span className='c-new-forest'>
    <Icon name='placeOutline' />
  </span>
)

interface GuideGeocoderProps
  extends Pick<
    AutocompleteProps,
    | 'id'
    | 'isInvalid'
    | 'placeholder'
    | 'onClick'
    | 'isDisabled'
    | 'listboxPosition'
    | 'name'
    | 'onBlur'
    | 'onFocus'
    | 'required'
    | 'fullWidth'
    | 'slotAfter'
    | 'slotBefore'
  > {
  config?: google.maps.places.TextSearchRequest
  count?: number
  errorText?: string
  helperText?: string
  label?: string
  onClear?: VoidFunction
  onResult: (result: GeocoderType) => void
  onResults?: (results: google.maps.places.PlaceResult[]) => void
  onSearchValueChange: (updatedSearchValue: string) => void
  searchValue?: string
  willAddEvent?: boolean
}

const cacheExpiration = 24 * 60 * 60 // 24 hours
const cacheKey = 'upa-text-search'

export const GuideGeocoder = ({
  config = {},
  errorText,
  helperText,
  isDisabled,
  isInvalid,
  label,
  count = 5,
  name,
  placeholder,
  slotAfter,
  slotBefore = <PlaceOutlineIcon />,
  onBlur,
  onClear,
  onClick,
  onResult,
  onResults,
  onSearchValueChange,
  searchValue,
  willAddEvent = true,
  ...props
}: GuideGeocoderProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [isLoading, setIsLoading] = useState(true)
  const [options, setOptions] = useState<google.maps.places.PlaceResult[]>([])
  const autocompleteRef = useRef<HTMLInputElement>(null)
  const geocoderRef = useRef<google.maps.places.PlacesService>(null)
  const showOptions = searchValue?.trim() && !!options?.length

  useEffect(() => {
    if (window?.google?.maps) {
      geocoderRef.current = new window.google.maps.places.PlacesService(
        document.createElement('div')
      )
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    debouncedSearch(config, searchValue)
  }, [searchValue])

  const debouncedSearch = useCallback(
    debounce((config, updatedValue) => {
      onSearch({
        config,
        updatedValue,
      })
    }, 300),
    []
  )

  const onSearch = ({
    config,
    updatedValue,
  }: {
    config: google.maps.places.TextSearchRequest
    updatedValue: string
  }) => {
    const validatedValue = updatedValue?.trim()
    const { lat, lng } = config?.location ?? ({} as google.maps.LatLng)
    const cacheItemKey = `${lat ?? ''}-${lng ?? ''}-${validatedValue}`

    if (!geocoderRef?.current || !validatedValue) return onClearHandler()

    let cachedData: Record<
      string,
      {
        data: google.maps.places.PlaceResult[]
        maxAge: number
      }
    > = {}

    try {
      cachedData = JSON.parse(sessionStorage.getItem(cacheKey) || '{}')
    } catch (error) {
      console.error(error)
    }

    cachedData = Object.keys(cachedData).reduce(
      (total: typeof cachedData, key) => {
        if (cachedData[key].maxAge - Date.now() >= 0)
          total[key] = cachedData[key]

        return total
      },
      {}
    )

    if (cachedData[cacheItemKey]) {
      const updatedOptions =
        cachedData?.[cacheItemKey]?.data?.slice(0, count) ?? []
      onResults?.(updatedOptions)
      setOptions(updatedOptions)
      return
    }

    geocoderRef.current.textSearch(
      {
        ...config,
        query: validatedValue,
      },
      (results, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          const updatedOptions = results?.slice(0, count) ?? []
          onResults?.(updatedOptions)
          setOptions(updatedOptions)
          pushDataToDataLayer('geocoder_text_search_results', {
            item_options: updatedOptions,
            item_value: validatedValue,
          })
          cachedData[cacheItemKey] = {
            data: results,
            maxAge: Date.now() + cacheExpiration * 1000,
          }

          try {
            sessionStorage.setItem(cacheKey, JSON.stringify(cachedData))
          } catch (error) {
            console.error(error)
          }
        }
      }
    )
  }

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const updatedValue = event.target.value
    onSearchValueChange(updatedValue)
  }

  const onClearHandler = () => {
    setOptions([])
    onSearchValueChange('')

    if (autocompleteRef.current) {
      autocompleteRef.current.value = ''
      autocompleteRef.current?.focus() // return focus to input
    }

    onResult(initialGeocoder)
    onClear?.()
  }

  const onKeyDown = event => {
    if (event.key === KeyCode.ESC) {
      onClearHandler()
    }

    if (event.key === KeyCode.ENTER) {
      // prevent default to stop accidental form submit when user selects option with ENTER key
      event.preventDefault()

      if (showOptions) onOptionSelect(options?.[0])
    }
  }

  const onOptionSelect = (
    selectedSuggestion: google.maps.places.PlaceResult
  ) => {
    const { formatted_address, geometry, name, place_id, types } =
      selectedSuggestion ?? {}
    autocompleteRef.current.value = name

    if (!willAddEvent && geometry?.location) {
      const { lat, lng } = getGuidePlaceLatLng(geometry?.location)
      onResult({
        center: [lat, lng],
        placeName: name,
        placeId: place_id,
      })
      return
    }

    if (place_id === 'SEARCH') {
      searchParams.set(GuideSearchParam.mapFullView, 'true')
      setSearchParams(searchParams, {
        replace: true,
      })
      return
    }

    onSearchValueChange('')
    setOptions([])
    pushDataToDataLayer('select_geocoder_text_search_option', {
      item_location: formatted_address,
      item_id: place_id,
      item_name: name,
      item_types: types,
    })
    getGeocode({
      address: formatted_address,
    }).then(data => {
      const { address_components, geometry, types } = data?.[0]
      const { lat, lng } = getLatLng(data?.[0])
      onResult({
        addressComponents: address_components,
        center: [lat, lng],
        placeName: name,
        placeId: place_id,
        types,
        viewport: geometry?.viewport,
      })
    })
  }

  const slotAfterClear = autocompleteRef.current?.value?.trim() !== '' && (
    <IconButton icon='clear' onClick={onClearHandler} />
  )

  if (isLoading) return

  return (
    /** @todo This is a one-off implementation that should be refactored in the future. */
    <div className='[&>div>div>div:first-child]:shadow-3 relative [&>div>div>div:first-child]:m-0'>
      <Autocomplete
        {...props}
        autoExpand
        autoComplete='off'
        errorText={errorText}
        helperText={helperText}
        isDisabled={isDisabled}
        isInvalid={isInvalid}
        label={label}
        name={name}
        placeholder={placeholder}
        ref={autocompleteRef}
        slotAfter={slotAfter ?? slotAfterClear}
        slotBefore={slotBefore}
        value={searchValue}
        onBlur={onBlur}
        onChange={onChange}
        onClick={onClick}
        onKeyDown={onKeyDown}
        onOptionSelect={onOptionSelect}
      >
        {/** @todo look into aria-selected no longer working */}
        {showOptions && (
          <div
            className={classNames({
              'max-h-92.25 overflow-y-auto md:max-h-96': count > 5,
            })}
          >
            {options?.map(
              option =>
                !!option?.place_id && (
                  <GuideGeocoderOption key={option?.place_id} option={option} />
                )
            )}
            <GuideGeocoderOption
              option={{
                name: `See results for "${searchValue}" on map`,
                place_id: 'SEARCH',
                types: [],
              }}
            />
          </div>
        )}
      </Autocomplete>
    </div>
  )
}
