import { useRef, useState, useEffect } from 'react'
import {
  SkeletonDots,
  Button,
  Icon,
  Autocomplete,
  KeyCode,
  AutocompleteOption,
  IconButton,
  useScreenQuery,
} from '@travelpass/design-system'
import { Navigate, useSearchParams } from 'react-router-dom'
import { type Suggestion, getGeocode, getLatLng } from 'use-places-autocomplete'
import { GuideSorts, GuideType } from 'src/__generated__/graphql'
import {
  GuideRecommendedCard,
  PageLayoutContainer,
  useOnClickOwnerProfile,
} from 'src/common/components'
import { AddToCollectionsModalListener } from 'src/common/components/Collections/AddToCollectionsModalListener'
import { GeocoderOption } from 'src/common/components/Geocoder/GeocoderOption'
import { constructCityAndState } from 'src/common/components/Geocoder/geocoderUtils'
import { useGeocoderSearch } from 'src/common/components/Geocoder/useGeocoderSearch'
import { useFlag } from 'src/common/hooks'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { explorePath } from 'src/constants'
import { AllGuidesSortBy } from './AllGuidesSortBy'
import { COUNTRY_STATE_SEARCH_TYPES } from './guideConstants'
import { GuideDraftCreateModalOld } from './lists-old/common'
import { LookingForMore } from './results/LookingForMore'
import { ProfileModalListener } from './results/ProfileModalListener'
import { useAllGuideSearchQuery } from './results/useAllGuidesSearchQuery'
import { useGuidesSearchParams } from './useGuidesSearchParams'

export const AllGuides = () => {
  const enableExplore = useFlag('enableExplore')
  const { isMobileScreen } = useScreenQuery()
  const [searchParams, setSearchParams] = useSearchParams()
  const { value, setValue, suggestions, clearSuggestions, ready } =
    useGeocoderSearch({
      requestOptions: {
        componentRestrictions: {
          country: [],
        },
      },
    })

  useEffect(() => {
    const currentDisplayName = searchParams.get('displayName') || ''
    setValue(currentDisplayName, false)
  }, [searchParams, setValue])

  const onClickOwnerProfile = useOnClickOwnerProfile()
  const {
    displayName,
    similarName,
    latitude,
    longitude,
    location,
    sortBy = GuideSorts.Recommended,
    spGuideType,
    country,
    state,
  } = useGuidesSearchParams()

  const enableAddGuideOnGuideSearch = useFlag('enableAddGuideOnGuideSearch')

  const autocompleteRef = useRef<HTMLInputElement>(null)
  const autocompleteParentRef = useRef<HTMLDivElement>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const options = suggestions?.data || []
  const showOptions = Boolean(options?.length)
  const searchTerm = value?.trim()
  // set to false initially, then true when the user types in the search input, then false when the user selects an option
  const [showSearchTerm, setShowSearchTerm] = useState(false)

  const lat = latitude && parseFloat(latitude)
  const lon = longitude && parseFloat(longitude)
  const guideType = GuideType[spGuideType]
  const minDuration =
    (guideType === GuideType.Blog && Number(searchParams.get('min'))) ||
    undefined // must ensure that is undefined, because Number() returns zero called with '', null, and undefined
  const maxDuration =
    (guideType === GuideType.Blog && Number(searchParams.get('max'))) ||
    undefined // must ensure that is undefined, because Number() returns zero called with '', null, and undefined
  const durationDays = minDuration
    ? {
        max: maxDuration,
        min: minDuration,
      }
    : undefined
  const { cityAndState } = constructCityAndState(
    displayName || similarName || ''
  )

  const { loading, data, error, fetchMore } = useAllGuideSearchQuery({
    state,
    country,
    latitude: lat || undefined,
    longitude: lon || undefined,
    location,
    sortBy,
    similarName,
    filterBy: {
      guideType,
      durationDays,
    },
  })

  const cursor =
    data?.guideSearch?.pageInfo?.hasNextPage &&
    data.guideSearch?.pageInfo.endCursor

  const guides = data?.guideSearch?.edges.map(edge => edge.node)
  const isEmpty = !guides?.length
  const displayBlank = !loading && (error || isEmpty)
  const totalCount = data?.guideSearch?.totalCount

  const handleOptionSelection = async (selection: Suggestion | string) => {
    if (typeof selection === 'string') {
      const similarName = selection
      // do not set a new value for the input since we want the user's search term to remain
      handleClearAndFocus({ newValue: null, focusesInput: !isMobileScreen })
      setSearchParams(sp => {
        sp.set('similarName', similarName)
        sp.set('displayName', similarName)
        sp.delete('latitude')
        sp.delete('longitude')
        sp.delete('location')
        sp.delete('country')
        sp.delete('state')
        return sp
      })
      return
    } else {
      const [result] = await getGeocode({
        placeId: selection.place_id,
      })
      const { lat, lng } = getLatLng(result)
      const isLatLongSearch =
        !result.address_components ||
        !result.address_components?.length ||
        result.address_components?.some(component =>
          component.types.some(type => !COUNTRY_STATE_SEARCH_TYPES.has(type))
        )

      if (!isLatLongSearch) {
        pushDataToDataLayer('guides_results_search_term', {
          location: result?.formatted_address,
          displayName,
        })
        handleClearAndFocus({
          newValue: result.formatted_address,
          focusesInput: !isMobileScreen,
        })

        setSearchParams(sp => {
          const country = result.address_components?.find(
            addressComponent => addressComponent.types[0] === 'country'
          )?.long_name
          const state = result.address_components?.find(
            addressComponent =>
              addressComponent.types[0] === 'administrative_area_level_1'
          )?.long_name

          country ? sp.set('country', country) : sp.delete('country')
          state ? sp.set('state', state) : sp.delete('state')

          sp.set('displayName', result.formatted_address)
          sp.set('location', result.formatted_address)
          sp.delete('latitude')
          sp.delete('longitude')
          sp.delete('similarName')
          return sp
        })
      }

      if (isLatLongSearch) {
        pushDataToDataLayer('guides_results_search_term', {
          location: result?.formatted_address,
          displayName,
        })
        handleClearAndFocus({
          newValue: result.formatted_address,
          focusesInput: !isMobileScreen,
        })
        setSearchParams(sp => {
          sp.set('displayName', result.formatted_address)
          sp.set('location', result.formatted_address)
          sp.set('latitude', lat.toString())
          sp.set('longitude', lng.toString())
          sp.delete('similarName')
          sp.delete('country')
          sp.delete('state')
          return sp
        })
        return
      }
    }
  }

  const handleClearAndFocus = ({
    newValue,
    focusesInput,
    shouldFetchData = false,
  }: {
    newValue: string | null
    focusesInput: boolean
    shouldFetchData?: boolean
  }) => {
    newValue !== null && setValue(newValue, shouldFetchData)
    clearSuggestions()
    setShowSearchTerm(false)
    // hacky way to force the functionality to work with ESC key
    autocompleteRef?.current?.blur()
    focusesInput && autocompleteRef?.current?.focus()
  }

  const handleSearchTermEnter = e => {
    if (e.key === KeyCode.ENTER) {
      if (showSearchTerm) handleOptionSelection(searchTerm)
      else if (showOptions) handleOptionSelection(options[0])
    }
    if (e.key === KeyCode.ESC)
      handleClearAndFocus({ newValue: '', focusesInput: true })
  }

  const handleSearchButtonClick = () => {
    if (showSearchTerm) handleOptionSelection(searchTerm)
    else if (showOptions) handleOptionSelection(options[0])
    else document.getElementById('guides-autocomplete-input')?.focus()
  }

  const toggleModal = () => setIsModalOpen(!isModalOpen)

  const ClearButton = () => (
    <span className='mr--3'>
      <IconButton
        icon='clear'
        onClick={() =>
          handleClearAndFocus({ newValue: '', focusesInput: true })
        }
      />
    </span>
  )

  const PlaceOutlineIcon = () => (
    <span className='c-new-forest'>
      <Icon name='placeOutline' />
    </span>
  )

  if (enableExplore) return <Navigate replace to={explorePath} />

  return (
    <div className='space-y-4 pb-4'>
      <div className='h-55 max-h-55 flex flex-col justify-end bg-black/35 bg-[url(https://static.travelpass.com/assets/guides/search-header.webp)] bg-cover bg-[position:center_65%] px-4 bg-blend-darken'>
        <div
          className='h-23 shadow-3 md:w-70% max-w-300 -mb-11.5 mx-auto flex w-full items-center gap-4 rounded-2xl border-b-0 bg-white px-3 py-1 md:gap-8 md:px-9 md:py-3 lg:gap-12'
          ref={autocompleteParentRef}
        >
          <div className='w-full'>
            <Autocomplete
              autoExpand
              fullWidth
              id='guides-autocomplete-input'
              isDisabled={!ready}
              placeholder='Search Guides'
              ref={autocompleteRef}
              slotAfter={value ? <ClearButton /> : null}
              slotBefore={<PlaceOutlineIcon />}
              value={value}
              onChange={e => {
                setShowSearchTerm(true)
                setValue(e.target.value)
              }}
              onFocus={
                isMobileScreen
                  ? () => {
                      autocompleteParentRef?.current?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                      })
                    }
                  : null
              }
              onKeyDown={handleSearchTermEnter}
              onOptionSelect={handleOptionSelection}
            >
              {showSearchTerm && value.trim() && (
                <AutocompleteOption
                  className='type-body-1 b-none c-black hover:bg-grey-100 focus:bg-grey-100 aria-selected:bg-warm-grey flex w-full cursor-pointer flex-row items-center gap-2 bg-white px-4 py-1.5 text-left outline-none'
                  value={searchTerm}
                >
                  Search for &quot;{searchTerm}&quot;
                </AutocompleteOption>
              )}
              {showOptions &&
                options.map(option => (
                  <GeocoderOption key={option.place_id} option={option} />
                ))}
            </Autocomplete>
          </div>
          <span className='lg:hidden'>
            <IconButton
              color='forestLight'
              icon='search'
              size='large'
              onClick={handleSearchButtonClick}
            />
          </span>
          <span className='hidden lg:block'>
            <Button
              fullWidth
              color='forestLight'
              label='Search'
              size='large'
              startIcon='search'
              onClick={handleSearchButtonClick}
            />
          </span>
        </div>
      </div>

      <PageLayoutContainer includeTopPadding>
        <header className='space-y-2'>
          <h1 className='type-h1 text-forest-dark text-center'>
            Explore Top Travel Guides
          </h1>
          <p className='type-body-1 text-grey-900 hidden text-center md:block'>
            Check out some of our favorite guide options, created by users just
            like you!
          </p>
        </header>

        <section className='space-y-8 px-4 py-8'>
          {displayName && guides && !isEmpty && (
            <h2 className='type-h4 color-grey-900 [&_i]:color-valley flex flex-row items-center gap-2'>
              <Icon name='navigationCompass' size='medium' />
              {cityAndState} {totalCount ? `(${totalCount})` : ''}
            </h2>
          )}
          <div className='flex flex-col justify-between gap-5 md:flex-row'>
            <AllGuidesSortBy />
            {enableAddGuideOnGuideSearch && (
              <div className='hidden md:block'>
                <Button startIcon='add' onClick={toggleModal}>
                  Guide
                </Button>
              </div>
            )}
          </div>
          {loading && (
            <div>
              <SkeletonDots />
            </div>
          )}
          {displayBlank && (
            <div className='mx-auto w-fit max-w-xl space-y-12 text-balance py-8 text-center md:py-14'>
              <h3 className='text-h4'>
                {similarName
                  ? `Looks like our explorers haven't published any guides for "${similarName}" yet!`
                  : "Looks like our explorers haven't quite made it here yet!"}
              </h3>
              <img
                alt=''
                className='object-contain max-md:w-64'
                src='https://static.travelpass.com/assets/guides/empty.svg'
              />
            </div>
          )}
          {guides && !isEmpty && (
            <>
              <div className='grid grid-cols-1 gap-2 md:grid-cols-2 md:gap-5 lg:grid-cols-3'>
                {guides.map((guide, index) => (
                  <GuideRecommendedCard
                    key={`guide-card-${index}`}
                    showBookmarkIcon
                    guideRecommendedCardData={guide}
                    onClickOwnerProfile={onClickOwnerProfile}
                  />
                ))}
              </div>
              {cursor && (
                <div className='flex justify-center py-6'>
                  <Button
                    aria-label='Load more guides'
                    disabled={loading}
                    size='small'
                    variant='outlined'
                    onClick={async () => {
                      await fetchMore({
                        variables: {
                          after: cursor,
                        },
                      })
                      pushDataToDataLayer('guide_search_load_more_click')
                    }}
                  >
                    Load more
                  </Button>
                </div>
              )}
            </>
          )}
          {enableAddGuideOnGuideSearch && (
            <div className='sticky bottom-9 flex justify-center md:hidden'>
              <Button
                aria-label='Create a new guide'
                startIcon='add'
                onClick={toggleModal}
              >
                Guide
              </Button>
            </div>
          )}
          {/* Only shows the section when it is a geocoder search and all guides search is already made */}
          {lat && lon && !loading && (
            <LookingForMore
              closerRadiusRange={{ min: 81, max: 90 }}
              fartherRadiusRange={{ max: 100 }}
              filters={{ durationDays, guideType }}
              onClickOwnerProfile={onClickOwnerProfile}
            />
          )}
        </section>
        {isModalOpen && <GuideDraftCreateModalOld onDismiss={toggleModal} />}
      </PageLayoutContainer>
      <ProfileModalListener />
      <AddToCollectionsModalListener />
    </div>
  )
}
