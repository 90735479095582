import { Icon } from '@travelpass/design-system'
import classNames from 'classnames'
import type { DashboardFeedItem } from '../../types'

interface DashboardFeedCardProps {
  dashboardFeedItem: DashboardFeedItem
}

export const DashboardFeedCard = ({
  dashboardFeedItem,
}: DashboardFeedCardProps) => {
  const {
    countOfFollowed,
    countOfFollowing,
    date,
    description,
    location,
    profileImageUrl,
    title,
    url,
  } = dashboardFeedItem ?? {}
  const isFollowing = !isNaN(countOfFollowed) && !isNaN(countOfFollowing)

  return (
    <div className='group relative'>
      <article className='p-x-4 p-y-3 rounded-3 shadow-3 transition-opacity-200 cursor-pointer space-y-1 bg-white ease-linear group-hover:opacity-70'>
        <header className='p-l-7 relative'>
          <h4 className='c-forest-dark type-body-1-medium-desktop'>{title}</h4>
          {isFollowing && (
            <div className='c-forest-light top--0.7 absolute left-0 h-6 w-6'>
              <Icon name='personAdd' size='medium' />
            </div>
          )}
          {!isFollowing && profileImageUrl && (
            <img
              alt=''
              className='rounded-1/2 absolute left-0 top-0 h-5 w-5 max-w-full object-cover'
              loading='lazy'
              src={profileImageUrl}
            />
          )}
        </header>
        <div
          className={classNames('space-y-1', {
            'p-l-7': !isFollowing,
          })}
        >
          {date && (
            <p className='c-grey-800 type-small-text-mobile flex flex-row items-center gap-1'>
              {date}
            </p>
          )}
          {location && (
            <p className='c-grey-800 type-small-text-mobile flex flex-row items-center gap-1'>
              <span className='c-orange'>
                <Icon name='placeOutline' size='small' />
              </span>
              <span>{location}</span>
            </p>
          )}
          <p
            className={classNames(
              'min-h-6.75 type-body-2-desktop line-clamp-3',
              {
                'c-grey-800': isFollowing,
              }
            )}
          >
            {description}
          </p>
          {isFollowing && (
            <div className='bg-warm-grey p-x-2.5 p-y-1 rounded-3 flex w-fit flex-row items-center gap-2.5'>
              <p className='m-0 flex flex-row items-center gap-1'>
                <span className='type-h6-mobile'>{countOfFollowing}</span>
                <span className='c-new-forest type-subtext'>
                  {countOfFollowed > 1 ? 'Followers' : 'Follower'}
                </span>
              </p>
              <p className='m-0 flex flex-row items-center gap-1'>
                <span className='type-h6-mobile'>{countOfFollowed}</span>
                <span className='c-new-forest type-subtext'>Following</span>
              </p>
            </div>
          )}
        </div>
      </article>
      <a
        className='absolute inset-0'
        href={url}
        rel='noreferrer'
        target='_blank'
      >
        <span className='sr-only'>
          More information about this card, opens in a new tab
        </span>
      </a>
    </div>
  )
}
