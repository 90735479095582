import { Skeleton } from '@travelpass/design-system'

export const GuideDraftPublishImagesSearchLoading = () => (
  <div className='b-1 b-grey-300 b-solid rounded-2 grid grid-cols-2 gap-1 bg-white md:grid-cols-3 md:gap-2'>
    <div className='b-4 b-solid b-transparent h-40 p-2'>
      <Skeleton variant='rounded' />
    </div>
    <div className='b-4 b-solid b-transparent h-40 p-2'>
      <Skeleton variant='rounded' />
    </div>
    <div className='b-4 b-solid b-transparent h-40 p-2'>
      <Skeleton variant='rounded' />
    </div>
    <div className='b-4 b-solid b-transparent h-40 p-2'>
      <Skeleton variant='rounded' />
    </div>
    <div className='b-4 b-solid b-transparent h-40 p-2'>
      <Skeleton variant='rounded' />
    </div>
    <div className='b-4 b-solid b-transparent h-40 p-2'>
      <Skeleton variant='rounded' />
    </div>
  </div>
)
