import { useState } from 'react'
import { Button } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import { GuideDraftCreateModalOld } from 'src/pages/guides/lists-old/common'
import { getGuideDraftDetailsUrl } from 'src/utils'
import { DashboardGuideDraftCard } from './DashboardGuideDraftCard'
import { DashboardGuideDraftsEmpty } from './DashboardGuideDraftsEmpty'
import { DashboardGuideDraftsLoading } from './DashboardGuideDraftsLoading'
import { useGetDashboardGuideDraftsQuery } from '../../useGetDashboardGuideDraftsQuery'

export const DashboardGuideDrafts = () => {
  const { guideDraftsData, hasError, isLoading } =
    useGetDashboardGuideDraftsQuery()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const onModalOpen = () => setIsModalOpen(true)

  const onModalClose = () => setIsModalOpen(false)

  if (isLoading) return <DashboardGuideDraftsLoading />

  if (hasError || isEmpty(guideDraftsData)) return <DashboardGuideDraftsEmpty />

  return (
    <>
      <section className='space-y-6'>
        <header>
          <Button
            aria-label='Create a guide'
            size='small'
            startIcon='add'
            variant='text'
            onClick={onModalOpen}
          >
            New Guide
          </Button>
        </header>
        <div className='space-y-6'>
          {guideDraftsData?.map((guideDraft, index) => (
            <DashboardGuideDraftCard
              key={index}
              guideDraft={guideDraft}
              href={getGuideDraftDetailsUrl({
                id: guideDraft?.id,
                isUserOwner: true,
                name: guideDraft?.name,
              })}
              isUserOwner={true}
            />
          ))}
        </div>
      </section>
      {isModalOpen && <GuideDraftCreateModalOld onDismiss={onModalClose} />}
    </>
  )
}
