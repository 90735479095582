import { Divider, Icon } from '@travelpass/design-system'
import { Link } from 'react-router-dom'
import type { GetTripDetailsQueryInTripsQuery } from 'src/__generated__/graphql'
import { PageLayoutContainer } from 'src/common/components'
import { tripsPath } from 'src/constants'
import { constructAddress } from 'src/utils'
import { TripTopNavMenu } from './TripTopNavMenu'
import { constructTripDetailsDates } from '../../utils'

interface TripTopNavProps {
  tripDetailsData: GetTripDetailsQueryInTripsQuery['getTrip']
}

export const TripTopNav = ({ tripDetailsData }: TripTopNavProps) => {
  const { endDate, name, startDate, timeZone, tripPreference } =
    tripDetailsData ?? {}
  const { city, state } = tripPreference?.addresses?.[0] ?? {}
  const dates = constructTripDetailsDates({
    arrival: startDate,
    departure: endDate,
    timezone: timeZone,
  })
  const location = constructAddress({
    addressFirstLine: '',
    city,
    state,
    hideCountry: true,
    hideZipcode: true,
  })

  return (
    <div className='lg:z-4 bg-white lg:sticky lg:top-0'>
      <PageLayoutContainer size='none'>
        <div className='p-y-5 lg:p-y-3.5 flex flex-row items-center gap-8'>
          <div className='flex min-w-0 grow flex-row gap-4 lg:items-center lg:gap-3.5'>
            <Link to={tripsPath}>
              <img
                alt='logo'
                className='h-7 w-7 lg:h-8 lg:w-8'
                src='/favicon.ico'
              />
            </Link>
            <div className='flex grow flex-col gap-2 lg:flex-row lg:gap-6'>
              <span className='type-h3 line-clamp-1'>{name}</span>
              <div className='flex flex-row items-center gap-6'>
                {dates && (
                  <p className='color-grey-800 type-body-1-desktop type-body-2-mobile flex flex-row items-center gap-1'>
                    <Icon name='eventNote' size='small' />
                    <span className='text-nowrap'>{dates}</span>
                  </p>
                )}
                {location && (
                  <p className='color-grey-800 type-body-1-desktop type-body-2-mobile flex flex-row items-center gap-1'>
                    <Icon name='placeOutline' size='small' />
                    <span className='text-nowrap'>{location}</span>
                  </p>
                )}
              </div>
            </div>
          </div>
          <TripTopNavMenu tripDetailsData={tripDetailsData} />
        </div>
      </PageLayoutContainer>
      <Divider className='bg-grey-200 h-1px w-full' />
    </div>
  )
}
