import { Avatar } from '@travelpass/design-system'
import defaultBanner from 'src/assets/images/temp-profile-banner-2.png'

interface AvatarContainerProps {
  avatarURL?: string
  bannerImage?: string
  showBannerImage?: boolean
}

export const AvatarContainer = ({
  bannerImage,
  avatarURL,
  showBannerImage,
}: AvatarContainerProps) => {
  return (
    <>
      {showBannerImage ? (
        <div
          className='h-2xl rounded-t-5 max-h-180px mb-25 flex w-full items-center justify-center pt-40'
          style={{
            backgroundImage: `url(${bannerImage || defaultBanner})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <Avatar size='lg' src={avatarURL} />
        </div>
      ) : (
        <Avatar size='lg' src={avatarURL} />
      )}
    </>
  )
}
