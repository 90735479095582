import { useState, useEffect } from 'react'
import debounce from 'lodash.debounce'
import { GuideSessionStorage } from './guideConstants'

type GuideDraftGeocoderConfig = {
  bounds: google.maps.LatLngBoundsLiteral
  location: google.maps.LatLngLiteral
}

const geocoderConfigEventName = 'guideDraftGeocoderConfig'

const debouncedDispatchEvent = debounce((eventName: string) => {
  window.dispatchEvent(new Event(eventName))
}, 50)

const getGuideDraftGeocoderConfig = (): GuideDraftGeocoderConfig => {
  const updatedGeocoderConfig = {
    bounds: null,
    location: null,
  }

  try {
    updatedGeocoderConfig.bounds = JSON.parse(
      sessionStorage.getItem(GuideSessionStorage.geocoderBounds) ?? '{}'
    )
    updatedGeocoderConfig.location = JSON.parse(
      sessionStorage.getItem(GuideSessionStorage.geocoderLocation) ?? '{}'
    )
  } catch (error) {
    console.error(error)
  }

  return updatedGeocoderConfig
}

const onGuideSessionStorageGeocoderChange = ({
  bounds,
  location,
}: {
  bounds: google.maps.LatLngBounds
  location: google.maps.LatLng
}) => {
  try {
    sessionStorage.setItem(
      GuideSessionStorage.geocoderBounds,
      JSON.stringify(bounds.toJSON())
    )
    sessionStorage.setItem(
      GuideSessionStorage.geocoderLocation,
      JSON.stringify(location.toJSON())
    )
    debouncedDispatchEvent(geocoderConfigEventName)
  } catch (error) {
    console.error(error)
  }
}

const useGuideSessionStorageGeocoder = () => {
  const [geocoderConfig, setGeocoderConfig] =
    useState<GuideDraftGeocoderConfig>({
      bounds: null,
      location: null,
    })

  useEffect(() => {
    const geocoderConfigListener = () =>
      setGeocoderConfig(getGuideDraftGeocoderConfig())

    window.addEventListener(geocoderConfigEventName, geocoderConfigListener)

    return () => {
      window.removeEventListener(
        geocoderConfigEventName,
        geocoderConfigListener
      )
    }
  }, [])

  return geocoderConfig
}

export { onGuideSessionStorageGeocoderChange, useGuideSessionStorageGeocoder }
