import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'
import type {
  BudgetEnum,
  LocationSelectionInput,
  PartyTypeEnum,
} from 'src/__generated__/graphql'
import { SEARCH_PREFRENCES_FRAGMENT } from '../searchPreferencesFragment'

interface UpsertSearchPreferencesInput {
  partyType?: PartyTypeEnum
  budget?: BudgetEnum
  locationSelections?: LocationSelectionInput[]
  hotelStarRating?: number
  amenitySelections?: { id: string }[]
  brandSelections?: String[]
  vibeSelections?: String[]
  experienceSelections?: { id: string }[]
}

const UPSERT_SEARCH_PREFERENCES = gql(`
  mutation UpsertSearchPreferences($input: UpsertSearchPreferencesInput!) {
    upsertSearchPreferences(input: $input) {
      ...SearchPreferencesFields
    }
  }
`)

export const useUpsertSearchPreferences = ({
  partyType,
  budget,
  locationSelections,
  hotelStarRating,
  amenitySelections,
  brandSelections,
  vibeSelections,
  experienceSelections,
}: UpsertSearchPreferencesInput) => {
  // Construct an initial object with all possible fields
  const allFields = {
    partyType,
    budget,
    locationSelections,
    hotelStarRating,
    amenitySelections,
    brandSelections,
    vibeSelections,
    experienceSelections,
  }

  // Filter out fields that are undefined
  const filteredFields = Object.entries(allFields).reduce(
    (acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = value // Include the field if its value is not undefined
      }
      return acc
    },
    {}
  )

  // Use the filtered fields in the mutation
  return useMutation(UPSERT_SEARCH_PREFERENCES, {
    variables: {
      input: filteredFields,
    },
  })
}
