import { useState } from 'react'
import { Button } from '@travelpass/design-system'
import type {
  ButtonSize,
  ButtonVariant,
} from '@travelpass/design-system/dist/src/components/Button/buttonConstants'
import debounce from 'lodash.debounce'
import { CollectionItemIdType } from 'src/__generated__/graphql'
import { AddToCollectionsModalListener } from 'src/common/components/Collections/AddToCollectionsModalListener'
import { dispatchToggleAddToCollectionsModal } from 'src/common/components/Collections/dispatchToggleAddToCollectionsModal'
import { useFirebaseUser } from 'src/common/hooks/useFirebaseUser'
import { SignInModal } from 'src/pages/signin'
import { getGuideMetricString } from './'
import type { GuideData } from '../../details/types'

interface GuideMetricSaveProps {
  guideData: Pick<GuideData, 'collectedCount' | 'id' | 'isCollected' | 'name'>
  hideLabel?: boolean
  isDisabled?: boolean
  size?: ButtonSize
  variant?: ButtonVariant
}

export const GuideMetricSave = ({
  guideData,
  hideLabel = false,
  isDisabled = false,
  size = 'medium',
  variant = 'text',
}: GuideMetricSaveProps) => {
  const { isAnonymous } = useFirebaseUser()
  const [showSignModal, setShowSignModal] = useState(false)
  const { collectedCount, id, isCollected, name } = guideData ?? {}

  const onSaveGuide = () => {
    if (isAnonymous) setShowSignModal(true)
    else {
      dispatchToggleAddToCollectionsModal({
        isModalOpen: true,
        item: { id, name, type: CollectionItemIdType.Guide },
      })
    }
  }

  return (
    <>
      <Button
        aria-label='Add this Guide to your collection'
        aria-pressed={isCollected}
        isDisabled={isDisabled}
        size={size}
        startIcon={isDisabled || !isCollected ? 'bookmarkBorder' : 'bookmark'}
        variant={variant}
        onClick={debounce(onSaveGuide, 100)}
      >
        {getGuideMetricString(collectedCount, hideLabel ? '' : 'save')}
      </Button>
      {showSignModal && <SignInModal onClose={() => setShowSignModal(false)} />}
      <AddToCollectionsModalListener />
    </>
  )
}
