import { useState } from 'react'
import { Button } from '@travelpass/design-system'
import { AvatarContainer } from './AvatarContainer'
import { UploadAvatarModal } from '../components/UploadAvatarModal/UploadAvatarModal'
import { useCheckEditingMode } from '../components/hooks/useCheckEditingMode'

interface ProfileAvatarAndHandleProps {
  accountHandle?: string
  displayName?: string
  profileImageUrl?: string
  hideEditButton?: boolean
  bannerImage?: string
}

export const ProfileAvatarAndHandle = ({
  accountHandle,
  displayName,
  profileImageUrl,
  bannerImage,
  hideEditButton = false,
}: ProfileAvatarAndHandleProps) => {
  const { isEditingMode } = useCheckEditingMode()
  const [modalOpen, setModalOpen] = useState(false)
  const showBannerImage = isEditingMode && hideEditButton

  return (
    <div
      className={`flex flex-col items-center justify-center ${showBannerImage ? 'rounded-t-5 bg-white' : 'sm:bg-transparent'}`}
    >
      <AvatarContainer
        avatarURL={profileImageUrl}
        bannerImage={bannerImage}
        showBannerImage={showBannerImage}
      />
      <div>
        {(accountHandle || displayName) && (
          <>
            <p className='type-h3 max-w-80 text-wrap break-words text-center'>
              {`@${accountHandle}`}
            </p>
            <p className='type-body-1 text-caption-1 break-all text-center'>
              {displayName}
            </p>
          </>
        )}
        {isEditingMode && !hideEditButton && (
          <Button
            label='Edit photo'
            size='small'
            startIcon='modeEdit'
            variant='text'
            onClick={() => setModalOpen(true)}
          />
        )}
        {modalOpen && (
          <UploadAvatarModal
            avatar={profileImageUrl}
            onClose={() => setModalOpen(false)}
          />
        )}
      </div>
    </div>
  )
}
