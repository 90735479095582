import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'

export const GET_CURRENT_USER_COMPETITION_INFO = gql(`
  query CurrentUserCompetitionInfo {
    currentUser {
      id
      userProfile {
        id
        competitionInfo {
          id
          enteredCompetition
          isEligible
        }
      }
    }
  }
`)

export const useGetCurrentUserCompetitionInfo = () =>
  useQuery(GET_CURRENT_USER_COMPETITION_INFO)
