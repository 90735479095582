import { useRef, useState } from 'react'
import {
  Button,
  Divider,
  IconButton,
  MenuItem,
} from '@travelpass/design-system'
import { useLocation, useNavigate } from 'react-router-dom'
import { Popover } from 'react-tiny-popover'
import {
  GuideDraftStatus,
  type GetTripDetailsQueryInTripsQuery,
} from 'src/__generated__/graphql'
import {
  exploreSegment,
  overviewSegment,
  timelineSegment,
  tripExplorePath,
  tripOverviewPath,
  tripTimelinePath,
  tripsPath,
} from 'src/constants'
import { GuideDraftPublishFromTrip } from 'src/pages/guides/lists-old/common'
import { TripDeleteModal, TripEditModal } from 'src/pages/trips/components'
import { getGuideDraftDetailsUrl } from 'src/utils'

interface TripTopNavMenuProps {
  tripDetailsData: GetTripDetailsQueryInTripsQuery['getTrip']
}

export const TripTopNavMenu = ({ tripDetailsData }: TripTopNavMenuProps) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const ref = useRef<HTMLDivElement>(null)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const [isEditTripModalOpen, setIsEditTripModalOpen] = useState(false)
  const [isDeleteTripModalOpen, setIsDeleteTripModalOpen] = useState(false)
  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false)
  const { guideDraft, id: tripId, name } = tripDetailsData ?? {}
  const activeTab =
    pathname.split('/trips/')?.[1]?.split(`/${tripId}`)?.[0] ?? ''
  const isGuideDraftActive =
    tripDetailsData?.guideDraft?.status === GuideDraftStatus.Active

  const onDeleteTripClose = () => {
    setIsEditTripModalOpen(true)
    setIsDeleteTripModalOpen(false)
  }

  const onDeleteTripOpen = () => {
    setIsEditTripModalOpen(false)
    setIsDeleteTripModalOpen(true)
  }

  return (
    <>
      <div className='flex flex-row items-center gap-8'>
        <div className='hidden gap-8 md:block'>
          {isGuideDraftActive && (
            <Button
              aria-label='View Guide'
              size='small'
              startIcon='campaign'
              onClick={() =>
                navigate(
                  getGuideDraftDetailsUrl({
                    id: guideDraft?.id ?? '',
                    isUserOwner: true,
                    name: guideDraft?.name ?? '',
                  })
                )
              }
            >
              View Guide
            </Button>
          )}
          {!isGuideDraftActive && (
            <div className='flex flex-row gap-8'>
              <Button
                size='small'
                startIcon='campaign'
                onClick={() => setIsPublishModalOpen(true)}
              >
                Create Guide
              </Button>
              <Divider className='w-1px m-0 min-h-8' />
            </div>
          )}
        </div>
      </div>
      <Popover
        containerClassName='z-5'
        content={
          <div className='shadow-1 w-50 mr-6 mt-1 rounded-xl bg-white p-2'>
            <div>
              <div className='block md:hidden'>
                {isGuideDraftActive && (
                  <MenuItem
                    isHighlighted={activeTab === overviewSegment}
                    label='View Guide'
                    startIcon='campaign'
                    value='View Guide'
                    onClick={() =>
                      navigate(
                        getGuideDraftDetailsUrl({
                          id: guideDraft?.id ?? '',
                          isUserOwner: true,
                          name: guideDraft?.name ?? '',
                        })
                      )
                    }
                  />
                )}
                {!isGuideDraftActive && (
                  <MenuItem
                    isHighlighted={activeTab === overviewSegment}
                    label='Create Guide'
                    startIcon='campaign'
                    value='Create Guide'
                    onClick={() => setIsPublishModalOpen(true)}
                  />
                )}
                <MenuItem
                  isHighlighted={activeTab === overviewSegment}
                  label='Overview'
                  startIcon='luggage'
                  value='Overview'
                  onClick={() => navigate(`${tripOverviewPath}/${tripId}`)}
                />
                <MenuItem
                  isHighlighted={activeTab === timelineSegment}
                  label='Itinerary'
                  startIcon='formatListBulleted'
                  value='Itinerary'
                  onClick={() => navigate(`${tripTimelinePath}/${tripId}`)}
                />
                <MenuItem
                  isHighlighted={activeTab === exploreSegment}
                  label='Explore'
                  startIcon='language'
                  value='Explore'
                  onClick={() => navigate(`${tripExplorePath}/${tripId}`)}
                />
                <Divider />
              </div>
              <MenuItem
                label='Edit Trip'
                startIcon='modeEdit'
                value='Edit Trip'
                onClick={() => {
                  setIsEditTripModalOpen(true)
                  setIsPopoverOpen(false)
                }}
              />
              <MenuItem
                label='Delete Trip'
                startIcon='delete'
                value='Edit Trip'
                onClick={() => {
                  setIsDeleteTripModalOpen(true)
                  setIsPopoverOpen(false)
                }}
              />
              {/** @todo 'Create Guide from Trip' Menu Item goes here */}
            </div>
          </div>
        }
        isOpen={isPopoverOpen}
        positions={['bottom']}
        onClickOutside={() => setIsPopoverOpen(false)}
      >
        <div ref={ref}>
          <div className='block lg:hidden'>
            <IconButton
              aria-label='Trip menu'
              color='transparent'
              icon='menuFilled'
              onClick={() => setIsPopoverOpen(!isPopoverOpen)}
            />
          </div>
          <div className='hidden lg:block'>
            <IconButton
              aria-label='Trip menu'
              icon='moreVert'
              onClick={() => setIsPopoverOpen(!isPopoverOpen)}
            />
          </div>
        </div>
      </Popover>
      {isDeleteTripModalOpen && (
        <TripDeleteModal
          tripId={tripId}
          tripName={name}
          onClose={onDeleteTripClose}
          onDelete={() => navigate(tripsPath)}
        />
      )}
      {isEditTripModalOpen && (
        <TripEditModal
          tripId={tripId}
          onClose={() => setIsEditTripModalOpen(false)}
          onDeleteOpen={onDeleteTripOpen}
        />
      )}
      {isPublishModalOpen && (
        <GuideDraftPublishFromTrip
          tripDetailsData={tripDetailsData}
          onDismiss={() => setIsPublishModalOpen(false)}
        />
      )}
    </>
  )
}
