import { useState } from 'react'
import { IconButton, Input } from '@travelpass/design-system'
import { useFormContext } from 'react-hook-form'
import type { ProfileFields } from 'src/pages/profile/types'

export const LinkVisualizer = ({ links, index, title, url }) => {
  //State
  const [titleValue, setTitle] = useState(title)
  const [urlValue, setUrl] = useState(url)
  //Hooks
  const { setValue } = useFormContext<ProfileFields>()

  const onDelete = (linkIndex: number) => {
    let updatedLinks = links.filter((_, i) => i !== linkIndex)
    setValue('links', JSON.stringify(updatedLinks))
  }

  const onBlur = () => {
    if (title !== titleValue || url !== urlValue) {
      links[index] = { title: titleValue, url: urlValue }
      setValue('links', JSON.stringify([...links]))
    }
  }

  return (
    <div className='flex place-content-between gap-3'>
      <Input
        fullWidth
        aria-label='Link title'
        value={titleValue}
        onBlur={onBlur}
        onChange={e => setTitle(e.target.value)}
      />
      <Input
        fullWidth
        aria-label='Link URL'
        value={urlValue}
        onBlur={onBlur}
        onChange={e => setUrl(e.target.value)}
      />
      <IconButton
        aria-label='Remove link'
        icon='deleteOutline'
        onClick={() => onDelete(index)}
      />
    </div>
  )
}
