import { Button, Input, useSnackbar } from '@travelpass/design-system'
import type { RegisterOptions } from 'react-hook-form'
import { FormProvider, useForm } from 'react-hook-form'
import { useFirebaseUser } from 'src/common/hooks/useFirebaseUser'
import {
  firebaseUpdatePassword,
  firebaseVerifiedCurrentPassword,
} from 'src/config/firebase/firebaseUtils'

type Inputs = {
  currentPassword: string
  newPassword: string
  confirmPassword: string
}

interface PasswordFormProps {
  onClose(): void
}

export const PasswordForm = ({ onClose }: PasswordFormProps) => {
  const { addSuccessSnack, addErrorSnack } = useSnackbar()
  const { email } = useFirebaseUser()
  const methods = useForm<Inputs>({
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
  })
  const { handleSubmit, register, formState, getValues, setError } = methods

  const options: RegisterOptions = {
    required: 'This field is required',
    minLength: {
      value: 6,
      message: 'Password must be at least 6 characters in length.',
    },
  }

  const validate = () => {
    const { currentPassword, newPassword, confirmPassword } = getValues()
    if (currentPassword == newPassword) {
      setError('newPassword', {
        message: 'Add a password different from your current one',
      })
      return false
    }

    if (newPassword !== confirmPassword) {
      const message = "Passwords don't match"

      setError('newPassword', { message })
      setError('confirmPassword', { message })
      return false
    }

    return true
  }

  const onSave = async (data: Inputs) => {
    try {
      await verifyCurrentPassword(data.currentPassword)
      await firebaseUpdatePassword(data.newPassword)
      addSuccessSnack({ title: 'Password updated!' })
      onClose()
    } catch (error) {
      addErrorSnack({
        title: error.message
          ? // (?<=Firebase:\s) is positive lookbehind to match only the portion after the 'Firebase: ' prefix
            error.message.match(/(?<=Firebase:\s)[\w+\s+]+/)
          : error || 'There was an error updating your password',
      })
    }
  }

  // List of all firebase auth errors: https://firebase.google.com/docs/reference/js/auth#autherrorcodes
  const verifyCurrentPassword = async (password: string) => {
    const res = await firebaseVerifiedCurrentPassword(email, password)
    if (res === 'success') return Promise.resolve()
    if (res === 'auth/wrong-password' || res === 'auth/user-mismatch')
      return Promise.reject('Wrong Email or password')
    if (res === 'auth/weak-password')
      return Promise.reject('Password should be at least 6 characters')
    if (res === 'auth/too-many-requests')
      return Promise.reject('Too many requests. Please come back later')
  }

  return (
    <FormProvider {...methods}>
      <form
        className='lg:w-50% flex flex-col gap-y-5'
        onSubmit={handleSubmit(onSave)}
      >
        <p className='type-body-1-desktop'>Password</p>
        <Input
          fullWidth
          errorText={formState?.errors?.currentPassword?.message}
          label='Current Password'
          placeholder='Current Password'
          type='password'
          {...register('currentPassword', { ...options, validate })}
        />
        <Input
          fullWidth
          errorText={formState?.errors?.newPassword?.message}
          label='New Password'
          placeholder='New Password'
          type='password'
          {...register('newPassword', { ...options, validate })}
        />
        <Input
          fullWidth
          errorText={formState?.errors?.confirmPassword?.message}
          label='Confirm Password'
          placeholder='Confirm Password'
          type='password'
          {...register('confirmPassword', { ...options, validate })}
        />
        <div className='lg:w-50% flex w-full gap-x-5'>
          <Button
            fullWidth
            label='cancel'
            variant='outlined'
            onClick={onClose}
          />
          <Button fullWidth label='save' type='submit' />
        </div>
      </form>
    </FormProvider>
  )
}
