import { Skeleton } from '@travelpass/design-system'
import { DashboardFeedCardLoading } from './DashboardFeedCardLoading'

export const DashboardFeedLoading = () => (
  <div className='space-y-6'>
    <div className='h-5.25 w-24 md:h-6'>
      <Skeleton />
    </div>
    <div className='space-y-6'>
      <DashboardFeedCardLoading />
      <DashboardFeedCardLoading />
      <DashboardFeedCardLoading />
    </div>
  </div>
)
