import { Icon } from '@travelpass/design-system'

interface GuideGeocoderOptionIconProps {
  placeId: string
  types: string[]
}

export const GuideGeocoderOptionIcon = ({
  placeId,
  types,
}: GuideGeocoderOptionIconProps) => {
  if (placeId === 'SEARCH')
    return (
      <span className='c-forest-light'>
        <Icon name='search' />
      </span>
    )

  if (types.includes('airport'))
    return (
      <span className='c-black'>
        <Icon name='airplaneModeActive' />
      </span>
    )

  if (types.includes('lodging'))
    return (
      <span className='c-black'>
        <Icon name='business' />
      </span>
    )

  return (
    <span className='c-black'>
      <Icon name='place' />
    </span>
  )
}
