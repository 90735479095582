import { useEffect } from 'react'
import isEmpty from 'lodash.isempty'
import { useParams } from 'react-router-dom'
import { type PublishedEvent } from 'src/__generated__/graphql'
import { AddToCollectionsModalListener } from 'src/common/components/Collections/AddToCollectionsModalListener'
import { AddToGuideModalListener } from 'src/common/components/Guides/AddToGuideModalListener'
import { useFlag } from 'src/common/hooks'
import { encodeGuideId } from 'src/utils'
import { GuideEventsEmpty } from './GuideEventsEmpty'
import { GuideEventsOldLoading } from './GuideEventsOldLoading'
import { getGuideEventMarkersOld } from '../../guideUtils'
import type { GuideOwner } from '../../types'
import { useGetGuideEventsOldQuery } from '../../useGetGuideEventsOldQuery'
import { useGuideSessionStorageIds } from '../../useGuideSessionStorageIds'
import { GuideEventCard } from '../GuideEventCard'
import { GuideSectionOld } from '../GuideSectionOld'

interface GuideEventsOldProps {
  eventCount: number
  onPointsChange: (updatedPoints: PublishedEvent[]) => void
  owner: GuideOwner
}

export const GuideEventsOld = ({
  eventCount,
  onPointsChange,
  owner,
}: GuideEventsOldProps) => {
  const isGuideUrlShortEnabled = useFlag('guideURLShort')
  const { hoverId, selectedId } = useGuideSessionStorageIds()
  const { guideId } = useParams()
  const guideIdEncoded = encodeGuideId({
    guideId,
    isGuideDraft: false,
  })
  const { guideEventsData, hasError, isLoading } = useGetGuideEventsOldQuery(
    isGuideUrlShortEnabled ? guideIdEncoded : guideId
  )
  const { groupedEvents } = guideEventsData ?? {}

  useEffect(() => {
    if (!isEmpty(groupedEvents) && !isLoading) {
      const updatedPoints = getGuideEventMarkersOld(groupedEvents)
      onPointsChange(updatedPoints)
    }
  }, [groupedEvents, isLoading])

  if (isLoading) return <GuideEventsOldLoading />

  return (
    <div className='space-y-6'>
      {groupedEvents?.map(({ description, id, events, name }) => {
        if (isEmpty(events)) return

        if (!id)
          return (
            <div key='uncategorized' className='space-y-3 md:space-y-5'>
              {events?.map((event, eventIndex) => (
                <GuideEventCard
                  key={`${id}-${eventIndex}`}
                  guideEventCardData={event}
                  isHovered={event?.id === hoverId}
                  isSelected={event?.id === selectedId}
                  owner={owner}
                />
              ))}
            </div>
          )

        return (
          <GuideSectionOld key={id} description={description} title={name}>
            {events?.map((event, eventIndex) => (
              <GuideEventCard
                key={`${id}-${eventIndex}`}
                guideEventCardData={event}
                isHovered={event?.id === hoverId}
                isSelected={event?.id === selectedId}
                owner={owner}
              />
            ))}
          </GuideSectionOld>
        )
      })}
      {(hasError || !eventCount) && <GuideEventsEmpty />}
      <AddToCollectionsModalListener />
      <AddToGuideModalListener />
    </div>
  )
}
