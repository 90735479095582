import type { ApolloError } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import { type GetCurrentUserQueryInDashboardQuery } from 'src/__generated__/graphql'
import { getDashboardFeedItem } from './dashboardUtils'
import type { DashboardFeedItem } from './types'

/** @todo remove activityFeed when dashboardV2HideActivityFeed is enabled */
export const dashboardCurrentUserQuery = gql(`
  query GetCurrentUserQueryInDashboardOld {
    currentUser {
      id
      activityFeed(feedCount: 5) {
        title
        follower {
          accountHandle
          displayName
          id
          followCount {
            countOfFollowed
            countOfFollowing
          }
          profileImageUrl
        }
        guide {
          id
          description
          guideType
          insertedAt
          name
          ownerProfile {
            id
            displayName
            profileImageUrl
          }
          timeZone
        }
        hotelRecommendation {
          id
          hotelInfo {
            city
            description
            name
            state
          }
          name
          notes
          standardHotelId
          userProfile {
            id
            accountHandle
            displayName
            profileImageUrl
          }
        }
        likedListGuide {
          guide {
            id
            addresses {
              id
              city
              state
            }
            description
            guideType
            name
          }
          likerProfile {
            id
            accountHandle
            displayName
            profileImageUrl
          }
        }
      }
      firstName
      userProfile {
        id
        accountHandle
        activeBannerImage {
          id
          extraSmallUrl
          largeUrl
          mediumUrl
          smallUrl
        }
        displayName
        followCount {
          countOfFollowed
          countOfFollowing
        }
        guideCount(guideStatus: PUBLISHED)
        isUserOwner
        profileCompletion {
          completionPercentage
          hasBio
          hasProfileImage
          hasSocialLinks
          hasTravelStats
          hasTravelStyle
        }
        profileImageSource
        profileImageUrl
        userId
      }
    }
  }
`)

export type UseGetDashboardQuery = {
  activityFeed: DashboardFeedItem[]
  dashboardData: GetCurrentUserQueryInDashboardQuery
  hasError: ApolloError
  isLoading: boolean
}

/** @todo waiting on BE to return follower feed */
export const useGetDashboardQuery = (): UseGetDashboardQuery => {
  const {
    data,
    error: hasError,
    loading: isLoading,
  } = useQuery(dashboardCurrentUserQuery)
  const dashboardData = data ?? null
  const activityFeed =
    data?.currentUser?.activityFeed?.map(feedItem =>
      getDashboardFeedItem(feedItem)
    ) ?? []

  return {
    activityFeed,
    dashboardData,
    hasError,
    isLoading,
  }
}
