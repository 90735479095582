import { Tile } from '@travelpass/design-system'
import { useFormContext } from 'react-hook-form'
import { useGetBannerImages } from 'src/pages/profile/components/hooks/useGetBannerImages'
import type { ProfileFields } from 'src/pages/profile/types'

export const BannerImages = () => {
  const { setValue, watch } = useFormContext<ProfileFields>()
  const { data } = useGetBannerImages()
  const bannerImages = data?.getBannerImages || []
  const activeBannerImageId = watch('activeBannerImageId')
  const isActive = (imageId: string) => activeBannerImageId === imageId

  return (
    <>
      {bannerImages.map((image, index) => (
        <Tile
          key={`banner-${index}`}
          pressed={isActive(image.id)}
          onClick={_e => {
            setValue('activeBannerImageId', image.id)
            setValue('activeBannerImage', image)
          }}
        >
          <img
            alt={`banner-${index}`}
            className='rounded-8px max-h-48 w-full object-cover'
            src={image.extraSmallUrl}
          />
        </Tile>
      ))}
    </>
  )
}
