import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const DELETE_USER_MUTATION = gql(`
  mutation DeleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      user {
        id
        isDeleted
      }
    }
  }
`)

export const useDeleteUserMutation = () => useMutation(DELETE_USER_MUTATION)
