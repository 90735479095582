import { useState, type ReactNode } from 'react'
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
} from '@reach/accordion'
import { Divider, Icon } from '@travelpass/design-system'

interface GuideSectionOldProps {
  children: ReactNode
  description?: string
  title: string
}

export const GuideSectionOld = ({
  children,
  description,
  title,
}: GuideSectionOldProps) => {
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <Accordion
      index={Number(isExpanded)}
      onChange={() => setIsExpanded(previousIsExpanded => !previousIsExpanded)}
    >
      <AccordionItem className='space-y-3 md:space-y-4'>
        {/** @todo look into tabbing/outline */}
        <AccordionButton className='b-none w-full cursor-pointer space-y-3 bg-transparent p-0 text-left'>
          <div className='flex flex-row items-center justify-between'>
            <h3 className='c-forest-light type-h4-mobile lg:type-h3-desktop'>
              {title}
            </h3>
            <div className='c-valley'>
              <Icon name={isExpanded ? 'arrowUpIos' : 'arrowDownIos'} />
            </div>
          </div>
          {description && (
            <p className='c-grey-800 m-t-3 type-body-2-desktop'>
              {description}
            </p>
          )}
        </AccordionButton>
        <div className='hidden md:block'>
          <Divider />
        </div>
        <AccordionPanel>
          <div className='relative space-y-3 md:space-y-5'>{children}</div>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}
