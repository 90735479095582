import { useRef } from 'react'
import { StopPropagation } from '@travelpass/design-system'
import classNames from 'classnames'
import type { Collection } from 'src/__generated__/graphql'
import { ImagePanel } from 'src/common/components'
import {
  collectionsPath,
  defaultCollectionImage,
  defaultLocationImage,
} from 'src/constants'
import { formatImageLinkForProductCard } from 'src/utils'
import { CollectionMenu } from './CollectionMenu'

interface CollectionCardProps {
  collection: Collection
  openFormModal(collection?: Collection): void
  openDeleteModal(collection?: Collection): void
}

export const CollectionCard = ({
  collection,
  openFormModal,
  openDeleteModal,
}: CollectionCardProps) => {
  const anchorRef = useRef<HTMLAnchorElement>(null)

  const { isDefault, name, id } = collection
  const collectedItemsCount = collection?.collectedItems?.totalCount || 0
  const previewImage = formatImageLinkForProductCard(
    collection?.previewImages[0] || defaultCollectionImage,
    '640x400',
    '200'
  )

  const showImagePanel = isDefault && collection?.previewImages?.length > 3
  const panelImages = showImagePanel
    ? collection?.previewImages?.map((url, index) => ({
        url: formatImageLinkForProductCard(
          url || defaultLocationImage,
          '320x200',
          '200'
        ),
        alt: `${name} - ${index}`,
      }))
    : []

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={classNames('flex cursor-pointer flex-col')}
      onClick={() => {
        anchorRef?.current?.click?.()
      }}
    >
      <div className='h-50 bg-grey-100 relative flex rounded-xl'>
        {!isDefault && (
          <StopPropagation>
            <div className='z-3 absolute right-2 top-2'>
              <CollectionMenu
                onDelete={() => openDeleteModal(collection)}
                onEdit={() => openFormModal(collection)}
              />
            </div>
          </StopPropagation>
        )}
        {showImagePanel ? (
          <ImagePanel images={panelImages} />
        ) : (
          <img
            alt={name}
            className={`relative inset-0 w-full rounded-xl ${
              previewImage === defaultCollectionImage
                ? 'object-contain'
                : 'object-cover'
            }`}
            src={previewImage}
          />
        )}
      </div>
      <a
        className='mt-5 pl-3 no-underline'
        href={`${collectionsPath}/${id}`}
        ref={anchorRef}
        rel='noreferrer'
        target='_blank'
      >
        <div className='type-h6 line-clamp-1'>{name}</div>
      </a>
      <div className='type-small-text-desktop c-grey-800 mb-4 pl-3'>
        {collectedItemsCount} saved items
      </div>
    </div>
  )
}
