import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import { LanguageTag } from 'src/__generated__/graphql'

const searchExperienceTagsGql = gql(`
query SearchExperienceTagsQuery($tagSearchRequest: TagSearchRequest!, $first: Int, $language: LanguageTag!) {
    searchTags(tagSearchRequest: $tagSearchRequest, first: $first) {
      edges {
        node {
          name(language: $language)
          tagId
        }
      }
    }
  }
`)

export const useExperienceTagQuery = (name: string) => {
  const { data, error, loading } = useQuery(searchExperienceTagsGql, {
    notifyOnNetworkStatusChange: true,
    variables: {
      tagSearchRequest: {
        name,
      },
      first: 10,
      language: LanguageTag.En,
    },
  })

  const { edges } = data?.searchTags ?? {}
  const resultsData = edges ?? []

  return {
    resultsData,
    hasError: !!error,
    loading,
  }
}
