import { Button } from '@travelpass/design-system'

interface SaveButtonFooterProps {
  onCancel(): void
}

export const SaveButtonFooter = ({ onCancel }: SaveButtonFooterProps) => {
  return (
    <div className='shadow-2 lg:left-50% lg:w-50% fixed bottom-0 flex justify-center gap-3 bg-white py-6 sm:left-0 sm:w-full'>
      <div className='w-40% md:hidden'>
        <Button
          fullWidth
          label='CANCEL'
          variant='outlined'
          onClick={onCancel}
        />
      </div>
      <div className='w-40% md:w-auto'>
        <Button fullWidth label='save profile' type='submit' />
      </div>
    </div>
  )
}
