import { useRef } from 'react'
import { StopPropagation } from '@travelpass/design-system'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { type GetGuideDraftsForUserQueryInDashboardOldQuery } from 'src/__generated__/graphql'
import { defaultLocationImage } from 'src/constants'
import { getGuideAddress } from 'src/utils'
import { DashboardGuideDraftCardMetrics } from './DashboardGuideDraftCardMetrics'
import { DashboardGuideDraftCardPopoverMenu } from './DashboardGuideDraftCardPopoverMenu'
import { FallbackImage } from '../../../profile/content-section/common/FallbackImage'
import { FormattedLocation } from '../../../profile/content-section/common/FormattedLocation'

const GuideImage = ({ imageUrl }) => (
  <FallbackImage
    alt='City'
    className='block h-full w-full object-cover'
    fallbackSrc={defaultLocationImage}
    src={imageUrl}
  />
)

export const DashboardGuideDraftCard = ({
  guideDraft,
  href,
  isUserOwner,
}: {
  guideDraft: GetGuideDraftsForUserQueryInDashboardOldQuery['getGuideDraftsForUser'][0]
  href: string
  isUserOwner: boolean
}) => {
  const anchorRef = useRef<HTMLAnchorElement>(null)
  const { description, images, imageUrl, guide, addresses, name } =
    guideDraft ?? {}
  const { city, country, state } = addresses?.[0] ?? {}
  const image = images?.[0]?.url ?? imageUrl

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        className={classNames(
          'rounded-3 border-grey-300 of-hidden shadow-3 relative border-2 border-solid hover:cursor-pointer',
          /**
           * @desc exposes DOM state for consumers to tap into Card events. eg. `opacity-0 group-hover:opacity-100 group-focus-within:opacity-100`
           * @see https://tailwindcss.com/docs/hover-focus-and-other-states#styling-based-on-parent-state
           */
          'group'
        )}
        onClick={() => anchorRef?.current?.click?.()}
      >
        <div className='relative flex flex-col justify-between gap-4 p-3 md:min-h-52 md:flex-row md:justify-start lg:gap-6'>
          {/* Top (Mobile) Picture */}
          <div className='h-44 w-full overflow-hidden rounded-lg md:hidden'>
            <GuideImage imageUrl={image} />
          </div>
          {/* Lefthand (Desktop) Picture */}
          <div className='rounded-3 max-w-50 min-w-50 hidden overflow-hidden md:block'>
            <GuideImage imageUrl={image} />
          </div>
          <div className='grow space-y-3 lg:space-y-4'>
            <div className='space-y-3'>
              <div className='flex min-w-0 flex-col gap-2'>
                {/* important for SEO! Preserving referrer allows search engine bots to crawl internal links */}
                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                <StopPropagation>
                  <div className='flex flex-row items-center justify-between gap-2'>
                    <Link
                      className='outline-offset-5 focus-visible:outline-emerald type-h3 lg:type-h4 c-forest inline-block no-underline'
                      ref={anchorRef}
                      to={href}
                    >
                      {name}
                    </Link>
                    {isUserOwner && (
                      <DashboardGuideDraftCardPopoverMenu
                        guideDraftData={guideDraft}
                        isUserOwner={isUserOwner}
                      />
                    )}
                  </div>
                </StopPropagation>
                <FormattedLocation
                  address={{
                    fullAddress: getGuideAddress({
                      city,
                      country,
                      state,
                    }),
                  }}
                />
              </div>
              <div className='lg:type-body-2 lg:min-h-13.5 type-body-1 line-clamp-2 min-h-9 lg:line-clamp-3 [&>button]:gap-1'>
                {description}
              </div>
            </div>
            <DashboardGuideDraftCardMetrics guideData={guide} />
          </div>
        </div>
      </div>
    </>
  )
}
