import { useState } from 'react'
import {
  Button,
  Link,
  Modal,
  ModalActions,
  ModalScrollContents,
  useSnackbar,
} from '@travelpass/design-system'
import { privacyPath } from 'src/constants'
import { useUserProfileQuery } from '../../../../common/hooks/useUserProfileQuery'
import { useDeleteUserMutation } from '../../hooks/useDeleteUserAccountMutation'

export const DeleteAccountModal = () => {
  const { data, loading } = useUserProfileQuery()
  const [deleteUser] = useDeleteUserMutation()
  const { addSuccessSnack, addErrorSnack } = useSnackbar()

  const [open, setOpen] = useState(false)
  const openModal = () => setOpen(true)
  const closeModal = () => setOpen(false)

  const handleDeleteAccount = async () => {
    try {
      const result = await deleteUser({
        variables: {
          input: {
            id: data?.currentUser?.id ?? '',
            deleted: true,
          },
        },
      })

      if (result?.data?.deleteUser?.user?.isDeleted) {
        addSuccessSnack({ title: 'Account deletion initiated.' })
        closeModal()
      }
    } catch (e) {
      addErrorSnack({ title: 'Error deleting account. Please try again.' })
    }
  }

  return (
    <>
      <Button
        disabled={loading}
        label='Delete your account'
        variant='error'
        onClick={openModal}
      />
      {open && (
        <Modal
          size='medium'
          title='We are sad to see you leave!'
          onDismiss={closeModal}
        >
          <ModalScrollContents>
            <p className='type-body-1 line-height-relaxed'>
              Your account will be deactivated immediately, and your data will
              be deleted in accordance with applicable law and our{' '}
              <Link href={privacyPath} label='Privacy Policy' target='_blank' />{' '}
              within the next 90 days. A confirmation will then be sent to{' '}
              <strong>{data?.currentUser?.email}</strong> when the deletion
              request is complete. Deleting your account will{' '}
              <strong>NOT</strong> cancel any existing bookings. If you need to
              cancel a booking,{' '}
              <em>please do so before deleting your account.</em>
              <br />
              <br />
              Do you still want to proceed with account&nbsp;deletion?
            </p>
          </ModalScrollContents>
          <ModalActions>
            <Button label='Yes, delete account' onClick={handleDeleteAccount} />
            <Button label='Cancel' variant='outlined' onClick={closeModal} />
          </ModalActions>
        </Modal>
      )}
    </>
  )
}
