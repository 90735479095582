import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { CheckedTile } from '../../common/CheckedTile'
import type { ExtendedUpsertSearchPreferencesInput } from '../ExtendedUpsertInputType'
import { useGetExperienceTypes } from '../hooks/useGetExperienceTypes'

export const QuestionExperiences = () => {
  const { data } = useGetExperienceTypes()
  const experiencesList = data?.getExperienceTypes || []

  const { watch, setValue } =
    useFormContext<ExtendedUpsertSearchPreferencesInput>()

  const experienceSelections = watch('experienceSelections')

  const setBothExperienceLists = ({
    experienceIds,
    experienceNames,
    shouldDirty,
  }) => {
    setValue('experienceSelections', experienceIds, { shouldDirty })
    setValue('experienceSelectionStrings', experienceNames, { shouldDirty })
  }

  useEffect(() => {
    const initialSelections = experiencesList.filter(exp => exp.isSelected)

    // Is the location list not populated yet?
    if (experiencesList.length) {
      const currentSelections = watch('experienceSelections')
      // If the current selections are different from the initial selections, reset the form to initial
      if (
        !currentSelections ||
        JSON.stringify(initialSelections) !== JSON.stringify(currentSelections)
      ) {
        setBothExperienceLists({
          experienceIds: initialSelections.map(exp => exp.id),
          experienceNames: initialSelections.map(exp => exp.displayName),
          shouldDirty: false,
        })
      }
    }
  }, [experiencesList])

  const toggleExperience = (experienceId: string) => {
    const currentlySelectedExperiences =
      experiencesList.filter(exp => experienceSelections.includes(exp.id)) || []

    // Do we currently have this experience selected?
    if (currentlySelectedExperiences.some(item => item.id === experienceId)) {
      // Yes - remove it from the list
      const amendedExperienceList = currentlySelectedExperiences.filter(
        item => item.id !== experienceId
      )

      setBothExperienceLists({
        experienceIds: amendedExperienceList.map(exp => exp.id),
        experienceNames: amendedExperienceList.map(exp => exp.displayName),
        shouldDirty: true,
      })
    } else {
      // No - add it to the list
      setBothExperienceLists({
        experienceIds: [...experienceSelections, experienceId],
        experienceNames: [
          ...currentlySelectedExperiences.map(exp => exp.displayName),
          experiencesList.find(exp => exp.id === experienceId)?.displayName,
        ],
        shouldDirty: true,
      })
    }
  }

  return (
    <div className='flex flex-col items-center'>
      <div className='type-h2 mb-4 self-start'>
        On vacation I look for activities / experiences like...
      </div>
      <div className='type-h5 mb-10 self-start'>
        Select all the activity types that interest you.
      </div>
      <div className='grid grid-cols-1 gap-10 lg:grid-cols-5'>
        {experiencesList.map((experience, index) => (
          <div key={index} className='flex flex-col items-center justify-start'>
            <CheckedTile
              pressed={experienceSelections?.includes(experience.id)}
              size='unsetHeight'
              onClick={() => toggleExperience(experience.id)}
            >
              <img
                alt={experience.displayName}
                className='max-h-66 max-w-66 m-2 h-auto w-[95%] rounded-md'
                src={experience.imageUrl}
              />
            </CheckedTile>
            <span className='type-h4 mt-2 cursor-default select-none'>
              {experience.displayName}
            </span>
          </div>
        ))}
      </div>
    </div>
  )
}
