import { useState } from 'react'
import { Chip, IconButton } from '@travelpass/design-system'
import { GuideStatus } from 'src/__generated__/graphql'
import type { GuideDraftData } from '../../types'
import { GuideDraftEditModal } from '../GuideDraftEditModal'
import { GuideDraftPublish, GuideDraftRepublish } from '../GuideDraftPublish'

interface GuideDraftHeaderProps {
  guideDraftData: GuideDraftData
}

export const GuideDraftHeader = ({ guideDraftData }: GuideDraftHeaderProps) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isManagePublishModalOpen, setIsManagePublishModalOpen] =
    useState(false)
  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false)
  const { description, guide } = guideDraftData ?? {}
  const isGuidePublic = guide?.status === GuideStatus.Published

  return (
    <>
      <header className='space-y-3'>
        <div className='flex flex-row items-center justify-between'>
          <h3 className='color-forest-dark type-h6-desktop flex flex-row items-center gap-1'>
            <span>Guide Description</span>
            <IconButton
              icon='modeEdit'
              size='small'
              onClick={() => setIsEditModalOpen(true)}
            />
          </h3>
          <Chip
            icon={isGuidePublic ? 'visibility' : 'visibilityOff'}
            label={isGuidePublic ? 'Public' : 'Private'}
            onClick={
              guide
                ? () => setIsManagePublishModalOpen(true)
                : () => setIsPublishModalOpen(true)
            }
          />
        </div>
        <p className='color-grey-800 type-body-2-desktop'>
          {description
            ? description
            : 'Add a brief introduction to your Guide or a sneak peek of what to expect.'}
        </p>
      </header>
      {isEditModalOpen && (
        <GuideDraftEditModal
          guideDraftData={guideDraftData}
          onDismiss={() => setIsEditModalOpen(false)}
        />
      )}
      {isPublishModalOpen && (
        <GuideDraftPublish
          guideDraftData={guideDraftData}
          onDismiss={() => setIsPublishModalOpen(false)}
        />
      )}
      {isManagePublishModalOpen && (
        <GuideDraftRepublish
          guideDraftData={guideDraftData}
          onDismiss={() => setIsManagePublishModalOpen(false)}
        />
      )}
    </>
  )
}
