import type { Dispatch, ReactNode, SetStateAction } from 'react'
import { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { useSnackbar } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import { gql } from 'src/__generated__/'
import { CreateAccount } from './CreateAccount'
import type { UpdateUserInfoProps, alternateModal } from './types'

const GetUserByExternalIdGql = gql(`
  query GetUserByExternalId($externalId: String!) {
    getUserByExternalId(externalId: $externalId) {
      id
    }
  }
`)

const updateUserCreateAccount = gql(`
  mutation UpdateUserCreateAccount($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
        email
        firstName
        lastName
        phoneNumber
      }
    }
  }
`)

export const CreateAccountApollo = ({
  createAccountOrSignInMessage,
  errorText,
  onCloseModal,
  onOpenAlternateModal,
  setErrorText,
  setIsCreatingAccount,
}: {
  createAccountOrSignInMessage: ReactNode
  errorText: string
  onCloseModal(): void
  onOpenAlternateModal(modal: alternateModal): void
  setErrorText: Dispatch<SetStateAction<string>>
  setIsCreatingAccount: React.Dispatch<React.SetStateAction<boolean>>
}): JSX.Element => {
  const [isCreatedByEmail, setIsCreatedByEmail] = useState(false)
  const { addSuccessSnack } = useSnackbar()

  const onChangeCreatedByEmail = () => setIsCreatedByEmail(true)

  const [formData, setFormData] = useState<UpdateUserInfoProps>({
    email: null,
    externalId: null,
    firstName: null,
    lastName: null,
    phoneNumber: null,
  })
  const [updateUser] = useMutation(updateUserCreateAccount)

  const { loading: isLoading } = useQuery(GetUserByExternalIdGql, {
    skip: !formData?.externalId,
    variables: { externalId: formData?.externalId },
    onCompleted: data => {
      const id = data?.getUserByExternalId?.id ?? ''
      if (!isEmpty(id)) {
        const { externalId, ...inputData } = formData

        updateUser({
          variables: {
            input: {
              id,
              ...inputData,
            },
          },
        })

        onCloseModal()
        if (isCreatedByEmail) {
          addSuccessSnack({ title: 'Account successfully created' })
        } else {
          addSuccessSnack({ title: 'Successfully signed in' })
        }
      }
    },
  })

  const updateUserInfo = (data: UpdateUserInfoProps) => {
    setFormData(data)
  }

  return (
    <CreateAccount
      createAccountOrSignInMessage={createAccountOrSignInMessage}
      errorText={errorText}
      isLoading={isLoading}
      setErrorText={setErrorText}
      setIsCreatingAccount={setIsCreatingAccount}
      updateUserInfo={updateUserInfo}
      onChangeCreatedByEmail={onChangeCreatedByEmail}
      onCloseModal={onCloseModal}
      onOpenAlternateModal={onOpenAlternateModal}
    />
  )
}
