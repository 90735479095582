import {
  Divider,
  Icon,
  IconButton,
  useSnackbar,
} from '@travelpass/design-system'
import classNames from 'classnames'
import type { HotelRecommendation } from 'src/__generated__/graphql'
import { EventType } from 'src/__generated__/graphql'
import { ResultsCardReviews } from 'src/common/components'
import { constructPopularHotelAmenities } from 'src/utils'
import { RecommendedHotelsNotes } from './RecommendedHotelsNotes'
import { useDeleteHotelRecommendation } from './hooks/useDeleteHotelRecommendation'
import { DefaultRecommendedHotelImage } from '../../../DefaultRecommendedHotelImage'
import { NotProvidedMessage } from '../../../NotProvidedMessage'
import { GET_CURRENT_USER_PROFILE } from '../../../hooks/useCurrentProfile'

interface FavoriteHotelListItemProps {
  hotel: HotelRecommendation
  onDelete?: () => void
}

export const RecommendedHotelListItem = ({
  hotel,
  onDelete,
}: FavoriteHotelListItemProps) => {
  const { addSuccessSnack, addErrorSnack } = useSnackbar()
  const [deleteHotelRecommendation] = useDeleteHotelRecommendation()
  const { id, isKnownHotel, hotelInfo } = hotel
  const {
    name,
    image,
    amenities,
    rating,
    customerReviewScore,
    totalCustomerReviews,
  } = { ...hotelInfo }

  const getAmenities = (amenities: string[], rating: string) => {
    return (
      <div className='mb1'>
        <p className={classNames(amenityCss, 'hidden md:flex')}>
          <Icon name='starBorder' />
          {rating}- star hotel
        </p>
        {constructPopularHotelAmenities(amenities)
          ?.slice(0, 2)
          ?.map(({ icon, label }, index) => (
            <p key={`${label}-${index}`} className={amenityCss}>
              <Icon name={icon} />
              <span>{label}</span>
            </p>
          ))}
      </div>
    )
  }

  const deleteHotel = async () => {
    const response = await deleteHotelRecommendation({
      variables: { id },
      refetchQueries: [
        { query: GET_CURRENT_USER_PROFILE },
        'CurrentUserProfile',
      ],
    })
    if (response.errors) {
      addErrorSnack({
        title:
          'An unknown error happened while trying to delete recommended hotel',
      })
    } else {
      addSuccessSnack({ title: 'Recommended hotel deleted!' })
      onDelete && onDelete()
    }
  }

  return (
    <div className='flex items-center'>
      <div className='w-full'>
        <div className='border-1 border-grey500 rounded-3 my-10 w-full md:flex md:border-solid'>
          {image ? (
            <img
              alt={name}
              className='md:w-206px md:h-160px w-100% rounded-3 mr-2'
              src={image}
            />
          ) : (
            <div className='md:w-35% w-100% rounded-3 mr-2'>
              <DefaultRecommendedHotelImage />
            </div>
          )}
          <div className='flex w-full flex-col pt-2'>
            <p className='type-h6-desktop'>{name}</p>
            {getAmenities(amenities, rating)}
            <ResultsCardReviews
              average={customerReviewScore}
              total={totalCustomerReviews}
              type={EventType.Stay}
            />
            <NotProvidedMessage isKnownHotel={isKnownHotel} />
          </div>
        </div>
        <RecommendedHotelsNotes favoriteHotelId={id} name={name} />
        <Divider />
      </div>
      <IconButton icon='deleteOutline' onClick={deleteHotel} />
    </div>
  )
}

const amenityCss = 'c-grey800 type-body-1 pt1 flex items-center gap-x-2'
