import type { UserProfile } from 'src/__generated__/graphql'
import { DashboardFeedCard } from './DashboardFeedCard'
import type { DashboardFeedItem } from '../../types'

interface DashboardFeedProps {
  activityFeed: DashboardFeedItem[]
}

export const DashboardFeed = ({ activityFeed }: DashboardFeedProps) =>
  !!activityFeed?.length && (
    <div className='space-y-4'>
      <h3 className='c-black type-h3-mobile type-h4-desktop'>Following</h3>
      <div className='space-y-6'>
        {activityFeed?.map((dashboardFeedItem, index) => (
          /** @todo replace index with ID when returned on the BE */
          <DashboardFeedCard
            key={index}
            dashboardFeedItem={dashboardFeedItem}
          />
        ))}
      </div>
    </div>
  )
