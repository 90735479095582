import type { ReactNode } from 'react'
import classNames from 'classnames'

type GuideBadgeSize = 'large' | 'medium' | 'small'

type GuideBadgeVariant = 'dark' | 'light'

interface GuideBadgeProps {
  /** The contents of the badge */
  children: ReactNode
  /** The size of the badge */
  size?: GuideBadgeSize
  /** The color variant of the badge */
  variant?: GuideBadgeVariant
}

/**
 * Badge
 *
 * @summary Badges serve the purpose of displaying concise color-coded metadata, effectively capturing a user's attention.
 *
 * @example what and how to import
 *
 * ```tsx
 * import { GuideBadge } from 'src/pages/guides/details/common'
 * ```
 *
 * @example
 * <GuideBadge size='small'>
 *  <Icon name='star'/>Example
 * </GuideBadge>
 *
 */
const GuideBadge = ({
  children,
  variant = 'light',
  size = 'medium',
}: GuideBadgeProps) => (
  <div
    className={classNames(
      'b-1 b-grey-300 b-solid p-x-2 rounded-2 type-body-1-medium-mobile flex flex-row items-center justify-center gap-1',
      {
        'c-grey-200': variant === 'dark',
        'bg-grey-100 c-forest': variant === 'light',
        'p-y-0.5': size === 'small',
        'p-y-1': size === 'medium',
        'p-y-2': size === 'large',
      }
    )}
    role='presentation'
  >
    {children}
  </div>
)

export { GuideBadge }
