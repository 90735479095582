import { Icon, useSnackbar } from '@travelpass/design-system'
import { PayPalButton } from 'src/common/components/PaymentOptions'
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from 'src/common/components/PaymentOptions/Accordion'
import PayPalMark from 'src/common/components/PaymentOptions/PayPalMark.svg'
import { CardPaymentFields } from './CardPaymentFields'
import { ExperienceTerms } from './ExperienceTerms'
import { useFormContext } from './useExperienceBookingForm'

export const PaymentOptionsAccordion = ({
  amount,
  onClickCancellationPolicyTerm,
}: {
  amount: number
  onClickCancellationPolicyTerm: VoidFunction
}) => {
  const { setValue, resetField, trigger } = useFormContext()
  const { addWarningSnack } = useSnackbar()

  const onApprove = braintreeTokenizedPayment => {
    setValue('braintreePayment', braintreeTokenizedPayment)
  }

  const onCancel = () => {
    resetField('braintreePayment')
    addWarningSnack({
      title:
        'PayPal payment cancelled. Please try again, or select another payment method.',
    })
  }

  const onClick = async (data, actions) => {
    const isGuestInfoValid = await trigger([
      'bookerInfo.firstName',
      'bookerInfo.lastName',
      'communication.email',
      'communication.phone',
    ])
    if (isGuestInfoValid) {
      return actions.resolve()
    } else {
      addWarningSnack({
        title: 'Please review guest information fields for errors.',
      })
      return actions.reject()
    }
  }

  return (
    <Accordion id='experience-booking-payment-options'>
      <AccordionItem>
        <AccordionButton>
          <Icon name='creditCard' size='large' />
          <span className='text-body-1'>Pay with card</span>
        </AccordionButton>
        <AccordionPanel>
          <CardPaymentFields
            terms={
              <ExperienceTerms
                onClickCancellationPolicyTerm={onClickCancellationPolicyTerm}
              />
            }
          />
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionButton aria-label='Pay with Paypal'>
          <img alt='PayPal mark' className='m-1.5 h-5' src={PayPalMark} />
        </AccordionButton>
        <AccordionPanel>
          <ExperienceTerms
            onClickCancellationPolicyTerm={onClickCancellationPolicyTerm}
          />
          <PayPalButton
            amount={amount}
            onApprove={onApprove}
            onCancel={onCancel}
            onClick={onClick}
          />
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}
