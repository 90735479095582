import { useState } from 'react'
import { Button, IconButton } from '@travelpass/design-system'
import { useSearchParams } from 'react-router-dom'
import { GuideDraftSectionAddModal } from './GuideDraftSection'
import { GuideSearchParam } from '../../details'
import { GuideStickyScrollUp } from '../../details/common'

interface GuideDraftStickyActionButtonsProps {
  guideDraftId: string
}

export const GuideDraftStickyActionButtons = ({
  guideDraftId,
}: GuideDraftStickyActionButtonsProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [isSectionAddModalOpen, setIsSectionAddModalOpen] = useState(false)

  const onMobileMapOpen = () => {
    searchParams.set(GuideSearchParam.mapFullView, 'true')
    setSearchParams(searchParams)
  }

  return (
    <>
      <GuideStickyScrollUp className='lg:w-202.25' defaultVisibility={true}>
        <Button
          aria-label='Add new section to the guide'
          size='small'
          startIcon='add'
          onClick={() => setIsSectionAddModalOpen(true)}
        >
          New Section
        </Button>
        <div className='m-a absolute bottom-0 right-6 top-0 flex flex-col justify-center md:hidden'>
          <IconButton
            aria-label='Click to open map'
            color='forestLight'
            icon='mapOutlined'
            outline='round'
            size='small'
            onClick={onMobileMapOpen}
          />
        </div>
      </GuideStickyScrollUp>
      {isSectionAddModalOpen && (
        <GuideDraftSectionAddModal
          guideDraftId={guideDraftId}
          onDismiss={() => setIsSectionAddModalOpen(false)}
        />
      )}
    </>
  )
}
