import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const updateGuideMutation = gql(`
  mutation UpdateGuideMutationInGuide($updateGuideInput: UpdateGuideInput!) {
    updateGuide(updateGuideInput: $updateGuideInput) {
      guide {
        id
        addresses {
          id
          addressLine1
          city
          country
          googlePlaceId
          lat
          long
          state
        }
        description
        guideDraft {
          id
          hasUnpublishedChanges
        }
        images {
          id
          source
          url
        }
        imageUrl
        name
        ownerProfile {
          id
          guides {
            id
          }
        }
        status
        updatedAt
      }
    }
  }
`)

export const useUpdateGuideMutation = () => useMutation(updateGuideMutation)
