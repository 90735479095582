import { Tile } from '@travelpass/design-system'
import './loader.css'
import classNames from 'classnames'

const NumberBubble = ({ number, loading }) => (
  <div
    className={classNames(
      'bg-forest border-grey-600 absolute right-3 top-3 flex max-h-12 min-h-12 min-w-12 max-w-12 transform items-center justify-center rounded-full border-2 border-solid p-2',
      {
        loader: loading,
      }
    )}
  >
    {loading ? (
      <div className='h-full w-full'></div>
    ) : (
      <div className='text-h4 text-white'>{number}</div>
    )}
  </div>
)

export const NumberedTile = ({
  pressed,
  onClick,
  children,
  number,
  loading,
}) => (
  <Tile pressed={pressed} style={{ padding: '.25rem' }} onClick={onClick}>
    <div className='relative flex items-center justify-center rounded-md bg-white'>
      {pressed && <NumberBubble loading={loading} number={number} />}
      {children}
    </div>
  </Tile>
)
