import type {
  BookingHotelDetailsQuery,
  BookingValidateRateQuery,
} from 'src/__generated__/graphql'
import { BookingAgreement } from 'src/common/components'

interface BookingTermsProps {
  bookingHotelData: BookingHotelDetailsQuery
  bookingRateData: BookingValidateRateQuery
}

export const BookingTerms = ({
  bookingHotelData,
  bookingRateData,
}: BookingTermsProps) => {
  const { checkinInstructions, checkinSpecialInstructions, fees, policies } =
    bookingHotelData?.standardHotel ?? {}
  const { cancelPolicy, policies: ratePolicies } =
    bookingRateData?.validatedRate?.room?.rates?.[0] ?? {}
  const {
    constructedCancellationPolicies,
    fullyRefundableUntil,
    nonRefundableAfter,
    partiallyRefundableUntil,
  } = cancelPolicy ?? {}
  const { policyText } = constructedCancellationPolicies ?? {}

  return (
    <BookingAgreement
      cancellationData={{
        fullyRefundableUntil,
        policies: policyText,
        nonRefundableAfter,
        partiallyRefundableUntil,
      }}
      policyData={{
        checkinInstructions,
        checkinSpecialInstructions,
        fees,
        policies,
        ratePolicies,
      }}
    />
  )
}
