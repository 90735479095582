import { Illustration } from '@travelpass/design-system'

export const RecommendedHotelsZeroState = () => {
  return (
    <div className='mt-20 flex flex-col items-center justify-center gap-y-10'>
      <div className='md:w-30% w-60%'>
        <Illustration name='resting' />
      </div>
      <p className='type-body-1-desktop c-grey800 md:w-35% w-full'>
        No recommended hotels added yet. Use the search bar above to find some
        today.
      </p>
    </div>
  )
}
