import type { ReactNode } from 'react'
import classNames from 'classnames'

type HomeContainerSize = 'sm' | 'md' | 'none' | 'lg' | 'xl' | 'xxl'

interface HomeContainerProps {
  children: ReactNode
  size?: HomeContainerSize
}

export const HomeContainer = ({
  children,
  size = 'none',
}: HomeContainerProps) => (
  <div className={homeContainerCss(size)}>{children}</div>
)

const homeContainerCss = (size: HomeContainerSize) =>
  classNames('max-w-full m-a px-6 md:px-12', {
    'w-274': size === 'md',
    'w-full': !size || size === 'none',
    'w-299': size === 'lg',
    'w-322': size === 'xl',
    'w-355': size === 'xxl',
  })
