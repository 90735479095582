const guideDraftDescriptionMaxLength = 600
const guideDraftNameMaxLength = 50
const guideDraftSectionDescriptionMaxLength = 500
const guideDraftSectionNameMaxLength = 40

export {
  guideDraftDescriptionMaxLength,
  guideDraftNameMaxLength,
  guideDraftSectionDescriptionMaxLength,
  guideDraftSectionNameMaxLength,
}
