import { useState } from 'react'
import {
  Avatar,
  Button,
  IconButton,
  useSnackbar,
} from '@travelpass/design-system'
import { GuideImage } from 'src/pages/guides/details/common'
import { FollowersModal } from 'src/pages/profile/components/FollowersModal/FollowersModal'
import { useGetProfile } from 'src/pages/profile/components/hooks/useGetProfile'
import { getProfilePageUrl } from 'src/pages/profile/components/profileUtils'
import type { FollowersModalType } from 'src/pages/profile/types'
import { copyToClipboard } from 'src/utils'
import { DashboardProfileAvatarModal } from './DashboardProfileAvatarModal'
import { DashboardProfileBannerModal } from './DashboardProfileBannerModal'
import { DashboardProfileCompletion } from './DashboardProfileCompletion'
import { DashboardProfileInfoModal } from './DashboardProfileInfo'
import type { UseGetDashboardQuery } from '../../useGetDashboardQuery'

interface DashboardProfileProps {
  dashboardData: UseGetDashboardQuery['dashboardData']
}

export const DashboardProfile = ({ dashboardData }: DashboardProfileProps) => {
  const { userProfile } = dashboardData?.currentUser ?? {}
  /** @todo When we remove profile queries entirely, */
  /** consider combining this 'profile' object with userProfile that comes from dashboardData */
  const { profile } = useGetProfile()
  const { addSuccessSnack } = useSnackbar()
  const [isModalAvatarOpen, setIsModalAvatarOpen] = useState(false)
  const [isModalBannerOpen, setIsModalBannerOpen] = useState(false)
  const [isModalInfoOpen, setIsModalInfoOpen] = useState(false)
  const [scrollId, setScrollId] = useState('')
  const [showModalFollowers, setShowModalFollowers] =
    useState<FollowersModalType>(null)
  const { profileCompletion } = userProfile ?? {}
  const ownerUrl = `${window.location.origin}${getProfilePageUrl(userProfile?.accountHandle)}`

  const onModalAvatarDismiss = () => {
    setIsModalAvatarOpen(false)
    setIsModalInfoOpen(true)
  }

  const onModalAvatarOpen = () => {
    setIsModalAvatarOpen(true)
    setIsModalInfoOpen(false)
  }

  const onModalBannerDismiss = () => {
    setIsModalBannerOpen(false)
    setIsModalInfoOpen(true)
  }

  const onModalBannerOpen = () => {
    setIsModalInfoOpen(false)
    setIsModalBannerOpen(true)
  }

  const onScrollIdChange = (updatedScrollId: string) =>
    setScrollId(updatedScrollId)

  const onShare = () => {
    copyToClipboard(ownerUrl)
    addSuccessSnack({ title: 'Profile URL copied' })
  }

  const toggleFollowersModal = (viewType: FollowersModalType) =>
    setShowModalFollowers(viewType)

  return (
    <>
      <section className='shadow-3 rounded-3 p-y-3.5 p-x-3 space-y-6 bg-white'>
        <div className='h-37.5 relative flex flex-col items-center'>
          <GuideImage
            className='absolute inset-0'
            src={userProfile?.activeBannerImage?.smallUrl}
          />
          <a
            className='bottom--10.5 [&>img]:shadow-1 absolute'
            href={ownerUrl}
            rel='noreferrer'
            target='_blank'
          >
            <Avatar border='sm' size='md' src={userProfile?.profileImageUrl} />
          </a>
        </div>
        <div className='p-t-10 space-y-8 p-3'>
          <header className='space-y-4'>
            <div className='flex flex-row items-center justify-between gap-4'>
              <h4 className='type-h4-desktop'>{userProfile?.displayName}</h4>
              <div className='flex flex-row items-center justify-between gap-2'>
                <IconButton
                  icon='modeEdit'
                  size='small'
                  onClick={() => {
                    setScrollId('')
                    setIsModalInfoOpen(true)
                  }}
                />
                <IconButton icon='iosShare' size='small' onClick={onShare} />
              </div>
            </div>
            <div className='flex flex-row items-center gap-4'>
              <div className='[&>button]:p-x-0 flex flex-row items-center gap-1.5'>
                <p className='type-body-1-medium-desktop color-black'>
                  {userProfile?.followCount?.countOfFollowing ?? 0}
                </p>
                <Button
                  size='small'
                  variant='text'
                  onClick={() => toggleFollowersModal('followers')}
                >
                  Followers
                </Button>
              </div>
              <div className='[&>button]:p-x-0 color-black flex flex-row items-center gap-1.5'>
                <p className='type-body-1-medium-desktop'>
                  {userProfile?.followCount?.countOfFollowed ?? 0}
                </p>
                <Button
                  size='small'
                  variant='text'
                  onClick={() => toggleFollowersModal('following')}
                >
                  Following
                </Button>
              </div>
            </div>
          </header>
          {profileCompletion?.completionPercentage < 1 && (
            <DashboardProfileCompletion
              profileCompletion={profileCompletion}
              onModalInfoOpen={() => setIsModalInfoOpen(true)}
              onScrollIdChange={onScrollIdChange}
            />
          )}
        </div>
      </section>
      {isModalAvatarOpen && (
        <DashboardProfileAvatarModal
          profile={profile}
          onDismiss={onModalAvatarDismiss}
        />
      )}
      {isModalBannerOpen && (
        <DashboardProfileBannerModal
          profile={profile}
          onDismiss={onModalBannerDismiss}
        />
      )}
      {isModalInfoOpen && (
        <DashboardProfileInfoModal
          profile={profile}
          scrollId={scrollId}
          onDismiss={() => setIsModalInfoOpen(false)}
          onModalAvatarOpen={onModalAvatarOpen}
          onModalBannerOpen={onModalBannerOpen}
        />
      )}
      {showModalFollowers && (
        <FollowersModal
          accountHandle={profile?.accountHandle}
          toggleModal={toggleFollowersModal}
          userId={profile?.userId}
          viewType={showModalFollowers}
          onClose={() => setShowModalFollowers(null)}
        />
      )}
    </>
  )
}
