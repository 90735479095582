import {
  Divider,
  UnderlineTab,
  type UnderlineTabProps,
} from '@travelpass/design-system'
import { ScrollMenu } from 'react-horizontal-scrolling-menu'
import { useSearchParams } from 'react-router-dom'
import { useFlag } from 'src/common/hooks'
import { DashboardSearchParam, DashboardTab } from '../../dashboardConstants'
import { DashboardTabs } from '../DashboardTabs'

interface DashboardNavProps {
  bookingsCount: number
  collectionsCount: number
  guideDraftsCount: number
  tripsCount: number
}

export const DashboardNav = ({
  bookingsCount,
  collectionsCount,
  guideDraftsCount,
  tripsCount,
}: DashboardNavProps) => {
  const isTripsHideEnabled = useFlag('tripsHide')
  const [searchParams, setSearchParams] = useSearchParams()
  const tab = searchParams.get(DashboardSearchParam.tab)

  const tabs: UnderlineTabProps['tabs'] = [
    {
      icon: 'viewList',
      label: `Guides${guideDraftsCount > 0 ? ` (${guideDraftsCount})` : ''}`,
      onClick: () => setSearchParams({ tab: DashboardTab.guides }),
    },
    {
      icon: 'menuFilled',
      label: 'Feed',
      onClick: () => setSearchParams({ tab: DashboardTab.feed }),
    },
    !isTripsHideEnabled && {
      icon: 'luggage',
      label: `Trips${tripsCount > 0 ? ` (${tripsCount})` : ''}`,
      onClick: () => setSearchParams({ tab: DashboardTab.trips }),
    },
    {
      icon: 'taskAlt',
      label: `Bookings${bookingsCount > 0 ? ` (${bookingsCount})` : ''}`,
      onClick: () => setSearchParams({ tab: DashboardTab.bookings }),
    },
    {
      icon: 'bookmarkBorder',
      label: `Collections${collectionsCount > 0 ? ` (${collectionsCount})` : ''}`,
      onClick: () => setSearchParams({ tab: DashboardTab.collections }),
    },
  ]

  const getActiveIndex = () => {
    if (isTripsHideEnabled) {
      if (tab === DashboardTab.guides) return 0

      if (tab === DashboardTab.feed) return 1

      if (tab === DashboardTab.bookings) return 2

      if (tab === DashboardTab.collections) return 3

      return 0
    }

    if (tab === DashboardTab.guides) return 0

    if (tab === DashboardTab.feed) return 1

    if (tab === DashboardTab.trips) return 2

    if (tab === DashboardTab.bookings) return 3

    if (tab === DashboardTab.collections) return 4

    return 0
  }

  /** @todo Remove this filtering when isTripsHideEnabled is disabled/removed */
  return (
    <>
      <div className='hidden flex-row md:flex'>
        <ScrollMenu scrollContainerClassName='hide-scrollbar'>
          <DashboardTabs
            activeIndex={getActiveIndex()}
            tabs={tabs.filter(tab => tab)}
          />
        </ScrollMenu>
        <div className='border-b-1 border-b-solid border-grey-200 grow self-end' />
      </div>
      <div className='flex flex-col md:hidden'>
        <ScrollMenu scrollContainerClassName='hide-scrollbar'>
          <UnderlineTab
            activeIndex={getActiveIndex()}
            fullWidth={true}
            tabs={tabs.filter(tab => tab)}
            tabsNotCapitalized={true}
            willScroll={false}
          />
        </ScrollMenu>
        <div className='border-b-1 border-b-solid border-grey-200' />
      </div>
    </>
  )
}
