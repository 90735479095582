import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import { GuideCategory } from 'src/__generated__/graphql'

export const FEATURED_GUIDES_FRAGMENT = gql(`
  fragment FeaturedGuidesFields on Guide {
    addresses {
        id
        city
        country
        state
      }
      isCollected
      endDate
      guideType
      id
      imageUrl
      insertedAt
      name
      startDate
      updatedAt
      timeZone
      ownerProfile {
        accountHandle
        displayName
        id
        isUserOwner
        profileImageUrl
        userId
      }
  }
`)

const GET_PLAN_PAGE_GUIDES = gql(`
  query getPlanPageGuides($guideCategory: GuideCategory!){
    curatedGuides(guideCategory: $guideCategory){
      ...FeaturedGuidesFields
    }
  }
`)

export const useGetPlanPageGuides = () => {
  const { data, loading, error } = useQuery(GET_PLAN_PAGE_GUIDES, {
    variables: {
      guideCategory: GuideCategory.PlanPageFeatured,
    },
  })

  return { data, loading, error }
}
