import { Skeleton } from '@travelpass/design-system'
import { GuideHeaderContentLoading } from './GuideHeaderContent'

export const GuideHeaderLoading = () => (
  <header className='space-y-6'>
    <div className='h-37.5 md:h-70 lg:h-110 grid grid-cols-10 gap-2'>
      <div className='col-span-5 flex flex-col justify-center lg:col-span-6'>
        <div className='rounded-3 h-full w-full overflow-hidden'>
          <Skeleton />
        </div>
      </div>
      <div className='col-span-5 lg:col-span-2'>
        <div className='rounded-3 h-full w-full overflow-hidden'>
          <Skeleton />
        </div>
      </div>
      <div className='relative hidden lg:col-span-2 lg:block'>
        <div className='rounded-3 h-full w-full overflow-hidden'>
          <Skeleton />
        </div>
      </div>
    </div>
    <div className='block lg:hidden'>
      <GuideHeaderContentLoading />
    </div>
  </header>
)
