import { useState } from 'react'
import { Button, IconButton } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import { GuideDraftCreateModalOld } from 'src/pages/guides/lists-old/common'
import { getGuideDraftDetailsUrl } from 'src/utils'
import { DashboardGuideDraftCard } from './DashboardGuideDraftCard'
import { DashboardGuideDraftsEmpty } from './DashboardGuideDraftsEmpty'
import { DashboardGuideDraftsLoading } from './DashboardGuideDraftsLoading'
import { useGetDashboardGuideDraftsQuery } from '../../useGetDashboardGuideDraftsQuery'

export const DashboardGuideDraftsOld = () => {
  const { guideDraftsData, hasError, isLoading } =
    useGetDashboardGuideDraftsQuery()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const onModalOpen = () => setIsModalOpen(true)

  const onModalClose = () => setIsModalOpen(false)

  if (isLoading) return <DashboardGuideDraftsLoading />

  if (hasError || isEmpty(guideDraftsData)) return <DashboardGuideDraftsEmpty />

  return (
    <>
      <section className='space-y-6'>
        <header className='flex w-full flex-row items-center justify-between'>
          <h2 className='c-black type-h3-mobile type-h4-desktop'>My Guides</h2>
          <div className='hidden md:block'>
            <Button
              aria-label='Create a guide'
              size='small'
              startIcon='add'
              onClick={onModalOpen}
            >
              New Guide
            </Button>
          </div>
          <div className='md:hidden'>
            <IconButton
              color='forestLight'
              icon='add'
              outline='round'
              size='small'
              onClick={onModalOpen}
            />
          </div>
        </header>
        <div className='space-y-6'>
          {guideDraftsData?.map((guideDraft, index) => (
            <DashboardGuideDraftCard
              key={index}
              guideDraft={guideDraft}
              href={getGuideDraftDetailsUrl({
                id: guideDraft?.id,
                isUserOwner: true,
                name: guideDraft?.name,
              })}
              isUserOwner={true}
            />
          ))}
        </div>
      </section>
      {isModalOpen && <GuideDraftCreateModalOld onDismiss={onModalClose} />}
    </>
  )
}
