import { Skeleton } from '@travelpass/design-system'
import { DashboardGuideDraftCardLoading } from './DashboardGuideDraftCardLoading'

export const DashboardGuideDraftsLoading = () => (
  <div className='space-y-6 lg:space-y-12'>
    <div className='flex flex-row items-center justify-between'>
      <div className='flex w-full flex-row items-center justify-between'>
        <div className='h-5.25 w-17.5 md:w-25 lg:h-6'>
          <Skeleton />
        </div>
        <div className='w-37.5 hidden h-8 md:block'>
          <Skeleton variant='rounded' />
        </div>
        <div className='h-8 w-8 md:hidden'>
          <Skeleton variant='circular' />
        </div>
      </div>
    </div>
    <div className='space-y-6'>
      <DashboardGuideDraftCardLoading />
      <DashboardGuideDraftCardLoading />
      <DashboardGuideDraftCardLoading />
    </div>
  </div>
)
