import { Input } from '@travelpass/design-system'
import { useFormContext } from 'react-hook-form'
import type { ProfileFields } from 'src/pages/profile/types'
import { validateYoutubeLink } from '../../../../components/profileUtils'

export const EmbedYoutubeVideo = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<ProfileFields>()

  return (
    <div className='space-y-2'>
      <h6 className='type-h6 c-black'>Embed Video</h6>
      <p className='type-small-text-desktop c-grey-800'>
        Provide a Youtube video link.
      </p>
      <Input
        {...register('introVideoEmbed', {
          validate: value =>
            value === '' ||
            validateYoutubeLink(value) ||
            'Please use a valid youtube link or embed link.',
        })}
        fullWidth
        aria-label='Display Embed video for Profile page'
        errorText={errors?.introVideoEmbed?.message as string}
        helperText='Add your youtube video link here'
        placeholder='Paste a video'
      />
    </div>
  )
}
