import { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'
import { gql } from 'src/__generated__'
import { IdType } from 'src/__generated__/graphql'
import { Helmet } from 'src/common/components'
import { useFirebaseUser } from 'src/common/hooks/useFirebaseUser'
import { findMyBookingPath, tripsPath } from 'src/constants'
import { BookingDetailsHero } from './BookingDetailsHero'
import { BookingDetailsHeroApolloLoading } from './BookingDetailsHeroApolloLoading'
import { useBookingDetailsIdsQuery } from '../../hooks'

const { Standard } = IdType

const standardHotelsQuery = gql(`
  query StandardHotelsQueryInBookingDetailsHero(
    $hotelId: ID!
    $hotelIdType: IdType!
  ) {
    standardHotel(id: $hotelId, idType: $hotelIdType) {
      id
      imageLinks
    }
  }
`)

export const BookingDetailsHeroApollo = () => {
  const { isAnonymous } = useFirebaseUser()
  const navigate = useNavigate()
  const { bookingId } = useParams()
  const { bookingDetailsIdsData, hasError: hasBookingDetailsIdsError } =
    useBookingDetailsIdsQuery({
      bookingId,
    })
  const { hotelId, externalConfirmationId } = bookingDetailsIdsData ?? {}
  const willSkip = !bookingId || !externalConfirmationId || !hotelId

  useEffect(() => {
    if (hasBookingDetailsIdsError) {
      navigate(isAnonymous ? findMyBookingPath : tripsPath)
    }
  }, [hasBookingDetailsIdsError])

  const {
    data,
    error: hasError,
    loading: isLoading,
  } = useQuery(standardHotelsQuery, {
    skip: willSkip,
    variables: {
      hotelId,
      hotelIdType: Standard,
    },
  })

  const { standardHotel: bookingDetailsData } = data ?? {}

  if (isLoading || willSkip) return <BookingDetailsHeroApolloLoading />

  if (hasError) return

  return (
    <>
      <Helmet pageName='Booking Details' />
      <BookingDetailsHero bookingDetailsData={bookingDetailsData} />
    </>
  )
}
