import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

export const REPORT_USER = gql(
  `
      mutation ReportUser($input: CreateFlagInput!) {
        createFlag(input: $input) {
          flag {
            reason
            user {
              id
            }
            userProfileCompetition {
              id
            }
          }
        }
      }
    `
)

export const useMutationReportUser = () => useMutation(REPORT_USER)
