import { useEffect, useState } from 'react'
import { SkeletonDots, Button, Illustration } from '@travelpass/design-system'
import { useSearchParams } from 'react-router-dom'
import { GuideSorts } from 'src/__generated__/graphql'
import {
  GuideRecommendedCard,
  PageLayoutContainer,
  useOnClickOwnerProfile,
} from 'src/common/components'
import { AddToCollectionsModalListener } from 'src/common/components/Collections/AddToCollectionsModalListener'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { GuidesSortBy } from './GuidesSortBy'
import { LookingForMore } from './LookingForMore'
import { ProfileModal } from './ProfileModal'
import { ProfileModalListener } from './ProfileModalListener'
import { SearchBar } from './SearchBar'
import { TripShareCTA } from './TripShareCTA'
import { useGuideSearchQuery } from './useGuideSearchQuery'

export const GuideResults = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const displayName = searchParams.get('displayName')
  const latitude = searchParams.get('latitude')
  const longitude = searchParams.get('longitude')
  const location = searchParams.get('location')
  const sortBy = searchParams.get('sort') as GuideSorts // unable to convert from string to enum in a way that doesn't induce rage or depression
  const lat = latitude ? parseFloat(latitude) : undefined
  const lon = longitude ? parseFloat(longitude) : undefined
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false)
  const [profileAccountHandle] = useState('')
  const onClickOwnerProfile = useOnClickOwnerProfile()

  useEffect(() => {
    setSearchParams(sp => {
      sp.set('sort', GuideSorts.Recommended)
      return sp
    })
  }, [])

  const { loading, data, error, fetchMore } = useGuideSearchQuery({
    latitude: lat,
    longitude: lon,
    location,
    sortBy,
    similarName: displayName,
  })

  const cursor =
    data?.guideSearch?.pageInfo?.hasNextPage &&
    data.guideSearch?.pageInfo.endCursor

  const totalCount = data?.guideSearch?.totalCount

  const guides = data?.guideSearch?.edges.map(edge => edge.node)
  const isEmpty = !guides?.length

  return (
    <div className='space-y-4 pb-4'>
      {isProfileModalOpen && (
        <ProfileModal
          accountHandle={profileAccountHandle}
          onClose={() => setIsProfileModalOpen(false)}
        />
      )}
      <div className='space-y-6 bg-black/35 bg-[url(https://static.travelpass.com/assets/guides/search-header.webp)] bg-cover bg-center pt-6 bg-blend-darken md:pb-8 md:pt-8'>
        <h1 className='type-h1 c-white text-shadow text-shadow-color-black/30 text-shadow-lg text-center'>
          Explore Travel Guides
        </h1>
        <p className='type-body-1 c-white text-shadow text-shadow-color-black/70 text-shadow-lg hidden text-center md:block'>
          Every journey begins with a single step
        </p>
        <SearchBar allowTextSearch />
      </div>
      <PageLayoutContainer>
        <section className='space-y-8 px-4 py-8'>
          {(!!location || !!displayName) && (
            <header>
              {/* <FilterByTags /> */}
              <div className='flex flex-col-reverse justify-between gap-5 md:flex-row-reverse'>
                <GuidesSortBy />
                <h2 className='type-h3 color-grey-700'>
                  {displayName} {!!totalCount && <span>({totalCount})</span>}
                </h2>
              </div>
            </header>
          )}
          {loading && (
            <div>
              <SkeletonDots />
            </div>
          )}
          {error && (
            <>
              <p>Oops! There was a problem.</p>
              <pre>{error.message}</pre>
            </>
          )}
          {guides && !isEmpty && (
            <>
              <div className='grid grid-cols-1 gap-2 md:grid-cols-2 md:gap-5 lg:grid-cols-3'>
                {guides.map((guide, index) => (
                  <GuideRecommendedCard
                    key={index}
                    guideRecommendedCardData={guide}
                    showBookmarkIcon={true}
                    onClickOwnerProfile={onClickOwnerProfile}
                  />
                ))}
                <TripShareCTA />
              </div>
              {cursor && (
                <div className='flex justify-center py-6'>
                  <Button
                    aria-label='Load more guides'
                    disabled={loading}
                    size='small'
                    variant='outlined'
                    onClick={async () => {
                      await fetchMore({
                        variables: {
                          after: cursor,
                        },
                      })
                      pushDataToDataLayer('guide_search_load_more_click')
                    }}
                  >
                    Load more
                  </Button>
                </div>
              )}
              <LookingForMore
                closerRadiusRange={{ min: 81, max: 90 }}
                fartherRadiusRange={{ max: 100 }}
                onClickOwnerProfile={onClickOwnerProfile}
              />
            </>
          )}
          {guides && isEmpty && (
            <div className='h-100 w-100 mx-auto space-y-6 text-center'>
              <h6 className='c-grey-800 type-h6'>
                No guides for this city yet!
              </h6>
              <Illustration name='guide' />
            </div>
          )}
        </section>
      </PageLayoutContainer>
      <ProfileModalListener />
      <AddToCollectionsModalListener />
    </div>
  )
}
