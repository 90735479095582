import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'

const GET_CURRENT_USER_GUEST_INFO_QUERY = gql(`
  query GetCurrentUserGuestInfo {
    currentUser {
      id
      firstName
      lastName
      email
      phoneNumber
    }
  }
`)

export const useUserGuestInfoQuery = () =>
  useQuery(GET_CURRENT_USER_GUEST_INFO_QUERY)
