import type { ReactNode } from 'react'

import type { AvailableIcons } from '@travelpass/design-system'
import { Icon } from '@travelpass/design-system'

const benefitsData: {
  icon: AvailableIcons
  title: ReactNode
  subtitle: string
}[] = [
  {
    icon: 'language',
    title: (
      <p className='truncate'>
        Inspiration For <br className='lg:hidden' />
        Every Adventure
      </p>
    ),
    subtitle:
      'Get that trip out of the group chat phase with our customizable Collections and itinerary builder, designed for easy sharing.',
  },
  {
    icon: 'peopleOutline',
    title: (
      <p className='truncate'>
        Join A <br className='lg:hidden' />
        Community
      </p>
    ),
    subtitle:
      'Discover the inside scoop on the best restaurants, local secrets, and hidden gems from a world-class community of travelers just like you.',
  },
  {
    icon: 'luggage',
    title: (
      <p className='truncate'>
        Plan Less, <br className='lg:hidden' />
        Experience More
      </p>
    ),
    subtitle:
      'Cut down on research time by using our smart trips feature, which effortlessly curates the best activities and hotel options for you.',
  },
]

export const HomeHeroBenefits = () => {
  return (
    <section className='items-initial flex justify-center gap-14 sm:mb-10 md:mb-0'>
      {benefitsData.map(({ icon, title, subtitle }) => (
        <div
          key={subtitle}
          className='sm:w-33% md:w-205px lg:w-289px sm:text-center md:text-left'
        >
          <div className='c-white items-center gap-2 lg:flex'>
            <Icon name={icon} />
            <h5 className='type-h5 c-white sm:hidden md:block'>{title}</h5>
          </div>
          <div className='h-.5 bg-valley w-full sm:my-2.5 md:my-5' />
          <div className='type-body-2 c-white sm:hidden md:block'>
            {subtitle}
          </div>
          <div className='type-h5 c-white md:hidden'>{title}</div>
        </div>
      ))}
    </section>
  )
}
