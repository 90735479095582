import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'
import {
  guideDraftGroupedEventFragmentOld,
  useUpdateGuideDraftQueryCache,
} from './useGetGuideDraftQuery'

const updatePublishedEventMutation = gql(`
  mutation UpdatePublishedEventMutationInGuideDraftOld($publishedEventInput: UpdatePublishedEventInput!) {
    updatePublishedEvent(updatePublishedEventInput: $publishedEventInput) {
      guideDraft {
        id
        groupedEvents {
          ...GuideDraftGroupedEventFields
        }
        guide {
          id
          status
        }
        hasUnpublishedChanges
        publishedEvents {
          id
          notes
        }
      }
    }
  }
`)

export const useUpdateGuideDraftEventMutation = () => {
  const { updateGuideDraftQueryCache } = useUpdateGuideDraftQueryCache()

  return useMutation(updatePublishedEventMutation, {
    update: (cache, { data }) => {
      const updatedCache = updateGuideDraftQueryCache(
        data?.updatePublishedEvent?.guideDraft
      )

      if (!updatedCache) return

      cache.modify(updatedCache)
    },
  })
}
