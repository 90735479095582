import { Skeleton } from '@travelpass/design-system'

export const GuideEventCardDescriptionLoading = () => (
  <div className='space-y-1'>
    <div className='w-50 h-3'>
      <Skeleton />
    </div>
    <div className='w-45 h-3'>
      <Skeleton />
    </div>
    <div className='h-3 w-40'>
      <Skeleton />
    </div>
  </div>
)
