enum GuideSearchParam {
  mapExpanded = 'mapExpanded',
  mapFullView = 'mapFullView',
}

enum GuideSessionStorage {
  geocoderBounds = 'geocoderBounds',
  geocoderLocation = 'geocoderLocation',
  hoverId = 'hoverId',
  selectedId = 'selectedId',
}

export { GuideSearchParam, GuideSessionStorage }
