import { Illustration } from '@travelpass/design-system'

export const GuideEventsEmpty = () => (
  <div className='b-2 b-canyon-light b-solid bg-warm-grey m-t-5 rounded-3 lg:m-t-10 flex flex-col items-center justify-center gap-4 p-6 text-center lg:flex-row-reverse lg:gap-6 lg:p-9 lg:text-left'>
    <div className='w-34'>
      <Illustration name='guide' />
    </div>
    <div className='space-y-1 lg:space-y-5'>
      <h3 className='c-grey-800 type-h4'>No events to show</h3>
      <p className='type-body-1'>
        Looks like there aren&apos;t any events in this guide. This guide is
        probably still being revised. Please check back later.
      </p>
    </div>
  </div>
)
