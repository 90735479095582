import React from 'react'
import {
  Accordion,
  AccordionItem as ReachAccordionItem,
  AccordionButton as ReachAccordionButton,
  AccordionPanel as ReachAccordionPanel,
} from '@reach/accordion'
import type { AccordionItemProps, AccordionPanelProps } from '@reach/accordion'

type AccordionButtonProps = React.ComponentPropsWithoutRef<
  typeof ReachAccordionButton
> // Using reach's AccordionButtonProps prevents from being able to pass `disable` prop for some reason

const AccordionButton = (props: AccordionButtonProps) => (
  <ReachAccordionButton
    className='hover:bg-grey200 transition-10 before:min-h-16px before:min-w-16px before:rounded-8 before:shadow-forest before:m-4px aria-expanded:before:bg-forest inline-flex w-full cursor-pointer items-center gap-2 border-none bg-transparent p-4 text-lg transition-colors before:box-border before:bg-transparent before:bg-origin-content before:shadow-[0_0_0_2px] before:transition-all before:duration-150 before:content-[""]'
    {...props}
  />
)
const AccordionItem = (props: AccordionItemProps) => (
  <ReachAccordionItem
    className='border-1 border-grey-300 first:rd-tl-4 first:rd-tr-4 last:rd-bl-4 last:rd-br-4 last:border-t-none items-center overflow-hidden border-solid bg-white'
    {...props}
  />
)
const AccordionPanel = (props: AccordionPanelProps) => (
  <ReachAccordionPanel
    className='border-t-1 border-grey300 flex-col gap-6 border-x-0 border-b-0 border-solid px-6 py-4 aria-expanded:flex'
    {...props}
  />
)

export { Accordion, AccordionButton, AccordionItem, AccordionPanel }
