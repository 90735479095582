import type { ReactNode } from 'react'

interface GuideDraftPublishGroupProps {
  children: ReactNode
}

export const GuideDraftPublishGroup = ({
  children,
}: GuideDraftPublishGroupProps) => (
  <section className='md:bg-warm-grey md:p-x-6 md:p-y-4 md:rounded-3 space-y-3'>
    {children}
  </section>
)
