/* eslint-disable */
const initializeLucency = () => {
  !(function (e, c, n) {
    ;(e.LucencyLoaderObject = n),
      (e[n] =
        e[n] ||
        function () {
          ;(e[n].q = e[n].q || []).push(arguments)
        })
    var t = c.createElement('script')
    ;(t.type = 'text/javascript'),
      (t.async = !0),
      (t.src = '//cdn.lucency.com/lucency.js')
    var r = c.getElementsByTagName('script')[0]
    r.parentNode.insertBefore(t, r)
  })(window, document, 'lucency')

  lucency(
    'token',
    'hYSQnvaqg-S81yhMI4l5xkmOjTahoayIuO0F88MryySP1tzCPL6Q458HCa0qlTMc'
  )
}

if (!import.meta.env.IS_LOCAL_ENV) {
  initializeLucency()
}
