import { Fragment } from 'react/jsx-runtime'
import type { GuideData } from '../../../types'
import { GuideBadge } from '../../GuideBadge'

interface GuideHeaderTagsProps {
  tags?: GuideData['tags']
}

export const GuideHeaderTags = ({ tags }: GuideHeaderTagsProps) => (
  <div className='flex hidden flex-row flex-wrap items-center gap-2'>
    {tags.map(({ id, name }) => (
      <Fragment key={id}>
        <div className='hidden lg:block'>
          <GuideBadge variant='dark'>{name}</GuideBadge>
        </div>
        <div className='lg:hidden'>
          <GuideBadge>{name}</GuideBadge>
        </div>
      </Fragment>
    ))}
  </div>
)
