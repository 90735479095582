import { useState } from 'react'
import { Button, Icon, ProgressBar } from '@travelpass/design-system'
import type { UserProfile } from 'src/__generated__/graphql'
import { OwnerBadge } from 'src/common/components/OwnerBadge/OwnerBadge'
import { useFlag } from 'src/common/hooks'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { DashboardProfileAvatarModal } from 'src/pages/dashboard-old/common/DashboardProfile/DashboardProfileAvatarModal'
import { DashboardProfileBannerModal } from 'src/pages/dashboard-old/common/DashboardProfile/DashboardProfileBannerModal'
import { DashboardProfileInfoModal } from 'src/pages/dashboard-old/common/DashboardProfile/DashboardProfileInfo'
import { useGetProfile } from 'src/pages/profile/components/hooks/useGetProfile'
import { getDashboardAccountOldCompletionPercentage } from './dashboardAccountOldUtils'

interface DashboardAccountOldProps {
  userProfile: UserProfile
}

export const DashboardAccountOld = ({
  userProfile,
}: DashboardAccountOldProps) => {
  const { profile } = useGetProfile()
  const isProfileEditModalEnabled = useFlag('profileEditModal')
  const [isModalAvatarOpen, setIsModalAvatarOpen] = useState(false)
  const [isModalBannerOpen, setIsModalBannerOpen] = useState(false)
  const [isModalInfoOpen, setIsModalInfoOpen] = useState(false)
  const { accountHandle, displayName, profileImageUrl, userId } =
    userProfile ?? {}
  /** @todo replace with BE percentage */
  const percentage = getDashboardAccountOldCompletionPercentage(userProfile)
  const hasCompletedProfile = percentage === 100
  const percentageText = `${percentage}% Complete`

  const onProfileClick = () => {
    pushDataToDataLayer('dashboard_profile_click', {
      user_id: userId,
    })
  }

  const onModalAvatarDismiss = () => {
    setIsModalAvatarOpen(false)
    setIsModalInfoOpen(true)
  }

  const onModalAvatarOpen = () => {
    setIsModalAvatarOpen(true)
    setIsModalInfoOpen(false)
  }

  const onModalBannerDismiss = () => {
    setIsModalBannerOpen(false)
    setIsModalInfoOpen(true)
  }

  const onModalBannerOpen = () => {
    setIsModalInfoOpen(false)
    setIsModalBannerOpen(true)
  }

  return (
    <>
      <div>
        <h3 className='c-black type-h5-mobile block md:hidden'>My Profile</h3>
        <div className='space-y-3 md:space-y-9'>
          <div className='relative'>
            {isProfileEditModalEnabled && (
              <div className='absolute right-0 top-1.5'>
                <Button
                  size='small'
                  variant='text'
                  onClick={() => setIsModalInfoOpen(true)}
                >
                  Edit
                </Button>
              </div>
            )}
            <OwnerBadge
              accountHandle={accountHandle}
              displayName={displayName}
              interactivity='link'
              profileImageUrl={profileImageUrl}
              supplementalContent={
                accountHandle && (
                  <p className='c-grey-800 type-body-2'>{`@${accountHandle}`}</p>
                )
              }
              onClick={onProfileClick}
            />
          </div>
          <div className='space-y-1'>
            <ProgressBar percentage={percentage} />
            <div className='min-h-6'>
              {hasCompletedProfile && (
                <p className='c-black type-h5-mobile flex flex-row items-center gap-1'>
                  <Icon name='checkCircleOutline' />
                  <span>Profile Complete</span>
                </p>
              )}
              {!hasCompletedProfile && (
                <p className='c-black type-h5-mobile'>{percentageText}</p>
              )}
            </div>
          </div>
        </div>
      </div>
      {isModalAvatarOpen && (
        <DashboardProfileAvatarModal
          profile={profile}
          onDismiss={onModalAvatarDismiss}
        />
      )}
      {isModalBannerOpen && (
        <DashboardProfileBannerModal
          profile={profile}
          onDismiss={onModalBannerDismiss}
        />
      )}
      {isModalInfoOpen && (
        <DashboardProfileInfoModal
          profile={profile}
          onDismiss={() => setIsModalInfoOpen(false)}
          onModalAvatarOpen={onModalAvatarOpen}
          onModalBannerOpen={onModalBannerOpen}
        />
      )}
    </>
  )
}
