import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { ProfileImageSource } from 'src/__generated__/graphql'
import type {
  GeneralLinksType,
  ProfileFields,
  SocialLinkType,
} from 'src/pages/profile/types'
import { EditingProfileTabs } from './EditingProfileTabs'
import { EditingProfileVisualizer } from './EditingProfileVisualizer'
import { DEFAULT_PROFILE_IMAGE_URL } from '../../profileConstants'
import { useGetBannerImages } from '../hooks/useGetBannerImages'
import { useGetProfile } from '../hooks/useGetProfile'

export const EditProfile = () => {
  //GraphQl
  const { profile } = useGetProfile()
  const { data } = useGetBannerImages()
  const bannerImages = data?.getBannerImages || []
  //Others
  const {
    bio,
    socialLinks,
    tags,
    links,
    displayName,
    accountHandle,
    profileImageUrl,
    profileImageSource,
    bragStates,
    bragContinents,
    bragCountries,
    activeBannerImage,
    hotelRecommendations,
    introVideoEmbed,
  } = profile || {}
  const socialMediaLinks = socialLinks as SocialLinkType
  const generalLinks = links as GeneralLinksType

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = ''
    }
  }, [])

  const formatSocialLinks = () =>
    socialMediaLinks.map(({ identifier, network }) => ({
      identifier,
      network,
    })) as SocialLinkType

  const formatLinks = () =>
    generalLinks.map(({ url, title }) => ({ url, title }))

  const methods = useForm<ProfileFields>()

  useEffect(() => {
    methods.reset({
      displayName: displayName || '',
      bio: bio || '',
      socialLink: '',
      socialLinks: socialMediaLinks ? JSON.stringify(formatSocialLinks()) : '',
      tags: tags ? JSON.stringify(tags) : '',
      links: generalLinks ? JSON.stringify(formatLinks()) : '',
      linkTitle: '',
      linkURL: '',
      accountHandle: accountHandle || '',
      profileImageUrl: profileImageUrl || DEFAULT_PROFILE_IMAGE_URL,
      profileImageSource: profileImageSource || ProfileImageSource.DefaultImage,
      bragContinents: bragContinents || 0,
      bragCountries: bragCountries || 0,
      bragStates: bragStates || 0,
      activeBannerImage: activeBannerImage || bannerImages?.[0],
      activeBannerImageId: activeBannerImage?.id || bannerImages?.[0]?.id,
      hotelNotes: hotelRecommendations?.map(({ id, notes }) => ({ id, notes })),
      introVideoEmbed: introVideoEmbed || '',
    })
  }, [profile])

  useEffect(() => {
    selectBannerImage()
  }, [bannerImages, activeBannerImage, methods])

  useEffect(() => {
    methods.reset({
      ...methods.getValues(),
      hotelNotes: hotelRecommendations?.map(({ id, notes }) => ({ id, notes })),
    })
  }, [hotelRecommendations])

  const selectBannerImage = () => {
    if (bannerImages?.length > 0) {
      methods.reset({
        ...methods.getValues(),
        activeBannerImageId: activeBannerImage?.id || bannerImages?.[0]?.id,
      })
    }
  }

  return (
    <div className='bg-disabled fixed left-0 top-0 h-full w-full place-content-between backdrop-blur-[30px] md:flex'>
      <FormProvider {...methods}>
        <EditingProfileVisualizer />
        <EditingProfileTabs />
      </FormProvider>
    </div>
  )
}
