import { useState } from 'react'
import { Button, Input } from '@travelpass/design-system'
import { useFormContext } from 'react-hook-form'
import type { ProfileFields } from 'src/pages/profile/types'

const NOTES_MAX_LENGTH = 175

interface FavoriteHotelNotesProps {
  favoriteHotelId: string
  name: string
}

export const RecommendedHotelsNotes = ({
  favoriteHotelId,
  name,
}: FavoriteHotelNotesProps) => {
  const { watch, setValue } = useFormContext<ProfileFields>()
  const notesList = watch('hotelNotes')
  const i = notesList?.findIndex(({ id }) => id == favoriteHotelId)
  const [showInput, setShowInput] = useState(false)

  const onChange = (notes: string) => {
    !showInput && setShowInput(true)
    notesList[i] = { ...notesList[i], notes }
    setValue('hotelNotes', [...notesList])
  }

  return (
    <div className='mb-10'>
      {notesList?.[i]?.notes || showInput ? (
        <div className='flex items-center gap-x-2 md:gap-x-10'>
          <Input
            fullWidth
            aria-label={`Enter your notes here for ${name}`}
            helperText={`${notesList[i]?.notes?.length || 0}/${NOTES_MAX_LENGTH} characters`}
            maxLength={NOTES_MAX_LENGTH}
            placeholder='Enter your notes here'
            value={notesList[i]?.notes || ''}
            onChange={event => onChange(event.target.value)}
          />
        </div>
      ) : (
        <div className='flex w-full justify-between px-5'>
          <p className='type-h6-desktop hidden md:flex'>Notes</p>
          <Button
            label='Add notes'
            startIcon='add'
            variant='outlined'
            onClick={() => setShowInput(true)}
          />
        </div>
      )}
    </div>
  )
}
