import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'

const CURRENT_USER_SEARCH_PREFERENCES = gql(`
  query CurrentUserSearchPreferences {
    currentUser {
      id
      searchPreferences {
        id
        userId
        partyType
        budgetSelection {
          budgetType
        }
        hotelStarRating
      }
    }
  }
`)

export const useCurrentUserSearchPreferences = () =>
  useQuery(CURRENT_USER_SEARCH_PREFERENCES)
