import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'

const GET_BRANDS_SELECTIONS = gql(`
  query GetBrandSelections {
    getBrandSelections {
      spotlightBrands {
        id
        name
        displayOrder
        imageUrl
        isSelected
      }
      brandSelections
    }
  }
`)

export const useGetBrandSelections = () =>
  useQuery(GET_BRANDS_SELECTIONS, {
    fetchPolicy: 'network-only',
  })
