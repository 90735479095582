import { useEffect, useState } from 'react'
import { Button, UnderlineTab, useScreenQuery } from '@travelpass/design-system'
import { useNavigate, useSearchParams } from 'react-router-dom'
import type { UserProfile } from 'src/__generated__/graphql'
import { AddToCollectionsModalListener } from 'src/common/components/Collections/AddToCollectionsModalListener'
import { useFlag } from 'src/common/hooks'
import { dashboardPath } from 'src/constants'
import { GuideDraftCreateModalOld } from 'src/pages/guides/lists-old/common'
import { getGuideDetailsUrl } from 'src/utils'
import { BragMetrics } from './BragMetrics'
import { CompetitionVoteCta } from './CompetitionVoteCta'
import { ProfileStatistics } from './ProfileStatistics'
import { VideoPreview } from './guides/VideoPreview'
import { RecommendedHotels } from './hotels/RecommendedHotels'
import { ProfileGuideCard } from './new-guides/ProfileGuideCard'
import { ProfileGuidesZeroState } from './new-guides/ProfileGuidesZeroState'
import { ProfileTabs } from '../components/EditProfile/types'
import { useCheckEditingMode } from '../components/hooks/useCheckEditingMode'
import { BioSection } from '../information-section/BioSection'
import { ProfileSearchParamTab, ProfileTab } from '../profileConstants'
import type { FollowersModalType } from '../types'

interface ProfileContentSectionProps {
  profile: UserProfile
  toggleModal: (viewType: FollowersModalType) => void
}

const headerContainerStyles = {
  tabsAlone: 'flex sm:justify-center md:justify-start mb-4',
  tabsWithButton: 'flex justify-between mb-4',
}

const getActiveTabIndex = (tab: string) => {
  if (tab === ProfileTab.topHotels) return 1
  return 0
}

export const ProfileContentSection = ({
  profile,
  toggleModal,
}: ProfileContentSectionProps) => {
  const enableProfileFavoriteHotels = useFlag('enableProfileFavoriteHotels')
  const { isMobileScreen, isDesktopScreen } = useScreenQuery()
  const { handleOpenEditMode } = useCheckEditingMode()
  const navigate = useNavigate()

  // Tab State
  const [searchParams, setSearchParams] = useSearchParams()
  const tab = searchParams.get(ProfileSearchParamTab) ?? 'guides'

  useEffect(() => {
    if ((!isMobileScreen && tab === ProfileTab.guides) || !tab)
      setSearchParams(sp => {
        sp.set('tab', ProfileTab.guides)
        return sp
      })
  }, [searchParams, tab])

  let tabs = [
    {
      label: 'Guides',
      onClick: () => setSearchParams({ tab: ProfileTab.guides }),
    },
  ]

  const [isGuideDraftCreateModalOldOpen, setIsGuideDraftCreateModalOldOpen] =
    useState(false)

  if (
    enableProfileFavoriteHotels &&
    (profile?.isUserOwner || profile?.hotelRecommendations?.length > 0)
  ) {
    tabs.push({
      label: 'Top Hotels',
      onClick: () => setSearchParams({ tab: ProfileTab.topHotels }),
    })
  }

  const buttonText =
    tab === ProfileTab.guides
      ? 'Manage Guides'
      : isMobileScreen
        ? 'Hotel'
        : 'Add Hotel Recommendation'

  const enableCompetition2024 = useFlag('enableCompetition2024')

  const showVoteForCta = enableCompetition2024

  return (
    <div className='grow-3 flex w-full flex-col gap-2 self-start md:pl-24'>
      <div className='hidden lg:grid'>
        <BragMetrics
          metrics={[
            { label: 'Continents', value: profile?.bragContinents || 0 },
            { label: 'Countries', value: profile?.bragCountries || 0 },
            { label: 'States', value: profile?.bragStates || 0 },
          ]}
        />
        <ProfileStatistics profile={profile} toggleModal={toggleModal} />
        {showVoteForCta && <CompetitionVoteCta />}
        <div className='flex gap-10 py-6'>
          <VideoPreview
            embedVideoLink={profile?.introVideoEmbed}
            fullWidth={!profile?.bio || profile?.bio?.length === 0}
          />
          {isDesktopScreen && <BioSection bio={profile?.bio} />}
        </div>
      </div>
      <div className='flex flex-col'>
        <div
          className={
            profile?.isUserOwner
              ? headerContainerStyles.tabsWithButton
              : headerContainerStyles.tabsAlone
          }
        >
          <UnderlineTab
            activeIndex={getActiveTabIndex(tab)}
            tabs={tabs}
            willScroll={false}
          />
          {profile?.isUserOwner && (
            <div className='flex flex-col justify-center'>
              <Button
                size='small'
                startIcon={tab === ProfileTab.guides ? null : 'add'}
                onClick={() => {
                  if (tab === ProfileTab.guides) {
                    navigate(dashboardPath)
                  } else if (tab === ProfileTab.topHotels) {
                    handleOpenEditMode(ProfileTabs.FavoriteHotels)
                  }
                }}
              >
                {buttonText}
              </Button>
            </div>
          )}
        </div>
        {tab === ProfileTab.guides ? (
          <div className='flex flex-col gap-y-4'>
            {profile?.guides?.length > 0 ? (
              profile?.guides?.map(guide => (
                <ProfileGuideCard
                  key={`guide-card-${guide.displayId}`}
                  guide={guide}
                  href={getGuideDetailsUrl({
                    id: guide?.id,
                    name: guide?.name,
                  })}
                />
              ))
            ) : (
              <ProfileGuidesZeroState
                displayName={profile?.displayName}
                isUserOwner={profile?.isUserOwner}
                onClick={() => setIsGuideDraftCreateModalOldOpen(true)}
              />
            )}
          </div>
        ) : (
          <RecommendedHotels profile={profile} />
        )}
      </div>
      {isGuideDraftCreateModalOldOpen && (
        <GuideDraftCreateModalOld
          onDismiss={() => setIsGuideDraftCreateModalOldOpen(false)}
        />
      )}
      <AddToCollectionsModalListener />
    </div>
  )
}
