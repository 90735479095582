/** @todo remove when implemented on the BE */
export const getDashboardAchievementsOldProgressValue = (
  value: number,
  maxValue: number
) => {
  if (value < 0 || isNaN(value)) return 0

  if (value > maxValue) return 100

  return Math.round((value / maxValue) * 100)
}
