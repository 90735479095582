import { IconButton, Input } from '@travelpass/design-system'
import { useFormContext } from 'react-hook-form'
import type { ProfileFields } from 'src/pages/profile/types'
import { isUrlValid } from 'src/utils'

export const LinkForm = ({ links }) => {
  const {
    register,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
    trigger,
  } = useFormContext<ProfileFields>()

  const onAdd = async () => {
    clearErrors(['linkTitle', 'linkURL'])
    const linkTitle = getValues('linkTitle')
    const linkUrl = getValues('linkURL')

    // Check if the title field is empty
    if (!linkTitle) {
      // Manually set an error for the socialLink field
      setError('linkTitle', {
        type: 'manual',
        message: 'A title is required',
      })
      return
    }

    // Check if the linkURL field is empty
    if (!linkUrl) {
      // Manually set an error for the socialLink field
      setError('linkURL', {
        type: 'manual',
        message: 'A URL is required',
      })
      clearErrors(['linkTitle'])
      return
    }

    // If not empty, trigger validation for the linkTitle and linkURL fields
    const isValid = await trigger(['linkTitle', 'linkURL'])

    if (isValid) {
      // Proceed to add the link, clear the field, and clear any errors
      clearErrors(['linkTitle', 'linkURL'])

      let updatedLinks = [...links, { title: linkTitle, url: linkUrl }]
      setValue('links', JSON.stringify(updatedLinks))
      // Clear input fields
      setValue('linkURL', '')
      setValue('linkTitle', '')
    }
  }

  return (
    <>
      <div className='flex place-content-between gap-3'>
        <Input
          {...register('linkTitle', {
            maxLength: {
              value: 30,
              message: 'Title cannot be more than 30 characters',
            },
          })}
          fullWidth
          aria-label='Add a link title'
          errorText={errors.linkTitle?.message}
          placeholder='Title (e.g. "My Amazon Store")'
        />
        <Input
          {...register('linkURL', {
            validate: {
              isUrlValid: (value: string) =>
                value === '' || isUrlValid(value) || 'Please enter a valid URL',
            },
          })}
          fullWidth
          aria-label='Add a link URL'
          autoCapitalize='off'
          autoCorrect='off'
          errorText={errors.linkURL?.message as string}
          placeholder='Add a link'
        />
        <IconButton
          aria-label='Add another link'
          icon='addCircleOutline'
          onClick={onAdd}
        />
      </div>
    </>
  )
}
