import { useState } from 'react'
import { Icon } from '@travelpass/design-system'
import { useFlag } from 'src/common/hooks'
import { myAccountPath } from 'src/constants'
import { DashboardAccountSettingsForm } from './DashboardAccountSettingsForm'

export const DashboardAccountSettings = () => {
  const isDashboardV3AccountSettings = useFlag('dashboardV3AccountSettings')
  const [isModalOpen, setIsModalOpen] = useState(false)

  if (isDashboardV3AccountSettings)
    return (
      <>
        <button
          className='shadow-3 rounded-3 decoration-none b-none color-inherit focus:outline-valley flex cursor-pointer flex-row items-center gap-2 bg-white p-3 text-left'
          type='button'
          onClick={() => setIsModalOpen(true)}
        >
          <div className='space-y-3'>
            <div className='flex flex-row items-center gap-2'>
              <Icon name='settings' />
              <h6 className='type-h6-desktop'>Account Settings</h6>
            </div>
            <p className='type-body-2-desktop color-grey-900'>
              Update your username and manage your account
            </p>
          </div>
          <Icon name='arrowForwardIos' />
        </button>
        {isModalOpen && (
          <DashboardAccountSettingsForm
            onDismiss={() => setIsModalOpen(false)}
          />
        )}
      </>
    )

  /** @todo remove when dashboardV3AccountSettings is released */
  return (
    <a
      className='shadow-3 rounded-3 decoration-none color-inherit flex flex-row items-center gap-2 bg-white p-3'
      href={`${window.location.origin}${myAccountPath}`}
    >
      <div className='space-y-3'>
        <div className='flex flex-row items-center gap-2'>
          <Icon name='settings' />
          <h6 className='type-h6-desktop'>Account Settings</h6>
        </div>
        <p className='type-body-2-desktop color-grey-900'>
          Update your username and manage your account
        </p>
      </div>
      <Icon name='arrowForwardIos' />
    </a>
  )
}
