import { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { SkeletonDots } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { gql } from 'src/__generated__'
import { IdType } from 'src/__generated__/graphql'
import { Helmet } from 'src/common/components'
import { BookingConfirmationSummary } from './BookingConfirmationSummary'
import type {
  BookingConfirmationNode,
  BookingConfirmationHotel,
} from './bookingConfirmationTypes'
import { HotelSearchParams } from '../../details/constants'

const { Standard } = IdType

const BOOKING_CONFIRMATION_SUMMARY = gql(`
  query BookingConfirmationSummary(
    $hotelId: ID!
    $hotelIdType: IdType!
    $nodeId: ID!
  ) {
    node(id: $nodeId) {
      ... on Booking {
        id
        arrival
        bookingDetails {
          hotelDetails {
            room {
              name
            }
          }
          occupancies {
            adults
            childAges
          }
        }
        customerPhone
        name
        departure
        email
        externalConfirmationId
        trip {
          id
        }
      }
    }
    standardHotel(id: $hotelId, idType: $hotelIdType) {
      id
      imageLinks
      name
    }
  }
`)

export const BookingConfirmationSummaryApollo = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { bookingId } = useParams()
  const currentTripId = searchParams.get(HotelSearchParams.tripId)
  const hotelId = searchParams.get(HotelSearchParams.hotelId)
  const {
    data,
    error: hasError,
    loading: isLoading,
  } = useQuery(BOOKING_CONFIRMATION_SUMMARY, {
    skip: !bookingId && !currentTripId && !hotelId,
    variables: {
      hotelId,
      hotelIdType: Standard,
      nodeId: bookingId,
    },
  })

  const { node, standardHotel } = data ?? {}
  const bookingConfirmationData = node as BookingConfirmationNode
  const bookingConfirmationHotelData = standardHotel as BookingConfirmationHotel

  useEffect(() => {
    if (
      hasError ||
      (!isLoading && isEmpty(bookingConfirmationData)) ||
      !currentTripId
    ) {
      navigate('/', {
        replace: true,
      })
    }
  }, [bookingConfirmationData, currentTripId, hasError, isLoading])

  if (hasError) return

  return (
    <>
      <Helmet pageName='Booking Confirmation' />
      <div>
        {isLoading ? (
          <div className='h-50 flex flex-col items-center justify-center'>
            <SkeletonDots />
          </div>
        ) : (
          <BookingConfirmationSummary
            bookingConfirmationData={bookingConfirmationData}
            bookingConfirmationHotelData={bookingConfirmationHotelData}
          />
        )}
      </div>
    </>
  )
}
