import { useRef, useState } from 'react'
import {
  Chip,
  Divider,
  IconButton,
  MenuItem,
  useScreenQuery,
  useSnackbar,
} from '@travelpass/design-system'
import { useParams } from 'react-router-dom'
import { Popover } from 'react-tiny-popover'
import type { PublishedEventCategory } from 'src/__generated__/graphql'
import { useFlag } from 'src/common/hooks'
import { onGuideSessionStorageSelectedIdChange } from 'src/pages/guides/details/useGuideSessionStorageIds'
import { encodeGuideId } from 'src/utils'
import type { GuideDraftDataOld } from '../../../types'
import { useArchiveGuideDraftEventMutation } from '../../../useArchiveGuideDraftEventMutation'
import { useUpdateGuideDraftEventMutation } from '../../../useUpdateGuideDraftEventMutation'
import { GuideDraftEventDeleteModal } from '../../GuideDraftEventDeleteModal'

interface GuideDraftEventCardMenuProps {
  categoryId: string
  eventId: string
  publishedEventCategories?: GuideDraftDataOld['publishedEventCategories']
  showDeleteWarningModal: boolean
}

export const GuideDraftEventCardMenu = ({
  categoryId,
  eventId,
  publishedEventCategories,
  showDeleteWarningModal,
}: GuideDraftEventCardMenuProps) => {
  const [archivePublishedEvent] = useArchiveGuideDraftEventMutation()
  const isGuideUrlShortEnabled = useFlag('guideURLShort')
  const { guideDraftId } = useParams()
  const ref = useRef<HTMLDivElement>(null)
  const { isMobileOrTablet } = useScreenQuery()
  const { addErrorSnack, addSuccessSnack } = useSnackbar()
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const [updatePublishedEvent] = useUpdateGuideDraftEventMutation()

  const onDelete = async () => {
    try {
      if (showDeleteWarningModal && !isDeleteModalOpen) {
        setIsDeleteModalOpen(true)
        return
      }
      await archivePublishedEvent({
        variables: {
          archivePublishedEventInput: {
            publishedEventId: eventId,
          },
        },
      })
      setIsDeleteModalOpen(false)
      onGuideSessionStorageSelectedIdChange('')
      addSuccessSnack({
        timeout: 1000,
        title: 'Guide event deleted',
      })
    } catch (error) {
      console.error(error)
      addErrorSnack({
        timeout: 1000,
        title: 'Server error',
      })
    } finally {
      setIsPopoverOpen(false)
    }
  }

  const onMoveToSectionClick = async (id: PublishedEventCategory['id']) => {
    try {
      await updatePublishedEvent({
        variables: {
          publishedEventInput: {
            guideDraftId: isGuideUrlShortEnabled
              ? encodeGuideId({
                  guideId: guideDraftId,
                  isGuideDraft: true,
                })
              : guideDraftId,
            publishedEventId: eventId,
            publishedEventCategoryId: id,
          },
        },
      })
      onGuideSessionStorageSelectedIdChange('')
      addSuccessSnack({
        timeout: 1000,
        title: 'Guide event updated',
      })
    } catch (error) {
      console.error(error)
      addErrorSnack({
        timeout: 1000,
        title: 'Server error',
      })
    } finally {
      setIsPopoverOpen(false)
    }
  }

  return (
    <>
      <Popover
        align={isMobileOrTablet ? 'end' : 'center'}
        containerClassName='z-5'
        content={
          <div className='max-h-45 of-y-auto shadow-1 w-50 mr-6 mt-1 rounded-xl bg-white p-2'>
            {publishedEventCategories?.map(({ id, name }) => (
              <MenuItem
                key={id}
                aria-label={`Move event to ${name}`}
                isHighlighted={id === categoryId}
                label={name}
                value={id}
                onClick={() => onMoveToSectionClick(id)}
              />
            ))}
            <Divider />
            <MenuItem
              aria-label='Delete event'
              label='Delete'
              startIcon='deleteOutline'
              value='delete'
              onClick={onDelete}
            />
          </div>
        }
        isOpen={isPopoverOpen}
        positions={['bottom']}
        onClickOutside={() => setIsPopoverOpen(false)}
      >
        <div className='relative' ref={ref}>
          <div className='hidden md:block'>
            {categoryId && (
              <div className='[&>button]:color-forest'>
                <IconButton
                  icon='expandMore'
                  size='large'
                  onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                />
              </div>
            )}
            {!categoryId && (
              <Chip
                icon='arrowDownIos'
                isSelected={false}
                label='Move to'
                onClick={() => setIsPopoverOpen(!isPopoverOpen)}
              />
            )}
          </div>
          <div className='[&>button]:color-forest md:hidden'>
            <IconButton
              icon='expandMore'
              size='small'
              onClick={() => setIsPopoverOpen(!isPopoverOpen)}
            />
          </div>
        </div>
      </Popover>
      {isDeleteModalOpen && (
        <GuideDraftEventDeleteModal
          onClose={() => setIsDeleteModalOpen(false)}
          onDelete={onDelete}
        />
      )}
    </>
  )
}
