import { Carousel, useScreenQuery } from '@travelpass/design-system'
import noImagesImage from 'src/assets/images/noImagesImage.png'
import type { HotelRoom as HotelRoomType } from '../hotelRoomsTypes'

interface HotelRoomCardImageProps {
  images: HotelRoomType['images']
  roomName: HotelRoomType['name']
}

export const HotelRoomCardImage = ({
  images,
  roomName,
}: HotelRoomCardImageProps) => {
  const { isMobileOrTablet } = useScreenQuery()

  const imgTag = (image, index) => (
    <img
      key={`${roomName}-${index}-${image?.caption}`}
      alt={image?.caption || roomName}
      className='rd-t-3 lg:rd-3 h-[200px] w-full object-cover lg:h-[134px] lg:w-[196px]'
      loading='lazy'
      src={image?.href || noImagesImage}
      onError={event => {
        event.currentTarget.src = noImagesImage
      }}
    />
  )

  return isMobileOrTablet && images.length ? (
    <Carousel
      isLight
      keyBoardControl
      showArrowsOnHover
      borderRadius={12}
      size='small'
    >
      {images.map((image, index) => {
        return imgTag(image, index)
      })}
    </Carousel>
  ) : (
    imgTag(images[0], 0)
  )
}
