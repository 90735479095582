import type { CSSProperties } from 'react'
import { useState } from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Accordion, AccordionItem, AccordionPanel } from '@reach/accordion'
import { Divider, EmptyState } from '@travelpass/design-system'
import type { GuideOwner } from 'src/pages/guides/details/types'
import { GuideDraftSectionHeader } from './GuideDraftSectionHeader'
import type { GuideDraftDataOld } from '../../types'
import type { UseGetGuideDraftQuery } from '../../useGetGuideDraftQuery'
import { GuideDraftEvents } from '../GuideDraftEvents'

interface GuideDraftSectionProps {
  eventCount: number
  groupedEvent: GuideDraftDataOld['groupedEvents'][0]
  groupedEvents: GuideDraftDataOld['groupedEvents']
  isOverlay?: boolean
  onMapMarkerCenterChange: UseGetGuideDraftQuery['onMapMarkerCenterChange']
  owner: GuideOwner
  publishedEventCategories: GuideDraftDataOld['publishedEventCategories']
  selectedId: string
}

export const GuideDraftSection = ({
  eventCount,
  groupedEvent,
  groupedEvents,
  isOverlay = false,
  onMapMarkerCenterChange,
  owner,
  publishedEventCategories,
  selectedId,
}: GuideDraftSectionProps) => {
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: groupedEvent?.id })
  const [isExpanded, setIsExpanded] = useState<boolean>(true)
  const isDraggingOrOverlay = isDragging || isOverlay
  const isEventGroupEmpty = !groupedEvent?.events?.length
  const isUncategorized = !groupedEvent?.id
  const style: CSSProperties = {
    opacity: isDragging ? 0.5 : undefined,
    transform: CSS.Transform.toString(transform),
    transition,
  }

  // Render empty state if all sections are empty and section is uncategorized
  if (!eventCount && isUncategorized)
    return (
      <div className='b-solid b-1px b-grey-300 bg-grey-100 rounded-2 p-x-3 p-y-3 lg:p-x-12 flex flex-row items-center gap-5 lg:gap-8'>
        <div className='lg:p-y-4 grow space-y-3'>
          <h4 className='type-h6'>Search and Add to your Guide!</h4>
          {/** @todo change this copy (replace 'move' with 'drag') when Drag and Drop across sections is done */}
          <p className='type-body-2'>
            Use the search bar above or zoom in on the map to find
            recommendations for your Guide. Add a note or description, then move
            them into the editable sections below.
          </p>
        </div>
        <div className='p-y-2 min-w-19.5 lg:min-w-39 lg:p-y-0'>
          <EmptyState name='balloon' />
        </div>
      </div>
    )

  // Render empty state if uncategorized section is empty
  if (isEventGroupEmpty && isUncategorized) return

  // Render uncategorized section
  if (isUncategorized)
    return (
      <GuideDraftEvents
        groupedEvent={groupedEvent}
        groupedEvents={groupedEvents}
        owner={owner}
        publishedEventCategories={publishedEventCategories}
        selectedId={selectedId}
        onMapMarkerCenterChange={onMapMarkerCenterChange}
      />
    )

  return (
    <Accordion
      collapsible
      defaultIndex={[0]}
      ref={setNodeRef}
      style={style}
      onChange={() => setIsExpanded(!isExpanded)}
    >
      <AccordionItem>
        <GuideDraftSectionHeader
          attributes={attributes}
          groupedEvent={groupedEvent}
          isDraggingOrOverlay={isDraggingOrOverlay}
          isExpanded={!isDraggingOrOverlay && isExpanded}
          listeners={listeners}
        />
        <Divider className='m-b-6 m-y-3' />
        {!isDraggingOrOverlay && (
          <AccordionPanel>
            {isEventGroupEmpty && (
              <p className='color-grey-700 type-small-text text-center'>
                Use the &apos;move to&apos; button to add events to this section
              </p>
            )}
            <GuideDraftEvents
              groupedEvent={groupedEvent}
              groupedEvents={groupedEvents}
              owner={owner}
              publishedEventCategories={publishedEventCategories}
              selectedId={selectedId}
              onMapMarkerCenterChange={onMapMarkerCenterChange}
            />
          </AccordionPanel>
        )}
      </AccordionItem>
    </Accordion>
  )
}
