import { AutocompleteOption } from '@travelpass/design-system'
import { GuideGeocoderOptionIcon } from './GuideGeocoderOptionIcon'
import { onGuideSessionStorageHoverIdChange } from '../../details'

export const GuideGeocoderOption = ({
  option,
}: {
  option: google.maps.places.PlaceResult
}) => {
  const { formatted_address, name, place_id: placeId, types } = option ?? {}

  return (
    <AutocompleteOption
      className='b-none b-b-grey-300 b-b-solid b-b-1 c-black p-x-4 p-y-3 type-body-1 focus:bg-grey-100 hover:bg-grey-100 w-full cursor-pointer bg-white text-left outline-none'
      value={option}
    >
      <div
        className='flex flex-row items-center gap-2'
        onMouseEnter={() => onGuideSessionStorageHoverIdChange(placeId)}
        onMouseLeave={() => onGuideSessionStorageHoverIdChange('')}
      >
        <GuideGeocoderOptionIcon placeId={placeId} types={types} />
        <div className='space-y-1'>
          <p className='type-body-1-medium line-clamp-1'>{name}</p>
          <p className='c-black type-body-2 line-clamp-1'>
            {formatted_address}
          </p>
        </div>
      </div>
    </AutocompleteOption>
  )
}
