import { GuideImage } from 'src/pages/guides/details/common'
import { getGuideAddress } from 'src/utils'
import type { GuideDraftPublishFormValues } from './types'

interface GuideDraftPublishHeroProps {
  formValues: GuideDraftPublishFormValues
}

export const GuideDraftPublishHero = ({
  formValues,
}: GuideDraftPublishHeroProps) => {
  const { address, name, images } = formValues ?? {}
  const image = images?.[0] ?? ''
  const location = getGuideAddress({
    city: address?.city ?? '',
    country: address?.country ?? '',
    state: address?.state ?? '',
  })

  return (
    <div className='rounded-3 relative h-full overflow-hidden'>
      <GuideImage className='absolute inset-0' src={image} />
      <div className='via-black/42 via-61% to-gray/0 absolute inset-0 bg-gradient-to-t from-black from-10% to-100%' />
      <div className='p-x-4 absolute bottom-7 space-y-2'>
        <h4 className='type-h3-mobile color-white'>{name}</h4>
        <p className='type-overline-mobile color-white'>{location}</p>
      </div>
    </div>
  )
}
