import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const updateUserNotificationPreferencesMutation = gql(`
mutation UpdateCommunicationPreferences($communicationPreferencesInput: UpdateCommunicationPreferencesInput!) {
  updateCommunicationPreferences(communicationPreferencesInput: $communicationPreferencesInput){
      ...UserNotificationPreferencesFragment
    }
  }
`)

export const useUpdateUserNotificationPreferences = () =>
  useMutation(updateUserNotificationPreferencesMutation)
