import { ProgressCircle } from 'src/common/components'
import { getDashboardAchievementsProgressValue } from './dashboardAchievementsUtils'
import { DashboardTabs } from '../DashboardTabs'
import type { DashboardTabsProps } from '../DashboardTabs/DashboardTabs'

interface DashboardAchievementsProps {
  numberOfCreatedGuides: number
}

export const DashboardAchievements = ({
  numberOfCreatedGuides,
}: DashboardAchievementsProps) => {
  /** @todo should be handled on the BE. */
  const firstValue = getDashboardAchievementsProgressValue(
    numberOfCreatedGuides,
    1
  )
  /** @todo should be handled on the BE. */
  const secondValue = getDashboardAchievementsProgressValue(
    numberOfCreatedGuides,
    3
  )
  /** @todo should be handled on the BE. */
  const thirdValue = getDashboardAchievementsProgressValue(
    numberOfCreatedGuides,
    5
  )

  const tabs: DashboardTabsProps['tabs'] = [
    {
      icon: 'starBorder',
      label: 'Achievements',
      onClick: () => {},
    },
  ]

  return (
    <section>
      <DashboardTabs activeIndex={0} size='small' tabs={tabs} variant='dark' />
      <div className='shadow-3 rounded-3 rounded-tl-0 space-y-4 bg-white p-3'>
        <p className='c-grey-800 type-body-2'>
          {numberOfCreatedGuides} Total Guides Created
        </p>
        <div className='flex flex-row gap-6'>
          <div>
            <ProgressCircle
              ariaLabel={`${firstValue === 1 ? '1 guide has been created' : 'No guides have been created'}`}
              label='1'
              value={firstValue}
            />
            <p className='c-grey-800 type-small-text-desktop text-center'>
              {numberOfCreatedGuides < 1 ? '0 Guides' : '1 Guide'}
            </p>
          </div>
          <div>
            <ProgressCircle
              ariaLabel={`${secondValue >= 1 ? '3 guides have been created' : 'Less than 3 guides have been created'}`}
              label='3'
              value={secondValue}
            />
            {numberOfCreatedGuides > 1 && (
              <p className='c-grey-800 type-small-text-desktop text-center'>
                {numberOfCreatedGuides < 3 ? '2 Guides' : '3 Guides'}
              </p>
            )}
          </div>
          <div>
            <ProgressCircle
              ariaLabel={`${thirdValue >= 1 ? '5 or more guides have been created' : 'Less than 5 guides have been created'}`}
              label='5+'
              value={thirdValue}
            />
            {numberOfCreatedGuides > 3 && (
              <p className='c-grey-800 type-small-text-desktop text-center'>
                {numberOfCreatedGuides < 5
                  ? `${numberOfCreatedGuides} Guides`
                  : '5+ Guides'}
              </p>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
