export const getGuideMetricString = (
  numberOfMetrics: number,
  metric: string
) => {
  const numberOfMetricsFormatted =
    numberOfMetrics === null || isNaN(numberOfMetrics) ? 0 : numberOfMetrics

  if (metric === '') return numberOfMetricsFormatted.toString()

  const trailingChar = numberOfMetricsFormatted === 1 ? '' : 's'

  return `${numberOfMetricsFormatted} ${metric}${trailingChar}`
}
