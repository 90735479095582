import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'

const getPredefinedGuideTagsQuery = gql(`
  query GetPredefinedGuideTags {
    getPredefinedGuideTags {
      id
      name
    }
  }
`)

export const useGetPredefinedGuideTagsQuery = () =>
  useQuery(getPredefinedGuideTagsQuery)
