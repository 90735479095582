import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'

const PARENT_CHAINS_ALL = gql(`
  query ParentChainsAll {
    parentChainsAll {
      id
      name
    }
  }  
`)

export const useGetParentChainsAll = () => useQuery(PARENT_CHAINS_ALL)
