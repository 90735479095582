import { isMobile } from 'react-device-detect'
import {
  Accordion,
  AccordionItemGroup,
  AccordionPanel,
  AccordionButton,
} from 'src/common/components/Accordion'

const AccordionSection = ({ title, children }) => {
  return (
    <AccordionItemGroup className='b-b-1 b-b-grey-300 b-b-solid children:px-4'>
      <AccordionButton className='hover:bg-black/3 text-forest cursor-pointer appearance-none py-4 text-xl transition-colors'>
        {title}
      </AccordionButton>
      <AccordionPanel>{children}</AccordionPanel>
    </AccordionItemGroup>
  )
}

export const CompetitionFaq = () => {
  return (
    <Accordion multiple={isMobile}>
      <AccordionSection
        title='How do I apply and become a candidate for the Chief Hospitality
            Officer position?'
      >
        <p>To officially apply for the position, you will need to:</p>
        <ol>
          <li>
            Create a profile on Travelpass.com complete with your bio, photo and
            passion for travel.
          </li>
          <li>
            Complete at least one Guide for a destination that you know well and
            love. These guides help share experiences and recommendations with
            fellow travelers to inspire, educate and explore.
          </li>
          <li>
            Create a one-minute video telling us who you are and why you would
            make a great Chief Hospitality Officer, then post it on YouTube or
            Vimeo (and link it on your Travelpass profile).
          </li>
          <li>
            Share your Travelpass profile with friends, family and social media
            networks so they can vote for your candidacy.
          </li>
          <li>Read and agree to the Campaign’s Terms and Conditions</li>
        </ol>
      </AccordionSection>
      <AccordionSection title='What does “CHO” stand for?'>
        We’re looking for a Chief Hospitality Officer &ndash;someone who is
        passionate about travel and helping our Travelpass community answer the
        3 questions:
        <ol className='list-decimal'>
          <li>Where should I go?</li>
          <li>What should I do?</li>
          <li>Where should I eat?</li>
        </ol>
      </AccordionSection>
      <AccordionSection title='What are the roles and responsibilities of the CHO?'>
        <p>
          The CHO will be Travelpass’ brand and experience ambassador. Their job
          will be to travel the world (yes, all over the world!) visiting iconic
          destinations, sampling unique, diverse cuisine and experiencing the
          biggest events, sports, excursions and activities.. During these
          experiences, the CHO will create content (videos, images, social media
          posts) for Travelpass and its social channels. The position may also
          include some research, planning, and coordination with the Travelpass
          team and local tourism departments. The CHO will also be asked to
          attend local marketing events.
        </p>
      </AccordionSection>
      <AccordionSection title='What are the specific job requirements?'>
        <p>
          We are looking for someone who is passionate about traveling, people,
          and cultures, and can communicate their enthusiasm creatively through
          video and social media. No other experience is required, but it will
          help applicants to have prior experience with blogging, video
          creation, and social platform posting&ndash;either on their own
          channels or as part of a previous position. The candidate must be
          available to travel globally during the proposed six-month period
          without interruptions and work a 40-hour work week. Check out our
          Terms and Conditions for more information.
        </p>
      </AccordionSection>
      <AccordionSection title='What are the restrictions for applying?'>
        <p>
          Applicants must have a valid passport, be able to travel domestically
          and Internationally, be at least 18 years old by Jan 1, 2025, and be
          fluent in written and spoken English.
        </p>
      </AccordionSection>
      <AccordionSection title='Who is your ideal candidate?'>
        <p>
          We are looking for an outgoing, authentic, and hard-working individual
          (or team) who can skillfully showcase destinations to a global
          audience. The ideal candidate will help build excitement, trust, and
          recognition for the Travelpass brand as well as the destinations they
          will visit.
        </p>
      </AccordionSection>
      <AccordionSection title='How do I know if my application went through?'>
        <p>
          You will receive an email confirmation that your submission has been
          received. You will also be able to search for your profile on the Vote
          Now page.
        </p>
      </AccordionSection>
      <AccordionSection title='What does the selection process look like?'>
        <ul>
          <li>
            As soon as an application has been submitted, it can be publicly
            voted on by friends, family, and followers of applicants.
          </li>
          <li>
            After the submission and voting periods have ended, all applications
            (including videos, written content, and number of votes per each
            application) will be reviewed by a panel of Travelpass
            representatives. From these applications, the Top 100 applicants
            will be selected and asked to create a second video.
          </li>
          <li>
            After the Top 100 are announced, the public will be able to show
            support for their favorite applications in a second round of voting.
            From the Top 100 applicants, Travelpass will review the applications
            and select the Top 10 to attend our week-long Interview Event.
          </li>
          <li>
            The winner(s) will be chosen and announced during the culmination of
            the Interview Event.
          </li>
        </ul>
        <p>
          Read more about the selection process and voting in our Terms and
          Conditions
        </p>
      </AccordionSection>
      <AccordionSection title='Do I win if I get the most votes?'>
        <p>
          Our search for a Chief Hospitality Officer is a true job search. We
          are searching for the best travel representative we can find. Voting
          in round 1 and 2 is just 1 of the aspects on which applicants will be
          evaluated as a way to measure how well the applicant engages and
          connects with an audience. This is{' '}
          <strong className='underline'>not</strong> a popularity contest and
          the top vote earner is not guaranteed a spot in the next rounds of the
          job search process. Travelpass reserves the right to select the best
          candidate based on the criteria listed in the job description and
          brand fit.
        </p>
      </AccordionSection>
      <AccordionSection title='Who is judging the applications?'>
        <p>
          A Travelpass panel of selected representatives will be reviewing each
          application. This panel may include CHO Search Campaign sponsors,
          travel industry experts, and other employees of Travelpass who will
          work regularly with the CHO when the job begins.
        </p>
      </AccordionSection>
      <AccordionSection title='Will you run background checks on the applicants?'>
        <p>We will run background checks on the Top 10 applicants.</p>
      </AccordionSection>
      <AccordionSection title='Why did you decide to run this job search?'>
        <p>
          Our goal is to make Travelpass the largest and best platform for
          discovery, planning, and travel booking. So many travelers have great
          ideas, recommendations and memories from their trips. Our platform
          allows you to not only record the activities you loved during your
          trip, but share the information with others. Building an authentic,
          unbiased community for travelers to share their guides will inspire
          others to see more of the world.
        </p>
        <p>
          To make that happen, we need authentic content (video, images, and
          stories) that we can share on our site and social media platforms. We
          decided that the best way to do this would be to hire a
          &ldquo;Hospitality Officer&rdquo; to travel the world and help us
          create guides and content.
        </p>
      </AccordionSection>
      <AccordionSection title="What's the timeline for all of this? When will you announce the finalists? The winner?">
        <p>Here&apos;s what each stage looks like in a nutshell:</p>
        <ul className='list-none'>
          <li>Aug 20: Campaign launch, applications open, voting begins</li>
          <li>Sept 22: Deadline for applications and voting</li>
          <li>Sept 26: Top 100 applicants asked to make second video</li>
          <li>
            Oct 1-13: Voting period for Top 100 applicants (with second video)
          </li>
          <li>Oct 14: Top 10 finalists announced</li>
          <li>
            Oct 21-27: Top 10 finalists attend a live, on-site Interview event
          </li>
          <li>Oct 28: CEO chosen and announced</li>
        </ul>
        <p>For a more detailed timeline, check out our Terms and Conditions.</p>
      </AccordionSection>
      <AccordionSection title='How long will the job last?'>
        <p>This is a 6-month contracted position.</p>
      </AccordionSection>
      <AccordionSection title='Is this a salaried position? Do I get overtime?'>
        <p>
          The CEO will be paid a gross monthly payment of $10,000 USD with the
          expectation of working around 40 hours each week. We do not anticipate
          a need for overtime.
        </p>
      </AccordionSection>
      <AccordionSection title='Is this a 9 - 5 or do I make my own hours?'>
        <p>
          Working hours will need to be somewhat flexible to accommodate the
          events and activities of the CHO. A schedule will be coordinated with
          the CHO in advance of weekly activities.
        </p>
      </AccordionSection>
      <AccordionSection title='Can you outline the approval process for the content the CHO will be creating?'>
        <p>
          A calendar will be created by the CHO and the Travelpass team
          outlining events and activities. We&apos;ll then determine what
          content will be created and how it will be promoted.
        </p>
      </AccordionSection>
      <AccordionSection title='Is there an opportunity for this to become a full-time job?'>
        <p>
          While the CHO is a 6-month position, at the conclusion of the program,
          we will evaluate the CHO&apos;s performance for potential to remain an
          employee of Travelpass in some capacity.
        </p>
      </AccordionSection>
      <AccordionSection title='Will the candidate be able to use the content they create on their own social channels?'>
        <p>
          Content created by the CHO for Travelpass will be the property of
          Travelpass. However, there will still be an opportunity for the CHO to
          share and build his or her own channels and audiences in connection
          with and during the CHO experience.
        </p>
      </AccordionSection>
      <AccordionSection title='What if a relative dies or some other emergency occurs while I am CHO?'>
        <p>
          Our company is all about the well-being of our customers and our
          employees. We will work with our CHO to schedule periods of personal
          leave in the case of extenuating circumstances.
        </p>
      </AccordionSection>
      <AccordionSection title='Can I take a vacation while I’m the CHO?'>
        <p>
          While our CHO will be having amazing adventures in some of the world’s
          top vacation destinations, we also realize everyone needs to take a
          break. We will provide you with a reasonable amount of time off while
          you are the CHO. We simply ask for advance notification to
          appropriately schedule activities and events around your break.
        </p>
      </AccordionSection>
      <AccordionSection title='What can I bring with me?'>
        <p>
          Aside from your passion for travel and sense of adventure, we
          recommend you bring the same items you would take on a normal trip.
          This includes appropriate clothing, toiletries, and anything else that
          will help you be comfortable traveling for six months.
        </p>
      </AccordionSection>
      <AccordionSection title='I have a dog; can I bring my dog or cat?'>
        <p>
          Several legal hurdles exist with bringing a pet to a foreign country,
          so we kindly ask that you find someone to watch your four-legged
          friend back home while you serve as our CHO.
        </p>
      </AccordionSection>
      <AccordionSection title='Can I bring my family with me?'>
        <p>
          Yes! There are some limitations here, but teams are welcome to apply
          together. We recognize many of you have families/roommates/business
          partners who help you make great content and who you love to work
          with. Whatever those relationships are, you can make a case for them
          on your application. Just keep in mind that wages paid and lodging
          provided will remain the same whether an individual or team is chosen
          for the CHO position. Read more about teams in the Job Description and
          Terms and Conditions.
        </p>
      </AccordionSection>
      <AccordionSection title='Where will I stay during my travels?'>
        <p>
          Our CHO will stay in different hotels and resorts around the world. A
          more detailed itinerary will be provided as the start date nears.
        </p>
      </AccordionSection>
      <AccordionSection title='If I am selected, will I need to use my own gear?'>
        <p>
          No, we will work with the chosen candidate to provide the necessary
          equipment.
        </p>
      </AccordionSection>
      <AccordionSection title='Who is paying for what?'>
        <p>
          Travelpass and our tourism partners will be providing all lodging and
          necessary equipment to complete the job of CHO. All activities and
          events in which the CHO takes part will also be covered by the
          company. Meals at restaurants featured as part of content creation
          will be covered by Travelpass as well. An additional meals stipend
          will be provided.
        </p>
      </AccordionSection>
      <AccordionSection title='How do I know this is for real?'>
        <p>
          Great question! Travelpass is an established travel site focused on
          answering the 3 most important travel questions:
        </p>
        <ol className='list-decimal'>
          <li>Where should I go?</li>
          <li>What should I do?</li>
          <li>Where should I eat?</li>
        </ol>
        <p>
          We are committed to creating a travel community where travelers can
          share their guides and trips with recommendations to answer these
          questions. Hiring a Chief Hospitality Officer will help us in this
          mission.
        </p>
        <p>
          Feel free to check out our website, social media accounts and LinkedIn
          Pages to learn more about Travelpass:
        </p>
        <ul>
          <li>
            <a href='/'>Travelpass.com</a>
          </li>
          <li>
            <a href='/'>Travelpass Instagram</a>
          </li>
          <li>
            <a href='/'>Travelpass TikTok</a>
          </li>
          <li>
            <a href='/'>Travelpass Group LinkedIn</a>
          </li>
        </ul>
      </AccordionSection>
    </Accordion>
  )
}
