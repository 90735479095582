import { useEffect, useState } from 'react'
import {
  Icon,
  Input,
  Modal,
  ModalScrollContents,
  UnderlineTab,
  useScreenQuery,
} from '@travelpass/design-system'
import { FollowType } from 'src/__generated__/graphql'
import { FollowersList } from './FollowersList'
import type { FollowersModalType } from '../../types'
import { useSearchProfiles } from '../ProfileSearchBar/useSearchProfiles'
import { useSearchFollowUserProfiles } from '../hooks/useSearchFollowUserProfiles'

const getViewTypeIndex = (viewType: FollowersModalType) => {
  if (viewType === 'following') return 0
  if (viewType === 'followers') return 1
  if (viewType === 'explore') return 2
  return 1
}

interface FollowersModalProps {
  accountHandle: string
  onClose(): void
  viewType: FollowersModalType
  toggleModal?: (viewType: FollowersModalType) => void
  userId: string
}

export const FollowersModal = ({
  accountHandle,
  onClose,
  viewType,
  toggleModal,
  userId,
}: FollowersModalProps) => {
  //State
  const { searchProfiles, data: searchData } = useSearchProfiles()
  const searchedProfiles = searchData?.searchUserProfiles || []
  const [searchString, setSearchString] = useState('')
  const { isMobileScreen } = useScreenQuery()
  const [tabIndex, setTabindex] = useState(getViewTypeIndex(viewType))
  const followTypeSearch =
    tabIndex == 0 ? FollowType.Followed : FollowType.Following

  //GraphQl
  const {
    countOfFollowing,
    countOfFollowed,
    allFollowedUsers,
    allFollowingUsers,
    followArray,
    loading,
    refetch,
  } = useSearchFollowUserProfiles({
    searchString,
    followTypeSearch,
    userId,
    accountHandle,
  })

  //Others
  useEffect(() => {
    if (searchString) {
      if (tabIndex == 2) {
        searchProfiles({ variables: { searchString } })
      } else {
        refetch({ searchInput: { searchString, followTypeSearch, userId } })
      }
    }
  }, [searchString, refetch, tabIndex])

  const onTabClick = (viewType: FollowersModalType, index) => {
    toggleModal(viewType)
    setTabindex(index)
    setSearchString('')
  }

  const tabs = [
    {
      label: `Following ${countOfFollowed || 0}`,
      onClick: () => onTabClick('following', 0),
    },
    {
      label: `Followers ${countOfFollowing || 0}`,
      onClick: () => onTabClick('followers', 1),
    },
    {
      label: 'Explore Users',
      onClick: () => onTabClick('explore', 2),
    },
  ]

  const listToDisplay =
    searchString.length >= 3
      ? followArray
      : tabIndex == 0
        ? allFollowedUsers
        : allFollowingUsers

  return (
    <Modal
      data-testid='followers-modal'
      size='medium'
      title={tabIndex == 0 ? 'Following' : tabIndex == 1 ? '' : 'Explore Users'}
      onDismiss={onClose}
    >
      <div className='my-5 flex w-full justify-center'>
        <UnderlineTab
          activeIndex={tabIndex}
          tabs={tabs}
          willScroll={isMobileScreen}
        />
      </div>
      <Input
        autoFocus
        fullWidth
        aria-label='Search'
        placeholder='Search'
        slotBefore={<Icon name='search' />}
        value={searchString}
        onChange={e => setSearchString(e.target.value)}
      />
      <ModalScrollContents>
        <FollowersList
          emptyListMessage={
            tabIndex == 0
              ? "You're not following anybody yet"
              : tabIndex == 1
                ? "You don't have followers yet"
                : 'No profile found'
          }
          list={
            tabIndex == 2
              ? searchString.length == 0
                ? []
                : searchedProfiles
              : listToDisplay
          }
          loading={loading}
        />
      </ModalScrollContents>
    </Modal>
  )
}
