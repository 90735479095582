import { useEffect } from 'react'
import { useGoogleMap } from '@react-google-maps/api'
import type { PublishedEvent } from 'src/__generated__/graphql'
import { constructMapCenter } from 'src/common/components/Map'
import { useGuideSessionStorageIds } from '../../useGuideSessionStorageIds'

interface GuideMapEventsProps {
  points: PublishedEvent[]
}

export const GuideMapEvents = ({ points }: GuideMapEventsProps) => {
  const { selectedId } = useGuideSessionStorageIds()
  const map = useGoogleMap()

  // Sets the bounds of the map to fit all the provided points.
  useEffect(() => {
    if (points?.length) {
      const bounds = new google.maps.LatLngBounds()
      points?.map(({ addresses }) => {
        const { lat, lng } = constructMapCenter(
          addresses?.[0]?.lat,
          addresses?.[0]?.long
        )

        return (
          lat &&
          lng &&
          bounds.extend({
            lat,
            lng,
          })
        )
      })

      map.fitBounds(bounds, {
        bottom: 100,
        left: 50,
        right: 50,
        top: 100,
      })
    }
  }, [map, points])

  // Sets the center of the map to the selected point.
  useEffect(() => {
    if (points?.length && selectedId) {
      const { addresses } = points?.find(({ id }) => id === selectedId) ?? {}
      const { lat, lng } = constructMapCenter(
        addresses?.[0]?.lat,
        addresses?.[0]?.long
      )

      if (lat && lng) {
        map?.setCenter({
          lat,
          lng,
        })
      }
    }
  }, [map, points, selectedId])

  return <></>
}
