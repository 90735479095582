import { Skeleton } from '@travelpass/design-system'

export const DashboardProfileLoading = () => (
  <div className='shadow-3 rounded-3 p-y-3.5 p-x-3 space-y-6 bg-white'>
    <div className='h-37.5 rounded-t-3 relative flex flex-col items-center'>
      <Skeleton />
      <div className='bottom--10.5 w-25 h-25 absolute'>
        <Skeleton variant='circular' />
      </div>
    </div>
    <div className='p-t-10 space-y-8 p-3'>
      <div className='space-y-4'>
        <div className='flex flex-row items-center justify-between gap-4'>
          <div className='h-8 w-24'>
            <Skeleton />
          </div>
          <div className='flex flex-row items-center justify-between gap-2'>
            <div className='h-4 w-4'>
              <Skeleton />
            </div>
            <div className='h-4 w-4'>
              <Skeleton />
            </div>
          </div>
        </div>
        <div className='flex flex-row items-center gap-4'>
          <div className='h-6.5 p-x-1.5 w-28'>
            <Skeleton />
          </div>
          <div className='h-6.5 p-x-1.5 w-28'>
            <Skeleton />
          </div>
        </div>
      </div>
    </div>
  </div>
)
