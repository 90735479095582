import { useEffect, useState } from 'react'
import {
  Button,
  DropdownOption,
  Input,
  SkeletonDots,
  useSnackbar,
} from '@travelpass/design-system'
import { FormProvider, useForm } from 'react-hook-form'
import type { GetCurrentUserQuery } from 'src/__generated__/graphql'
import { FormDropdown } from 'src/common/components/FormDropdown'
import { FormInput } from 'src/common/components/FormInput'
import { useUserProfileQuery } from 'src/common/hooks/useUserProfileQuery'
import { countries } from 'src/constants'
import { emailValidationRegex } from 'src/constants/validation'
import { Legend } from 'src/pages/account-settings/components/common/Legend'
import { useUserProfileMutation } from 'src/pages/account-settings/hooks/useUserProfileMutation'
import { isValidPhoneNumber } from 'src/utils'

const DEFAULT_VALUES = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  addressFirstLine: '',
  addressSecondLine: '',
  city: '',
  state: '',
  country: '',
  zip: '',
}

const getDefaultPersonalInfo = (user: GetCurrentUserQuery['currentUser']) => {
  const values = DEFAULT_VALUES
  for (const key in values) {
    // update values with user data if it exists
    if (user && user[key]) values[key] = user[key]
  }
  return values
}

export const PersonalInformationForm = () => {
  const { data, loading } = useUserProfileQuery()
  const user = data?.currentUser
  const [defaultValues] = useState<typeof DEFAULT_VALUES>()
  const { addWarningSnack, addErrorSnack, addSuccessSnack } = useSnackbar()
  const methods = useForm<typeof DEFAULT_VALUES>({ defaultValues })
  const { handleSubmit, register, formState, reset } = methods
  const [mutation, { loading: mutationLoading }] = useUserProfileMutation()

  useEffect(() => {
    reset(getDefaultPersonalInfo(user))
  }, [user])

  const onSubmit = handleSubmit(async data => {
    if (mutationLoading) {
      addWarningSnack({
        title: 'Please wait a moment',
        subTitle: 'Your request is in progress',
      })
      return
    }
    try {
      const response = await mutation({
        variables: {
          input: {
            id: user?.id,
            ...data,
          },
        },
      })
      if (response?.data?.updateUser?.user) {
        const updatedValues = getDefaultPersonalInfo(
          response.data.updateUser.user
        )
        reset(updatedValues)
        addSuccessSnack({ title: 'Personal Information updated!' })
      }
    } catch (error) {
      addErrorSnack({ title: 'An unexpected error was found.' })
    }
  })

  if (loading) return <SkeletonDots />

  return (
    <div className='mb-8 p-6'>
      <FormProvider {...methods}>
        <form className={personalInfoContainerCss} onSubmit={onSubmit}>
          <section className={sectionCss}>
            <Legend>Legal Name</Legend>
            <div className={responsiveFormSection}>
              <Input
                fullWidth
                errorText={formState?.errors?.firstName?.message}
                label='First Name *'
                maxLength={45}
                placeholder='First Name'
                {...register('firstName', {
                  required: 'First name is required',
                  pattern: {
                    message: 'At least two alpha characters are required',
                    value: /^[a-zA-Z]{2,}$/,
                  },
                })}
              />
              <Input
                fullWidth
                errorText={formState?.errors?.lastName?.message}
                label='Last Name *'
                placeholder='Last Name'
                {...register('lastName', { required: 'Last name is required' })}
              />
            </div>
          </section>
          <section className={sectionCss}>
            <Legend>Contact</Legend>
            <div className={responsiveFormSection}>
              <FormInput
                fullWidth
                format='(###) ###-####'
                label='Phone Number'
                name='phoneNumber'
                placeholder='(XXX) XXX-XXXX'
                rules={{
                  validate: (phone: string) => {
                    if (phone.length === 0) return true
                    return (
                      isValidPhoneNumber(phone) || 'Phone number is invalid'
                    )
                  },
                }}
              />
              <Input
                fullWidth
                errorText={formState?.errors?.email?.message}
                label='Email *'
                placeholder='you@email.com'
                {...register('email', {
                  required: 'Email is required',
                  validate: (value: string) => {
                    const regex = new RegExp(emailValidationRegex)
                    return regex.test(value) || 'Email Address is invalid'
                  },
                })}
              />
            </div>
          </section>
          <section className={sectionCss}>
            <Legend>Address</Legend>
            <Input
              fullWidth
              label='Street address'
              placeholder='Street address'
              {...register('addressFirstLine')}
            />
            <Input
              fullWidth
              placeholder='Apt, suite. (optional)'
              {...register('addressSecondLine')}
            />
            <div className='grid grid-cols-1 gap-4 gap-x-8 lg:grid-cols-2'>
              <Input label='City' placeholder='City' {...register('city')} />
              <Input label='State' placeholder='State' {...register('state')} />
              <Input
                label='Zip code'
                placeholder='Zip code'
                {...register('zip')}
              />
              <FormDropdown
                label='Country'
                name='country'
                placeholder='Country'
              >
                {countries.map(({ label, value }) => (
                  <DropdownOption key={value} value={value}>
                    {label}
                  </DropdownOption>
                ))}
              </FormDropdown>
            </div>
          </section>
          <footer className='flex justify-center gap-4'>
            <div className='lg:w-20% w-50%'>
              <Button fullWidth label='Save' type='submit' />
            </div>
          </footer>
        </form>
      </FormProvider>
    </div>
  )
}

const personalInfoContainerCss =
  'flex flex-col my-0 mx-auto gap-y-4 gap-x-0 md:gap-y-8'

const responsiveFormSection =
  'flex flex-col md:flex-row gap-y-4 gap-x-10 md:gap-y-0 md:gap-x-8'

const sectionCss = 'gap-5 grid'
