import { Button, useSnackbar } from '@travelpass/design-system'
import { GuideStatus } from 'src/__generated__/graphql'
import {
  useCreateGuideFromGuideDraftMutation,
  useUpdateGuideMutation,
} from 'src/pages/guides'
import type { GuideDraftData } from '../../types'

interface GuideDraftPublishQuickBannerProps {
  guideDraftData: GuideDraftData
}

export const GuideDraftPublishQuickBanner = ({
  guideDraftData,
}: GuideDraftPublishQuickBannerProps) => {
  const { id: guideDraftId } = guideDraftData ?? {}
  const [updateGuide] = useUpdateGuideMutation()
  const [createGuideFromGuideDraft] = useCreateGuideFromGuideDraftMutation()
  const { addSuccessSnack } = useSnackbar()

  const onQuickPublish = async () => {
    try {
      const createGuideFromGuideDraftResponse = await createGuideFromGuideDraft(
        {
          variables: {
            input: {
              guideDraftId,
            },
          },
        }
      )
      const id =
        createGuideFromGuideDraftResponse?.data?.createGuideFromGuideDraft
          ?.guide?.id
      await updateGuide({
        variables: {
          updateGuideInput: {
            guideId: id,
            status: GuideStatus.Published,
          },
        },
      })
      addSuccessSnack({
        title: 'Your Guide has been updated.',
      })
    } catch (error) {
      addSuccessSnack({
        title: 'There was an error updating your Guide.',
      })
    }
  }

  return (
    <div className='p-x-2 md:p-r-6 md:p-l-15.5 lg:p-r-12 p-y-3 bg-teal-medium relative flex flex-col items-center justify-center gap-3 md:flex-row md:justify-between md:gap-4'>
      <svg
        className='absolute left-0 hidden md:block'
        fill='none'
        height='32'
        viewBox='0 0 52 32'
        width='52'
      >
        <path
          d='M36.0029 18.0919C36.0029 18.0919 31.5339 23.7226 19.9863 18.3884C19.9863 18.3884 8.15558 14.2212 11.7947 7.30838C14.9774 1.73326 21.9305 1.41838 23.7765 9.71811C24.7282 13.9968 23.7765 18.3884 11.7943 26.4845C3.93805 31.049 3.20919 30.4182 -6.02493 29.1328'
          stroke='white'
          strokeDasharray='4.34 4.34'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit='1.5'
          strokeWidth='1.25'
        />
        <path
          d='M39.3513 10.7725L45.0492 14.9075C45.1449 14.9771 45.2557 15.0236 45.3737 15.0435C45.4917 15.0634 45.6139 15.0561 45.7312 15.0224C45.8485 14.9886 45.958 14.9291 46.0518 14.8482C46.1456 14.7674 46.2212 14.6672 46.2732 14.555L50.7655 4.76687C51.039 4.17092 50.5575 3.53671 49.9209 3.65278L35.5368 6.29287C34.8256 6.42336 34.5867 7.34675 35.1581 7.75962L36.6735 8.85515L37.5009 9.4479M41.5399 12.3596L39.3981 14.3585C38.761 14.8118 37.9461 14.2303 38.1783 13.4889L38.7763 11.5795C38.8892 11.2192 39.1305 10.9088 39.4523 10.71L43.1089 8.44928'
          stroke='white'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.25'
        />
      </svg>
      <p className='type-small-text-desktop color-white text-center md:text-left'>
        Changes have been made that aren&apos;t reflected publicly. Want to
        update your Guide?
      </p>
      <Button
        endIcon='arrowForward'
        size='small'
        variant='transparent'
        onClick={onQuickPublish}
      >
        Quick Update
      </Button>
    </div>
  )
}
