import { useEffect, useState } from 'react'
import { Radio } from '@travelpass/design-system'
import { useFormContext } from 'react-hook-form'
import type { Vibe } from 'src/__generated__/graphql'
import { CheckedTile } from '../../common/CheckedTile'
import type { ExtendedUpsertSearchPreferencesInput } from '../ExtendedUpsertInputType'
import { useGetVibes } from '../hooks/useGetVibes'

export const QuestionVibes = () => {
  const [noneSelected, setNoneSelected] = useState(false)
  const { watch, setValue } =
    useFormContext<ExtendedUpsertSearchPreferencesInput>()
  const { data } = useGetVibes()
  const vibesList = data?.getVibes || []

  const vibeSelections = watch('vibeSelections')
  const vibeSelectionStrings = watch('vibeSelectionStrings')

  const setBothVibeLists = ({ vibeIds, vibeNames, shouldDirty }) => {
    setValue('vibeSelections', vibeIds, { shouldDirty })
    setValue('vibeSelectionStrings', vibeNames, { shouldDirty })
  }

  useEffect(() => {
    if (vibesList.length) {
      setBothVibeLists({
        vibeIds: vibesList.filter(vibe => vibe.isSelected).map(vibe => vibe.id),
        vibeNames: vibesList
          .filter(vibe => vibe.isSelected)
          .map(vibe => vibe.displayName),
        shouldDirty: false,
      })
    }
  }, [vibesList])

  const handleNoneClicked = () => {
    setNoneSelected(true)
    setBothVibeLists({ vibeIds: [], vibeNames: [], shouldDirty: true })
  }

  const toggleVibe = (vibe: Vibe) => {
    if (noneSelected) {
      setNoneSelected(false)
    }

    if (vibeSelections?.includes(vibe.id)) {
      setBothVibeLists({
        vibeIds: vibeSelections.filter(v => v !== vibe.id),
        vibeNames: vibeSelectionStrings.filter(v => v !== vibe.displayName),
        shouldDirty: true,
      })
    } else {
      setBothVibeLists({
        vibeIds: [...vibeSelections, vibe.id],
        vibeNames: [...vibeSelectionStrings, vibe.displayName],
        shouldDirty: true,
      })
    }
  }

  return (
    <div className='flex flex-col items-center'>
      <div className='type-h2 mb-4 self-start'>
        What vibe are you looking for?
      </div>
      <div className='type-h5 mb-10 self-start'>
        Select all the vibes that interest you.
      </div>
      <div className='grid grid-cols-1 gap-10 sm:grid-cols-2 md:grid-cols-3'>
        {vibesList.map((vibe, index) => (
          <div key={index} className='flex flex-col items-center justify-start'>
            <CheckedTile
              pressed={vibeSelections?.includes(vibe.id)}
              size='unsetHeight'
              onClick={() => toggleVibe(vibe)}
            >
              <img
                alt={vibe.displayName}
                className='max-h-66 max-w-66 m-2 h-auto w-[95%] rounded-md'
                src={vibe.imageUrl}
              />
            </CheckedTile>
            <span className='type-h4 mt-2 cursor-default select-none'>
              {vibe.displayName}
            </span>
          </div>
        ))}
      </div>
      <div className='type-h2 mt-10'>
        <Radio
          id='vibe-none'
          isChecked={noneSelected}
          label='None of these match my vibe'
          value='noneSelected'
          onClick={handleNoneClicked}
        />
      </div>
    </div>
  )
}
