import { Illustration } from '@travelpass/design-system'

export const GuideDraftPublishImagesSearchEmpty = () => (
  <div className='b-1px b-grey-300 b-solid p-b-10 p-t-8 p-x-20 rounded-2 md:p-x-40 flex h-full flex-col items-center justify-center gap-4 bg-white'>
    <div className='w-31'>
      <Illustration name='world' />
    </div>
    <span className='color-grey-700 type-body-1'>
      Search destinations above and choose photos for your guide.
    </span>
  </div>
)
