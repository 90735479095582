import { Link } from '@travelpass/design-system'
import classNames from 'classnames'
import type { NavigateFunction } from 'react-router-dom'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import {
  lucencyNumber,
  onPhoneNumberClick,
} from 'src/config/analytics/lucencyUtils'
import type { FaqItem } from './faqDetailsTypes'
import { MY_TRIPS_LINK, SEARCH_LINK } from '../../../infoConstants'

const phoneNumber = lucencyNumber()

export const faqItemsData = (navigate: NavigateFunction): FaqItem[] => [
  {
    content: (
      <p className={contentParagraphClasses}>
        You may access and cancel your reservation{' '}
        <Link label='here' onClick={() => navigate(MY_TRIPS_LINK)} />. Before
        doing so, please carefully review your reservation&apos;s cancellation
        policy, as not all reservations are fully refundable. For further
        assistance, you may contact our support team at{' '}
        <Link
          href={`tel:${phoneNumber}`}
          label={phoneNumber}
          onClick={() => onPhoneNumberClick(phoneNumber)}
        />
        , available Monday through Friday from 9 a.m. to 5 p.m. MST.
      </p>
    ),
    title: 'How do I cancel my hotel reservation?',
  },
  {
    content: (
      <>
        <p className={contentParagraphClasses}>
          Unfortunately, modifications cannot be made to a reservation after it
          has been booked. To make any changes (such as dates or room
          selection), you will have to cancel your initial reservation and then
          rebook a new one with your desired updates.*
        </p>
        <p className={contentParagraphClasses}>
          *Please note that the incredible discounts that Travelpass.com members
          access are continually updating, and we cannot guarantee a previous
          rate.
        </p>
      </>
    ),

    isFeatured: true,
    title: 'Can I modify my reservation?',
  },
  {
    content: (
      <p className={contentParagraphClasses}>
        Once you&apos;ve submitted your request to cancel your reservation, an
        email confirming the cancellation will be sent to the email address on
        file. Additionally, your Booking Details page in your Travelpass account
        will be updated to show &quot;Cancelled&quot; in red. If your Booking
        Details page does <u className='font-700'>not</u> display
        &quot;cancelled&quot; at the top of the page and you{' '}
        <u className='font-700'>haven&apos;t</u> received an email confirming
        the cancellation, please reach out to our Travelpass support team at{' '}
        <Link href={`tel:${phoneNumber}`} label={phoneNumber} /> during business
        hours (Monday through Friday, from 9 a.m. to 5 p.m. MST.)
      </p>
    ),

    isFeatured: true,
    title: 'How do I make sure my reservation was cancelled?',
  },
  {
    content: (
      <p className={contentParagraphClasses}>
        Unfortunately, non-refundable reservations do not allow for changes or
        cancellations. However, if there are extenuating circumstances that the
        customer feels like qualifies them for a refund, they can contact the
        Travelpass support team at{' '}
        <Link href={`tel:${phoneNumber}`} label={phoneNumber} /> during business
        hours, which are Monday through Friday, from 9 a.m. to 5 p.m. MST.
      </p>
    ),

    title: 'Can I cancel or change my dates on my non-refundable reservation?',
  },
  {
    content: (
      <p className={contentParagraphClasses}>
        Because rooms with non-refundable cancellation policies are offered at
        such a low price, they are unable to be changed or modified.
        Unfortunately, refunds cannot be processed on non-refundable
        reservations. Please check your trip before you cancel or change your
        reservation in order to confirm the type of reservation you booked.
      </p>
    ),

    title: 'Will I be able to get a refund on a non-refundable room rate?',
  },
  {
    content: (
      <>
        <p className={contentParagraphClasses}>
          We understand that sometimes your travel plans change! Our refund
          policy is contingent upon the type of reservation you made, as many
          hotels have differing policies and procedures. Please check the
          cancellation policy on your reservation prior to reaching out about
          your cancellation/refund to confirm whether or not{' '}
          <Link
            label='your reservation'
            onClick={() => navigate(MY_TRIPS_LINK)}
          />{' '}
          is refundable. If it is, you can contact our support team at{' '}
          <Link href={`tel:${phoneNumber}`} label={phoneNumber} /> (available
          Monday through Friday, from 9 a.m. to 5 p.m. MST) to initiate your
          cancellation and refund.
        </p>
        <p className={contentParagraphClasses}>
          Please keep in mind that while Travelpass does not charge any
          additional change or cancellation fees, some hotels impose fees to its
          customers for cancellations that we are required to pass on.
        </p>
      </>
    ),

    title: "What is Travelpass' refund policy?",
  },
  {
    content: (
      <>
        <p className={contentParagraphClasses}>
          We understand that travel plans sometimes change! Because of this, we
          do not charge a change or cancel fee. To double-check whether you
          booked a refundable room, please first{' '}
          <Link
            label='review your reservation'
            onClick={() => navigate(MY_TRIPS_LINK)}
          />
          , which will clearly state whether your booking is refundable or not.
        </p>
        <p className={contentParagraphClasses}>
          <b>If you did book a refundable room</b>, please allow five to ten
          (5-10) business days for the refund to process and appear in your bank
          account. As each financial institution operates differently, your
          refund may take longer to appear. If you have completed the
          reservation cancellation process and more than ten (10) business days
          have passed, we suggest you reach out to your financial institution to
          inquire on the status of your refund.
        </p>
        <p className={contentParagraphClasses}>
          <b>
            Can&apos;t find a &quot;refundable&quot; status for your
            reservation?
          </b>{' '}
          If your reservation does not say that your booking is refundable but
          you are sure you booked a refundable room, contact our support team at{' '}
          <Link href={`tel:${phoneNumber}`} label={phoneNumber} /> (available
          Monday through Friday from 9 a.m. to 5 p.m. MST), and we&apos;d be
          happy to assist you!
        </p>
        <p className={contentParagraphClasses}>
          **Please note that some hotel properties apply change/cancellation
          penalties to their customers, which we are required to apply to your
          reservation. Please carefully check the cancellation policy on your
          reservation prior to reaching out about your refund. Travelpass.com
          does not charge any additional change or cancellation fees.
        </p>
      </>
    ),
    title: 'If I cancel my reservation, when will I receive a refund?',
  },
  {
    content: (
      <p className={contentParagraphClasses}>
        Refunds are released within 24 business hours after a refund is
        approved, or a refundable reservation is cancelled. Then, depending on
        the card company or financial institution, it will typically take 3-10
        business days to post to your account. If it has been longer than 10
        business days, please contact your financial institution for assistance.
        If you cancel a non-refundable reservation,{' '}
        <Link
          label='please review the cancellation policy detailed on your trip.'
          onClick={() => navigate(MY_TRIPS_LINK)}
        />
      </p>
    ),

    title:
      'I am still waiting for a refund. How long does it take for it to arrive?',
  },
  {
    content: (
      <p className={contentParagraphClasses}>
        Currency exchange rates fluctuate daily. The difference is likely due to
        an exchange rate difference.
      </p>
    ),

    title: 'Why was my refund amount different from my charged amount?',
  },
  {
    content: (
      <p className={contentParagraphClasses}>
        For most hotels, check-in time is between 3 – 4 p.m. local time, and
        check-out time is between 11 a.m. – 12 p.m. local time. Should you need
        to check-in later than 4 p.m., your reservation is guaranteed for late
        check-in up until midnight (sweet!). For a check-in earlier than 3 p.m.
        or a late check-out, you would need to directly contact the hotel for
        approval.
      </p>
    ),

    isFeatured: true,
    title: 'What time is check in/check out?',
  },
  {
    content: (
      <p className={contentParagraphClasses}>
        This depends on the hotel and its policy. Even if your reservation was
        paid in advance, a bank-issued card may still be required for a
        requisite deposit, resort fee or anticipated incidentals. These
        requirements can be found by calling the hotel directly!
      </p>
    ),

    isFeatured: true,
    title: 'Do I need to provide a credit card at check in?',
  },
  {
    content: (
      <>
        <p className={contentParagraphClasses}>
          While most hotels require the primary guest checking in to be 18 years
          of age or older, some hotels require a minimum age of 21 years of age
          or older. The more you know!
        </p>
        <p className={contentParagraphClasses}>
          Details for your specific hotel can be found in the hotel policies
          section of your trip or by contacting your hotel directly.
        </p>
      </>
    ),

    title: 'How old do I need to be to check in?',
  },
  {
    content: (
      <p className={contentParagraphClasses}>
        Nope! As long as you have a valid <u>bank-issued</u> card to present
        upon request and provide a government ID with a name that matches the
        name on the reservation, then you should be good to go.
      </p>
    ),

    title:
      'Do I have to use the same card at check-in that I used to pay for the booking?',
  },
  {
    content: (
      <>
        <p className={contentParagraphClasses}>
          For an <u className='font-700'>early</u> check-in or late check-out,
          you will need to contact the hotel directly for approval. Please keep
          in mind that hotels cannot always accommodate these types of requests.
          However, should you need to <u className='font-700'>check-in late</u>,
          your reservation is guaranteed for late check-in up until midnight.
        </p>
        <p className={contentParagraphClasses}>
          If you will be arriving earlier or later than the stated check-in
          time, we recommend contacting the hotel to discuss your options. While
          the hotel cannot always accommodate these changes, we&apos;ve found
          that the properties we work with are happy to accommodate guest
          requests when possible.
        </p>
      </>
    ),

    title: 'Can I check in early / check out late?',
  },
  {
    content: (
      <p className={contentParagraphClasses}>
        You will need to come prepared with a driver&apos;s license or
        government-issued ID card, and a credit or debit card that will be kept
        on file for any incidental expenses. Any incidental expenses incurred
        during your stay will be charged at time of check-out. While your
        reservation trip is not required at the time of check-in, we recommend
        that you print a copy for your records. Please carefully review this
        section before arriving at your hotel. For specific check-in questions
        and requests, please contact your hotel directly.
      </p>
    ),

    title: 'What do I need to check in?',
  },
  {
    content: (
      <p className={contentParagraphClasses}>
        We recommend confirming any early or late check-ins with the hotel, and
        we have provided the direct line for your hotel in the trip page sent to
        you as part of your booking confirmation. The good news is that
        we&apos;ve found that the properties we work with are happy to
        accommodate guest requests when possible! However, it&apos;s important
        to remember that the property cannot always accommodate these requests.
      </p>
    ),

    title:
      'I will be arriving earlier / later than the stated check-in time. Can I still check in?',
  },
  {
    content: (
      <>
        <p className={contentParagraphClasses}>
          Possibly. You may be charged a penalty fee by the hotel, depending on
          the hotel cancellation policy for the rate you have booked. We
          recommend that you check the cancellation policy detailed in your trip
          ahead of time to see what options you have available to you!*
        </p>
        <p className={contentParagraphClasses}>
          *Additionally, we are always here to help you through the process!
          Feel free to give us a call at{' '}
          <Link href={`tel:${phoneNumber}`} label={phoneNumber} /> Monday
          through Friday, from 9 a.m. to 5 p.m. MST, and we would be happy to
          help answer or clarify any additional questions you may have.
        </p>
      </>
    ),
    title:
      "Will I be charged a night's stay if I miss my reservation or arrive late?",
  },
  {
    content: (
      <p className={contentParagraphClasses}>
        Taxes and fees are dictated by the hotel suppliers and are not itemized
        when we receive them. Included in the taxes and fees is a booking
        convenience fee. This fee is applicable for all local, state and
        hotel-occupancy taxes. We receive all these as one amount from our hotel
        suppliers and are not able to break it down into its individual
        components. Your total price includes taxes and fees required to
        complete your reservation.
      </p>
    ),

    title: 'Can you explain the taxes and fees listed on my reservation?',
  },
  {
    content: (
      <p className={contentParagraphClasses}>
        Some banks post failed transactions and correct them later. This will be
        unique to a customer&apos;s individual card and/or bank. If the charges
        are all the same amount, in most cases, these are authorizations and
        will be dropping off within 48 to 72 hours (depending on your bank).
        Please contact your financial institution for more information. If these
        charges do not fall off your account within the time frame provided, or
        you suspect something else is causing the additional charges, please
        contact us at <Link href={`tel:${phoneNumber}`} label={phoneNumber} />.
      </p>
    ),

    title: 'Why might I see multiple charges?',
  },
  {
    content: (
      <p className={contentParagraphClasses}>
        We accept Visa, MasterCard, Discover and American Express.
      </p>
    ),
    title: 'What credit cards do you accept?',
  },
  {
    content: (
      <>
        <p className={contentParagraphClasses}>
          We send a confirmation email to the email address provided when you
          booked your reservation. Our &quot;My Trips&quot; page also allows you
          to view your reservation details and resend the confirmation email.
        </p>
        <p className={contentParagraphClasses}>
          If your email was not received and does not appear in your &quot;My
          Trips&quot; page, please contact customer support at{' '}
          <Link href={`tel:${phoneNumber}`} label={phoneNumber} />. Support is
          available Monday through Friday from 9:00am to 5:00pm MST.
        </p>
      </>
    ),
    title: 'How may I receive a copy of my reservation trip?',
  },
  {
    content: (
      <>
        <p className={contentParagraphClasses}>
          Some accessibility features can be reserved and others cannot, as they
          are either property-level features (such as path of travel) or they
          are property-stocked items, such as equipment for the hearing
          impaired.
        </p>
        <p className={contentParagraphClasses}>
          &quot;<b>Accessibility equipment for the deaf</b>
          &quot;: This includes TTY for phone, visual or flashing fire alarm,
          and vibrating alarm clock. Request these directly from the property.
        </p>
        <p className={contentParagraphClasses}>
          &quot;<b>Accessible path of travel</b>&quot;: This means that the
          property has a path that is either flat or ramped, at least 36 inches
          wide, between the parking lot (if any) and the front desk and between
          the front desk and some or all of the guest accommodations. This
          feature cannot be reserved.
        </p>
        <p className={contentParagraphClasses}>
          &quot;<b>Braille and raised signage</b>&quot;: This means that the
          property has Braille or raised signage and vibrating alarm clocks
          available upon special request. Braille itself cannot be reserved as
          it is a property-wide feature when provided by the property.
        </p>
        <p className={contentParagraphClasses}>
          &quot;<b>Handicap parking spaces</b>&quot;: This means that parking
          spaces are set aside for accessible guests. These are available on a
          first come, first-serve basis and cannot be reserved.
        </p>
        <p className={contentParagraphClasses}>
          &quot;<b>In-room accessibility</b>&quot;: This means that the property
          has an accessible path from the room entrance to the window, an
          accessible path from the room entrance to at least one side of the
          bed, and an accessible path from the room entrance to the toilet, as
          well as accessible hardware and minimal resistance to open the door,
          accessible temperature controls, and accessible window coverings. This
          feature can be requested.
        </p>
        <p className={contentParagraphClasses}>
          &quot;<b>Roll-in shower</b>&quot;: This includes a hand-held shower,
          grab bars, and a sitting area. The shower space is large enough for
          wheelchairs to roll in. Roll-in showers are not the same as accessible
          bathrooms. This feature is available on request.
        </p>
        <p className={contentParagraphClasses}>
          &quot;<b>Accessible bathroom</b>&quot;: This includes a hand-held
          shower, grab bars at the tub, grab bars at the toilet, and raised
          toilet. This feature is available on request.
        </p>
        <p className={contentParagraphClasses}>
          Additionally, you may search hotels with additional accessibility
          features by using the &quot;Increased Accessibility&quot; filter on
          the search page.
        </p>
      </>
    ),
    title: 'What does "accessible room and amenities" mean?',
  },
  {
    content: (
      <p className={contentParagraphClasses}>
        Absolutely. We have thousands of hotels available to you for those
        last-minute bookings! You can discover them{' '}
        <Link label='here' onClick={() => navigate(SEARCH_LINK)} />.
      </p>
    ),
    title: 'Can I book same-day reservations?',
  },
  {
    content: (
      <p className={contentParagraphClasses}>
        Yes, you can! This is done at the initial booking stage, and you will
        have a selection to choose from. If you have already completed your
        booking, you can confirm the bed type you selected by checking your
        trip.
      </p>
    ),

    title: 'Can I book a specific bed type?',
  },
  {
    content: (
      <p className={contentParagraphClasses}>
        You can find your reservation receipt{' '}
        <Link label='here' onClick={() => navigate(MY_TRIPS_LINK)} />, or by
        accessing your trip under My Trips.
      </p>
    ),
    title: 'Where can I access my receipt for my reservation?',
  },
  {
    content: (
      <>
        <p className={contentParagraphClasses}>
          It never hurts to do your research! You can find your
          reservation&apos;s cancellation policy at the bottom of the
          confirmation email sent to you at the time of booking. Please
          reference this policy before attempting to make any modifications to
          your booking.
        </p>
        <p className={contentParagraphClasses}>
          Your reservation&apos;s cancellation policy can also be found by
          accessing the trip for your reservation under My Trips.
        </p>
      </>
    ),
    title: "Where can I find my reservation's cancellation policy?",
  },
  {
    content: (
      <>
        <p className={contentParagraphClasses}>
          Great question! Here&apos;s our guide to star ratings:
        </p>
        <ol className={classNames(contentParagraphClasses, 'list-none p-0')}>
          <li>1 star - No-frills comfort, accessibility and hospitality</li>
          <li>
            2 stars - Facilities, décor and amenities are modestly enhanced
          </li>
          <li>
            3 stars - Distinguished facilities featuring marked upgrades in
            amenities, comforts, and hospitality.
          </li>
          <li>
            4 stars - Refined and stylish facilities that boast extensive
            amenities. Highly rated options in hospitality, service, and
            attention to detail.
          </li>
          <li>
            5 stars - The ultimate hotel experience in luxury/sophistication.
            Enjoy an extraordinary setting, meticulous service, and first-class
            comfort
          </li>
        </ol>
      </>
    ),
    title: 'What is the difference between hotel star ratings?',
  },
  {
    content: (
      <p className={contentParagraphClasses}>
        Currently, we unfortunately cannot accommodate adding rooms to an
        existing reservation. To add more rooms, an additional booking will need
        to be made. It&apos;s important to note that the rates for the hotel may
        have changed since you made your original booking, and that we will have
        to honor the current price for a new booking. We are unable to match
        pricing for your previous booking.
      </p>
    ),
    title: 'Can I add rooms to my booked reservation?',
  },
  {
    content: (
      <p className={contentParagraphClasses}>
        Absolutely. You can contact one of our experienced Travelpass agents who
        can easily assist you with getting a name added to a reservation. Our
        phone number is <Link href={`tel:${phoneNumber}`} label={phoneNumber} />{' '}
        for further assistance.
      </p>
    ),
    title:
      "I won't be the first person to arrive to the hotel. Can someone else in my party check-in without me?",
  },
  {
    content: (
      <p className={contentParagraphClasses}>
        No problem. All of our trips include your hotel&apos;s direct line, for
        your convenience.
      </p>
    ),

    title: "Can you provide me with the hotel's front desk phone number?",
  },
  {
    content: (
      <p className={contentParagraphClasses}>
        We love amenities as much as the next traveler. You can find a list of
        hotel amenities on the page of each hotel listed on our website.
        However, one travel tip is to check directly with the hotels on their
        provided amenities as they sometimes accommodate or change them
        according to the time of year or maintenance requirements. For the most
        accurate information on your hotel&apos;s amenities and offerings,
        please contact the hotel directly.
      </p>
    ),
    title: "Where can I find my hotel's amenities?",
  },
  {
    content: (
      <p className={contentParagraphClasses}>
        As much as we love pets, sometimes the properties or chains we work with
        don&apos;t! Because pet policies vary by hotel and/or chain, we strongly
        recommend contacting the property you are interested in directly to see
        what their policies (&quot;paw-licies&quot;, if you will) are.
      </p>
    ),
    isFeatured: true,
    title: 'What is the pet policy?',
  },
  {
    content: (
      <p className={contentParagraphClasses}>
        Travelpass members have exclusive access to the best rates available,
        which are constantly updated- meaning that rates frequently change and
        cannot be guaranteed. This is due to the hotel&apos;s availability and
        demand. Please note that rates are not guaranteed until you have
        completed your booking process. If you find an awesome deal, make sure
        to complete your booking as soon as possible to take full advantage!
      </p>
    ),
    title: 'Why has the rate changed since my previous hotel search?',
  },
  {
    content: (
      <>
        <p className={contentParagraphClasses}>
          For frequently asked questions regarding Covid-19&apos;s effect on the
          hotel industry and its standards, please review the following
          resources detailing how the hotel industry has responded to the
          Coronavirus threat, including the latest improvements being made to
          hotels&apos; cleaning, disinfecting, check in & check out procedures.
          Stay healthy, and stay safe!
        </p>
        <ul className={classNames(contentParagraphClasses, 'pl-8')}>
          <li>
            <Link
              href='https://www.ustravel.org/sites/default/files/media_root/document/HealthandSafetyGuidance.pdf'
              label='US Travel Association Industry Guidance for Promoting the Health and Safety of All Travelers'
              target='_BLANK'
            />
          </li>
          <li>
            <Link
              href='https://help.hilton.com/s/article/What-are-the-latest-Coronavirus-COVID-19-updates'
              label='Hilton Coronavirus Update'
              target='_BLANK'
            />
          </li>
          <li>
            <Link
              href='https://www.hyatt.com/en-US/info/coronavirus-statement'
              label='Hyatt COVID-19 Travel Update'
              target='_BLANK'
            />
          </li>
          <li>
            <Link
              href='https://www.ihg.com/content/us/en/customer-care/travel-advisory'
              label='IHG Travel Advisory: COVID-19'
              target='_BLANK'
            />
          </li>
          <li>
            <Link
              href='https://www.choicehotels.com/support/travel-alerts'
              label='Choice Hotels Updated Travel Information'
              target='_BLANK'
            />
          </li>
          <li>
            <Link
              href='https://www.bestwestern.com/content/best-western/en_US/hotels/discover-best-western/covid-19-response.html'
              label='Best Western Important Update for Guests'
              target='_BLANK'
            />
          </li>
          <li>
            <Link
              href='https://corporate.wyndhamhotels.com/news-releases/statement-from-wyndham-hotels-resorts-coronavirus-2/'
              label='Statement from Wyndham Hotels & Resorts: COVID-19'
              target='_BLANK'
            />
          </li>
        </ul>
      </>
    ),
    title: 'Where can I get COVID-19 updates?',
  },
]

const contentParagraphClasses = 'color-grey-800 type-body-1'
