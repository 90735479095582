import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { NumberedTile } from '../../common/NumberedTile'
import type { ExtendedUpsertSearchPreferencesInput } from '../ExtendedUpsertInputType'
import { useGetLocations } from '../hooks/useGetLocations'

export const QuestionLocationSelections = () => {
  const { watch, setValue } =
    useFormContext<ExtendedUpsertSearchPreferencesInput>()
  const { data, loading: locationsLoading } = useGetLocations()
  const locationList = data?.getLocations || []

  const handleSetLocationValues = (locations, setArgs) => {
    setValue('locationObjects', locations, {
      ...setArgs,
    })
    setValue(
      'sortedLocationObjectsString',
      locations
        .sort((a, b) => (a.userRank || Infinity) - (b.userRank || Infinity))
        .map(item => item.name + ' (' + item.userRank + ')')
        .join(', ')
    )
  }

  const locationSelections = watch('locationObjects')

  useEffect(() => {
    const initialSelections = locationList?.filter(
      location => location.userRank
    )

    // Is the location list not populated yet?
    if (locationList.length > 0) {
      const currentSelections = watch('locationObjects')
      // If the current selections are different from the initial selections, reset the form to initial
      if (
        !currentSelections ||
        JSON.stringify(initialSelections) !== JSON.stringify(currentSelections)
      ) {
        handleSetLocationValues(initialSelections, { shouldTouch: true })
      }
    }
  }, [locationList])

  const handleLocationClick = clickedLocation => {
    const locationTypeId = clickedLocation?.id
    const existingIndex = locationSelections.findIndex(
      loc => loc.id === locationTypeId
    )
    const isDeselecting = existingIndex !== -1

    let updatedLocations = [...locationSelections]
    if (isDeselecting) {
      // Remove the deselected location and update ranks of others
      updatedLocations.splice(existingIndex, 1)
      updatedLocations.forEach(loc => {
        if (loc.userRank > locationSelections[existingIndex].userRank) {
          loc.userRank--
        }
      })
    } else {
      // Assign a new rank to the newly selected location
      const nextRank =
        Math.max(0, ...updatedLocations.map(loc => loc.userRank)) + 1
      updatedLocations.push({
        ...clickedLocation,
        userRank: nextRank,
      })
    }

    handleSetLocationValues(updatedLocations, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }

  const getLocationSelectionRank = locationId => {
    const location = locationSelections?.find(loc => loc.id === locationId)
    return location?.userRank
  }

  return (
    <div className='flex flex-col'>
      <div className='type-h2 mb-4 self-start'>
        I generally prefer to travel somewhere...
      </div>
      <div className='type-h5 mb-10 self-start'>
        Select from favorite (1) to least favorite (4)
      </div>
      <div className='grid grid-cols-1 gap-4 lg:grid-cols-2'>
        {locationList &&
          locationList.map(location => (
            <div key={location?.id} className='m-2 flex flex-col items-center'>
              <NumberedTile
                loading={locationsLoading}
                number={getLocationSelectionRank(location?.id)}
                pressed={getLocationSelectionRank(location?.id) != null}
                onClick={() => handleLocationClick(location)}
              >
                <img
                  alt={`${location?.displayName}`}
                  className='max-h-77 max-w-77 m-2 h-auto w-[95%] rounded-md'
                  src={location?.imageUrl}
                />
              </NumberedTile>
              <span className='type-h4 mt-2 cursor-default select-none'>
                {location?.displayName}
              </span>
            </div>
          ))}
      </div>
    </div>
  )
}
