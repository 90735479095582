import { Input, TextArea } from '@travelpass/design-system'
import { useFormContext } from 'react-hook-form'
import { ProfileAvatarAndHandle } from 'src/pages/profile/information-section/ProfileAvatarAndHandle'
import type { ProfileFields } from 'src/pages/profile/types'
import { EmbedYoutubeVideo } from './EmbedYoutubeVideo'
import { Links } from './Links'
import { SocialMediaLinks } from './SocialMediaLinks'
import { Tags } from './Tags'
import { TravelTracker } from './TravelTracker'

const BIO_TEXT_AREA_MAX_LENGTH = 375
const MIN_CHARACTERS = 3

export const ProfileTab = () => {
  const {
    register,
    formState: { errors },
    watch,
    getValues,
  } = useFormContext<ProfileFields>()
  const accountHandle = watch('accountHandle')
  const biography = getValues('bio')
  const displayName = watch('displayName')
  const accountHandleCharCount = accountHandle?.length || 0
  const bioCharCount = biography?.length || 0
  const displayNameCharCount = displayName?.length || 0

  return (
    <>
      <ProfileAvatarAndHandle profileImageUrl={watch('profileImageUrl')} />
      <section className='flex flex-col gap-6 lg:flex-row'>
        <div className='lg:max-w-1/2 flex flex-col gap-4 lg:w-1/2'>
          <h6 className='type-h6 c-black'>
            Name
            <span className='text-5 c-grey-800 font-extralight'>*</span>
          </h6>
          <Input
            {...register('displayName', {
              required: 'Please add a display name',
              minLength: {
                value: MIN_CHARACTERS,
                message: `Display name must be at least ${MIN_CHARACTERS} characters long`,
              },
            })}
            aria-label='Display name for Profile page'
            errorText={errors?.displayName?.message as string}
            helperText={`${displayNameCharCount}/25`}
            maxLength={25}
            placeholder='Display name'
          />
        </div>
        <div className='lg:max-w-1/2 flex flex-col gap-4 lg:w-1/2'>
          <h6 className='type-h6 c-black'>
            Account Handle
            <span className='text-5 c-grey-800 font-extralight'>*</span>
          </h6>
          <Input
            {...register('accountHandle', {
              required: 'Please add an account handle',
              minLength: {
                value: MIN_CHARACTERS,
                message: `Account Handle must be at least ${MIN_CHARACTERS} characters long`,
              },
            })}
            aria-label='Display account handle for Profile page'
            errorText={errors?.accountHandle?.message as string}
            helperText={`${accountHandleCharCount}/25. Use only letters, numbers, ".", and "_". No spaces. `}
            maxLength={25}
            placeholder='e.g. john_doe'
            slotBefore={<span className='c-grey-600'>@</span>}
          />
        </div>
      </section>
      <section>
        <h6 className='type-h6 c-black mb-4'>My Bio</h6>
        <TextArea
          {...register('bio', {
            maxLength: {
              value: BIO_TEXT_AREA_MAX_LENGTH,
              message: `Bio cannot be more than ${BIO_TEXT_AREA_MAX_LENGTH} characters`,
            },
          })}
          aria-label='Bio for Profile page'
          className='max-h-60'
          errorText={errors?.bio?.message as string}
          helperText={`${bioCharCount}/${BIO_TEXT_AREA_MAX_LENGTH}`}
          maxLength={BIO_TEXT_AREA_MAX_LENGTH}
          placeholder='Tell other users who you are, how you travel, and what your favorite destinations are.'
        />
      </section>
      <Tags />
      <TravelTracker />
      <EmbedYoutubeVideo />
      <SocialMediaLinks />
      <Links />
    </>
  )
}
