import { Skeleton } from '@travelpass/design-system'
import { GuideEventCardDescriptionLoading } from './GuideEventCardDescriptionLoading'

export const GuideEventCardLoading = () => (
  <div className='b-grey-300 b-2px b-solid rounded-4 flex w-full flex-row gap-2'>
    <div className='min-w-30 w-30 md:min-w-45.5 md:w-45.5 relative'>
      <Skeleton variant='rounded' />
    </div>
    <div className='p-x-4 p-y-3 max-w-[calc(100%-136px)] grow space-y-3 md:max-w-[calc(100%-198px)]'>
      <div className='space-y-1'>
        <div className='h-5 w-20'>
          <Skeleton />
        </div>
        <p className='c-grey-800 flex flex-row items-center gap-1'>
          <span className='h-4 w-4'>
            <Skeleton variant='circular' />
          </span>
          <span className='w-15 h-2.5 lg:h-4'>
            <Skeleton />
          </span>
        </p>
      </div>
      <div className='min-h-18'>
        <GuideEventCardDescriptionLoading />
      </div>
    </div>
  </div>
)
