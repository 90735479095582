import { DeleteAccountModal } from 'src/pages/account-settings/components/SignInAndSecurity/DeleteAccountModal'

export const DeleteAccount = () => {
  return (
    <section className='mb-8 flex flex-col gap-5 p-6 lg:gap-5'>
      <h5 className='text-h5'>Account</h5>
      <div className='flex flex-col justify-between gap-5 lg:flex'>
        <p className='type-body-1-desktop c-grey700 flex flex-col justify-center'>
          Permanently delete your account and all of your content.
        </p>
        <DeleteAccountModal />
      </div>
    </section>
  )
}
