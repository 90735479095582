import { useEffect, useState } from 'react'
import {
  Carousel,
  Illustration,
  useScreenQuery,
} from '@travelpass/design-system'
import { GuideCategory } from 'src/__generated__/graphql'
import {
  GuideRecommendedCard,
  GuideRecommendedCardLoading,
  useOnClickOwnerProfile,
} from 'src/common/components'
import { AddToCollectionsModalListener } from 'src/common/components/Collections/AddToCollectionsModalListener'
import { Header } from './components/header'
import { ProfileModalListener } from '../guides/results/ProfileModalListener'
import { useGetCuratedGuides } from '../home/useGetCuratedGuides'

export const Guides = () => {
  const [category, setCategory] = useState<GuideCategory>(
    GuideCategory.Searchpage
  )
  const { data, loading, error } = useGetCuratedGuides(category)
  const guides = data?.curatedGuides || []

  const onClickOwnerProfile = useOnClickOwnerProfile()
  const { isMobileOrTablet, isDesktopScreen } = useScreenQuery()
  const showResults = !loading && !!guides.length

  // set fallback
  useEffect(() => {
    if (!guides.length && !loading && !error)
      setCategory(GuideCategory.Outdoors)
  }, [guides, loading, error])

  return (
    <div className='pt-25 bg-warm-grey bg-top-center mt--35 mb-40 bg-[url(https://static.travelpass.com/assets/search/hero-sm.webp)] bg-cover bg-no-repeat md:bg-[url(https://static.travelpass.com/assets/search/hero-md.webp)] lg:bg-[url(https://static.travelpass.com/assets/search/hero-lg.webp)]'>
      <section className='translate-y-30 max-w-300 mx-auto space-y-8'>
        <Header
          className='c-white text-shadow-lg'
          description='Explore Guides created by travelers like you'
          title='Featured Guides'
        />
        {error && (
          <div className='h-76 w-76 mx-auto'>
            <Illustration name='guide' />
            <p className='type-body-1 c-gray-400 text-center'>
              {error.message}
            </p>
          </div>
        )}
        {loading && (
          <ul
            aria-busy={loading}
            className='ps-none mt-18! grid list-none gap-4 max-md:mx-auto max-md:w-fit md:grid-cols-2 lg:grid-cols-3' // `max-md:mx-auto max-md:w-fit` are necessay due to loading card behavior of not stretching
          >
            <GuideRecommendedCardLoading />
            <GuideRecommendedCardLoading className='max-md:hidden' />
            <GuideRecommendedCardLoading className='max-lg:hidden' />
            <span className='sr-only'>Loading...</span>
          </ul>
        )}
        {isMobileOrTablet && showResults && (
          <Carousel
            borderRadius={12}
            hideArrows={true}
            keyBoardControl={true}
            size='small'
            slidesPerViewOnDesktop={2}
            slidesPerViewOnMobile={1}
            slidesPerViewOnTablet={2}
          >
            {guides?.map(guide => (
              <div
                key={guide.id}
                className='mx-auto mb-8 max-w-[calc(100%-24px)]'
              >
                <GuideRecommendedCard
                  asLink
                  showBookmarkIcon
                  guideRecommendedCardData={guide}
                  onClickOwnerProfile={onClickOwnerProfile}
                />
              </div>
            ))}
          </Carousel>
        )}
        {isDesktopScreen && showResults && (
          <ul className='ps-none mt-18 grid list-none gap-4 overflow-y-auto md:grid-cols-3'>
            {guides?.map(guide => (
              <li key={guide.id}>
                <GuideRecommendedCard
                  asLink
                  showBookmarkIcon
                  guideRecommendedCardData={guide}
                  onClickOwnerProfile={onClickOwnerProfile}
                />
              </li>
            ))}
          </ul>
        )}
      </section>
      <ProfileModalListener />
      <AddToCollectionsModalListener />
    </div>
  )
}
