import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'
import {
  guideDraftPublishedEventFragment,
  useUpdateGuideDraftQueryCache,
} from './useGetGuideDraftQuery'

const createPublishedEventMutation = gql(`
  mutation CreatePublishedEventMutationInGuideDraft($first: Int = 100, $publishedEventInput: CreatePublishedEventInput!) {
    createPublishedEvent(createPublishedEventInput: $publishedEventInput) {
      guideDraft {
        id
        guide {
          id
          status
        }
        hasUnpublishedChanges
        uncategorizedPublishedEvents(first: $first) {
          edges {
            node {
              ...GuideDraftPublishedEventFields
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
          totalCount
        }
      }
    }
  }
`)

export const useCreateGuideDraftEventMutation = () => {
  const { updateGuideDraftQueryCache } = useUpdateGuideDraftQueryCache()

  return useMutation(createPublishedEventMutation, {
    update: (cache, { data }) => {
      const updatedCache = updateGuideDraftQueryCache(
        data?.createPublishedEvent?.guideDraft
      )

      if (!updatedCache) return

      cache.modify(updatedCache)
    },
  })
}
