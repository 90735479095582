import { useState, useEffect } from 'react'
import debounce from 'lodash.debounce'
import { GuideSessionStorage } from './guideConstants'

const hoverIdEventName = 'guideHoverId'
const selectedIdEventName = 'guideSelectedId'

// Debounce function to optimize event dispatch
const debouncedDispatchEvent = debounce((eventName: string) => {
  window.dispatchEvent(new Event(eventName))
}, 50)

// Function to handle hoverId changes
const onGuideSessionStorageHoverIdChange = (updatedHoverId: string) => {
  const previousHoverId =
    sessionStorage.getItem(GuideSessionStorage.hoverId) ?? ''

  if (updatedHoverId !== previousHoverId) {
    sessionStorage.setItem(GuideSessionStorage.hoverId, updatedHoverId)
    debouncedDispatchEvent(hoverIdEventName)
  }
}

// Function to handle selectedId changes
const onGuideSessionStorageSelectedIdChange = (updatedSelectedId: string) => {
  const previousSelectedId =
    sessionStorage.getItem(GuideSessionStorage.selectedId) ?? ''

  if (updatedSelectedId !== previousSelectedId) {
    sessionStorage.setItem(GuideSessionStorage.selectedId, updatedSelectedId)
    debouncedDispatchEvent(selectedIdEventName)
  }
}

// Custom hook to use both hoverId and selectedId from sessionStorage
const useGuideSessionStorageIds = () => {
  const [hoverId, setHoverId] = useState(
    sessionStorage.getItem(GuideSessionStorage.hoverId) ?? ''
  )
  const [selectedId, setSelectedId] = useState(
    sessionStorage.getItem(GuideSessionStorage.selectedId) ?? ''
  )

  useEffect(() => {
    const hoverIdListener = () =>
      setHoverId(sessionStorage.getItem(GuideSessionStorage.hoverId) ?? '')

    const selectedIdListener = () =>
      setSelectedId(
        sessionStorage.getItem(GuideSessionStorage.selectedId) ?? ''
      )

    window.addEventListener(hoverIdEventName, hoverIdListener)
    window.addEventListener(selectedIdEventName, selectedIdListener)

    return () => {
      window.removeEventListener(hoverIdEventName, hoverIdListener)
      window.removeEventListener(selectedIdEventName, selectedIdListener)
    }
  }, [])

  return { hoverId, selectedId }
}

export {
  onGuideSessionStorageHoverIdChange,
  onGuideSessionStorageSelectedIdChange,
  useGuideSessionStorageIds,
}
