import { useState } from 'react'
import { UnderlineTab, useScreenQuery } from '@travelpass/design-system'
import { useSearchParams } from 'react-router-dom'
import { useFlag } from 'src/common/hooks'
import { EditingProfileModal } from './EditingProfileModal'
import { EditingProfileOverview } from './EditingProfileOverview'
import { ProfileTabs } from './types'
import { useCheckEditingMode } from '../hooks/useCheckEditingMode'

export const EditingProfileTabs = () => {
  const [searchParams] = useSearchParams()
  //State
  const [tabIndex, setTabIndex] = useState(
    parseInt(searchParams.get('tab') || '0')
  )
  const enableProfileFavoriteHotels = useFlag('enableProfileFavoriteHotels')
  //Hooks
  const { isEditingMode } = useCheckEditingMode()
  const { isMobileScreen } = useScreenQuery()
  //Others
  const tabs = [
    { label: 'Profile', onClick: () => onTabClick(ProfileTabs.Profile) },
    // {label: 'Posts display', onClick: () => onTabClick(ProfileTabs.PostsDisplay)},
    enableProfileFavoriteHotels && {
      label: 'Top Hotels',
      onClick: () => onTabClick(ProfileTabs.FavoriteHotels),
    },
    {
      label: 'Banner Image',
      onClick: () => onTabClick(ProfileTabs.BannerImage),
    },
  ].filter(Boolean)

  const onTabClick = (index: number) => {
    setTabIndex(index)
  }

  return (
    <>
      {/*Desktop version */}
      <div className='w-full bg-white pt-10 sm:hidden md:block md:overflow-auto'>
        <EditingProfileOverview tabIndex={tabIndex} tabs={tabs} />
      </div>

      {/*Mobile version */}
      <div className='shadow-2 rounded-t-5 h-50px fixed bottom-0 flex w-full justify-center bg-white md:hidden'>
        <UnderlineTab activeIndex={tabIndex} tabs={tabs} willScroll={false} />
      </div>
      {isMobileScreen && isEditingMode && (
        <EditingProfileModal tabIndex={tabIndex} tabs={tabs} />
      )}
    </>
  )
}
