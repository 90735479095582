import { Divider, Skeleton } from '@travelpass/design-system'
import { HeroLoading } from 'src/pages/trips/components/TripDrawerContent/components/Hero'
import { GuideDrawerContentDescriptionLoading } from './GuideDrawerContentDescriptionLoading'

export const GuideDrawerContentLoading = () => (
  <div className='flex h-[calc(100%-48px)] flex-col'>
    <div className='p-b-8 grow space-y-8 overflow-y-auto md:space-y-12'>
      <HeroLoading />
      <div className='p-x-8 space-y-8 md:space-y-10'>
        <div className='space-y-2'>
          <div className='space-y-1'>
            <div className='w-35 h-4 lg:h-5'>
              <Skeleton />
            </div>
            <div className='lg:h-4.5 h-4 w-1/2'>
              <Skeleton />
            </div>
          </div>
          <div className='h-5.5 w-20 lg:h-6'>
            <Skeleton />
          </div>
          <Divider className='m-t-1' />
        </div>
        <GuideDrawerContentDescriptionLoading />
      </div>
    </div>
  </div>
)
