import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'

const GET_CURRENT_USER_PROFILE_ID = gql(`
  query CurrentUserProfileId {
    currentUser {
      id
      userProfile {
        id
      }
    }
  } 
`)

export const useCurrentUserProfileId = () =>
  useQuery(GET_CURRENT_USER_PROFILE_ID)
