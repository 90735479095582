import { Icon, useSnackbar } from '@travelpass/design-system'
import type {
  BookingHotelDetailsQuery,
  BookingValidateRateQuery,
} from 'src/__generated__/graphql'
import { PayPalButton } from 'src/common/components/PaymentOptions'
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from 'src/common/components/PaymentOptions/Accordion'
import PayPalMark from 'src/common/components/PaymentOptions/PayPalMark.svg'
import { StepCard } from 'src/common/components/StepCard/StepCard'
import { CardPaymentFields } from './CardPaymentFields'
import { CreditCardsDisplay } from './CreditCardsDisplay'
import { GuestInformationFields } from './GuestInformationFields'
import { useFormContext } from '../../hooks'
import { BookingTerms } from '../BookingReview/BookingTerms'
import { calculatePriceBreakdown } from '../BookingSidebar/bookingSidebarUtils'

export const BookingPaymentLayout = ({
  bookingHotelData,
  bookingRateData,
}: {
  bookingHotelData: BookingHotelDetailsQuery
  bookingRateData: BookingValidateRateQuery
}) => {
  const { totalPrice } = calculatePriceBreakdown(bookingRateData)
  const hasAmount = !!totalPrice
  const showAlternativePayments = (
    bookingRateData?.validatedRate?.frontEndRequirements || []
  ).some(requirement => requirement.name === 'SHOW_ALTERNATIVE_PAYMENTS')
  const { setValue, resetField, trigger } = useFormContext()
  const { addWarningSnack } = useSnackbar()

  const onApprove = braintreeTokenizedPayment => {
    setValue('braintreePayment', braintreeTokenizedPayment)
  }

  const onCancel = () => {
    resetField('braintreePayment')
    addWarningSnack({
      title:
        'PayPal payment cancelled. Please try again, or select another payment method.',
    })
  }

  const onClick = async (_, actions) => {
    const isGuestInfoValid = await trigger([
      'firstName',
      'lastName',
      'email',
      'phone',
    ])
    if (isGuestInfoValid) {
      return actions.resolve()
    } else {
      addWarningSnack({
        title: 'Please review guest information fields for errors.',
      })
      return actions.reject()
    }
  }

  return (
    <>
      <StepCard>
        <h2 className='type-h5'>1. Guest Information</h2>
        <GuestInformationFields />
      </StepCard>
      <StepCard>
        <h2 className='type-h5'>2. Billing Information</h2>
        <Accordion
          collapsible
          // TODO: when Apple Pay is implemented, the defaultIndex logic will need to change
          defaultIndex={showAlternativePayments ? 1 : 0}
          id='booking-payment-options'
        >
          {showAlternativePayments && hasAmount && (
            <AccordionItem>
              <AccordionButton aria-label='Pay with Paypal'>
                <img alt='PayPal mark' className='h-5' src={PayPalMark} />
              </AccordionButton>
              <AccordionPanel>
                <BookingTerms
                  bookingHotelData={bookingHotelData}
                  bookingRateData={bookingRateData}
                />
                <PayPalButton
                  amount={totalPrice}
                  onApprove={onApprove}
                  onCancel={onCancel}
                  onClick={onClick}
                />
              </AccordionPanel>
            </AccordionItem>
          )}
          <AccordionItem>
            <AccordionButton>
              <Icon name='creditCard' size='large' />
              <span className='type-body-1'>Pay with card</span>
              <CreditCardsDisplay />
            </AccordionButton>
            <AccordionPanel>
              <CardPaymentFields
                bookingHotelData={bookingHotelData}
                bookingRateData={bookingRateData}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </StepCard>
    </>
  )
}
