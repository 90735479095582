import { Input } from '@travelpass/design-system'
import { useFormContext } from 'react-hook-form'
import type { ProfileFields } from 'src/pages/profile/types'
import { onlyNumbers } from 'src/utils'

interface TravelTrackerQuestionProps {
  question: string
  name: 'bragStates' | 'bragContinents' | 'bragCountries'
  maxNumber: number
}

export const TravelTrackerQuestion = ({
  question,
  name,
  maxNumber,
}: TravelTrackerQuestionProps) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext<ProfileFields>()

  const validate = (number: number) => {
    if (number <= maxNumber) return true
    return `Please enter a number up to ${maxNumber}`
  }

  return (
    <div className='px-25% my-1 grid grid-cols-2 items-center gap-5 lg:flex lg:grow lg:justify-start lg:p-0'>
      <p className='type-body-1 my-0'>{question}</p>
      <div className='w-12.5 justify-self-end'>
        <Input
          fullWidth
          {...register(name, {
            onChange: e =>
              setValue(name, parseInt(onlyNumbers(e.target.value || 0))),
            validate,
          })}
          aria-label='Add how many places you visited'
          errorText={errors[name]?.message}
          maxLength={
            name == 'bragContinents' ? 1 : name == 'bragCountries' ? 3 : 4
          }
          onClick={event =>
            event.currentTarget.querySelector('input')?.select()
          }
        />
      </div>
    </div>
  )
}
