import type { AvailableIcons } from '@travelpass/design-system'
import { Icon, Skeleton } from '@travelpass/design-system'
import classNames from 'classnames'
import isEmpty from 'lodash.isempty'
import { isMobile } from 'react-device-detect'
import { useSearchParams } from 'react-router-dom'
import type { BookingValidateRateQuery } from 'src/__generated__/graphql'
import { initialRooms } from 'src/constants/user'
import { BookingSidebarRoomImage } from './BookingSidebarRoomImage'
import { nightCount } from './bookingSidebarUtils'

interface BookingSidebarRoomProps {
  bookingRateData: BookingValidateRateQuery
  bookingRateLoading: boolean
  city: string
  name: string
  state: string
}

export const BookingSidebarRoom = ({
  bookingRateData,
  bookingRateLoading,
  city,
  name,
  state,
}: BookingSidebarRoomProps) => {
  const [searchParams] = useSearchParams()
  const { images, name: roomName } = bookingRateLoading
    ? { images: [], name: '' }
    : bookingRateData?.validatedRate?.room

  const showImage = bookingRateLoading || !isEmpty(images)
  const guests = parseInt(searchParams.get('guests') || '0', 10)
  const nights = nightCount(searchParams)

  const getRoomName = () => {
    if (bookingRateLoading)
      return (
        <div className='h-18px md:h-20px'>
          <Skeleton variant='rounded' />
        </div>
      )

    return <div className='type-h6 truncate'>{roomName}</div>
  }

  return (
    <div className='space-y-4 lg:space-y-6'>
      <h2 className='type-h4'>Selected Room:</h2>
      <div className='flex flex-row items-center gap-4 lg:gap-6'>
        {showImage && (
          <BookingSidebarRoomImage
            bookingRateLoading={bookingRateLoading}
            city={city}
            images={images}
            name={name}
            state={state}
          />
        )}
        <div
          className={classNames('flex-grow-1', {
            'max-w-full': !showImage,
            'sm:max-w-[calc(100%-137px)] md:max-w-[calc(100%-168px)] lg:max-w-[calc(100%-205px)]':
              showImage,
          })}
        >
          <div className='space-y-1 lg:space-y-2'>
            {!isMobile && getRoomName()}
            <p className='type-body-1'>
              <span className='inline-flex flex-row items-center gap-2'>
                <Icon name='personOutline' size='small' /> {guests} Guest
                {guests === 1 ? '' : 's'}
              </span>
            </p>
            <p className='type-body-1'>
              <span className='inline-flex flex-row items-center gap-2'>
                <Icon name='nightlight' size='small' /> {nights} Night
                {nights === 1 ? '' : 's'}
              </span>
            </p>
            <p className='type-body-1'>
              <span className='inline-flex flex-row items-center gap-2'>
                <Icon name='bed' size='small' /> {initialRooms} Room
                {initialRooms === 1 ? '' : 's'}
              </span>
            </p>
          </div>
        </div>
      </div>
      {isMobile && getRoomName()}
    </div>
  )
}
