import type { ApolloError } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import {
  BookingTimeState,
  ExperienceBookingTimeState,
  GuideDraftStatus,
  TripStatus,
} from 'src/__generated__/graphql'
import { type GetCurrentUserQueryInDashboardQuery } from 'src/__generated__/graphql'
import { getDashboardFeedItem } from './dashboardUtils'
import type { DashboardFeedItem } from './types'

/** @todo remove activityFeed when dashboardV2HideActivityFeed is enabled */
export const dashboardCurrentUserQuery = gql(`
  query GetCurrentUserQueryInDashboard($searchUserExperienceBookingsArgs: SearchUserExperienceBookingsArgs!, $searchUserHotelBookingsArgs: SearchUserHotelBookingsArgs! $tripSearchInput: TripSearchInput!, $userGuideDraftsArgs: UserGuideDraftsArgs!) {
    currentUser {
      id
      activityFeed(feedCount: 5) {
        title
        follower {
          accountHandle
          displayName
          id
          followCount {
            countOfFollowed
            countOfFollowing
          }
          profileImageUrl
        }
        guide {
          id
          description
          guideType
          insertedAt
          name
          ownerProfile {
            id
            displayName
            profileImageUrl
          }
          timeZone
        }
        hotelRecommendation {
          id
          hotelInfo {
            city
            description
            name
            state
          }
          name
          notes
          standardHotelId
          userProfile {
            id
            accountHandle
            displayName
            profileImageUrl
          }
        }
        likedListGuide {
          guide {
            id
            addresses {
              id
              city
              state
            }
            description
            guideType
            name
          }
          likerProfile {
            id
            accountHandle
            displayName
            profileImageUrl
          }
        }
      }
      collections(first: 1) {
        totalCount
      }
      experienceBookings(first: 1, searchUserExperienceBookingsArgs: $searchUserExperienceBookingsArgs) {
        totalCount
      }
      firstName
      hotelBookings(first: 1, searchUserHotelBookingsArgs: $searchUserHotelBookingsArgs) {
        totalCount
      }
      searchPreferences {
        id
      }
      trips(first: 1, tripSearchInput: $tripSearchInput) {
        totalCount
      }
      userGuideDrafts(first: 1, userGuideDraftsArgs: $userGuideDraftsArgs) {
        totalCount
      }
      userProfile {
        id
        accountHandle
        activeBannerImage {
          id
          extraSmallUrl
          largeUrl
          mediumUrl
          smallUrl
        }
        displayName
        followCount {
          countOfFollowed
          countOfFollowing
        }
        guideCount(guideStatus: PUBLISHED)
        isUserOwner
        profileCompletion {
          completionPercentage
          hasBio
          hasProfileImage
          hasSocialLinks
          hasTravelStats
          hasTravelStyle
        }
        profileImageSource
        profileImageUrl
        userId
      }
    }
  }
`)

export type UseGetDashboardQuery = {
  activityFeed: DashboardFeedItem[]
  dashboardData: GetCurrentUserQueryInDashboardQuery
  hasError: ApolloError
  isLoading: boolean
}

/** @todo waiting on BE to return follower feed */
export const useGetDashboardQuery = (): UseGetDashboardQuery => {
  const {
    data,
    error: hasError,
    loading: isLoading,
  } = useQuery(dashboardCurrentUserQuery, {
    variables: {
      searchUserExperienceBookingsArgs: {
        bookingTimeState: ExperienceBookingTimeState.Upcoming,
      },
      searchUserHotelBookingsArgs: {
        bookingTimeState: BookingTimeState.Upcoming,
      },
      tripSearchInput: {
        status: TripStatus.Upcoming,
      },
      userGuideDraftsArgs: {
        includeStatuses: [GuideDraftStatus.Active],
      },
    },
  })
  const dashboardData = data ?? null
  const activityFeed =
    data?.currentUser?.activityFeed?.map(feedItem =>
      getDashboardFeedItem(feedItem)
    ) ?? []

  return {
    activityFeed,
    dashboardData,
    hasError,
    isLoading,
  }
}
