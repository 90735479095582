import React, { useRef, useState } from 'react'
import { StopPropagation } from '@travelpass/design-system'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import type { HotelRecommendation } from 'src/__generated__/graphql'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { hotelsSegment } from 'src/constants'
import { localizedDateWithFullMonth } from 'src/utils'
import { DefaultRecommendedHotelImage } from '../../components/DefaultRecommendedHotelImage'
import { AvatarRow } from '../common/AvatarRow'
import { FormattedLocation } from '../common/FormattedLocation'

const ImageWithFallback = ({
  alt,
  src,
  fallbackImg: Fallback,
}: {
  alt: string
  src: string
  fallbackImg?: string | React.ReactNode
}) => {
  const [imgSrc, setImgUrl] = useState<string | React.ReactNode>(
    src || Fallback
  )
  const [loading, setLoading] = useState(true)

  return typeof imgSrc !== 'string' ? (
    Fallback
  ) : (
    <img
      alt={alt}
      className={`rounded-3 bg-grey-300 block aspect-[3/2] w-full object-cover ${loading ? 'animate-pulse' : ''}`}
      height='auto'
      src={imgSrc}
      width='100%'
      onError={() => {
        setLoading(false)
        setImgUrl(Fallback)
      }}
      onLoad={() => {
        setLoading(false)
      }}
    />
  )
}

export const HotelCard = ({ hotel }: { hotel: HotelRecommendation }) => {
  const href = hotel?.isKnownHotel
    ? `/${hotelsSegment}/${hotel?.standardHotelId}`
    : ''

  const anchorRef = useRef<HTMLAnchorElement>(null)

  const handleDataLayerPush = () => {
    pushDataToDataLayer('hotel_card_click', {
      hotel_id: hotel.id,
      hotel_name: hotel?.hotelInfo?.name,
      owner_id: hotel?.userProfile?.id,
      trigger_url: window.location.href,
    })
  }

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={classNames(
        'rounded-3 border-grey-300 of-hidden relative border-2 border-solid',
        'flex flex-col gap-4 p-4 md:flex-row md:p-6',
        /**
         * @desc exposes DOM state for consumers to tap into Card events. eg. `opacity-0 group-hover:opacity-100 group-focus-within:opacity-100`
         * @see https://tailwindcss.com/docs/hover-focus-and-other-states#styling-based-on-parent-state
         */
        'group',
        {
          'hover:cursor-pointer': hotel?.isKnownHotel,
          'cursor-default select-none': !hotel?.isKnownHotel,
        }
      )}
      onClick={() => {
        if (hotel?.isKnownHotel) {
          handleDataLayerPush()
          anchorRef?.current?.click?.()
        }
      }}
    >
      <div className='flex-grow md:order-1'>
        <ImageWithFallback
          alt={hotel?.hotelInfo?.name}
          fallbackImg={<DefaultRecommendedHotelImage />}
          src={hotel?.hotelInfo?.image}
        />
      </div>
      <div className='space-y-2 md:w-[60%]'>
        {hotel?.isKnownHotel ? (
          <StopPropagation>
            <Link
              className='outline-offset-5 focus-visible:outline-emerald type-h3 c-forest inline-block no-underline'
              ref={anchorRef}
              to={href}
              onClick={() => handleDataLayerPush()}
            >
              {hotel?.hotelInfo?.name}
            </Link>
          </StopPropagation>
        ) : (
          <div className='outline-offset-5 focus-visible:outline-emerald type-h3 c-forest inline-block no-underline'>
            {hotel?.hotelInfo?.name}
          </div>
        )}
        <FormattedLocation
          address={{
            fullAddress: hotel?.hotelInfo?.address,
            city: hotel?.hotelInfo?.city,
            state: hotel?.hotelInfo?.state,
            country: hotel?.hotelInfo?.country,
          }}
        />
        <AvatarRow
          dateTimeText={localizedDateWithFullMonth(
            hotel?.lastUpdated
          ).toUpperCase()}
          displayName={hotel?.userProfile?.displayName}
          profileImageUrl={hotel?.userProfile?.profileImageUrl}
        />
        <p className='text-body-1 text-ellipsis'>{hotel?.notes}</p>
        {!hotel?.isKnownHotel && (
          <p className='type-small-text-desktop c-gray600'>
            Not provided by Travelpass
          </p>
        )}
      </div>
    </div>
  )
}
