import type { LazyQueryExecFunction } from '@apollo/client'
import isEmpty from 'lodash.isempty'
import {
  GuideStatus,
  GuideUrlSource,
  PresignedUrlType,
  type GetPresignedUrlsLazyQuery,
  type GetPresignedUrlsLazyQueryVariables,
} from 'src/__generated__/graphql'
import { initialGuideDraftPublishFormValues } from './guideDraftPublishConstants'
import type {
  GuideDraftPublishFormValues,
  GuideDraftPublishInvalidFormValues,
  GuideDraftPublishProcessedImage,
  UseGuideDraftPublishForm,
} from './types'
import type { GuideDraftData } from '../../types'

const clearInvalidFormValues = ({
  formValues,
  invalidFormValues,
}: {
  formValues: UseGuideDraftPublishForm['formValues']
  invalidFormValues: UseGuideDraftPublishForm['invalidFormValues']
}): UseGuideDraftPublishForm['invalidFormValues'] => ({
  ...invalidFormValues,
  address: invalidFormValues?.address ? isEmpty(formValues.address) : false,
  description: invalidFormValues?.description
    ? !formValues.description.trim()
    : false,
  images: invalidFormValues?.images ? !formValues.images.length : false,
  imagesSize: false,
  imagesType: false,
  imagesUploaded: false,
  name: invalidFormValues?.name ? !formValues.name.trim() : false,
  tagIds: invalidFormValues?.tagIds ? isEmpty(formValues.tagIds) : false,
})

const getGuideDraftPublishCategorizedImages = ({
  currentImages,
  formValues,
}: {
  currentImages: GuideDraftData['images']
  formValues: GuideDraftPublishFormValues
}): {
  createdImages: string[]
  deletedImageIds: string[]
  unchangedImageIds: string[]
} =>
  currentImages.reduce(
    (total, current) => {
      if (!formValues.images.includes(current.url)) {
        total.deletedImageIds.push(current.id)
      } else if (
        formValues.images.includes(current.url) &&
        !total.createdImages.includes(current.id)
      ) {
        total.unchangedImageIds.push(current.id)
      }

      return total
    },
    {
      createdImages: formValues.images.filter(
        url => !currentImages.some(image => image.url === url)
      ),
      deletedImageIds: [],
      unchangedImageIds: [],
    }
  )

const getGuideDraftPublishImagesErrorMessage = (
  invalidFormValues: GuideDraftPublishInvalidFormValues
): string => {
  const { images, imagesSize, imagesType, imagesUploaded } = invalidFormValues

  if (imagesSize && imagesType)
    return 'Images must be less than 5MB and either JPG or PNG.'

  if (imagesSize) return 'Images must be less than 5MB.'

  if (imagesType) return 'Images must be either JPG or PNG.'

  if (imagesUploaded)
    return 'One or more of your images failed to upload because it did not meet our community guidelines.'

  if (images) return 'At least one image is required.'

  return ''
}

const getGuideDraftPublishInitialFormValues = (
  listData: GuideDraftData
): GuideDraftPublishFormValues => {
  const { addresses, description, guide, images, name, tags } = listData ?? {}
  const { id, __typename, ...address } = addresses?.[0] ?? {}

  return {
    ...initialGuideDraftPublishFormValues,
    address: address ?? {},
    description: description ?? '',
    images: images?.map(image => image.url) ?? [],
    name: name ?? '',
    status: guide?.status ?? GuideStatus.Published,
    tagIds: tags?.map(tag => tag.id) ?? [],
  }
}

const processGuideDraftPublishNextStep = async ({
  formValues,
  invalidFormValues,
  onInvalidFormValuesChange,
  onPresignedUpload,
  onSubmit,
  onStepChange,
  skipPresignedUpload,
  step,
}: {
  formValues: UseGuideDraftPublishForm['formValues']
  invalidFormValues: UseGuideDraftPublishForm['invalidFormValues']
  onInvalidFormValuesChange: UseGuideDraftPublishForm['onInvalidFormValuesChange']
  onPresignedUpload: (callback?: VoidFunction) => void
  onSubmit: UseGuideDraftPublishForm['onSubmit']
  onStepChange: UseGuideDraftPublishForm['onStepChange']
  skipPresignedUpload: boolean
  step: UseGuideDraftPublishForm['step']
}): Promise<void> => {
  const updatedInvalidFormValues = validateFormValues({
    formValues,
    invalidFormValues,
    step,
  })
  onInvalidFormValuesChange(updatedInvalidFormValues)

  // Introduction
  if (step === 0) return onStepChange(1)

  // Manage Guide
  if (step === 1) {
    if (
      updatedInvalidFormValues.description ||
      updatedInvalidFormValues.name ||
      updatedInvalidFormValues.address
    )
      return

    return onStepChange(2)
  }

  // Add Tags
  if (step === 2) {
    if (updatedInvalidFormValues.tagIds) return

    return onStepChange(3)
  }

  // Add Photos
  if (step === 3) {
    if (updatedInvalidFormValues.images) return

    if (skipPresignedUpload) return onStepChange(4)

    return await onPresignedUpload(() => onStepChange(4))
  }

  // Confirm
  if (step === 4) await onSubmit(() => onStepChange(5))
}

/** @todo write tests for this function */
const processGuideDraftPublishImageUploads = async ({
  createdImages,
  getPresignedUrls,
}: {
  createdImages: string[]
  getPresignedUrls: LazyQueryExecFunction<
    GetPresignedUrlsLazyQuery,
    GetPresignedUrlsLazyQueryVariables
  >
}): Promise<GuideDraftPublishProcessedImage[]> => {
  if (isEmpty(createdImages)) return []

  const presignedUrlsResponse = await getPresignedUrls({
    fetchPolicy: 'network-only',
    variables: {
      count: createdImages.length,
      presignedUrlType: PresignedUrlType.GuideImages,
    },
  })
  const presignedUrls = presignedUrlsResponse?.data?.getPresignedUrls ?? []

  if (presignedUrls.length !== createdImages.length) {
    console.error('Mismatch between number of images and presigned URLs')
    return []
  }

  const uploadedUrls = await Promise.all(
    createdImages.map(async (image, index) => {
      try {
        const presignedUrl = presignedUrls[index]

        if (!presignedUrl) {
          console.error(
            `Failed to get presigned URL for image at index ${index}`
          )
          return null
        }

        const presignedUrlWithoutQuery = presignedUrl.split('?')[0]
        const blob = await fetch(image).then(response => response.blob())
        const uploadResponse = await fetch(presignedUrl, {
          method: 'PUT',
          body: blob,
        })

        if (!uploadResponse.ok) {
          console.error(`Failed to upload image at index ${index}`)
          return null
        }

        return {
          source:
            image.indexOf('pixabay') > -1
              ? GuideUrlSource.PixabayImage
              : GuideUrlSource.UserUploadedImage,
          url: presignedUrlWithoutQuery,
        }
      } catch (error) {
        console.error(error)
        return null
      }
    })
  )

  const filteredUploadedUrls = uploadedUrls.filter(url => url !== null)

  if (filteredUploadedUrls.length !== createdImages.length) return []

  return filteredUploadedUrls
}

/** @todo write tests for this function */
const validateFormValues = ({
  formValues,
  invalidFormValues,
  step,
}: {
  formValues: UseGuideDraftPublishForm['formValues']
  invalidFormValues: UseGuideDraftPublishForm['invalidFormValues']
  step: UseGuideDraftPublishForm['step']
}): GuideDraftPublishInvalidFormValues => {
  let updatedInvalidFormValues = {
    ...invalidFormValues,
  }

  // Manage Guide
  if (step === 1) {
    updatedInvalidFormValues = {
      ...updatedInvalidFormValues,
      address: isEmpty(formValues.address),
      description: !formValues.description.trim(),
      name: !formValues.name.trim(),
    }
  }

  // Add Tags
  if (step === 2) {
    updatedInvalidFormValues = {
      ...updatedInvalidFormValues,
      tagIds: isEmpty(formValues.tagIds),
    }
  }

  // Add Photos
  if (step === 3) {
    updatedInvalidFormValues = {
      ...updatedInvalidFormValues,
      images: !formValues.images.length,
      imagesSize: false,
      imagesType: false,
      imagesUploaded: false,
    }
  }

  return updatedInvalidFormValues
}

export {
  clearInvalidFormValues,
  getGuideDraftPublishCategorizedImages,
  getGuideDraftPublishImagesErrorMessage,
  getGuideDraftPublishInitialFormValues,
  processGuideDraftPublishImageUploads,
  processGuideDraftPublishNextStep,
  validateFormValues,
}
