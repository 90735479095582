import { Skeleton } from '@travelpass/design-system'
import { useFlag } from 'src/common/hooks'

export const DashboardNavLoading = () => {
  const isTripsHideEnabled = useFlag('tripsHide')

  return (
    <div className='h-9.5 md:h-13.5 flex flex-row items-center'>
      <div className='h-4.5 flex flex-row items-center gap-1 px-3 py-2 md:gap-2 md:py-4'>
        <div className='h-6 w-6'>
          <Skeleton />
        </div>
        <div className='md:w-6.5 md:h-4.5 h-5 w-8'>
          <Skeleton />
        </div>
      </div>
      <div className='h-4.5 flex flex-row items-center gap-1 px-3 py-2 md:gap-2 md:py-4'>
        <div className='h-6 w-6'>
          <Skeleton />
        </div>
        <div className='md:w-9.5 md:h-4.5 h-5 w-11'>
          <Skeleton />
        </div>
      </div>
      {!isTripsHideEnabled && (
        <div className='h-4.5 flex flex-row items-center gap-1 px-3 py-2 md:gap-2 md:py-4'>
          <div className='h-6 w-6'>
            <Skeleton />
          </div>
          <div className='w-7.5 md:h-4.5 h-5 md:w-7'>
            <Skeleton />
          </div>
        </div>
      )}
      <div className='h-4.5 flex flex-row items-center gap-1 px-3 py-2 md:gap-2 md:py-4'>
        <div className='h-6 w-6'>
          <Skeleton />
        </div>
        <div className='md:w-13 w-14.5 md:h-4.5 h-5'>
          <Skeleton />
        </div>
      </div>
      <div className='h-4.5 flex flex-row items-center gap-1 px-3 py-2 md:gap-2 md:py-4'>
        <div className='h-6 w-6'>
          <Skeleton />
        </div>
        <div className='md:w-15.5 w-17 md:h-4.5 h-5'>
          <Skeleton />
        </div>
      </div>
    </div>
  )
}
