import { GuideImage } from 'src/pages/guides/details/common'
import { getGuidePublishedDate } from 'src/utils'
import type { GuideData, GuideOwner } from '../../types'

interface GuideProfileProps {
  guideData: GuideData
  owner: GuideOwner
}

export const GuideProfile = ({ guideData, owner }: GuideProfileProps) => {
  const { insertedAt, timeZone, updatedAt } = guideData ?? {}
  const { image, name, url } = owner ?? {}

  return (
    <div className='flex flex-row items-center gap-3'>
      <a
        aria-label={`View profile of ${name}`}
        className='b-0 of-hidden rounded-1/2 transition-opacity-200 h-10 w-10 cursor-pointer bg-transparent p-0 ease-linear hover:opacity-50 active:opacity-50'
        href={url}
        rel='noreferrer'
        target='_BLANK'
      >
        <GuideImage alt={`Profile image for ${name}`} src={image} />
      </a>
      <div>
        <address>
          <a
            aria-label={`View profile of ${name}`}
            className='b-0 decoration-none of-hidden text-color-primary-forest transition-opacity-200 type-body-1 lg:text-color-white cursor-pointer bg-transparent p-0 not-italic ease-linear hover:opacity-50 active:opacity-50 md:truncate'
            href={url}
            rel='noreferrer'
            target='_BLANK'
          >
            By {name}
          </a>
        </address>
        <time
          className='text-color-grey-600 type-small-text lg:text-color-white md:truncate'
          dateTime={insertedAt}
        >
          {getGuidePublishedDate({
            insertedAt,
            timeZone,
            updatedAt,
          })}
        </time>
      </div>
    </div>
  )
}
