import { useState } from 'react'
import { Illustration } from '@travelpass/design-system'
import classNames from 'classnames'
import { HomeContainer } from '../HomeContainer'

type OverviewSection = 'explore' | 'plan' | 'experience'

export const HomeTripOverview = () => {
  const [selectedSection, setSelectedSection] =
    useState<OverviewSection>('explore')

  return (
    <HomeContainer size='xl'>
      <section className='h-250 sm:h-1500px md:h-1250px lg:h-1000px flex w-full items-center justify-center'>
        <div className='mt--66px'>
          <div
            className={classNames(
              'h-58 b-0 b-r-2 mr-5 hidden justify-between lg:flex lg:flex-col',
              {
                'b-forest b-solid': selectedSection !== 'explore',
                'b-canyon b-dashed': selectedSection === 'explore',
              }
            )}
          >
            <div className='mr--5px rounded-50% bg-forest h-2 w-2' />
            <div
              className={classNames('mr--5px rounded-50% h-2 w-2', {
                'bg-forest': selectedSection !== 'explore',
                'bg-canyon': selectedSection === 'explore',
              })}
            />
          </div>
          <div
            className={classNames(
              'h-58 b-0 b-r-2 mr-5 hidden justify-between lg:flex lg:flex-col',
              {
                'b-forest b-solid': selectedSection === 'experience',
                'b-canyon b-dashed': selectedSection !== 'experience',
              }
            )}
          >
            <div className='' />
            <div
              className={classNames('mr--5px rounded-50% h-2 w-2', {
                'bg-forest': selectedSection === 'experience',
                'bg-canyon': selectedSection !== 'experience',
              })}
            />
          </div>
        </div>
        <div className='lg:w-290px lg:space-y-30 lg:mr-50px sm:space-y-20 md:w-full'>
          <div className='lg:w-289px flex items-center justify-center sm:flex-col-reverse md:w-full md:flex-row md:space-x-6'>
            <div className='w-311px relative space-y-4'>
              <button
                aria-pressed={true}
                className={sectionHeaderClasses}
                onClick={() => setSelectedSection('explore')}
              >
                Explore
                <p>
                  Got a case of #wanderlust? We have thousands of top-rated
                  experiences and hotels for you to discover with the touch of a
                  button.
                </p>
              </button>
              <div
                aria-selected={true}
                className='sm:top--15px sm:left--40px lg:top--10px lg:left--60px w-4.5 c-forest absolute'
              >
                <Illustration name='arrow' />
              </div>
            </div>
            <img
              alt=''
              className='w-350px lg:hidden'
              src='https://static.travelpass.com/assets/homev2-page/trip-overview-explore.webp'
            />
          </div>
          <div className='lg:w-289px flex items-center justify-center sm:flex-col-reverse md:w-full md:flex-row md:space-x-6'>
            <div className='w-311px relative space-y-4'>
              <button
                aria-pressed={selectedSection !== 'explore'}
                className={sectionHeaderClasses}
                onClick={() => setSelectedSection('plan')}
              >
                Plan
                <p>
                  Save your favorite finds to your Collection, then use our
                  custom itinerary feature to build out and book your dream
                  trip!
                </p>
              </button>
              <div
                aria-selected={selectedSection !== 'explore'}
                className='sm:top--15px sm:left--40px lg:top--10px lg:left--60px w-4.5 c-forest absolute lg:hidden lg:aria-selected:block'
              >
                <Illustration name='arrow' />
              </div>
            </div>
            <img
              alt=''
              className='w-350px lg:hidden'
              src='https://static.travelpass.com/assets/homev2-page/trip-overview-plan.webp'
            />
          </div>
          <div className='lg:w-289px flex items-center justify-center sm:flex-col-reverse md:w-full md:flex-row md:space-x-6'>
            <div className='w-311px relative space-y-4'>
              <button
                aria-pressed={selectedSection === 'experience'}
                className={sectionHeaderClasses}
                onClick={() => setSelectedSection('experience')}
              >
                Experience
                <p>
                  Here today and off on an adventure tomorrow. When trip
                  planning is this easy, the experience of travel is that much
                  sweeter.
                </p>
              </button>
              <div
                aria-selected={selectedSection === 'experience'}
                className='sm:top--15px sm:left--40px lg:top--10px lg:left--60px w-4.5 c-forest absolute lg:hidden lg:aria-selected:block'
              >
                <Illustration name='arrow' />
              </div>
            </div>
            <img
              alt=''
              className='w-350px lg:hidden'
              src='https://static.travelpass.com/assets/homev2-page/trip-overview-experience.webp'
            />
          </div>
        </div>
        <img
          alt=''
          className='min-w-50% max-w-70% hidden lg:block'
          src={`https://static.travelpass.com/assets/homev2-page/trip-overview-${selectedSection}.webp`}
        />
      </section>
    </HomeContainer>
  )
}

const sectionHeaderClasses =
  'text-left type-h3 b-none p-0 bg-transparent focus-visible:outline-valley focus-visible:outline-solid' +
  ' focus-visible:outline-2 focus-visible:rounded-2px cursor-pointer sm:c-forest lg:c-canyon aria-pressed:c-forest hover:c-forest' +
  ' children:aria-pressed:c-forest children:c-canyon children:type-body-2 children:hover:c-forest children:mt-4'
