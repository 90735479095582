import { EventType, type PublishedEvent } from 'src/__generated__/graphql'
import { MarkerWrapper } from 'src/common/components/Map'
import { StyledMarker } from 'src/pages/trips/components'
import { stringToNumber } from 'src/utils'
import { GuideMapMarkerPopup } from './GuideMapMarkerPopup'
import type { GuideOwner } from '../../types'
import {
  onGuideSessionStorageHoverIdChange,
  onGuideSessionStorageSelectedIdChange,
} from '../../useGuideSessionStorageIds'

interface GuideMapMarkerProps {
  isHovered: boolean
  isSelected: boolean
  owner: GuideOwner
  point: PublishedEvent
}

export const GuideMapMarker = ({
  isHovered,
  isSelected,
  owner,
  point,
}: GuideMapMarkerProps) => {
  const { addresses, id, type } = point ?? {}
  const [address] = addresses ?? []
  const lat = stringToNumber(address?.lat)
  const lng = stringToNumber(address?.long)

  const onClick = () => onGuideSessionStorageSelectedIdChange(id)

  return (
    <MarkerWrapper
      isHovered={isHovered || isSelected}
      position={{
        lat,
        lng,
      }}
      onClick={onClick}
    >
      <div
        className='relative'
        onMouseEnter={() => onGuideSessionStorageHoverIdChange?.(id)}
        onMouseLeave={() => onGuideSessionStorageHoverIdChange?.('')}
      >
        <StyledMarker
          isHovered={isHovered}
          isSelected={isSelected}
          type={type ?? EventType.Miscellaneous}
        />
        {isHovered && (
          <div className='w-95 md:w-115 absolute top-[calc(100%+12px)] hidden -translate-x-1/2 md:block'>
            <GuideMapMarkerPopup
              owner={owner}
              point={point}
              onClick={onClick}
            />
          </div>
        )}
      </div>
    </MarkerWrapper>
  )
}
