import type { MutableRefObject } from 'react'
import { useEffect } from 'react'
import isEmpty from 'lodash.isempty'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Helmet } from 'src/common/components'
import { lucencyNumber } from 'src/config/analytics/lucencyUtils'
import { baseUrl } from 'src/constants'
import { useHotelHeroStandardHotelQuery } from 'src/pages/hotels/details/hooks/useHotelHeroStandardHotelQuery'
import {
  constructImageLink,
  getUtmParams,
  generateHotelDetailsUrl,
  getAdultGuests,
  getKidGuests,
} from 'src/utils'
import { HotelHeroApolloError } from './HotelHeroApolloError'
import { HotelHeroApolloLoading } from './HotelHeroApolloLoading'
import { HotelSearchParams } from '../../../constants'
import { useHotelReviewsSummaryListHotelReviewsQuery } from '../../../hooks'
import { constructHotelName } from '../../../utils/hotelUtils'
import { HotelHeroHeader } from '../HotelHeroHeader'
import { HotelHeroImages } from '../HotelHeroImages'

interface HotelHeroApolloProps {
  hotelRoomsRef: MutableRefObject<HTMLDivElement>
}

export const HotelHeroApollo = ({ hotelRoomsRef }: HotelHeroApolloProps) => {
  const navigate = useNavigate()
  const { hotelId, hotelName } = useParams()
  const [searchParams] = useSearchParams()
  const { standardHotel, isLoading, hasError } =
    useHotelHeroStandardHotelQuery()
  const adults = searchParams.get(HotelSearchParams.adults)
  const arrival = searchParams.get(HotelSearchParams.arrival)
  const departure = searchParams.get(HotelSearchParams.departure)
  const eventId = searchParams.get(HotelSearchParams.eventId)
  const kids = searchParams.get(HotelSearchParams.kids)
  const tripId = searchParams.get(HotelSearchParams.tripId)
  const { hotelReviewsSummaryData } =
    useHotelReviewsSummaryListHotelReviewsQuery()
  const {
    address,
    city,
    country,
    descriptions,
    imageLinks,
    name,
    postalCode,
    rating,
    state,
    stateCode,
  } = standardHotel ?? {}

  const { averageOverall, totalReviews } = hotelReviewsSummaryData ?? {}
  const excerpt = descriptions?.[0].text ?? ''
  const showError = hasError || !standardHotel?.active || isEmpty(standardHotel)

  const hotelsSchemaMarkups = {
    address,
    averageOverall: averageOverall?.toString(),
    city,
    country,
    description: excerpt,
    image: constructImageLink(imageLinks?.[0]),
    name,
    postalCode,
    rating,
    stateCode,
    totalReviews: totalReviews?.toString(),
  }

  const constructedTitle = () => {
    if (isLoading) {
      return 'Hotel'
    }

    return `${name} - ${city}, ${state}` ?? ''
  }

  useEffect(() => {
    if (name) {
      const constructedHotelName = constructHotelName(hotelName)
      const utmParams = getUtmParams(searchParams)
      if (name !== constructedHotelName) {
        const url = generateHotelDetailsUrl({
          adults: getAdultGuests(adults),
          arrival,
          city,
          departure,
          eventId,
          kids: getKidGuests(kids),
          id: hotelId,
          name,
          state,
          stateAbbreviation: stateCode,
          tripId,
          utmParams,
        })
        navigate(url, {
          replace: true,
        })
      }
    }
  }, [
    adults,
    arrival,
    city,
    departure,
    hotelId,
    hotelName,
    kids,
    name,
    navigate,
    state,
  ])

  useEffect(() => {
    if (hotelId) {
      // track visited hotel id to lucency
      lucencyNumber({
        hotelsViewed: [hotelId],
      })
    }
  }, [hotelId])

  if (isLoading) return <HotelHeroApolloLoading />

  if (showError) return <HotelHeroApolloError hotelData={standardHotel} />

  const hotelUrlWithoutParams = generateHotelDetailsUrl({
    adults: getAdultGuests(adults),
    arrival,
    city,
    departure,
    kids: getKidGuests(kids),
    id: hotelId,
    name,
    state,
    stateAbbreviation: stateCode,
    excludeSearchParams: true,
  })

  return (
    <>
      <Helmet
        canonicalUrl={`${baseUrl}${hotelUrlWithoutParams}`}
        hotelsSchemaMarkups={hotelsSchemaMarkups}
        metaDescription={excerpt}
        pageName={constructedTitle()}
      />
      <HotelHeroImages hotelData={standardHotel} />
      <HotelHeroHeader
        hotelData={standardHotel}
        hotelRoomsRef={hotelRoomsRef}
      />
    </>
  )
}
