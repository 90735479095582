import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import type { GuideSorts } from 'src/__generated__/graphql'
import { pushDataToDataLayer } from 'src/config/analytics/googleTagManagerIntegration'
import { getTracker } from 'src/utils'

const RESULTS_LIMIT = 14

export const GUIDE_SEARCH_QUERY = gql(`
  query GuideSearch($guideSearchArgs: GuideSearchArgs!, $first: Int, $after: String) {
    guideSearch(guideSearchArgs: $guideSearchArgs, first: $first, after: $after) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          addresses {
            id
            city
            country
            state
          }
          isCollected
          endDate
          guideType
          images {
            id
            source
            type
            url
          }
          imageUrl
          insertedAt
          name
          ownerProfile {
            id
            accountHandle
            displayName
            profileImageUrl
          }
          startDate
          updatedAt
          timeZone
        }
      }
    }
  }
`)

export type UseGuideSearchQueryProps = {
  latitude?: number
  longitude?: number
  location?: string
  similarName?: string
  sortBy: GuideSorts
  tagIds?: string[]
  queryCount?: number
}

export const useGuideSearchQuery = ({
  latitude,
  longitude,
  location,
  queryCount,
  similarName,
  sortBy,
  tagIds,
}: UseGuideSearchQueryProps) => {
  const constructedSearchBy =
    !latitude || !longitude
      ? {
          similarName,
        }
      : {
          nearbyLocations: {
            radiusRange: {
              min: null,
              max: 80,
            },
            latLong: {
              latitude,
              longitude,
            },
          },
        }

  const constructedGetTracker = similarName
    ? getTracker(similarName)
    : getTracker(location)

  const skip = (!location || !latitude || !longitude) && !similarName

  return useQuery(GUIDE_SEARCH_QUERY, {
    variables: {
      first: queryCount || RESULTS_LIMIT,
      guideSearchArgs: {
        searchBy: constructedSearchBy,
        filterBy: {
          tagIds: tagIds?.length ? tagIds : undefined,
        },
        sortBy: sortBy as GuideSorts,
        tracker: constructedGetTracker,
      },
    },
    notifyOnNetworkStatusChange: true,
    skip,
    onCompleted: data => {
      const firstFive = data.guideSearch?.edges.slice(0, 5)
      pushDataToDataLayer('guide_search_results', {
        firstResults: firstFive,
        location,
        latitude,
        longitude,
        sortBy,
      })
    },
  })
}
