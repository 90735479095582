import { StopPropagation } from '@travelpass/design-system'
import {
  GuideMetricLike,
  GuideMetricSave,
  GuideMetricShare,
  GuideMetricView,
} from 'src/pages/guides/common'
import type { GuideData } from 'src/pages/guides/details/types'

interface DashboardGuideDraftCardMetricsProps {
  guideData: Pick<
    GuideData,
    | 'collectedCount'
    | 'id'
    | 'isCollected'
    | 'name'
    | 'numberOfLikes'
    | 'shareCount'
    | 'ownerProfile'
    | 'viewCount'
  >
}

export const DashboardGuideDraftCardMetrics = ({
  guideData,
}: DashboardGuideDraftCardMetricsProps) => (
  <div className='w-fit cursor-default'>
    <StopPropagation>
      {/** @todo refactor GuideMetricLike, Share, etc. to prevent using this nastiness */}
      <div className='[&>div>button]:p-x-0 [&>div>button>i]:color-grey-900 [&>div>button>div]:color-grey-900 [&>div>button>div]:normal-case [&>div>button]:gap-1'>
        <div className='inline-flex flex-row flex-wrap items-center gap-x-3 gap-y-2'>
          <GuideMetricLike
            guideData={guideData}
            hideLabel={true}
            isDisabled={true}
            size='small'
          />
          <GuideMetricView
            guideData={guideData}
            hideLabel={true}
            isDisabled={true}
            size='small'
          />
          <GuideMetricSave
            guideData={guideData}
            hideLabel={true}
            isDisabled={true}
            size='small'
          />
          <GuideMetricShare
            guideData={guideData}
            hideLabel={true}
            isDisabled={true}
            size='small'
          />
        </div>
      </div>
    </StopPropagation>
  </div>
)
