import { useState } from 'react'
import { Skeleton } from '@travelpass/design-system'
import type { BookingValidateRateQuery } from 'src/__generated__/graphql'

interface BookingSidebarRoomImageProps {
  bookingRateLoading: boolean
  city: string
  images: BookingValidateRateQuery['validatedRate']['room']['images']
  name: string
  state: string
}

export const BookingSidebarRoomImage = ({
  bookingRateLoading,
  city,
  images,
  name,
  state,
}: BookingSidebarRoomImageProps) => {
  const [hasError, setHasError] = useState(false)
  const constructedImage = images?.[0]?.href

  const bookingSidebarRoomImage = () => {
    if (bookingRateLoading) return <Skeleton variant='rounded' />

    return (
      <img
        alt={`${name} ${city} ${state} Hotel Room}`}
        className='h-full w-full object-cover'
        src={constructedImage}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null
          setHasError(true)
        }}
      />
    )
  }

  return (
    !hasError && (
      <div className='rounded-14px h-19 lg:h-118px w-121px md:w-140px lg:w-181px overflow-hidden'>
        {bookingSidebarRoomImage()}
      </div>
    )
  )
}
