import isEmpty from 'lodash.isempty'
import { createSearchParams } from 'react-router-dom'
import {
  GuideDraftStatus,
  type CreateGuideDraftMutationInGuideDraftOldMutationVariables,
  type CreatePublishedEventMutationInGuideDraftOldMutationVariables,
  type GetPlaceDetailsInTripsQuery,
  type PublishedEvent,
  type UpdateGuideDraftMutationInGuideDraftOldMutationVariables,
  type UpdateGuideMutationInGuideMutationVariables,
} from 'src/__generated__/graphql'
import { type GeocoderType } from 'src/constants/user'
import { constructAddressInput, getEventType } from 'src/pages/trips/utils'
import { getGuideDraftDetailsUrl } from 'src/utils'
import type { GuideDraftDataOld } from './types'
import { GuideSearchParam } from '../details'

const getCreateGuideDraftVariables = ({
  description,
  geocoder,
  name,
}: {
  description: string
  geocoder: GeocoderType
  name: string
}): CreateGuideDraftMutationInGuideDraftOldMutationVariables => {
  const variables: CreateGuideDraftMutationInGuideDraftOldMutationVariables = {
    createGuideDraftInput: {
      name,
    },
    userGuideDraftsArgs: {
      includeStatuses: [GuideDraftStatus.Active],
    },
  }

  if (description) variables.createGuideDraftInput.description = description

  if (!isEmpty(geocoder?.center))
    variables.createGuideDraftInput.addresses = [
      constructAddressInput(geocoder),
    ]

  return variables
}

const getGuideDraftCreatePublishedVariables = ({
  guideDraftId,
  placeDetailsData,
}: {
  guideDraftId: CreatePublishedEventMutationInGuideDraftOldMutationVariables['publishedEventInput']['guideDraftId']
  placeDetailsData: GetPlaceDetailsInTripsQuery
}): CreatePublishedEventMutationInGuideDraftOldMutationVariables => {
  const {
    address,
    city,
    country,
    googlePlaceId,
    latitude,
    longitude,
    name,
    postalCode,
    state,
    type,
  } = placeDetailsData?.getPlaceDetails ?? {}
  const inputAddress = {
    addressLine1: address,
    city,
    country,
    googlePlaceId,
    lat: latitude,
    long: longitude,
    state,
    zipcode: postalCode,
  }

  return {
    publishedEventInput: {
      addresses: [inputAddress],
      guideDraftId,
      name,
      type: getEventType(type),
    },
  }
}

const getGuideDraftEventMarkers = (
  groupedEvents: GuideDraftDataOld['groupedEvents']
): GuideDraftDataOld['groupedEvents'][0]['events'] => {
  if (isEmpty(groupedEvents)) return []

  return groupedEvents
    ?.flatMap(groupedEvent => groupedEvent?.events ?? [])
    ?.reduce((total, event) => {
      const { addresses } = event ?? {}
      const [address] = addresses ?? []

      if (address?.lat && address?.long) total.push(event)

      return total
    }, [])
}

const getGuideDraftPublishedEventCategorySortOrder = (
  groupedEvents: GuideDraftDataOld['groupedEvents']
): UpdateGuideMutationInGuideMutationVariables['updateGuideInput']['publishedEventCategorySortOrder'] =>
  groupedEvents?.reduce((total, { id }) => {
    if (id) total.push(id)

    return total
  }, [] as string[])

const getUpdateGuideDraftVariables = ({
  description,
  geocoder,
  guideDraftId,
  name,
}: {
  description: string
  geocoder: GeocoderType
  guideDraftId: string
  name: string
}): UpdateGuideDraftMutationInGuideDraftOldMutationVariables => {
  const variables: UpdateGuideDraftMutationInGuideDraftOldMutationVariables = {
    input: {
      description: description ?? '',
      guideDraftId,
      name,
      addresses: [],
    },
  }

  if (!isEmpty(geocoder?.center))
    variables.input.addresses = [constructAddressInput(geocoder)]

  return variables
}

export {
  getCreateGuideDraftVariables,
  getGuideDraftCreatePublishedVariables,
  getGuideDraftEventMarkers,
  getGuideDraftPublishedEventCategorySortOrder,
  getUpdateGuideDraftVariables,
}
