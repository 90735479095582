import { useState } from 'react'
import { Divider } from '@travelpass/design-system'
import { useNavigate } from 'react-router-dom'
import { useFlag } from 'src/common/hooks'
import { SearchPreferencesQuiz } from 'src/pages/account-settings/components/search-preferences-quiz/SearchPreferencesQuiz'
import { GuideDraftCreateModal } from 'src/pages/guides/lists/common'
import { FollowersModal } from 'src/pages/profile/components/FollowersModal/FollowersModal'
import type { FollowersModalType } from 'src/pages/profile/types'
import { DashboardCta } from './DashboardCta'
import { DashboardCtaQuiz } from './DashboardCtaQuiz'
import type { UseGetDashboardQuery } from '../../useGetDashboardQuery'

interface DashboardCtasProps {
  dashboardData: UseGetDashboardQuery['dashboardData']
}

export const DashboardCtas = ({ dashboardData }: DashboardCtasProps) => {
  const enablePlanPage = useFlag('enablePlanPage')
  const navigate = useNavigate()
  const [showModalCreateGuideDraft, setShowModalCreateGuideDraft] =
    useState(false)
  /** @todo look at refactoring this showModalFollowers state (strange that it's not boolean type) */
  const [showModalFollowers, setShowModalFollowers] =
    useState<FollowersModalType>(null)
  const [showModalQuiz, setShowModalQuiz] = useState(false)
  const { searchPreferences, userProfile } = dashboardData?.currentUser ?? {}
  const hasUserTakenQuiz = !!searchPreferences?.id
  const pathSegment = enablePlanPage ? 'book' : 'search'

  const toggleFollowersModal = (viewType: FollowersModalType) =>
    setShowModalFollowers(viewType)

  return (
    <>
      <section className='pt-18 space-y-12 px-4 pb-12'>
        <div className='pb-21 space-y-8'>
          <DashboardCta
            buttonText='New Guide'
            description='Curate your list of top spots, personalize it with notes, and watch it come to life on the map.'
            illustration='world'
            title='Create a Travel Guide'
            onClick={() => setShowModalCreateGuideDraft(true)}
          />
          <Divider />
          <DashboardCta
            buttonText='Find Friends'
            description='Find and invite friends to view or create guides on Travelpass.'
            illustration='scooter'
            title='Find Friends'
            onClick={() => toggleFollowersModal('explore')}
          />
          <Divider />
          <DashboardCta
            buttonText='Search'
            description='Book hotels and experiences for your upcoming trips!'
            illustration='suitcase'
            title='Book Travel'
            onClick={() => navigate(`/${pathSegment}`)}
          />
        </div>
        <DashboardCtaQuiz
          hasUserTakenQuiz={hasUserTakenQuiz}
          onShowModalQuiz={() => setShowModalQuiz(true)}
        />
      </section>
      {showModalQuiz && (
        <SearchPreferencesQuiz
          useModal={true}
          onClose={() => setShowModalQuiz(false)}
        />
      )}
      {showModalCreateGuideDraft && (
        <GuideDraftCreateModal
          onDismiss={() => setShowModalCreateGuideDraft(false)}
        />
      )}
      {showModalFollowers && (
        <FollowersModal
          accountHandle={userProfile?.accountHandle}
          toggleModal={toggleFollowersModal}
          userId={userProfile?.userId}
          viewType={showModalFollowers}
          onClose={() => setShowModalFollowers(null)}
        />
      )}
    </>
  )
}
