import { Skeleton } from '@travelpass/design-system'

export const DashboardAccountSettingsLoading = () => (
  <div className='rounded-3 shadow-3 flex flex-row items-center gap-2 bg-white p-3'>
    <div className='space-y-3'>
      <div className='flex flex-row items-center gap-2'>
        <div className='h-6 w-6'>
          <Skeleton />
        </div>
        <div className='w-31 h-5'>
          <Skeleton />
        </div>
      </div>
      <div className='w-70 h-9'>
        <Skeleton />
      </div>
    </div>
    <div className='h-10 w-6'>
      <Skeleton />
    </div>
  </div>
)
