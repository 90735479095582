import { Button, Modal, ModalActions } from '@travelpass/design-system'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { type HotelHeroHeaderAndImagesQuery } from 'src/__generated__/graphql'
import { generateHotelResultsUrl } from 'src/utils'
import { HotelSearchParams } from '../../../constants'

interface HotelHeroApolloErrorProps {
  hotelData: HotelHeroHeaderAndImagesQuery['standardHotel']
}

export const HotelHeroApolloError = ({
  hotelData,
}: HotelHeroApolloErrorProps) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { city, latitude, longitude, state } = hotelData ?? {}
  const location = !!city && !!state && `${city}, ${state}`

  const onConfirm = () =>
    navigate(
      generateHotelResultsUrl({
        adults: searchParams.get(HotelSearchParams.adults),
        arrival: searchParams.get(HotelSearchParams.arrival),
        departure: searchParams.get(HotelSearchParams.departure),
        kids: searchParams.get(HotelSearchParams.kids),
        latitude,
        location,
        longitude,
      })
    )

  return (
    <>
      <Modal
        size='medium'
        title='Looks like this hotel needs a vacation too!'
        onDismiss={onConfirm}
      >
        <div className='space-y-4'>
          <p>
            It&apos;s taking a break from bookings right now. Don&apos;t worry
            though, we have plenty of other sunny spots to explore.
          </p>
          <ModalActions>
            <Button label='New Search' onClick={onConfirm} />
          </ModalActions>
        </div>
      </Modal>
    </>
  )
}
