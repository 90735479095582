import type { AvailableIcons } from '@travelpass/design-system'
import {
  Button,
  Divider,
  Link,
  Modal,
  ModalActions,
  ModalScrollContents,
  SkeletonDots,
} from '@travelpass/design-system'
import type { SocialNetwork } from 'src/__generated__/graphql'
import { profilePath } from 'src/constants'
import { FollowButton } from 'src/pages/profile/components/FollowButton/FollowButton'
import { getSocialPlatformIcon } from 'src/pages/profile/components/profileUtils'
import { BragMetrics } from 'src/pages/profile/content-section/BragMetrics'
import type { BragMetric } from 'src/pages/profile/types'
import { getGuideOwnerImage } from 'src/utils'
import { useGetUserProfile } from './useGetUserProfile'
import { GuideImage } from '../details/common'

interface ProfileModalProps {
  accountHandle: string
  onClose: VoidFunction
}

export const ProfileModal = ({ accountHandle, onClose }: ProfileModalProps) => {
  const { profileData, loading } = useGetUserProfile({
    accountHandle,
  })
  const {
    bio,
    bragContinents,
    bragCountries,
    bragStates,
    displayName,
    followingUsers,
    followCount,
    guideCount,
    isFollowed,
    links,
    profileImageUrl,
    socialLinks,
    userId,
  } = profileData ?? {}

  const bragMetrics: BragMetric[] = [
    {
      label: 'Continents',
      value: bragContinents || 0,
    },
    {
      label: 'Countries',
      value: bragCountries || 0,
    },
    {
      label: 'States',
      value: bragStates || 0,
    },
  ]

  return (
    <Modal size='small' onDismiss={onClose}>
      <ModalScrollContents>
        {loading ? (
          <div className='p-16'>
            <SkeletonDots />
          </div>
        ) : (
          <div className='flex flex-col items-center space-y-8'>
            <section className='text-center' data-testid='user-display-section'>
              {profileImageUrl && (
                <GuideImage
                  alt={`Profile image for ${displayName}`}
                  className='h-25 rounded-1/2 w-25 m-auto'
                  draggable={false}
                  src={getGuideOwnerImage(profileImageUrl)}
                />
              )}
              <h5 className='type-h5'>{displayName}</h5>
              <p className='type-body-2'>{`@${accountHandle}`}</p>
            </section>
            <section
              className='c-forestLight flex items-start justify-center max-md:flex-wrap max-md:gap-6 md:flex-row md:space-x-6'
              data-testid='guides-and-followers-section'
            >
              <div className='flex flex-row items-center space-x-2.5'>
                <span className='type-h3'>{guideCount ?? 0}</span>
                <p className='c-forestLight type-button whitespace-nowrap'>
                  {guideCount === 1 ? 'Guide' : 'Guides'}
                </p>
              </div>
              <div className='flex flex-row items-center space-x-2.5'>
                <span className='type-h3'>
                  {followCount?.countOfFollowing ?? 0}
                </span>
                <p className='c-forestLight type-button whitespace-nowrap'>
                  {followCount?.countOfFollowing === 1
                    ? 'Follower'
                    : 'Followers'}
                </p>
              </div>
              <div className='flex flex-row items-center space-x-2.5'>
                <span className='type-h3'>
                  {followCount?.countOfFollowed ?? 0}
                </span>
                <p className='c-forestLight type-button whitespace-nowrap'>
                  Following
                </p>
              </div>
            </section>
            <section
              className='space-y-3 px-4 text-center'
              data-testid='bio-section'
            >
              {bio && (
                <>
                  <h6 className='type-h6'>Bio</h6>
                  <p className='c-grey-700 type-body-'>{bio}</p>
                </>
              )}
              {followingUsers && !!followingUsers.length && (
                <p className='c-grey-700 text-wrap font-bold'>
                  Followed by{' '}
                  {followingUsers.slice(0, 3).map((user, index) => (
                    <span key={user.id} className='c-grey-900'>
                      <Link
                        href={`${profilePath}/${user.accountHandle}`}
                        showUnderlineOnlyOnHover={true}
                        target='_blank'
                      >
                        {`@${user.accountHandle}`}
                      </Link>
                      {index < 2 && index < followingUsers.length - 1
                        ? ', '
                        : ''}
                    </span>
                  ))}{' '}
                  <span className='whitespace-nowrap'>
                    {followingUsers?.length > 3 &&
                      `+ ${followingUsers?.length - 3} more`}
                  </span>
                </p>
              )}
            </section>
            <Divider />
            {socialLinks?.length > 0 && (
              <>
                <section className='space-y-3 text-center'>
                  <h6 className='type-h6'>Social Media</h6>
                  <div className='flex flex-row space-x-5 px-10'>
                    {socialLinks?.map(
                      ({
                        identifier,
                        network,
                      }: {
                        identifier: string
                        network: SocialNetwork
                      }) => (
                        <Link
                          key={identifier}
                          href={identifier}
                          startIcon={
                            getSocialPlatformIcon(network) as AvailableIcons
                          }
                          target='_blank'
                        />
                      )
                    )}
                  </div>
                </section>
                <Divider />
              </>
            )}
            {links?.length > 0 && (
              <>
                <section className='flex flex-col items-center space-y-4 text-center'>
                  <h6 className='type-h6 -mb-1'>Links</h6>
                  {links?.map(({ url, title }) => (
                    <Button
                      key={`${title}-${url}`}
                      fullWidth
                      aria-label='View link'
                      className='px-2 py-1'
                      size='small'
                      type='button'
                      variant='outlined'
                      onClick={() => window.open(url, '_blank')}
                    >
                      {title}
                    </Button>
                  ))}
                </section>
                <Divider />
              </>
            )}
            <section className='space-y-8 text-center'>
              <h6 className='type-h6'>I&apos;ve visited</h6>
              <BragMetrics metrics={bragMetrics} />
            </section>
          </div>
        )}
      </ModalScrollContents>
      <ModalActions>
        <section className='flex flex-row gap-5'>
          <FollowButton
            displayName={displayName}
            isFollowed={isFollowed}
            label={isFollowed ? 'unfollow' : 'follow'}
            userId={userId}
            variant={isFollowed ? 'outlined' : 'filled'}
          />
          <Button
            label='view profile'
            size='small'
            variant='outlined'
            onClick={() =>
              window.open(`${profilePath}/${accountHandle}`, '_blank')
            }
          />
        </section>
      </ModalActions>
    </Modal>
  )
}
