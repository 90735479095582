import { Divider, Icon } from '@travelpass/design-system'
import { useFlag } from 'src/common/hooks'
import { bookingsPath } from 'src/constants'
import { MenuButton } from './MenuButton'
import { MenuSubtitle } from './MenuSubtitle'
import { MenuTitle } from './MenuTitle'

interface AnonymousProfileMenuProps {
  openSignInModal: Function
}

export const AnonymousProfileMenu = ({
  openSignInModal,
}: AnonymousProfileMenuProps) => {
  const isCompetition2024Enabled = useFlag('enableCompetition2024')

  const enablePlanPage = useFlag('enablePlanPage')
  const pathSegment = enablePlanPage ? 'book' : 'search'

  return (
    <>
      {isCompetition2024Enabled && (
        <section id='account-submenu'>
          <MenuTitle
            icon='businessCenter'
            title='Job Promotion'
            to='/competition/about'
          />
          <div className='children:pl-12'>
            <MenuSubtitle title='About' to='/competition/about' />
            <MenuSubtitle title='Leaderboard' to='/competition/leaderboard' />
            <MenuSubtitle title={"FAQ's"} to='/competition/faq' />
          </div>
          <Divider />
        </section>
      )}

      <section>
        {enablePlanPage ? (
          <>
            <div className='md:hidden'>
              <MenuTitle icon='mapOutlined' title='Explore' to='/guides/all' />
              <Divider />
              <MenuTitle icon='menuBook' title='Plan' to='/plan' />
              <Divider />
              <MenuTitle icon='search' title='Book' to={`/${pathSegment}`} />
              <div className='children:pl-12'>
                <MenuSubtitle title='Hotels' to={`/${pathSegment}/hotels`} />
                <MenuSubtitle
                  title='Experiences'
                  to={`/${pathSegment}/experiences`}
                />
                <MenuSubtitle title='Find My Booking' to={bookingsPath} />
              </div>
              <Divider />
            </div>
            <div className='max-md:hidden md:my-2'>
              <MenuTitle
                icon='search'
                title='Find My Booking'
                to={bookingsPath}
              />
            </div>
          </>
        ) : (
          <>
            <MenuTitle icon='search' title='Search' to={`/${pathSegment}`} />
            <div className='children:pl-12'>
              <MenuSubtitle title='Hotels' to={`/${pathSegment}/hotels`} />
              <MenuSubtitle
                title='Experiences'
                to={`/${pathSegment}/experiences`}
              />
              <MenuSubtitle title='Find My Booking' to={bookingsPath} />
            </div>
          </>
        )}
        <Divider />
      </section>

      {!enablePlanPage && (
        <section className='md:hidden' id='guide-submenu'>
          <MenuTitle icon='mapOutlined' title='Guides' to='/guides/all' />
          <Divider />
        </section>
      )}

      <section className='md:my-2'>
        <MenuButton onClick={openSignInModal}>
          <span className='c-grey800 contents'>
            <Icon name='login' />
          </span>
          <h5 className='type-h5'>Sign Up / Sign In</h5>
        </MenuButton>
      </section>
    </>
  )
}
