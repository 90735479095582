import { useEffect, useState } from 'react'
import { Button, DropdownOption, Icon } from '@travelpass/design-system'
import creditCardType from 'credit-card-type'
import isEmpty from 'lodash.isempty'
import { countries } from 'src/constants/countries'
import { states } from 'src/utils'
import {
  BookingFormInput,
  BookingFormDropdown,
  rules,
  useWatch,
} from './useExperienceBookingForm'

const useDetectAmexCardNumber = () => {
  const [isAmexCard, setIsAmexCard] = useState(false)
  const number = useWatch({ name: 'creditCardSensitive.number' })

  useEffect(() => {
    const cardTypes = isEmpty(number)
      ? []
      : creditCardType(number).map(card => card.niceType)

    setIsAmexCard(cardTypes.includes('American Express'))
  }, [number])

  return isAmexCard
}

export const CardPaymentFields = ({ terms }: { terms: React.ReactNode }) => {
  const isAmexCard = useDetectAmexCardNumber()

  return (
    <div className='space-y-4'>
      <div className='grid grid-cols-2 gap-4'>
        <div className='col-span-2 col-start-1'>
          <BookingFormInput
            fullWidth
            autoComplete='cc-number'
            format={!isAmexCard ? '#### #### #### ####' : '#### ###### #####'}
            label='Debit/Credit card number'
            name='creditCardSensitive.number'
            placeholder='0000 0000 0000 0000'
            required={rules.required}
            rules={isAmexCard ? rules.numberAmex : rules.number}
            slotBefore={<Icon name='creditCard' />}
          />
        </div>
        <BookingFormInput
          fullWidth
          isNumericString
          autoComplete='cc-exp'
          format='##/##'
          label='Expiration'
          name='creditCardSensitive.expiration'
          placeholder='MM/YY'
          required={rules.required}
          rules={rules.expiration}
          type='tel'
        />
        <BookingFormInput
          fullWidth
          autoComplete='cc-csc'
          format={!isAmexCard ? '###' : '####'}
          label='Security code'
          name='creditCardSensitive.cvc'
          placeholder='CVC'
          required={rules.required}
          rules={isAmexCard ? rules.cvcAmex : rules.cvc}
          type='tel'
        />
        <div className='col-span-2 col-start-1'>
          <BookingFormInput
            fullWidth
            autoComplete='cc-name'
            label='Name on Card'
            name='creditCard.cardHolder'
            placeholder='Cardholder name'
            required={rules.required}
          />
        </div>
        <div className='col-span-2 col-start-1'>
          <BookingFormInput
            fullWidth
            autoComplete='address-line1'
            label='Address Line 1'
            name='creditCard.address1'
            placeholder='Address'
            required={rules.required}
          />
        </div>
        <div className='col-span-2 col-start-1'>
          <BookingFormInput
            fullWidth
            autoComplete='address-line2'
            label='Address Line 2'
            name='creditCard.address2'
            placeholder='Suite or Apt #'
          />
        </div>
        <BookingFormInput
          fullWidth
          autoComplete='address-level2'
          label='City'
          name='creditCard.city'
          placeholder='City'
          required={rules.required}
        />
        <BookingFormInput
          fullWidth
          autoComplete='postal-code'
          label='Zip Code'
          name='creditCard.postalCode'
          required={rules.required}
          rules={rules.postalCode}
        />
        <BookingFormDropdown
          fullWidth
          label='State'
          name='creditCard.stateProvince'
          placeholder='State'
          required={rules.required}
        >
          {states.map(({ value, label }) => (
            <DropdownOption key={value} value={value}>
              {label}
            </DropdownOption>
          ))}
        </BookingFormDropdown>
        <BookingFormDropdown
          fullWidth
          label='Country'
          name='creditCard.country'
          required={rules.required}
        >
          {countries.map(({ value, label }) => (
            <DropdownOption key={value} value={value}>
              {label}
            </DropdownOption>
          ))}
        </BookingFormDropdown>
      </div>
      <div>{terms}</div>
      <div className='flex flex-row-reverse'>
        <Button
          fullWidth
          label='Complete booking'
          type='submit'
          variant='filled'
        />
      </div>
    </div>
  )
}
