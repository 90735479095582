import { useState } from 'react'
import { Button, ProgressBar } from '@travelpass/design-system'
import { DashboardProfileProgressButton } from './DashboardProfileProgressButton'
import type { UseGetDashboardQuery } from '../../useGetDashboardQuery'

interface DashboardProfileCompletionProps {
  onModalInfoOpen: VoidFunction
  onScrollIdChange: (updatedScrollId: string) => void
  profileCompletion: UseGetDashboardQuery['dashboardData']['currentUser']['userProfile']['profileCompletion']
}

export const DashboardProfileCompletion = ({
  onModalInfoOpen,
  onScrollIdChange,
  profileCompletion,
}: DashboardProfileCompletionProps) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const formattedCompletionPercentage =
    profileCompletion?.completionPercentage * 100

  return (
    <div className='space-y-4'>
      <div className='space-y-3'>
        <p className='type-body-2-desktop'>Complete your Profile</p>
        <div className='space-y-1.5'>
          <ProgressBar percentage={formattedCompletionPercentage} />
          <div className='type-subtext color-black'>
            {formattedCompletionPercentage}% Completed
          </div>
        </div>
      </div>
      {isExpanded && (
        <div className='p-x-4 space-y-4'>
          <DashboardProfileProgressButton
            isCompleted={profileCompletion?.hasProfileImage}
            onClick={() => {
              onScrollIdChange('dashboard-profile-info-header')
              onModalInfoOpen()
            }}
          >
            Add Photo
          </DashboardProfileProgressButton>
          <DashboardProfileProgressButton
            isCompleted={profileCompletion?.hasBio}
            onClick={() => {
              onScrollIdChange('dashboard-profile-info-about')
              onModalInfoOpen()
            }}
          >
            Write Bio
          </DashboardProfileProgressButton>
          <DashboardProfileProgressButton
            isCompleted={profileCompletion?.hasSocialLinks}
            onClick={() => {
              onScrollIdChange('dashboard-profile-info-social-links')
              onModalInfoOpen()
            }}
          >
            Add Social Links
          </DashboardProfileProgressButton>
          <DashboardProfileProgressButton
            isCompleted={profileCompletion?.hasTravelStats}
            onClick={() => {
              onScrollIdChange('dashboard-profile-info-travel-tracker')
              onModalInfoOpen()
            }}
          >
            Add Travel Stats
          </DashboardProfileProgressButton>
          <DashboardProfileProgressButton
            isCompleted={profileCompletion?.hasTravelStyle}
            onClick={() => {
              onScrollIdChange('dashboard-profile-info-tags')
              onModalInfoOpen()
            }}
          >
            Add Travel Style
          </DashboardProfileProgressButton>
        </div>
      )}
      <Button
        aria-label={
          isExpanded
            ? 'Hide profile completion options'
            : 'Show profile completion options'
        }
        fullWidth={true}
        size='small'
        variant='text'
        onClick={() => setIsExpanded(previous => !previous)}
      >
        {isExpanded ? 'Show Less' : "See What's Missing"}
      </Button>
    </div>
  )
}
