import { SocialNetwork } from 'src/__generated__/graphql'

const getSocialPlatformIcon = (platform: SocialNetwork) => {
  switch (platform) {
    case SocialNetwork.Facebook:
      return 'facebook'
    case SocialNetwork.Twitter:
      return 'x'
    case SocialNetwork.Instagram:
      return 'instagram'
    case SocialNetwork.Linkedin:
      return 'linkedIn'
    case SocialNetwork.Tiktok:
      return 'tikTok'
    default:
      'warningAmber' // We don't know what this social platform is
  }
}

const identifySocialLink = (link: string): string | undefined => {
  const patterns: { [key in SocialNetwork]: RegExp } = {
    FACEBOOK: /(?:http:\/\/|https:\/\/)?(?:www\.)?facebook\.com(?:\/.*)?/i,
    TWITTER:
      /(?:http:\/\/|https:\/\/)?(?:www\.)?(twitter\.com|x\.com)(?:\/.*)?/i, // Adjusted for Twitter now known as X
    INSTAGRAM: /(?:http:\/\/|https:\/\/)?(?:www\.)?instagram\.com(?:\/.*)?/i,
    TIKTOK: /(?:http:\/\/|https:\/\/)?(?:www\.)?tiktok\.com(?:\/.*)?/i,
    LINKEDIN: /(?:http:\/\/|https:\/\/)?(?:www\.)?linkedin\.com(?:\/.*)?/i,
  }

  for (const platform in patterns) {
    if (patterns[platform].test(link)) return platform
  }
  return undefined // Link does not match any of the specified platforms
}

const validateYoutubeLink = (value: string) => {
  // it validates the following youtube video links.
  /**
   * Normal Url: https://www.youtube.com/watch?v=12345678901
   * Share Url: https://youtu.be/12345678901
   * Share Url with start time: https://youtu.be/12345678901?t=6
   * Mobile browser url: https://m.youtube.com/watch?v=12345678901&list=RD12345678901&start_radio=1
   * Long url: https://www.youtube.com/watch?v=12345678901&list=RD12345678901&start_radio=1&rv=smKgVuS
   * Long url with start time: https://www.youtube.com/watch?v=12345678901&list=RD12345678901&start_radio=1&rv=12345678901&t=38
   * Youtube Shorts: https://youtube.com/shorts/12345678901
   * youtue embed iframe link: <iframe width="560" height="315" src="https://www.youtube.com/embed/sJplQSfniMw?si=-KgcqOCag8JOxVq7" title=...... </iframe>
   */
  return /(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|shorts\/|watch\?.+&v=))((\w|-){11})(?:\S+)?/.test(
    value.trim()
  )
}

export { getSocialPlatformIcon, identifySocialLink, validateYoutubeLink }
