import type { CSSProperties, ReactNode } from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Icon } from '@travelpass/design-system'

interface GuideDraftEventProps {
  children: ReactNode
  eventId: string
}

export const GuideDraftEvent = ({
  children,
  eventId,
}: GuideDraftEventProps) => {
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id: eventId,
  })
  const style: CSSProperties = {
    opacity: isDragging ? 0.5 : undefined,
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <div
      className='group flex flex-row items-center gap-2'
      ref={setNodeRef}
      style={style}
    >
      <div className='c-forest-dark w-6 min-w-6' {...attributes} {...listeners}>
        <Icon name='draggable' />
      </div>
      <div className='grow'>{children}</div>
    </div>
  )
}
