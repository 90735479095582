export const NotProvidedMessage = ({
  isKnownHotel,
}: {
  isKnownHotel: boolean
}) => {
  if (isKnownHotel) return null

  return (
    <p className='type-small-text-desktop c-gray600 pr-3 text-end'>
      Not provided by Travelpass
    </p>
  )
}
