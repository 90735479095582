import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const archiveGuideDraft = gql(`
  mutation ArchiveGuideDraftMutationInGuideDraftOld($input: ArchiveGuideDraftInput!, $userGuideDraftsArgs: UserGuideDraftsArgs!) {
    archiveGuideDraft(input: $input) {
      guideDraft {
        id
        owner {
          id
          userGuideDrafts(first: 1, userGuideDraftsArgs: $userGuideDraftsArgs) {
            totalCount
          }
        }
        status
      }
    }
  }
`)

export const useArchiveGuideDraftMutation = () => useMutation(archiveGuideDraft)
