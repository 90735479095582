import {
  Button,
  Modal,
  ModalActions,
  ModalScrollContents,
  useSnackbar,
} from '@travelpass/design-system'
import type { GuideDraftDataOld } from '../../types'
import { useDeleteGuideDraftEventCategoryMutation } from '../../useDeleteGuideDraftEventCategoryMutation'

interface GuideDraftSectionDeleteModalProps {
  groupedEvent: GuideDraftDataOld['groupedEvents'][0]
  onDismiss: VoidFunction
}

export const GuideDraftSectionDeleteModal = ({
  groupedEvent,
  onDismiss,
}: GuideDraftSectionDeleteModalProps) => {
  const [deleteSection] = useDeleteGuideDraftEventCategoryMutation()
  const { addSuccessSnack, addErrorSnack } = useSnackbar()
  const { id, name } = groupedEvent ?? {}

  const onDelete = async () => {
    try {
      await deleteSection({
        variables: {
          deleteCategoryInput: {
            id,
          },
        },
      })
      addSuccessSnack({
        timeout: 1000,
        title: 'Section deleted',
      })
      onDismiss()
    } catch (error) {
      console.error(error)
      addErrorSnack({
        timeout: 1000,
        title: 'Server error',
      })
    }
  }

  return (
    <Modal
      size='small'
      title={`Remove '${name}' Section?`}
      onDismiss={onDismiss}
    >
      <ModalScrollContents>
        <h3 className='type-body-2'>
          {`Are you sure you want to remove the '${name}' section? The events within will be uncategorized.`}
        </h3>
      </ModalScrollContents>
      <ModalActions>
        <Button
          aria-label='Delete section'
          size='large'
          variant='error'
          onClick={onDelete}
        >
          Delete
        </Button>
        <Button
          aria-label='Cancel section removal'
          size='large'
          variant='outlined'
          onClick={onDismiss}
        >
          Cancel
        </Button>
      </ModalActions>
    </Modal>
  )
}
