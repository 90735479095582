import classNames from 'classnames'
import { Link } from 'react-router-dom'

const FooterPartners = ({ className }: { className?: string }) => (
  <section
    className={classNames(
      'flex flex-col items-center justify-center gap-8 md:flex-row md:justify-end',
      className
    )}
  >
    <span className='block'>
      Proud Partner of
      <span className='sr-only'>
        The Utah Jazz, Travel+Leisure, and The Salt Lake Bees.
      </span>
    </span>
    <Link to='/jazz'>
      <img
        alt='Jazz Logo Lockup'
        className='not-sr-only block h-auto w-[5rem] md:h-[1.8rem] md:w-auto'
        src='https://static.travelpass.com/assets/landing-pages/utah-jazz/jazz-note-white.webp'
      />
    </Link>
    <Link to='/salt-lake-bees'>
      <img
        alt='Bees Logo Lockup'
        className='not-sr-only block h-auto w-[5rem] md:h-[1.8rem] md:w-auto'
        src='https://static.travelpass.com/assets/brands/salt-lake-bees/logo.webp'
      />
    </Link>
  </section>
)

export default FooterPartners
