import { useEffect, useState } from 'react'
import { Divider, Icon, Link, useSnackbar } from '@travelpass/design-system'
import isEmpty from 'lodash.isempty'
import type { PublishedEvent } from 'src/__generated__/graphql'
import { useGetGuideHotelLazyQuery } from 'src/pages/guides/lists-old'
import { Hero } from 'src/pages/trips/components/TripDrawerContent/components'
import {
  useGetTripPlaceDetailsLazyQuery,
  useGetTripPlaceDetailsWebsiteLazyQuery,
} from 'src/pages/trips/hooks'
import { getEventImage } from 'src/pages/trips/utils'
import { constructAddress, generateHotelDetailsUrl } from 'src/utils'
import { GuideDrawerContentDescription } from './GuideDrawerContentDescription'
import { GuideDrawerContentLoading } from './GuideDrawerContentLoading'
import type { GuideOwner } from '../../types'

interface GuideDrawerContentProps {
  owner: GuideOwner
  selectedEvent: PublishedEvent
}

export const GuideDrawerContent = ({
  owner,
  selectedEvent,
}: GuideDrawerContentProps) => {
  const [getGuideDraftHotel] = useGetGuideHotelLazyQuery()
  const [getPlaceDetails, { data, loading: isLoading }] =
    useGetTripPlaceDetailsLazyQuery()
  const [getPlaceDetailsWebsiteQuery] = useGetTripPlaceDetailsWebsiteLazyQuery()
  const { addErrorSnack } = useSnackbar()
  const [websiteText, setWebsiteText] = useState('View Website')
  const placeDetailsData = data?.getPlaceDetails ?? {}
  const {
    address: addressFirstLine,
    city,
    country,
    imageLinks,
    postalCode,
    standardHotelId,
    state,
  } = placeDetailsData ?? {}
  const { addresses, name, notes } = selectedEvent ?? {}
  const address = constructAddress({
    addressFirstLine,
    city,
    country,
    state,
    zipcode: postalCode,
  })
  const googlePlaceId = addresses?.[0]?.googlePlaceId
  const images =
    imageLinks?.slice(0, 5)?.map(image => getEventImage(image)) ?? []

  useEffect(() => {
    if (!googlePlaceId) return

    try {
      getPlaceDetails({
        variables: {
          includeImageLinks: true,
          placeDetailsRequest: {
            isHotelSearch: true,
            placeId: googlePlaceId,
          },
        },
      })
      setWebsiteText('View Website')
    } catch (error) {
      console.error(error)
    }
  }, [googlePlaceId])

  const onWebsiteClick = async () => {
    try {
      let website = ''

      if (standardHotelId) {
        const response = await getGuideDraftHotel({
          variables: {
            hotelId: standardHotelId.toString(),
          },
        })
        const { id, city, name, state, stateCode } =
          response?.data?.standardHotel ?? {}
        website = generateHotelDetailsUrl({
          city,
          id,
          name,
          state,
          stateAbbreviation: stateCode,
        })
      } else {
        const response = await getPlaceDetailsWebsiteQuery({
          variables: {
            placeDetailsRequest: {
              placeId: googlePlaceId,
            },
          },
        })
        website = response?.data?.getPlaceDetails?.website
      }

      if (website) window.open(website)

      if (!website) setWebsiteText('No Website Found')
    } catch (error) {
      console.error(error)
      addErrorSnack({
        timeout: 1000,
        title: 'Server error',
      })
    }
  }

  if (isLoading || isEmpty(data)) return <GuideDrawerContentLoading />

  return (
    <div className='flex h-[calc(100%-48px)] flex-col'>
      <div className='p-b-8 grow space-y-8 overflow-y-auto md:space-y-12'>
        <Hero hideArrows={false} images={images} showDefaultImage={true} />
        <div className='p-x-8 space-y-8 md:space-y-10'>
          <div className='space-y-2'>
            <div className='space-y-1'>
              <h2 className='type-h6 line-clamp-1'>{name}</h2>
              <p className='color-grey-800 type-body-2 flex flex-row gap-1'>
                <span className='color-orange lg:p-t-0.25'>
                  <Icon name='placeOutline' size='small' />
                </span>
                <span>{address}</span>
              </p>
            </div>
            {websiteText && (
              <Link
                isDisabled={websiteText === 'No Website Found'}
                onClick={onWebsiteClick}
              >
                {websiteText}
              </Link>
            )}
            <Divider className='m-t-1' />
          </div>
          <GuideDrawerContentDescription notes={notes} owner={owner} />
        </div>
      </div>
    </div>
  )
}
