import { useEffect, useState } from 'react'
import { Button, IconButton } from '@travelpass/design-system'
import classNames from 'classnames'
import { isbot } from 'isbot'
import isEmpty from 'lodash.isempty'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { GuideStatus, type PublishedEvent } from 'src/__generated__/graphql'
import { Helmet } from 'src/common/components'
import { useFlag } from 'src/common/hooks'
import { guidesPath } from 'src/constants'
import {
  decodeGuideId,
  encodeGuideId,
  getGuideDetailsUrl,
  stringToBoolean,
} from 'src/utils'
import {
  GuideCreateGuideAction,
  GuideDrawer,
  GuideEmpty,
  GuideEventsOld,
  GuideHeader,
  GuideLoading,
  GuideMap,
  GuideSections,
  GuideStickyScrollUp,
  GuideStickyActionButtons,
} from './common'
import { GuideSearchParam } from './guideConstants'
import { useGetGuideQuery } from './useGetGuideQuery'
import { useGuideSessionStorageIds } from './useGuideSessionStorageIds'

export const Guide = () => {
  const isGuideUrlShortEnabled = useFlag('guideURLShort')
  const isGuidePaginationEnabled = useFlag('guidePagination')
  const isGuideUrlV2Enabled = useFlag('guideURLV2')
  const { selectedId } = useGuideSessionStorageIds()
  const navigate = useNavigate()
  const { guideId, guideName } = useParams()
  const guideIdDecoded = decodeGuideId(guideId)
  const guideIdEncoded = encodeGuideId({
    guideId: guideIdDecoded,
    isGuideDraft: false,
  })
  const {
    eventCount,
    guideData,
    guideDraftUrl,
    guideMetaData,
    hasError,
    isLoading,
    location,
    owner,
  } = useGetGuideQuery(isGuideUrlShortEnabled ? guideIdEncoded : guideId)
  const [searchParams] = useSearchParams()
  const [points, setPoints] = useState<PublishedEvent[]>()
  const mapExpanded = stringToBoolean(
    searchParams.get(GuideSearchParam.mapExpanded)
  )
  const { isUserOwner } = owner ?? {}
  const { id, name, status } = guideData ?? {}

  useEffect(() => {
    if (guideName && guideId !== guideIdDecoded) {
      navigate(
        getGuideDetailsUrl({
          id: isGuideUrlShortEnabled ? guideIdDecoded : guideId,
          isNameQueryParam: isGuideUrlV2Enabled,
          name: guideName,
        }),
        {
          replace: true,
        }
      )
    }
  }, [guideId, guideName, isGuideUrlShortEnabled, isGuideUrlV2Enabled])

  const onPointsChange = (updatedPoints: PublishedEvent[]) =>
    setPoints(updatedPoints)

  if (isLoading || isEmpty(guideData)) return <GuideLoading />

  if (hasError || status === GuideStatus.Draft) return <GuideEmpty />

  return (
    <>
      {!isEmpty(guideData) && (
        <Helmet
          canonicalUrl={`${window.location.origin}${getGuideDetailsUrl({
            id,
          })}`}
          metaDescription={guideMetaData?.metaDescription}
          metaImage={guideMetaData?.metaImage}
          pageName={guideMetaData?.pageName}
        />
      )}
      <article>
        <div
          className={classNames({
            'invisible absolute lg:visible lg:static': mapExpanded,
          })}
        >
          <GuideHeader guideData={guideData} owner={owner} />
        </div>
        <div className='lg:flex lg:flex-row'>
          <div
            className={classNames(
              'p-x-4 p-y-6 lg:max-w-195 lg:p-x-9 lg:p-y-8 lg:w-70vw lg:z-3 lg:b-r-grey-400 lg:b-r-solid lg:b-r-1 max-w-full space-y-3 bg-white',
              {
                'invisible absolute lg:visible lg:static': mapExpanded,
              }
            )}
          >
            {/** @todo replace this 'All Guides' Button with <Breadcrumbs /> from Design-System when the DS matches Figma */}
            <div className='hidden flex-row justify-between gap-3 lg:flex'>
              <div className='m-l--3.25'>
                <Button
                  startIcon='chevronLeft'
                  variant='text'
                  onClick={() => navigate(guidesPath)}
                >
                  All Guides
                </Button>
              </div>
              {isUserOwner && (
                <IconButton
                  color='forestLight'
                  icon='modeEdit'
                  outline='round'
                  size='small'
                  onClick={() => window.open(guideDraftUrl, '_blank')}
                />
              )}
            </div>
            {isGuidePaginationEnabled && (
              <GuideSections
                eventCount={eventCount}
                owner={owner}
                onPointsChange={onPointsChange}
              />
            )}
            {!isGuidePaginationEnabled && (
              <GuideEventsOld
                eventCount={eventCount}
                owner={owner}
                onPointsChange={onPointsChange}
              />
            )}
            <GuideStickyScrollUp className='lg:hidden' defaultVisibility={true}>
              <GuideStickyActionButtons
                guideName={name}
                isUserOwner={isUserOwner}
              />
            </GuideStickyScrollUp>
          </div>
          <GuideDrawer owner={owner} points={points} selectedId={selectedId} />
          {!isbot(navigator.userAgent) && (
            <div
              className={classNames(
                'lg:h-100svh h-[calc(100svh-64px)] lg:sticky lg:top-0 lg:grow',
                {
                  'lg:-m-l-119': !selectedId,
                  relative: mapExpanded,
                  'lg:w-initial invisible absolute w-full lg:visible':
                    !mapExpanded,
                }
              )}
            >
              <GuideMap
                isLoading={isLoading}
                location={location}
                owner={owner}
                points={points}
              />
            </div>
          )}
        </div>
        <div
          className={classNames({
            'invisible absolute lg:visible lg:static': mapExpanded,
          })}
        >
          <GuideCreateGuideAction />
        </div>
      </article>
    </>
  )
}
