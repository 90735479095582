import { Skeleton } from '@travelpass/design-system'

export const DashboardFeedCardLoading = () => (
  <div className='relative'>
    <div className='p-l-11 p-r-4 p-y-3 rounded-3 shadow-3 space-y-1 bg-white'>
      <div className='relative'>
        <div className='h-5 w-20'>
          <Skeleton />
        </div>
        <div className='rounded-1/2 absolute left--7 top-0 h-5 w-5 max-w-full object-cover'>
          <Skeleton variant='circular' />
        </div>
      </div>
      <div className='h-17'>
        <Skeleton />
      </div>
    </div>
  </div>
)
