import { useEffect } from 'react'
import { Icon, Tile } from '@travelpass/design-system'
import { useFormContext } from 'react-hook-form'
import type { ExtendedUpsertSearchPreferencesInput } from '../ExtendedUpsertInputType'
import { useGetAllAmenities } from '../hooks/useGetAllAmenities'

export const QuestionAmenities = () => {
  const { watch, setValue } =
    useFormContext<ExtendedUpsertSearchPreferencesInput>()

  const { data } = useGetAllAmenities()
  const amenityList = data?.getAllAmenities || []
  const selectedAmenities = watch('amenitySelections')

  const setBothAmenityLists = ({
    amenityIds,
    amenityNames,
    formContextArgs,
  }) => {
    setValue('amenitySelections', amenityIds, formContextArgs)
    setValue('amenitySelectionStrings', amenityNames, formContextArgs)
  }

  useEffect(() => {
    const initialAmenites = amenityList.filter(amenity => amenity.isSelected)

    // Is the location list not populated yet?
    if (amenityList.length > 0) {
      const currentSelections = watch('amenitySelections')
      // If the current selections are different from the initial selections, reset the form to initial
      if (
        !currentSelections ||
        JSON.stringify(initialAmenites) !== JSON.stringify(currentSelections)
      ) {
        setBothAmenityLists({
          amenityIds: initialAmenites.map(amenity => amenity.id),
          amenityNames: initialAmenites.map(amenity => amenity.displayName),
          formContextArgs: { shouldTouch: true },
        })
      }
    }
  }, [amenityList])

  const toggleAmenitySelection = amenity => {
    // Directly get the array of currently selected amenities' names
    const currentlySelectedAmenities =
      amenityList.filter(amenity => selectedAmenities.includes(amenity.id)) ||
      []

    let updatedAmenities = []

    if (currentlySelectedAmenities.some(item => item.id === amenity.id)) {
      // Remove the amenity name if it's already selected
      updatedAmenities = currentlySelectedAmenities.filter(
        item => item.id !== amenity.id
      )
    } else {
      // Add the amenity name if it's not already selected
      updatedAmenities = [...currentlySelectedAmenities, amenity]
    }

    // Update the form value with the array of amenity names
    setBothAmenityLists({
      amenityIds: updatedAmenities.map(amenity => amenity.id),
      amenityNames: updatedAmenities.map(amenity => amenity.displayName),
      formContextArgs: { shouldValidate: true, shouldDirty: true },
    })
  }

  return (
    <div className='flex flex-col'>
      <div className='type-h2 mb-4 self-start'>Hotel perks I look for:</div>
      <div className='type-h5 mb-10 self-start'>
        {`When I’m booking a stay, I can't live without... (select multiple
        options)`}
      </div>
      <div className='grid grid-cols-1 gap-4 lg:grid-cols-4'>
        {amenityList?.map((amenity, index) => (
          <Tile
            key={index}
            // Check if the amenity's name is in the currentlySelectedAmenities array
            pressed={selectedAmenities?.includes(amenity.id)}
            onClick={() => toggleAmenitySelection(amenity)}
          >
            <div className='flex flex-col items-center justify-center gap-y-2'>
              {/*@ts-ignore*/}
              <Icon name={amenity.iconName} size='large' />
              <div className='type-body1'>{amenity.displayName}</div>
            </div>
          </Tile>
        ))}
      </div>
    </div>
  )
}
