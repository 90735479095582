import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { gql } from 'src/__generated__'

const HOTEL_MAP = gql(`
  query HotelLocationStandardHotel($hotelId: ID!) {
    standardHotel(id: $hotelId, idType: STANDARD) {
      id
      city
      latitude
      longitude
      name
      pointsOfInterest {
        distance
        name
        unit
        type
        geocode
      }
      stateCode
    }
  }
`)

export const useHotelLocationStandardHotelQuery = () => {
  const { hotelId } = useParams()

  const {
    data,
    error: hasError,
    loading: isLoading,
  } = useQuery(HOTEL_MAP, {
    skip: !hotelId,
    variables: { hotelId },
  })

  const { standardHotel: hotelLocationData } = data ?? {}

  return {
    hasError,
    hotelLocationData,
    isLoading,
  }
}
