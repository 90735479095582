import { Icon, IconButton, Input } from '@travelpass/design-system'
import { useFormContext } from 'react-hook-form'
import type { ProfileFields } from '../../../../types'
import {
  getSocialPlatformIcon,
  identifySocialLink,
} from '../../../profileUtils'

const SOCIAL_PLACEHOLDER_TEXT =
  'Add a link to your FB, IG, TikTok, X, or LinkedIn'

export const SocialMediaLinks = () => {
  const {
    register,
    getValues,
    setValue,
    watch,
    clearErrors,
    setError,
    formState: { errors },
    trigger,
  } = useFormContext<ProfileFields>()
  const socialLinks =
    watch('socialLinks')?.length > 0
      ? JSON.parse(watch('socialLinks') as string)
      : []

  const onAdd = async () => {
    const socialLinkValue = getValues('socialLink')

    // Check if the socialLink field is empty
    if (!socialLinkValue) {
      // Manually set an error for the socialLink field
      setError('socialLink', {
        type: 'manual',
        message: 'A social media link is required',
      })
      return
    }

    // If not empty, trigger validation for the socialLink field
    const isValid = await trigger('socialLink')

    if (isValid) {
      // Proceed to add the link, clear the field, and clear any errors
      clearErrors('socialLink')
      let updatedSocialLinks = [
        ...socialLinks,
        {
          network: identifySocialLink(socialLinkValue),
          identifier: socialLinkValue,
        },
      ]
      setValue('socialLinks', JSON.stringify(updatedSocialLinks))
      setValue('socialLink', '')
    }
  }

  const onDelete = (linkIndex: number) => {
    let updatedSocialLinks = socialLinks.filter((_, i) => i !== linkIndex)
    setValue('socialLinks', JSON.stringify(updatedSocialLinks))
  }

  const isRepeatedLink = (value: string) => {
    return socialLinks.find(({ identifier }) => value == identifier)
  }

  return (
    <div className='space-y-2'>
      <h6 className='type-h6 c-black'>Connect with me on social media</h6>
      {socialLinks.map(({ network, identifier }, index) => {
        const platformIcon = getSocialPlatformIcon(network)
        return (
          <div
            key={identifier}
            className='flex place-content-between items-center gap-3'
          >
            <Icon name={platformIcon} />
            <Input
              fullWidth
              readOnly
              aria-label='hello'
              name='socialMediaURL'
              placeholder={SOCIAL_PLACEHOLDER_TEXT}
              value={identifier}
            />
            <IconButton
              aria-label='Remove link'
              icon='deleteOutline'
              onClick={() => onDelete(index)}
            />
          </div>
        )
      })}
      <div className='flex place-content-between items-center gap-3'>
        <div className={socialLinks?.length ? 'invisible' : 'hidden'}>
          {/* dummy icon component to easily preserve correct spacing */}
          <Icon name='addCircleOutline' />
        </div>
        <Input
          {...register('socialLink', {
            pattern: {
              value:
                /^(?:http:\/\/|https:\/\/)?(?:www\.)?([\w\d-]+\.)+\w{2,}(\/.+)?$/,
              message: 'Please enter a valid URL',
            },
            validate: {
              isUniqueLink: (value: string) =>
                value === '' ||
                !isRepeatedLink(value) ||
                'This profile link was already added.', // Check uniqueness only if filled out
              isSupportedPlatform: (value: string) =>
                value === '' ||
                !!identifySocialLink(value) ||
                'Only Facebook, Instagram, TikTok, Twitter, and LinkedIn profile links are accepted.', // Check platform support only if filled out
            },
          })}
          fullWidth
          aria-label='Add a social media link'
          autoCapitalize='off'
          autoCorrect='off'
          errorText={errors?.socialLink?.message as string}
          placeholder={SOCIAL_PLACEHOLDER_TEXT}
          spellCheck='false'
        />
        <IconButton
          aria-label='Add another link'
          icon='addCircleOutline'
          onClick={onAdd}
        />
      </div>
    </div>
  )
}
