import type { ExtendedUpsertSearchPreferencesInput } from './ExtendedUpsertInputType'

export const defaultPreferences: ExtendedUpsertSearchPreferencesInput = {
  partyType: null,
  budget: null,
  locationSelections: null,
  hotelStarRating: null,
  amenitySelections: [],
  amenitySelectionStrings: [],
  brandSelections: null,
  vibeSelections: null,
  vibeSelectionStrings: [],
  experienceSelections: null,
  experienceSelectionStrings: [],
}
