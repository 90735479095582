import { SocialNetwork } from 'src/__generated__/graphql'
import { profilePath } from 'src/constants'
import type { ProfileTabs } from './EditProfile/types'

export const getSocialPlatformIcon = (platform: SocialNetwork) => {
  switch (platform) {
    case SocialNetwork.Facebook:
      return 'facebook'
    case SocialNetwork.Twitter:
      return 'x'
    case SocialNetwork.Instagram:
      return 'instagram'
    case SocialNetwork.Linkedin:
      return 'linkedIn'
    case SocialNetwork.Tiktok:
      return 'tikTok'
    default:
      'warningAmber' // We don't know what this social platform is
  }
}

export const identifySocialLink = (link: string): string | undefined => {
  const patterns: { [key in SocialNetwork]: RegExp } = {
    FACEBOOK: /(?:http:\/\/|https:\/\/)?(?:www\.)?facebook\.com(?:\/.*)?/i,
    TWITTER:
      /(?:http:\/\/|https:\/\/)?(?:www\.)?(twitter\.com|x\.com)(?:\/.*)?/i, // Adjusted for Twitter now known as X
    INSTAGRAM: /(?:http:\/\/|https:\/\/)?(?:www\.)?instagram\.com(?:\/.*)?/i,
    TIKTOK: /(?:http:\/\/|https:\/\/)?(?:www\.)?tiktok\.com(?:\/.*)?/i,
    LINKEDIN: /(?:http:\/\/|https:\/\/)?(?:www\.)?linkedin\.com(?:\/.*)?/i,
  }

  for (const platform in patterns) {
    if (patterns[platform].test(link)) return platform
  }
  return undefined // Link does not match any of the specified platforms
}

export const validateYoutubeLink = (value: string) => {
  // it validates the following youtube video links.
  /**
   * Normal Url: https://www.youtube.com/watch?v=12345678901
   * Share Url: https://youtu.be/12345678901
   * Share Url with start time: https://youtu.be/12345678901?t=6
   * Mobile browser url: https://m.youtube.com/watch?v=12345678901&list=RD12345678901&start_radio=1
   * Long url: https://www.youtube.com/watch?v=12345678901&list=RD12345678901&start_radio=1&rv=smKgVuS
   * Long url with start time: https://www.youtube.com/watch?v=12345678901&list=RD12345678901&start_radio=1&rv=12345678901&t=38
   * Youtube Shorts: https://youtube.com/shorts/12345678901
   * youtue embed iframe link: <iframe width="560" height="315" src="https://www.youtube.com/embed/sJplQSfniMw?si=-KgcqOCag8JOxVq7" title=...... </iframe>
   */
  return /(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|shorts\/|watch\?.+&v=))((\w|-){11})(?:\S+)?/.test(
    value.trim()
  )
}

export const getProfilePageUrl = (accountHandle?: string, tab?: ProfileTabs) =>
  accountHandle
    ? `${profilePath}/${accountHandle}${tab != undefined ? `?tab=${tab}` : ''}`
    : profilePath

const createImage = (url: string): Promise<CanvasImageSource> =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', error => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
  })

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
export async function getCroppedImg(
  imageSrc,
  pixelCrop,
  flip = { horizontal: false, vertical: false }
) {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  if (!ctx) return null

  // Set dimensions of the canvas to the dimensions of the cropping area
  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height

  ctx.save() // Save the current state
  ctx.beginPath() // Start a new path
  ctx.arc(canvas.width / 2, canvas.height / 2, canvas.width / 2, 0, 2 * Math.PI) // Draw a circle
  ctx.clip() // Clip to the current path

  // Adjust for flipping
  if (flip.horizontal || flip.vertical) {
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
    ctx.translate(
      flip.horizontal ? -canvas.width : 0,
      flip.vertical ? -canvas.height : 0
    )
  }

  // Draw the image - ensuring it is centered in the cropped area
  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    canvas.width,
    canvas.height
  )

  ctx.restore() // Restore the original state (without the clip)

  return new Promise(resolve => {
    canvas.toBlob(file => resolve(URL.createObjectURL(file)), 'image/jpeg')
  })
}

export const convertUrlToFile = async (
  imageUrl: string,
  fileName = 'croppedImage.jpeg'
) => {
  try {
    // Fetch the blob from the URL
    const response = await fetch(imageUrl)
    if (!response.ok) throw new Error('Network response was not ok')
    const blob = await response.blob()

    // Create a File object from the blob
    const file = new File([blob], fileName, {
      type: blob.type,
      lastModified: new Date().getTime(),
    })

    return file
  } catch (error) {
    console.error('Error converting URL to file:', error)
    return null
  }
}
