import { useEffect, useState } from 'react'
import { AddToCollectionsModal } from './AddToCollectionsModal'
import {
  TOGGLE_ADD_TO_COLLECTIONS_MODAL,
  dispatchToggleAddToCollectionsModal,
} from './dispatchToggleAddToCollectionsModal'

const AddToCollectionsModalListener = () => {
  const [item, setItem] = useState<AddToItem>()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  useEffect(() => {
    const listen = (event: CustomEvent) => {
      setIsModalOpen(event?.detail?.isModalOpen)
      setItem(event?.detail?.item)
    }

    window?.addEventListener(TOGGLE_ADD_TO_COLLECTIONS_MODAL, listen)
    window?.addEventListener('keydown', e => {
      if (e.key === 'Escape') {
        dispatchToggleAddToCollectionsModal({
          isModalOpen: false,
          item: null,
        })
      }
    })
    return () => {
      window?.removeEventListener(TOGGLE_ADD_TO_COLLECTIONS_MODAL, listen)
      window?.removeEventListener('keydown', e => {
        if (e.key === 'Escape') {
          dispatchToggleAddToCollectionsModal({
            isModalOpen: false,
            item: null,
          })
        }
      })
    }
  }, [])

  if (!isModalOpen) return null

  return (
    <AddToCollectionsModal
      item={item}
      onClose={() =>
        dispatchToggleAddToCollectionsModal({ isModalOpen: false, item: null })
      }
    />
  )
}

export { AddToCollectionsModalListener }
