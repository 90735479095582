import { Skeleton } from '@travelpass/design-system'

export const DashboardAccountOldLoading = () => (
  <div>
    <div className='m-b-3 flex flex-row items-center justify-between md:hidden'>
      <div className='h-4.5 w-15'>
        <Skeleton />
      </div>
      <div className='h-12 w-12'>
        <Skeleton variant='rounded' />
      </div>
    </div>
    <div className='space-y-3 md:space-y-9'>
      <div className='flex min-w-0 flex-row items-center gap-x-3'>
        <div className='h-12 w-12'>
          <Skeleton variant='circular' />
        </div>
        <div className='h-4.5 w-15 lg:h-5'>
          <Skeleton />
        </div>
        <div className='lg:h-4.5 h-4 w-10'>
          <Skeleton />
        </div>
      </div>
      <div className='max-w-79 space-y-1'>
        <div className='h-9'>
          <Skeleton variant='rounded' />
        </div>
      </div>
    </div>
  </div>
)
