import { Icon } from '@travelpass/design-system'
import type { PublishedEvent } from 'src/__generated__/graphql'
import type { GuideOwner } from '../../types'
import { GuideImage } from '../GuideImage'

interface GuideDrawerContentDescriptionProps {
  notes: PublishedEvent['notes']
  owner: GuideOwner
}

export const GuideDrawerContentDescription = ({
  notes,
  owner,
}: GuideDrawerContentDescriptionProps) => {
  const { image, name } = owner ?? {}

  return (
    !!notes && (
      <div className='bg-warm-grey color-grey-800 p-x-8 p-y-3 rounded-2 space-y-4'>
        <div className='c-forest-light flex flex-row items-center gap-1'>
          <Icon name='stickyNote2' size='large' />
          <h3 className='c-forest-light type-body-1-medium'>Notes</h3>
        </div>
        <div className='space-y-2'>
          <p className='type-body-2'>{notes}</p>
          <div className='flex flex-row items-center gap-2'>
            <div className='b-0 of-hidden rounded-1/2 transition-opacity-200 h-6 min-h-6 w-5 min-w-6 cursor-pointer space-x-2 bg-transparent p-0 ease-linear hover:opacity-50 active:opacity-50'>
              <GuideImage
                alt={`Profile image for ${name}`}
                className='shadow-1'
                src={image}
              />
            </div>
            <p className='c-grey-900 type-subtext'>By {name}</p>
          </div>
        </div>
      </div>
    )
  )
}
